<template>
    <div class="action_Jump">
        <div class="goto-list">
            <div
                class="jump-cell"
            >
                <div class="jump-cell-l">
                    <div class="left-select">
                        <el-select
                            style="width: 120px"
                            v-model="unit.content.scope"
                            size="small"
                            @change="jumpTypeValueChange"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in filterJumpTypeOptions(
                                    jumpTypeOptions
                                )"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div class="right-select">
                        <SelectAddnode
                            v-if="unit.content.scope === 'NODE'"
                            :nodeOptions="nodeOptions"
                            :nodeId="unit.content.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionJumpAddnode_${com_index}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeId"
                            :index="0"
                        ></SelectAddnode>
                        <el-cascader
                            v-show="unit.content.scope === 'INTENT'"
                            v-model="unit.content.value"
                            placeholder="请选择意图"
                            size="small"
                            :options="intentTree4Radio"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddnode from "./../cell/SelectAddnode.vue";
export default {
    components: { SelectAddnode },
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "type",
        "isStartOrEnd",
        "intentId",
        "nodeId",
        "com_index",
    ],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
        };
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            this.$set(this.unit.content, "value", nodeId);
        },
        filterJumpTypeOptions(value) {
            if (this.isStartOrEnd) {
                return [
                    {
                        value: "INTENT",
                        label: "跳转到意图",
                    },
                ];
            } else {
                return value;
            }
        },
        jumpTypeValueChange() {
            this.unit.content.value = "";
        },
    },
};
</script>
<style lang="less" scoped>
.action_Jump {
    .goto-list {
        margin-top: 8px;
        .jump-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 4px;
            .jump-cell-l {
                flex: auto;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                /deep/.inline-input {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-cascader {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-input__inner {
                    border: none;
                    padding-right: 0;
                }
                /deep/.el-input__suffix {
                    display: none;
                }
            }
            .left-select {
                flex: none;
                width: 120px;
                .el-select {
                    width: 100%;
                }
            }
            .right-select {
                margin-left: 16px;
                flex: auto;
                &.el-select {
                    width: 100%;
                }
                &.el-cascader {
                    width: 100%;
                }
            }
            .el-icon-plus-box {
                color: white;
                width: 29px;
                height: 29px;
                line-height: 29px;
                background: #366aff;
                border-radius: 5px;
                margin-left: 14px;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
            .el-icon-delete-box {
                margin-left: 14px;
                width: 27px;
                height: 27px;
                line-height: 27px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                color: #366aff;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
        }
    }
}
</style>