<template>
  <el-dialog
    v-loading="loading"
    class="note-details"
    width="850px"
    @close="close"
    :close-on-click-modal="false"
    :visible.sync="show"
    title="训练详情"
    top="4vh"
  >
    <ul class="info-list">
        <li>机器人：{{noteInfo.botName}}</li>
        <li>版本号：{{noteInfo.botVersion}}</li>
    </ul>
    <ul class="info-list">
        <li>训练时间：{{noteInfo.trainTime}}</li>
        <li>标注问题条数：{{noteInfo.tagCount}}</li>
    </ul>
    <el-input
        v-model="keyword"
        class="search-q"
        size="small"
        placeholder="搜索问题"
        @input="emptySearch"
        @keyup.enter.native="getQuestion('search')"
    >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="getQuestion('search')"></i>
    </el-input>
    <el-table
        :data="tableData"
        stripe
        style="width: 100%;height: calc(100vh - 425px);overflow-y: scroll">
        <el-table-column
            prop="question"
            label="问题"
            min-width="180">
        </el-table-column>
        <el-table-column
            prop="tagIntentName"
            label="意图"
            min-width="180">
        </el-table-column>
        <el-table-column
            prop="annotatorName"
            label="操作人"
            width="120">
        </el-table-column>
        <el-table-column
            prop="updateTimeStr"
            label="操作时间"
            width="150">
        </el-table-column>
        <el-table-column
            width="100"
            align="center"
            prop=""
            label="操作">
            <template slot-scope="scope">
                <el-popover
                    placement="top"
                    width="200"
                    v-model="scope.row.visible">
                    <p>移除问题后，该问题将会被移出到待标注问题中，移除后，需要重新训练机器人才能生效，是否移除该问题？</p>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" @click="scope.row.visible = false">取消</el-button>
                        <el-button type="primary" size="mini"  @click="deleteQuestion(scope.row)">移除</el-button>
                    </div>
                    <el-button v-if="RP_Visible('OPEN_ML_RECORD_DELETE')" slot="reference" type="text">移除</el-button>
                </el-popover>
                
            </template>
        </el-table-column>
    </el-table>
    <el-pagination
        :page-size="pageSize"
        :current-page="currentPage"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="pageChange"> 
    </el-pagination>
    <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="close">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
    props: {
        visibleDetails:{
            type: Boolean,
            default: false
        },
        rId: ''
    },
    watch: {
        visibleDetails(n, o) {
            this.show = n;
            if (n === true) this.getData();
        }
    },
    data () {
        return{
            show: false,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            keyword: '',
            noteInfo: {},
            tableData: [],
            loading: false
        }
    },
    mounted() {
        
    },
    methods: {
        close() {
            this.show = false;
            this.$emit('close');
        },
        // 移除问题标注记录
        deleteQuestion(row) {
            this.FetchPost(this.requestUrl.trainList.removeTag, {
                rId: this.noteInfo.id,
                tId: row.id
            }).then(res => {
                if (res.code === "0") {
                    this.getQuestion();
                    this.getData();
                } else {
                    this.$message.error(res.message);
                }
                row.visible = false;
            });
        },
        // 获取训练记录下的问题
        getQuestion(type) {
            if (type){
                this.currentPage = 1
            }
            this.FetchPost(this.requestUrl.trainList.search, {
                botId: this.noteInfo.botId,
                botVersion: this.noteInfo.botVersion,
                keyword: this.keyword.trim(),
                // status: this.noteInfo.status,
                // orderType: 0,
                page: this.currentPage,
                size: this.pageSize
            }).then(res => {
                if (res.code === "0") {
                    res.data.list.forEach(v => {
                        v.visible = false;
                        if (v.tagTime != null || v.tagTime != undefined) {
                            v.updateTimeStr = new Date(v.tagTime).Format("yyyy-MM-dd hh:mm:ss");
                        } else {
                            v.updateTimeStr = "";
                        }
                        if (v.annotatorInfo != null) {
                            v.annotatorName = v.annotatorInfo.username;
                        } else {
                            v.annotatorName = "";
                        }
                    });
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        // 获取训练记录信息
        getData() {
            this.loading = true;
            this.FetchGet(this.requestUrl.trainList.trainDetails + '/' + this.rId).then(res => {
                if (res.code === "0") {
                    this.noteInfo = res.data;
                    this.noteInfo.trainTime = new Date(res.data.trainTime).Format("yyyy-MM-dd hh:mm:ss");
                    this.getQuestion();
                } else {
                    this.loading = false;
                    this.$message.error(res.message);
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        emptySearch() {
            if(this.keyword === ''){
                this.getQuestion('search');
            }
        },
        pageChange(curr) {
            this.currentPage = curr;
            this.getData();
        }
    }
}
</script>

<style lang="less" scoped>
    .note-details{
        text-align: left;
        .info-list{
            margin-left: 10px;
            display: flex;
            li{
                flex: 1;
                line-height: 32px;
                // text-align: center;
            }
        }
        .el-icon-search{
            cursor: pointer;
        }
        .search-q{
            width: 260px;
            margin-top: 20px;
            margin-bottom: 10px;
            margin-left: 10px;
        }
        .el-dialog__header{
            background: #366AFF;
            padding: 15px 20px;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
        }
        .el-dialog__title, .el-dialog__close, .el-dialog__close:hover{
            color: #fff;
        }
        .el-dialog__close{
            top: 18px;
        }
        .el-dialog__body{
            padding: 20px 0 0 0!important;
        }
        .el-table td, .el-table th{
            padding: 6px 0;
        }
        .el-table th .cell{
            line-height: 32px;
            color: #333333;
        }
        .el-table th{
            background: #F2F6FC;
            border-top: 1px solid #EBEEF5;
        }
        .el-pagination{
            margin-bottom: 0;
        }
    }
</style>