<template>
    <div class="edit-i-g">
        <div class="edit-ig-cell">
            <div class="edit-ig-des-title">
                <span class="edit-ig-des-title-pre">描述</span>
                <div class="gr-evaluate-table-switch">
                    <el-switch
                        @change="changDescriptionEnable"
                        :width="42"
                        v-model="unit.content.descriptionVisible"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    >
                        >
                    </el-switch>
                    <span
                        class="switch-open-icon"
                        @click="changDescriptionEnable(false)"
                        v-if="unit.content.descriptionVisible"
                        ><i class="iconfont guoran-a-16-17"></i
                    ></span>
                    <span
                        class="switch-close-icon"
                        v-if="!unit.content.descriptionVisible"
                        @click="changDescriptionEnable(true)"
                        ><i class="arsenal_icon arsenalcuo1"></i
                    ></span>
                </div>
            </div>
            <div class="edit-ig-des-content">
                <el-input
                    v-model="unit.content.description"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </div>
        </div>
        <div class="edit-ig-cell">
            <div class="edit-ig-des-title">
                <span class="edit-ig-des-title-pre">分类</span>
                <div class="gr-evaluate-table-switch">
                    <el-switch
                        @change="changTypesEnable"
                        :width="42"
                        v-model="unit.content.typesVisible"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    >
                        >
                    </el-switch>
                    <span
                        class="switch-open-icon"
                        @click="changTypesEnable(false)"
                        v-if="unit.content.typesVisible"
                        ><i class="iconfont guoran-a-16-17"></i
                    ></span>
                    <span
                        class="switch-close-icon"
                        v-if="!unit.content.typesVisible"
                        @click="changTypesEnable(true)"
                        ><i class="arsenal_icon arsenalcuo1"></i
                    ></span>
                </div>
                <span class="edit-ig-des-title-after"
                    >分类最多支持设置二级分类</span
                >
            </div>
            <div class="edit-ig-types-content">
                <div class="eitc-input-box">
                    <div class="eitc-input-cell">
                        <div
                            v-for="(typeCell, typeCellIndex) in unit.content
                                .options"
                            :key="`tc_${typeCellIndex}`"
                            class="eitc-input-cell-f-i-outer"
                        >
                            <!-- 一级分类 -->
                            <div class="eitc-input-cell-f-i">
                                <span
                                    v-show="unit.content.options.length > 1"
                                    class="
                                        first-sort-handle
                                        eitc-input-cell-f-i-pre
                                        iconfont
                                        guoran-tongyichicun-16-10-paixu
                                    "
                                ></span>
                                <span
                                    v-show="unit.content.options.length <= 1"
                                    class="
                                        first-sort-handle
                                        eitc-input-cell-f-i-pre
                                    "
                                ></span>
                                <div class="el-input-cell-outer">
                                    <el-input
                                        v-model="typeCell.name"
                                        placeholder="请输入"
                                        maxlength="30"
                                        size="small"
                                    ></el-input>
                                    <div class="el-input-cell-f-handle-box">
                                        <el-tooltip class="item" effect="dark" content="新增二级分类" placement="top">
                                            <span
                                                @click="
                                                    addSecondTypeFt(typeCellIndex)
                                                "
                                                class="el-input-cell-f-handle"
                                                ><i
                                                    class="
                                                        iconfont
                                                        guoran-tongyichicun-tianjiaxiaji
                                                    "
                                                ></i
                                            ></span>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="新增一级分类" placement="top">
                                            <span
                                                @click="addFirstType(typeCellIndex)"
                                                class="el-input-cell-f-handle"
                                                ><i
                                                    class="
                                                        iconfont
                                                        guoran-tongyichicun-tianjiatongji
                                                    "
                                                ></i
                                            ></span>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <span
                                        v-show="unit.content.options.length > 1"
                                        @click="deleteFirstType(typeCellIndex)"
                                        class="
                                            eitc-input-cell-f-i-aft
                                            iconfont
                                            guoran-tongyichicun-16-09-shanchu2
                                        "
                                    ></span>
                                </el-tooltip>
                                
                                <span
                                    v-show="unit.content.options.length <= 1"
                                    class="eitc-input-cell-f-i-aft"
                                ></span>
                            </div>
                            <!-- 二级分类 -->
                            <div
                                :class="[
                                    'eitc-input-cell-s-i-outer',
                                    'eitc-input-cell-s-i-outer-' +
                                        typeCellIndex,
                                ]"
                            >
                                <div
                                    v-for="(
                                        sTypeCell, sTypeCellIndex
                                    ) in typeCell.types"
                                    :key="`stc_${sTypeCellIndex}`"
                                    class="eitc-input-cell-s-i"
                                >
                                    <span class="eics-left-line"
                                        ><span class="eics-left-line-top"></span
                                        ><span
                                            v-if="
                                                sTypeCellIndex !==
                                                typeCell.types.length - 1
                                            "
                                            class="eics-left-line-bottom"
                                        ></span
                                    ></span>
                                    <span class="eics-mid-line"
                                        ><span class="eics-mid-line-left"></span
                                        ><span
                                            class="eics-mid-line-right"
                                        ></span
                                        ><span
                                            @mouseenter="
                                                sTyperowDrop(
                                                    $event,
                                                    typeCellIndex
                                                )
                                            "
                                            class="
                                                eitc-input-cell-s-move
                                                last-sort-handle
                                            "
                                        >
                                            <i
                                                class="
                                                    iconfont
                                                    guoran-tongyichicun-16-10-paixu
                                                "
                                            ></i
                                        ></span>
                                    </span>
                                    <div class="el-input-cell-outer">
                                        <el-input
                                            v-model="sTypeCell.name"
                                            placeholder="请输入"
                                            size="small"
                                            maxlength="30"
                                        ></el-input>
                                        <el-tooltip class="item" effect="dark" content="新增二级分类" placement="top">
                                            <div
                                                @click="
                                                    addSecondTypeSd(
                                                        typeCellIndex,
                                                        sTypeCellIndex
                                                    )
                                                "
                                                class="el-input-cell-f-handle-box"
                                            >
                                                <span class="el-input-cell-f-handle"
                                                    ><i
                                                        class="
                                                            iconfont
                                                            guoran-tongyichicun-tianjiatongji
                                                        "
                                                    ></i
                                                ></span>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                        <span
                                            @click="
                                                deleteSecondType(
                                                    typeCellIndex,
                                                    sTypeCellIndex
                                                )
                                            "
                                            class="
                                                eitc-input-cell-s-i-aft
                                                iconfont
                                                guoran-tongyichicun-16-09-shanchu2
                                            "
                                        ></span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-show="
                    !(
                        filterNameEmpty(unit.content.options).length === 0 &&
                        filterNameEmpty(
                            unit.content.options[activeFirstTypeIndex].types
                        ).length === 0
                    )
                "
                class="eitc-input-box-view"
            >
                <!-- 一级分类 -->
                <div class="ig-types-f">
                    <span
                        v-for="(fType, fTypeIndex) in filterNameEmpty(
                            unit.content.options
                        )"
                        @click="switchFirstType(fTypeIndex)"
                        :key="`f_${fTypeIndex}`"
                        :class="[
                            'ig-types-f-cell',
                            activeFirstTypeIndex === fTypeIndex
                                ? 'ig-types-f-cell-active'
                                : '',
                        ]"
                        >{{ fType.name }}</span
                    >
                </div>
                <!-- 二级分类 -->
                <div class="ig-types-s">
                    <span
                        v-for="(sType, sTypeIndex) in filterNameEmpty(
                            unit.content.options[activeFirstTypeIndex].types
                        )"
                        @click="switchSecondType(sTypeIndex)"
                        :key="`s_${sTypeIndex}`"
                        :class="[
                            'ig-types-s-cell',
                            activeSecondTypeIndex === sTypeIndex
                                ? 'ig-types-s-cell-active'
                                : '',
                        ]"
                        >{{ sType.name }}</span
                    >
                </div>
                <div
                    :style="{
                        'border-top':
                            unit.content.options[activeFirstTypeIndex].types
                                .length == 0
                                ? 'none'
                                : 'solid 2px #e0e6f7',
                    }"
                    class="recommend-type"
                >
                    <el-radio v-model="activeLastType.recommendType" :label="0"
                        >关联意图</el-radio
                    >
                    <el-radio v-model="activeLastType.recommendType" :label="1"
                        >高频意图推荐</el-radio
                    >
                    <el-radio
                        v-if="false"
                        v-model="activeLastType.recommendType"
                        :label="2"
                        >智能推荐</el-radio
                    >
                </div>
                <div
                    v-if="activeLastType.recommendType === 0"
                    class="eig-relation-intent"
                >
                    <div class="eri-title">
                        <span class="eri-title-name">问题名称</span
                        ><span class="eri-title-intent"
                            >用户点击跳转到意图</span
                        >
                    </div>
                    <div class="eri-list">
                        <div
                            v-for="(
                                eriCell, eriCellIndex
                            ) in activeLastType.recommendIntentList"
                            :key="eriCellIndex"
                            class="eri-list-cell"
                        >
                            <span
                                @mouseover="
                                    relationIntentRowDrop($event, eriCellIndex)
                                "
                                class="eri-list-cell-move"
                            >
                                <i
                                    class="
                                        iconfont
                                        guoran-tongyichicun-16-10-paixu
                                    "
                                ></i
                            ></span>
                            <div class="eri-list-cell-name">
                                <el-input
                                    size="small"
                                    v-model="eriCell.questionName"
                                    placeholder="请输入"
                                ></el-input>
                            </div>
                            <div class="eri-list-cell-intent">
                                <el-cascader
                                    v-model="eriCell.intentId"
                                    :options="intentTree4Radio"
                                    :key="`elCAS_0_${eriCellIndex}`"
                                    :show-all-levels="false"
                                    size="small"
                                    :props="{
                                        expandTrigger: 'hover',
                                        emitPath: false,
                                        label: 'name',
                                        value: 'id',
                                    }"
                                ></el-cascader>
                            </div>
                            <span
                                @click="deleteRelationIntent(eriCellIndex)"
                                class="eri-list-cell-delete"
                            >
                                <i
                                    class="
                                        iconfont
                                        guoran-tongyichicun-16-09-shanchu2
                                    "
                                ></i>
                            </span>
                        </div>
                    </div>

                    <span @click="addRelationIntent" class="eri-add">
                        <i class="el-icon-plus"></i>
                    </span>
                </div>
                <div
                    v-if="activeLastType.recommendType === 1"
                    class="eig-high-frequency"
                >
                    <div class="ehf-title">
                        <span class="ehf-title-pre">推荐范围</span>
                        <span class="ehf-title-des"
                            >在哪些意图范围中进行推荐</span
                        >
                    </div>
                    <div class="ehf-content">
                        <el-cascader
                            size="small"
                            v-model="activeLastType.highFrequency.rang"
                            :key="`elCAS_1_${activeLastType.recommendType}`"
                            :options="intentTree4RadioToST"
                            :props="recommendType1Props"
                            :collapse-tags="
                                activeLastType.highFrequency.rang.length > 5
                            "
                            :show-all-levels="false"
                            clearable
                        ></el-cascader>
                    </div>
                    <div class="edit-hf-group-content">
                        <span class="span-text">最多推荐</span>
                        <el-input-number
                            style="width: 80px"
                            :controls="false"
                            size="small"
                            min="1"
                            max="1000"
                            v-model="activeLastType.highFrequency.number"
                            :step="1"
                        ></el-input-number>
                        <span class="span-text span-text-pre">个问题</span>
                    </div>
                </div>
                <div
                    v-if="activeLastType.recommendType === 2"
                    class="eig-intelligence-recommend"
                >
                    <div class="eir-steag">
                        <span class="eir-title-pre">推荐策略</span>
                        <div class="eir-steag-check">
                            <el-checkbox-group
                                v-model="
                                    activeLastType.intelligenceRecommend
                                        .recommendStrategy
                                "
                            >
                                <el-checkbox :label="0"
                                    >根据与当前用户咨询过相同意图的用户所问的意图进行推荐</el-checkbox
                                >
                                <el-checkbox :label="1"
                                    >根据当前用户部门的其他用户咨询过的意图进行推荐</el-checkbox
                                >
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="ehf-title">
                        <span class="ehf-title-pre">推荐范围</span>
                        <span class="ehf-title-des"
                            >在哪些意图范围中进行推荐</span
                        >
                    </div>
                    <div class="ehf-content">
                        <el-cascader
                            size="small"
                            v-model="activeLastType.intelligenceRecommend.rang"
                            :options="intentTree4RadioToST"
                            :key="`elCAS_1_${activeLastType.recommendType}`"
                            :collapse-tags="true"
                            :props="recommendType1Props"
                            clearable
                        ></el-cascader>
                    </div>
                    <div class="edit-hf-group-content">
                        <span class="span-text">最多推荐</span>
                        <el-input-number
                            style="width: 50px"
                            :controls="false"
                            size="small"
                            v-model="
                                activeLastType.intelligenceRecommend.number
                            "
                            :step="1"
                        ></el-input-number>
                        <span class="span-text span-text-pre">个问题</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="edit-ig-cell edit-ig-cell-group">
            <div class="edit-ig-des-title">
                <span class="edit-ig-des-title-pre w-60">分组展示</span>
                <div class="gr-evaluate-table-switch">
                    <el-switch
                        @change="changGroupVisibleEnable"
                        :width="42"
                        v-model="unit.content.groupVisible"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                    >
                        >
                    </el-switch>
                    <span
                        class="switch-open-icon"
                        @click="changGroupVisibleEnable(false)"
                        v-if="unit.content.groupVisible"
                        ><i class="iconfont guoran-a-16-17"></i
                    ></span>
                    <span
                        class="switch-close-icon"
                        v-if="!unit.content.groupVisible"
                        @click="changGroupVisibleEnable(true)"
                        ><i class="arsenal_icon arsenalcuo1"></i
                    ></span>
                </div>
                <span class="edit-ig-des-title-after"
                    >开启后，当超出设置的每组个数时，咨询者可点击换下一批查看下一组
                    问题</span
                >
            </div>
            <div class="edit-ig-group-content">
                <span class="span-text">每</span>
                <el-input-number
                    style="width: 80px"
                    :controls="false"
                    min="3"
                    max="100"
                    size="small"
                    v-model="unit.content.groupNumber"
                    :step="1"
                ></el-input-number>
                <span class="span-text span-text-pre">个问题一组</span>
                <span class="span-des">最少3个问题</span>
            </div>
        </div>
    </div>
</template>
<script>
import Sortable from "sortablejs";
export default {
    data() {
        return {
            unit: JSON.parse(JSON.stringify(this.unitObj)),
            recommendType1Props: {
                multiple: true,
                value: "id",
                label: "name",
                checkStrictly: false,
            },
            activeFirstTypeIndex: 0,
            activeSecondTypeIndex: 0,
            activeLastType: {
                name: "",
                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                highFrequency: {
                    // recommendType = 1
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
                intelligenceRecommend: {
                    // recommendType = 2
                    recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
            },
        };
    },
    props: ["unitObj", "intentTree4Radio"],
    filters: {},
    mounted() {
        if (this.unit.content.options[0].types.length === 0) {
            this.activeLastType = this.unit.content.options[0];
            console.log(541, this.activeLastType);
        } else {
            this.activeLastType = this.unit.content.options[0].types[0];
            console.log(544, this.activeLastType);
        }
        this.$nextTick(() => {
            this.fTyperowDrop();
            this.sTyperowDrop();
            this.relationIntentRowDrop();
        });
    },
    computed: {
        intentTree4RadioToST: function () {
            let arr = JSON.parse(JSON.stringify(this.intentTree4Radio));
            arr.forEach((skill) => {
                if (skill.children) {
                    skill.children.forEach((type) => {
                        type.children = null;
                    });
                }
            });
            return arr;
        },
    },

    methods: {
        /**
         * 一级分类列表元素拖动
         * @method fTyperowDrop
         * @param {}
         * @return {}
         */

        fTyperowDrop() {
            const box = document.querySelector(".eitc-input-cell");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".first-sort-handle",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.options.splice(oldIndex, 1);
                    _this.unit.content.options.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.unit.content.options = _this.unit.content.options;
        },
        /**
         * 二级分类列表元素拖动
         * @method sTyperowDrop
         * @param {}
         * @return {}
         */

        sTyperowDrop(e, index) {
            let className = ".eitc-input-cell-s-i-outer-" + index;
            const box = document.querySelector(className);
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".last-sort-handle",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.options[index].types.splice(
                        oldIndex,
                        1
                    );
                    _this.unit.content.options[index].types.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                    // 下一个tick就会走patch更新
                },
            });
            this.unit.content.options[index].types =
                _this.unit.content.options[index].types;
        },

        /**
         * 关联意图元素拖动
         * @method sTyperowDrop
         * @param {}
         * @return {}
         */

        relationIntentRowDrop(e, index) {
            let className = ".eri-list";
            const box = document.querySelector(className);
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".eri-list-cell-move",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeLastType.recommendIntentList.splice(
                        oldIndex,
                        1
                    );
                    _this.activeLastType.recommendIntentList.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                    // 下一个tick就会走patch更新
                },
            });
            this.activeLastType.recommendIntentList =
                _this.activeLastType.recommendIntentList;
        },
        // 编辑第一级分类不为空展示
        filterNameEmpty(list) {
            let arr = list.filter((item) => item.name != "");
            return arr;
        },
        changDescriptionEnable(val) {
            this.unit.content.descriptionVisible = val;
        },

        printUnit(compIndex, callback) {
            console.log(684, this.unit, compIndex);
            // 过滤空的分类
            let flag = true;
            this.unit.content.options.forEach((fType) => {
                if (fType.name.trim() === "") {
                    this.$message.error("一级分类名称不能为空，请检查填写完整");
                    flag = false;
                } else {
                    if (fType.recommendType === 0) {
                        let tampArr = [];
                        fType.recommendIntentList.forEach((cell) => {
                            if (cell.questionName.trim() !== "") {
                                tampArr.push(cell);
                            }
                        });
                        fType.recommendIntentList = [...tampArr];
                    }
                    if (fType.types.length !== 0) {
                        fType.types.forEach((sType) => {
                            if (sType.name.trim() === "") {
                                this.$message.error(
                                    "二级分类名称不能为空，请检查填写完整"
                                );
                                flag = false;
                            }
                            if (sType.recommendType === 0) {
                                let tampArr = [];
                                sType.recommendIntentList.forEach((cell) => {
                                    if (cell.questionName.trim() !== "") {
                                        tampArr.push(cell);
                                    }
                                });
                                sType.recommendIntentList = [...tampArr];
                            }
                        });
                    }
                }
            });
            if (flag) {
                callback(this.unit);
            }
        },

        changTypesEnable(val) {
            this.unit.content.typesVisible = val;
        },

        changGroupVisibleEnable(val) {
            this.unit.content.groupVisible = val;
        },
        // 添加一级分类
        addFirstType(index) {
            this.unit.content.options.splice(index + 1, 0, {
                name: "",
                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                highFrequency: {
                    // recommendType = 1
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
                intelligenceRecommend: {
                    // recommendType = 2
                    recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
                types: [
                    {
                        name: "",
                        recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                        recommendIntentList: [
                            { questionName: "", intentId: "" },
                        ], // recommendType = 0
                        highFrequency: {
                            // recommendType = 1
                            rang: [], // 推荐范围
                            number: 20, // 最多推荐问题数
                        },
                        intelligenceRecommend: {
                            // recommendType = 2
                            recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                            rang: [], // 推荐范围
                            number: 20, // 最多推荐问题数
                        },
                    },
                ],
            });
            if (index < this.activeFirstTypeIndex) {
                this.activeFirstTypeIndex++;
            }
        },

        // 一级分类 点击 添加二级分类
        addSecondTypeFt(index) {
            this.unit.content.options[index].types.push({
                name: "",
                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                highFrequency: {
                    // recommendType = 1
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
                intelligenceRecommend: {
                    // recommendType = 2
                    recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
            });
        },

        // 二级分类 点击 添加二级分类
        addSecondTypeSd(typeCellIndex, sTypeCellIndex) {
            this.unit.content.options[typeCellIndex].types.push({
                name: "",
                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                recommendIntentList: [{ questionName: "", intentId: "" }], // recommendType = 0
                highFrequency: {
                    // recommendType = 1
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
                intelligenceRecommend: {
                    // recommendType = 2
                    recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                    rang: [], // 推荐范围
                    number: 20, // 最多推荐问题数
                },
            });
        },
        // 删除一级分类
        deleteFirstType(index) {
            console.log(index);
            this.unit.content.options.splice(index, 1);
            console.log(this.unit.content.options);
        },
        // 删除二级分类
        deleteSecondType(typeCellIndex, sTypeCellIndex) {
            this.unit.content.options[typeCellIndex].types.splice(
                sTypeCellIndex,
                1
            );
            if (
                this.unit.content.options[typeCellIndex].types.length === 0 &&
                typeCellIndex === this.activeFirstTypeIndex
            ) {
                this.activeLastType = this.unit.content.options[typeCellIndex];
            }
        },
        // 切换一级分类
        switchFirstType(index) {
            this.activeFirstTypeIndex = index;
            this.activeSecondTypeIndex = 0;
            // 利用 js 对象浅拷贝
            if (this.unit.content.options[index].types.length == 0) {
                this.activeLastType = this.unit.content.options[index];
            } else {
                this.activeLastType = this.unit.content.options[index].types[0];
            }
        },
        // 切换二级分类
        switchSecondType(index) {
            this.activeSecondTypeIndex = index;
            this.activeLastType =
                this.unit.content.options[this.activeFirstTypeIndex].types[
                    index
                ];
        },

        // 添加关联意图
        addRelationIntent() {
            // activeFirstTypeIndex
            // activeSecondTypeIndex
            if (
                this.unit.content.options[this.activeFirstTypeIndex].types
                    .length === 0
            ) {
                this.unit.content.options[
                    this.activeFirstTypeIndex
                ].recommendIntentList.push({
                    questionName: "",
                    intentId: "",
                });
            } else {
                this.unit.content.options[this.activeFirstTypeIndex].types[
                    this.activeSecondTypeIndex
                ].recommendIntentList.push({
                    questionName: "",
                    intentId: "",
                });
            }
        },

        // 删除关联意图
        deleteRelationIntent(eriCellIndex) {
            if (
                this.unit.content.options[this.activeFirstTypeIndex].types
                    .length === 0
            ) {
                this.unit.content.options[
                    this.activeFirstTypeIndex
                ].recommendIntentList.splice(eriCellIndex, 1);
            } else {
                this.unit.content.options[this.activeFirstTypeIndex].types[
                    this.activeSecondTypeIndex
                ].recommendIntentList.splice(eriCellIndex, 1);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.edit-i-g {
    .edit-ig-cell {
        margin-bottom: 12px;
        .edit-ig-des-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 6px;
            .edit-ig-des-title-pre {
                margin-right: 8px;
            }
            .w-60 {
                width: 60px;
                text-align: left;
            }
            .edit-ig-des-title-after {
                font-size: 12px;
                font-weight: 400;
                color: #a9b3c6;
                margin-left: 8px;
                text-align: left;
            }
        }
        .edit-ig-types-content {
            margin-left: -30px;
            margin-right: -30px;
            .eitc-input-box {
                .eitc-input-cell {
                    .eitc-input-cell-f-i-outer {
                        margin-bottom: 18px;
                        .eitc-input-cell-f-i {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .eitc-input-cell-f-i-pre {
                                width: 30px;
                                color: #366aff;
                                font-size: 1;
                                opacity: 0;
                            }
                            .el-input-cell-outer {
                                flex: auto;
                                // border: 1px solid #dcdfe6;
                                height: 32px;
                                line-height: 32px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: flex-start;
                                position: relative;
                                /deep/.el-input {
                                    border: none;
                                    .el-input__inner {
                                        &:focus {
                                            border: 1px solid #366aff;
                                        }
                                    }
                                }
                                .el-input-cell-f-handle-box {
                                    position: absolute;
                                    color: #366aff;
                                    width: 50px;
                                    display: none;
                                    justify-content: space-around;
                                    right: 12px;
                                    cursor: pointer;
                                }
                            }
                            .eitc-input-cell-f-i-aft {
                                width: 30px;
                                color: #366aff;
                                font-size: 1;
                                opacity: 0;
                            }
                            &:hover {
                                .eitc-input-cell-f-i-pre {
                                    font-size: 16;
                                    opacity: 1;
                                    cursor: pointer;
                                }
                                .eitc-input-cell-f-i-aft {
                                    font-size: 16;
                                    opacity: 1;
                                    cursor: pointer;
                                }
                                .el-input-cell-f-handle-box {
                                    display: flex;
                                }
                            }
                        }

                        .eitc-input-cell-s-i {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            &:hover {
                                .eics-mid-line {
                                    .eitc-input-cell-s-move {
                                        width: 15px;
                                    }
                                    .eics-mid-line-right {
                                        width: 0;
                                    }
                                }
                                .eitc-input-cell-s-i-aft {
                                    opacity: 1;
                                }
                                .el-input-cell-outer {
                                    .el-input-cell-f-handle-box {
                                        display: flex;
                                    }
                                }
                            }
                            .eics-left-line {
                                margin-left: 50px;
                                height: 45px;
                                .eics-left-line-top {
                                    display: block;
                                    height: 28px;
                                    border-left: dashed 1px #a0a9bd;
                                    width: 1px;
                                }
                                .eics-left-line-bottom {
                                    display: block;
                                    height: 15px;
                                    border-left: dashed 1px #a0a9bd;
                                    width: 1px;
                                }
                            }
                            .eics-mid-line {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: 36px;
                                margin-top: 15px;
                                .eics-mid-line-left {
                                    border-top: dashed 1px #a0a9bd;
                                    width: 18px;
                                }
                                .eics-mid-line-right {
                                    border-top: dashed 1px #a0a9bd;
                                    width: 18px;
                                }
                                .eitc-input-cell-s-move {
                                    width: 0px;
                                    font-size: 12px;
                                    color: #366aff;
                                }
                            }
                            .el-input-cell-outer {
                                margin-top: 15px;
                                flex: auto;
                                // border: 1px solid #dcdfe6;
                                height: 32px;
                                line-height: 32px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: flex-start;
                                position: relative;
                                /deep/.el-input {
                                    border: none;
                                    .el-input__inner {
                                        &:focus {
                                            border: 1px solid #366aff;
                                        }
                                    }
                                }
                                .el-input-cell-f-handle-box {
                                    position: absolute;
                                    color: #366aff;
                                    width: 30px;
                                    display: none;
                                    justify-content: space-around;
                                    right: 12px;
                                    cursor: pointer;
                                }
                            }
                            .eitc-input-cell-s-i-aft {
                                margin-top: 15px;
                                width: 30px;
                                color: #366aff;
                                font-size: 1;
                                opacity: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .eitc-input-box-view {
            padding: 12px 0;
            background: #fbfcfd;
            .ig-types-f {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 0 8px;
                .ig-types-f-cell {
                    height: 30px;
                    line-height: 30px;
                    padding: 0 14px;
                    margin-left: 8px;
                    margin-top: 6px;
                    background: #ffffff;
                    border: 1px solid #e0e6f7;
                    border-radius: 17px;
                    color: #a3adc6;
                    cursor: pointer;
                    font-weight: 600;
                    &:hover {
                        border: 1px solid #366aff;
                        color: #366aff;
                    }
                    &-active {
                        background-color: #366aff;
                        border: 1px solid #366aff;
                        color: white;
                        &:hover {
                            color: white;
                        }
                    }
                }
            }
            .ig-types-s {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin: 8px 16px 0;
                .ig-types-s-cell {
                    height: 34px;
                    line-height: 34px;
                    margin-right: 18px;
                    border: 2px solid rgba(0, 0, 0, 0);
                    color: #a3adc6;
                    cursor: pointer;
                    font-weight: 600;
                    margin-bottom: -2px;
                    &:hover {
                        color: #366aff;
                    }
                    &-active {
                        border-bottom: 2px solid #366aff;
                        color: #366aff;
                    }
                }
            }
            .recommend-type {
                margin: 0 16px;
                padding: 18px 0 14px;
                border-top: solid 2px #e0e6f7;
                border-bottom: dashed 1px #e0e6f7;
                display: flex;
                justify-content: flex-start;
            }
            .eig-relation-intent {
                .eri-title {
                    height: 38px;
                    line-height: 38px;
                    display: flex;
                    padding: 0 40px;
                    color: #616161;
                    text-align: left;
                    .eri-title-name {
                        flex: 1;
                    }
                    .eri-title-intent {
                        margin-left: 8px;
                        flex: 1;
                    }
                }
                .eri-list-cell {
                    margin-bottom: 6px;
                    display: flex;
                    align-items: center;
                    .eri-list-cell-move {
                        flex: none;
                        width: 40px;
                        color: #366aff;
                    }
                    .eri-list-cell-name {
                        flex: 1;
                        margin-right: 8px;
                    }
                    .eri-list-cell-intent {
                        flex: 1;
                        .el-cascader {
                            width: 100%;
                        }
                    }
                    .eri-list-cell-delete {
                        flex: none;
                        width: 40px;
                        color: #366aff;
                    }
                }
                .eri-add {
                    display: block;
                    margin: 12px auto;
                    width: 31px;
                    height: 31px;
                    line-height: 33px;
                    text-align: center;
                    border: 1px solid #d5e0ff;
                    border-radius: 50%;
                    font-size: 16px;
                    i {
                        color: #366aff;
                    }
                }
            }
            .eig-high-frequency {
                padding: 0 14px;
                .ehf-content {
                    /deep/.el-cascader {
                        width: 100%;
                    }
                }
                .ehf-title {
                    display: flex;
                    justify-content: flex-start;
                    line-height: 38px;
                    height: 38px;
                    .ehf-title-pre {
                        margin-right: 8px;
                    }
                    .ehf-title-des {
                        font-size: 12px;
                        font-weight: 400;
                        color: #a9b3c6;
                    }
                }
                .edit-hf-group-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // border-top: dashed 1px #e0e6f7;
                    padding: 12px 0 4px;
                    .span-des {
                        margin-right: 8px;
                        font-size: 12px;
                        color: #a9b3c6;
                    }
                    .span-text {
                        margin-right: 8px;
                    }

                    .span-text-pre {
                        margin-left: 8px;
                    }
                }
            }
            .eig-intelligence-recommend {
                padding: 0 14px;
                .ehf-content {
                    /deep/.el-cascader {
                        width: 100%;
                    }
                }
                .eir-steag {
                    margin-bottom: 8px;
                    .eir-title-pre {
                        display: block;
                        height: 38px;
                        line-height: 38px;
                        text-align: left;
                    }
                    .eir-steag-check {
                        .el-checkbox-group {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            text-align: left;
                            /deep/.el-checkbox {
                                margin-bottom: 6px;
                                .el-checkbox__label {
                                    color: #616161;
                                }
                            }
                        }
                    }
                }
                .ehf-title {
                    display: flex;
                    justify-content: flex-start;
                    line-height: 38px;
                    height: 38px;
                    .ehf-title-pre {
                        margin-right: 8px;
                    }
                    .ehf-title-des {
                        font-size: 12px;
                        font-weight: 400;
                        color: #a9b3c6;
                    }
                }
                .edit-hf-group-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // border-top: dashed 1px #e0e6f7;
                    padding: 12px 0 4px;
                    .span-des {
                        margin-right: 8px;
                        font-size: 12px;
                        color: #a9b3c6;
                    }
                    .span-text {
                        margin-right: 8px;
                    }

                    .span-text-pre {
                        margin-left: 8px;
                    }
                }
            }
        }
        .edit-ig-group-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-top: dashed 1px #e0e6f7;
            padding: 12px 0 4px;
            .span-des {
                margin-right: 8px;
                font-size: 12px;
                color: #a9b3c6;
            }
            .span-text {
                margin-right: 8px;
            }

            .span-text-pre {
                margin-left: 8px;
            }
        }
    }

    .edit-ig-cell-group {
        border-radius: 5px;
        padding: 12px;
        background: #fbfcfd;
    }
}
</style>