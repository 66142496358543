<template>
    <div
        id="trainList"
        v-loading="loading"
        element-loading-text="模型训练中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(20, 20, 20, 0.8)"
    >
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenalstudy1"></i>
                <span class="page-name">{{ activePageInfo.headerName }}</span>
                
            </div>
            <div slot="top-header-right" class="top-header-right">
                <div class="right-title" >
                    <el-button
                        v-if="
                            activeTab === 'question' && RP_Visible('OPEN_ML_TRAIN')
                        "
                        :disabled="Boolean(tagCount < 1 && trainStatus)"
                        class="train-bot"
                        type="primary"
                        size="small"
                        @click="trainBot"
                        >重新训练机器人</el-button>
                </div>
                <div class="right-title" >
                    <p
                        style="flex: 1"
                        v-if="
                            activeTab === 'question' || activeTab === 'feedbackNote'
                        "
                    ></p>
                </div>
                <div v-if="activeTab === 'note'" class="right-titl dateSelection-t">
                    <span
                        :class="currentDate == 'yesterday' ? 'isActive' : ''"
                        @click="yesterdayData"
                        >昨日</span
                    >
                    <span
                        :class="currentDate == 'today' ? 'isActive' : ''"
                        @click="todayData"
                        >今日</span
                    >
                    <span
                        :class="currentDate == 'lastWeek' ? 'isActive' : ''"
                        @click="lastWeekData"
                        >近7日</span
                    >
                    <span
                        :class="currentDate == 'lastMonth' ? 'isActive' : ''"
                        @click="lastMonthData"
                        >近30日</span
                    >
                    <span
                        :class="currentDate == 'all' ? 'isActive' : ''"
                        @click="allData"
                        >上线至今</span
                    >
                    <el-date-picker
                        v-model="dateSeleteData.value"
                        @change="datePickerChange"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </div>
                <el-select
                    v-if="activeTab === 'note'"
                    v-model="noteCurrentBot"
                    style="width: 140px;"
                    size="small"
                    @change="changeNoteBot"
                >
                    <el-option
                        v-for="item in botList"
                        :key="item.name"
                        :value="item.id"
                        :label="item.name"
                    />
                </el-select>
                <div class="right-title" v-if="activeTab === 'question'">
                    <el-select
                        v-model="currentBot"
                        size="small"
                        style="width: 150px;"
                        @change="changeBot"
                    >
                        <el-option
                            v-for="item in botList"
                            :key="item.name"
                            :value="item.id"
                            :label="item.name"
                        />
                    </el-select>
                    <el-input
                        v-show="activeTab === 'question'"
                        v-model="keyword"
                        size="small"
                        placeholder="搜索问题"
                        style="width: 130px; margin: 0 6px"
                        @input.native="emptySearch"
                        @keyup.enter.native="$refs.myQuestion.getQuestion()"
                    >
                        <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="$refs.myQuestion.getQuestion()"
                        ></i>
                    </el-input>
                    <el-button @click="showAdvSearch" type="text"
                        >高级筛选</el-button
                    >
                </div>
                <div class="right-title" v-show="activeTab === 'feedbackNote'">
                    <el-select
                        @change="changeCurrentBotApikey"
                        v-model="currentBotApikey"
                        size="small"
                    >
                        <el-option
                            v-for="item in botList"
                            :key="item.apiKey"
                            :value="item.apiKey"
                            :label="item.name"
                        />
                    </el-select>
                </div>
            </div>
        </page-top-header>
        <div class="botContent">
            <el-tabs
                id="left-sub-nav"
                class="tl-el-tab"
                tab-position="left"
                v-model="activeTab"
                @tab-click="tabClick"
            >
                <el-tab-pane label="待标注问题" name="question">
                    <div
                        v-if="JSON.stringify(advSearchData) != '{}'"
                        style="height: 58px"
                        class="advSearchParams"
                    >
                        <div
                            class="tag-b"
                            v-if="advSearchData.queryTypeObj != null"
                        >
                            <el-tag
                                key="queryTypeObj"
                                size="mini"
                                @close="closeParam('queryTypeObj')"
                                closable
                            >
                                问题类型：{{ advSearchData.queryTypeObj.text }}
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.intents != null">
                            <el-tag
                                key="intents"
                                size="mini"
                                @close="closeParam('intents')"
                                closable
                            >意图：
                                {{
                                    advSearchData.intents
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/").length > 12
                                        ? advSearchData.intents
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                              .substring(0, 12) + "..."
                                        : advSearchData.intents
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                }}
                            </el-tag>
                        </div>
                        <div
                            class="tag-b"
                            v-if="advSearchData.lastMsgTime != null"
                        >
                            <el-tag
                                key="lastMsgTime"
                                size="mini"
                                @close="closeParam('lastMsgTime')"
                                closable
                            >
                                最后消息时间：{{
                                    formatDateTime(
                                        advSearchData.lastMsgTime[0]
                                    )
                                }}-{{
                                    formatDateTime(advSearchData.lastMsgTime[1])
                                }}
                            </el-tag>
                        </div>
                        <div
                            class="tag-b"
                            v-if="advSearchData.orgnation != null"
                        >
                            <el-tag
                                key="orgnation"
                                size="mini"
                                @close="closeParam('orgnation')"
                                closable
                            >
                                最后消息部门：{{
                                    advSearchData.orgnation
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/").length > 12
                                        ? advSearchData.orgnation
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                              .substring(0, 12) + "..."
                                        : advSearchData.orgnation
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                }}
                            </el-tag>
                        </div>
                        <div
                            class="tag-b"
                            v-if="advSearchData.noteUser != null"
                        >
                            <el-tag
                                key="noteUser"
                                size="mini"
                                @close="closeParam('noteUser')"
                                closable
                            >
                                操作人：{{
                                    advSearchData.noteUser
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/").length > 12
                                        ? advSearchData.noteUser
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                              .substring(0, 12) + "..."
                                        : advSearchData.noteUser
                                              .map((x) => {
                                                  return x.name;
                                              })
                                              .join("/")
                                }}
                            </el-tag>
                        </div>
                        <div
                            class="tag-b"
                            v-if="advSearchData.lastNoteTime != null"
                        >
                            <el-tag
                                key="lastNoteTime"
                                size="mini"
                                @close="closeParam('lastNoteTime')"
                                closable
                            >
                                最后标注时间：{{
                                    formatDateTime(
                                        advSearchData.lastNoteTime[0]
                                    )
                                }}-{{
                                    formatDateTime(
                                        advSearchData.lastNoteTime[1]
                                    )
                                }}
                            </el-tag>
                        </div>
                        <div
                            class="tag-b"
                            v-if="advSearchData.noteStetus != null"
                        >
                            <el-tag
                                key="noteStetus"
                                size="mini"
                                @close="closeParam('noteStetus')"
                                closable
                            >
                                是否标注过：{{ advSearchData.noteStetus.name }}
                            </el-tag>
                        </div>
                        <i @click="deleteAllAdv" class="el-icon-delete"></i>
                    </div>
                    <div
                        class="guoran-index-tab"
                    >
                    <div class="left">
                        <div
                            @click="changeQwType('mark')"
                            :class="
                                qwType == 'mark'
                                    ? 'gruoran-index-tab-cell-active'
                                    : 'gruoran-index-tab-cell'
                            "
                        >
                            <span>待标注</span>
                        </div>
                        <div
                            @click="changeQwType('ignore')"
                            :class="
                                qwType == 'ignore'
                                    ? 'gruoran-index-tab-cell-active'
                                    : 'gruoran-index-tab-cell'
                            "
                        >
                            <span>已忽略</span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="no-mark">未标注:{{OverviewData.notTagNum || 0}}</div>
                        <div class="had-mark">已标注：{{OverviewData.tagAlreadyNum || 0}}</div>
                        <div class="mark-right">标注正确：{{OverviewData.tagCorrectNum || 0}}</div>
                        <div class="mark-error">标注错误：{{OverviewData.tagErrorNum || 0}}</div>
                        <div class="unrecognized-mark">不识别标注：{{OverviewData.notRecognizeTagNum || 0}}</div>
                    </div>
                    </div>
                    <bot-question
                        :isShowSearch="JSON.stringify(advSearchData) != '{}'"
                        ref="myQuestion"
                        :topAdd="top"
                        :bot-id="currentBot"
                        :qwType="qwType"
                        :keyword="keyword"
                        @refresh="changeStatus"
                        @getOverview="getOverview"
                    />
                </el-tab-pane>
                <el-tab-pane
                    v-if="RP_Visible('OPEN_ML_RECORD_VIEW')"
                    label="训练记录"
                    name="note"
                    class="note-tab"
                >
                    <div class="statistics-box">
                        <div class="had-train item">
                            <div :class="['num',summaryTrainingRecordsData.haveBeenTrainedNum > 99 ? 'big' : '']">{{summaryTrainingRecordsData.haveBeenTrainedNum || 0}}</div>
                            <div class="text">已训练</div>
                        </div>
                        <div class="mark-right item">
                            <div :class="['num',summaryTrainingRecordsData.tagCorrectNum > 99 ? 'big' : '']">{{summaryTrainingRecordsData.tagCorrectNum || 0}}</div>
                            <div class="text">标注正确</div>
                        </div>
                        <div class="mark-error item">
                            <div :class="['num',summaryTrainingRecordsData.tagErrorNum > 99 ? 'big' : '']">{{summaryTrainingRecordsData.tagErrorNum || 0}}</div>
                            <div class="text">标注错误</div>
                        </div>
                        <div class="unrecognized-mark item">
                            <div :class="['num',summaryTrainingRecordsData.notRecognizeTagNum > 99 ? 'big' : '']">{{summaryTrainingRecordsData.notRecognizeTagNum || 0}}</div>
                            <div class="text">不识别标注</div>
                        </div>
                    </div>
                    <train-note :noteCurrentBot="noteCurrentBot" :newStartTime="newStartTime" :newEndTime="newEndTime" ref="myNote" @getSummaryTrainingRecords="getSummaryTrainingRecords"/>
                </el-tab-pane>
                <el-tab-pane label="答案反馈记录" name="feedbackNote">
                    <feedback-note
                        ref="feedbackNote"
                        :apikey="currentBotApikey"
                        :bot-id="currentBot"
                        :keyword="keyword"
                    />
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-drawer
            :withHeader="false"
            :modal="false"
            custom-class="acv-search-drawer"
            :visible.sync="advSearchdrawer"
            :direction="direction"
            :before-close="handleClose"
        >
            <div>
                <AdvSearch
                    ref="advSearch"
                    @closeAdvSerch="closeAdvSerch"
                    @submitAdvSerch="submitAdvSerch"
                    :apikey="currentBotApikey"
                    :botId="currentBot"
                    :keyword="keyword"
                />
            </div>
        </el-drawer>
    </div>
</template>
<script>
import pageTopHeader from '../../components/page-top-header.vue'
import TrainNote from "./note.vue";
import BotQuestion from "./question.vue";
import feedbackNote from "./feedbackNote.vue";
import AdvSearch from "./component/AdvSearch.vue";
export default {
    data() {
        return {
            dateSeleteData: [{ value: "" }],
            currentDate: "",
            newStartTime: "",
            newEndTime: "",
            noteCurrentBot: "",
            top: 0,
            advSearchdrawer: false,
            advSearchData: {},
            direction: "ttb",
            qwType: "mark",
            botList: [],
            currentBot: "",
            currentBotApikey: "", // 答案反馈记录 当前机器人
            keyword: "",
            activePageInfo: {
                // 当前页跳转信息
                name: "返回全部实体",
                // gotoPageName: "botIndex",
                // botId: 1,
                headerName: "机器人训练",
                entityName: "",
            },
            activeTab: "question",
            tagCount: 0,
            trainStatus: true,
            loading: false,
            OverviewData:{
                "notTagNum": 0,          // 待标注问题数
                "tagAlreadyNum": 0,       // 已标注问题数
                "tagCorrectNum": 0,       // 标注正确问题数
                "tagErrorNum": 0,         // 标注错误问题数
                "notRecognizeTagNum": 0   // 不识别标注
            },
            summaryTrainingRecordsData:{
                "haveBeenTrainedNum": 0,   // 已训练数
                "tagCorrectNum": 0,     // 标注正确
                "tagErrorNum": 0,     // 标注错误
                "notRecognizeTagNum": 0  // 不识别标志
            }
        };
    },
    components: {
        TrainNote,
        BotQuestion,
        feedbackNote,
        AdvSearch,
        pageTopHeader
    },
    beforeDestroy() {
        this.FetchGet(
            this.requestUrl.trainList.noteCount + this.currentBot
        ).then((res) => {
            if (res.code === "0") {
                if (res.data > 0) {
                    this.$notify({
                        duration: 5000,
                        dangerouslyUseHTMLString: true,
                        message: `<p>您当前标注了<span style="color:#f56c6c">${res.data}</span>条问题，需要对机器人进行训练才能提高机器人的回复能力哦~</p>
                            <p style="text-align: right;margin-top:10px;cursor:pointer;color:#f56c6c" id="goToTrain">去训练</p>
                        `,
                    });
                    document.getElementById("goToTrain").onclick = () => {
                        this.$router.push("/main/trainList");
                    };
                }
            } else {
                this.$message.error(res.message);
            }
        });
    },
    mounted() {
        if (this.$route.query.type != undefined) {
            this.activeTab = this.$route.query.type;
        }
        let activeId = this.$route.query.BotId ? this.$route.query.BotId : "";
        let botApiKey = this.$route.query.scope ? this.$route.query.scope : "";
        this.getBotList(activeId, botApiKey);
    },
    methods: {
        // 获取待标注问题的统计数量
        getOverview(searchParams){
            this.FetchPost(this.requestUrl.trainList.getOverview, searchParams).then((res) => {
                if (res.code === "0") {
                    this.OverviewData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
          // 获取=训练记录的统计数量
        getSummaryTrainingRecords(searchParams){
            this.FetchPost(this.requestUrl.trainList.summaryTrainingRecords, searchParams).then((res) => {
                if (res.code === "0") {
                    this.summaryTrainingRecordsData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        todayData() {
            this.currentDate = "today";
            let endTime = new Date().getTime();
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let todayStartTime = yy + "-" + mm + "-" + dd + " " + "0:0:0";
            let todayStartTimeDate = new Date(
                Date.parse(todayStartTime.replace(/-/g, "/"))
            );
            let startTime = todayStartTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        yesterdayData() {
            this.currentDate = "yesterday";
            var time = new Date().getTime() - 24 * 60 * 60 * 1000;
            var yesday = new Date(time); // 获取的是前一天日期
            yesday =
                yesday.getFullYear() +
                "-" +
                (yesday.getMonth() > 9
                    ? yesday.getMonth() + 1
                    : yesday.getMonth() + 1) +
                "-" +
                yesday.getDate();

            let yesterdayStartTime = yesday + " " + "0:0:0";
            let yesterdayEndTime = yesday + " " + "23:59:59";
            let yesterdayStartTimeDate = new Date(
                Date.parse(yesterdayStartTime.replace(/-/g, "/"))
            );
            let yesterdayEndTimeDate = new Date(
                Date.parse(yesterdayEndTime.replace(/-/g, "/"))
            );
            let startTime = yesterdayStartTimeDate.getTime();
            let endTime = yesterdayEndTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        lastWeekData() {
            this.currentDate = "lastWeek";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-6);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        lastMonthData() {
            this.currentDate = "lastMonth";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-29);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        allData(){
            this.currentDate = "all";
            this.newStartTime = null;
            this.newEndTime = null;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: null,
                endTrainTime: null,
            });
            this.dateSeleteData = [];
        },
        datePickerChange() {
            if (this.dateSeleteData.value) {
                this.currentDate = "diy";
                let d = this.dateSeleteData.value[0];
                let startTimeDated =
                    d.getFullYear() +
                    "-" +
                    (d.getMonth() + 1) +
                    "-" +
                    d.getDate() +
                    " " +
                    d.getHours() +
                    ":" +
                    d.getMinutes() +
                    ":" +
                    d.getSeconds();
                let startTimeDate = new Date(
                    Date.parse(startTimeDated.replace(/-/g, "/"))
                );
                let startTime = startTimeDate.getTime();
                let end = this.dateSeleteData.value[1];
                let e = new Date(end);
                let endday =
                    e.getFullYear() +
                    "-" +
                    (e.getMonth() + 1) +
                    "-" +
                    e.getDate();
                let timestamp = new Date().getTime();
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let today = yy + "-" + mm + "-" + dd;
                let todaystamp = Date.parse(today);
                let enddaystamp = Date.parse(endday);
                let endTime = "";
                if (todaystamp === enddaystamp) {
                    endTime = timestamp;
                } else {
                    let endTimeDated =
                        e.getFullYear() +
                        "-" +
                        (e.getMonth() + 1) +
                        "-" +
                        e.getDate() +
                        " " +
                        "23:59:59";
                    let endTimeDate = new Date(
                        Date.parse(endTimeDated.replace(/-/g, "/"))
                    );
                    endTime = endTimeDate.getTime();
                }
                if (startTime > timestamp || endTime > timestamp) {
                    this.dateSeleteData = [];
                    this.$message.error("不能穿越哦，请重新选择：");
                } else {
                    this.newStartTime = startTime;
                    this.newEndTime = endTime;
                    this.$refs.myNote.getTrainList({
                        botId: this.noteCurrentBot,
                        beginTrainTime: this.newStartTime,
                        endTrainTime: this.newEndTime,
                    });
                }
            } else {
                this.yesterdayData();
            }
        },
        changeGetData() {
            switch (true) {
                case this.currentDate === "today":
                    this.todayData();
                    break;
                case this.currentDate === "yesterday":
                    this.yesterdayData();
                    break;
                case this.currentDate === "lastWeek":
                    this.lastWeekData();
                    break;
                case this.currentDate === "lastMonth":
                    this.lastMonthData();
                    break;
                case this.currentDate == "all":
                    this.allData();
                    break;
                case this.currentDate === "diy":
                    this.datePickerChange();
                    break;
            }
        },
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        formatDateTime(date) {
            var date = new Date(date);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            return y + "-" + m + "-" + d;
        },
        closeParam(type) {
            this.$delete(this.advSearchData, type);
            if (JSON.stringify(this.advSearchData) == "{}") {
                this.top = 0;
            } else {
                this.top = 50;
            }
            this.$refs.advSearch.clearAdvConditionCell(
                type,
                this.advSearchData
            );
            this.$refs.myQuestion.getQuestion(1, this.advSearchData);
        },
        deleteAllAdv(){
            this.advSearchData = {};
            this.top = 0;
            console.log(this.$refs.advSearch);
            if (this.$refs.advSearch) {
                this.$refs.advSearch.clearAdvConditionAll();
            }
            this.$refs.myQuestion.getQuestion(1, {});
        },
        showAdvSearch() {
            this.advSearchdrawer = true;
            this.$nextTick(() => {
                this.$refs.advSearch.getAnnotator();
            })
        },
        closeAdvSerch() {
            this.advSearchdrawer = false;
        },
        submitAdvSerch(param) {
            this.advSearchData = JSON.parse(param);
            if (Object.keys(this.advSearchData).length == 0) {
                this.top = 0;
            } else {
                this.top = 50;
            }
            this.$refs.myQuestion.getQuestion(1, this.advSearchData);
            this.advSearchdrawer = false;
        },
        changeQwType(value) {
            this.qwType = value;
            this.$nextTick(() => {
                // this.$refs.myQuestion.getQuestion(1); // param 切换TAB改变页码
                if (Object.keys(this.advSearchData).length == 0) {
                    this.top = 0;
                } else {
                    this.top = 50;
                }
                this.$refs.myQuestion.getQuestion(1, this.advSearchData);
            });
        },
        changeCurrentBotApikey(data) {
            this.currentBotApikey = data;
            this.botList.forEach((item) => {
                if (item.apiKey == data) {
                    this.currentBot = item.id;
                }
            });
            this.$refs.feedbackNote.changeCurrentBotApikey(data);
        },
        // 获取机器人列表
        getBotList(id, apiKey) {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    let apiKeys = ''
                    res.data.list.forEach((item,index) => {
                        if(this.$route.query.isHeathly == 1 && this.$route.query.botId){
                            apiKeys = item.apiKey;
                        }
                    })
                    if(this.$route.query.isHeathly == 1 && this.$route.query.botId){
                        this.currentBot = parseInt(this.$route.query.botId);
                        this.noteCurrentBot =  parseInt(this.$route.query.botId);
                        this.currentBotApikey = apiKeys;
                    } else {
                        this.currentBot = id ? parseInt(id) : this.botList[0].id;
                        this.noteCurrentBot = id ? parseInt(id) : this.botList[0].id;
                        this.currentBotApikey = apiKey
                            ? apiKey
                            : this.botList[0].apiKey;
                    }
                    
                    /* this.currentBot = this.botList[0].id;*/
                    /*this.currentBotApikey = this.botList[0].apiKey;*/
                    this.changeStatus();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        tabClick() {
            console.log(this.activeTab,'activeTab');
            if (this.activeTab === "note") {
                this.allData();
                this.getSummaryTrainingRecords()
            } else {
                this.$nextTick(() => {
                    this.deleteAllAdv();
                    this.$refs.myQuestion.getQuestion();
                })
            }
        },
        trainBot() {
            this.loading = true;
            this.FetchPost(this.requestUrl.trainList.trainTag, {
                bId: this.currentBot,
            }).then((res) => {
                if (res.code === "0") {
                    this.changeStatus();
                    this.$refs.myQuestion.getQuestion();
                    setTimeout(() => {
                        this.loading = false;
                        this.$notify({
                            title: "成功",
                            message:
                                "已成功训练模型，机器人能力又增强了一些，继续加油！",
                            type: "success",
                        });
                    }, 5000);
                } else {
                    this.loading = false;
                    this.$message.error(res.message);
                }
            });
        },
        getTrainStatus() {
            this.FetchGet(
                this.requestUrl.trainList.trainStatus + this.currentBot
            ).then((res) => {
                if (res.code === "0") {
                    if (res.data && res.data.status === "TRAINING")
                        this.trainStatus = true;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        getCount() {
            this.FetchGet(
                this.requestUrl.trainList.noteCount + this.currentBot
            ).then((res) => {
                if (res.code === "0") {
                    this.tagCount = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeNoteBot(val) {
            this.noteCurrentBot = val;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.tagCount = 0;
            this.trainStatus = true;
            this.getTrainStatus();
            this.getCount();
            this.getSummaryTrainingRecords();
        },
        changeBot(val) {
            this.changeStatus();
            this.botId = val;
            this.deleteAllAdv();
        },
        changeStatus() {
            this.tagCount = 0;
            this.trainStatus = true;
            this.getTrainStatus();
            this.getCount();
        },
        emptySearch() {
            if (activeTab === "question") {
                if (this.keyword === "") this.$refs.myQuestion.getQuestion();
            }
            if (activeTab === "feedbackNote") {
                if (this.keyword === "")
                    this.$refs.myFeedbackNote.getFeedbackNote();
            }
        },
    },
};
</script>
<style lang="less">
@import "./../../assets/less/train/list.less";
.tl-el-tab>.el-tabs__content {
    background: white;
}
.guoran-index-tab {
    position: absolute;
    // min-width: 1175px;
    // top: 98px;
    z-index: 9;
    background-color: white;
    // width: calc(100% - 233px);
    border-top: solid 6px rgba(0, 0, 0, 0);
    display: flex;
    // justify-content: flex-start;
    justify-content: space-between;
    height: 50px;
    width: calc(100% - 16px);
    .left{
        display: flex;
        align-items: center;
        .gruoran-index-tab-cell {
            flex: none;
            width: 80px;
            line-height: 50px;
            border-bottom: solid 2px white;
            color: #303133;
            cursor: pointer;
        }
        .gruoran-index-tab-cell:hover {
            color: #366AFF;
        }
        .gruoran-index-tab-cell-active {
            flex: none;
            width: 80px;
            line-height: 50px;
            border-bottom: solid 2px #366AFF;
            color: #366AFF;
            cursor: pointer;
        }
    }
    .right{
        display: flex;
        height: 50px;
        align-items: center;
        >div{
            padding: 0 8px;
            height: 31px;
            border-radius: 5px;
            text-align: center;
            line-height: 31px;
            &.no-mark{
                background: #FFF3EC;
                color: #FF9555;
                margin-right: 6px;
            }
            &.had-mark{
                background: #EFF5FF;
                color: #458FFF;
                margin-right: 6px;
            }
            &.mark-right{
                background: #E1FAF9;
                color: #00C2BB;
                margin-right: 6px;
            }
            &.mark-error{
                background: #FFEBEC;
                color: #FE5965;
                margin-right: 6px;
            }
            &.unrecognized-mark{
                background: #F6F8FD;
                color: #616161;
            }
        }
    }
}
.dateSelection-t {
    flex: auto;
    line-height: 38px;
    height: 38px;
    width: 60%;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    span {
        white-space: nowrap;
        margin-right: 10px;
        cursor: pointer;
    }
    span:hover {
        color: #366AFF;
    }
    .isActive {
        color: #366AFF;
        font-weight: 600;
    }
    .el-date-editor {
        width: 220px;
        margin-top: 4px;
        margin-left: 10px;
        margin-right: 10px;
    }
    /deep/.el-select {
        .el-input {
            width: 130px;
        }
    }
}
.advSearchParams {
    display: flex;
    justify-content: flex-start;
    background-color: white;
    flex-wrap: wrap;
    top: 155px;
    position: fixed;
    padding-left: 8px;
    width: calc(100% - 341px);
    z-index: 10;
    align-items: center;
    .tag-b {
        margin: 0px 5px;
    }
    .el-icon-delete {
        color: #f56c6c;
        cursor: pointer;
    }
}
.acv-search-drawer {
    height: 400px !important;
    width: 700px !important;
    right: 0 !important;
    top: 55px !important;
    left: calc(100vw - 700px) !important;
}
</style>



