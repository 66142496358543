<template>
    <div class="answer-video">
        <div class="respose-video">
            <div class="album albumvideo">
                <div>
                    <div class="pic_img">
                        <div class="pic_img_box">
                            <el-upload
                                class="avatar-uploader"
                                :action="importFileUrl"
                                accept=".mp4,.MP4,.ogg,.OGG,.avi,.AVI,.mov,.MOV,.flv,.FLV,.wmv,.WMV"
                                v-bind:data="{}"
                                v-bind:on-progress="
                                    (event, file, fileList) =>
                                        uploadVideoProcess(
                                            event,
                                            file,
                                            fileList,
                                            index
                                        )
                                "
                                :on-success="
                                    (res, file) =>
                                        handleVideoSuccess(res, file, index)
                                "
                                :before-upload="
                                    (file) => beforeUploadVideo(file, index)
                                "
                                v-bind:show-file-list="false"
                            >
                                <video
                                    width="248px"
                                    height="148px"
                                    controlslist="nodownload"
                                    object-fit="fill"
                                    v-if="
                                        unit.content.url != '' &&
                                        !unit.content.videoFlag
                                    "
                                    v-bind:src="unit.content.url"
                                    muted="true"
                                    class="avatar video-avatar"
                                    controls="controls"
                                >
                                    您的浏览器不支持视频播放
                                </video>
                                <i
                                    v-else-if="
                                        unit.content.url == '' &&
                                        !unit.content.videoFlag
                                    "
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                                <el-progress
                                    v-if="unit.content.videoFlag == true"
                                    type="circle"
                                    :percentage="
                                        unit.content.videoUploadPercent
                                    "
                                    style="margin-top: 7px"
                                ></el-progress>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <p class="Upload_pictures">
                    <span></span>
                    <span
                        >最多可以上传1个视频，小于10M，格式mp4、avi、mov等（可点击已上传视频重新上传！）</span
                    >
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["unit", "unit"],
    data() {
        return {
            importFileUrl: location.origin + "/api/oss",
        };
    },
    methods: {
        //上传前回调
        beforeUploadVideo(file, index) {
            this.activeVideoIndex = index;
            var fileSize = file.size / 1024 / 1024 < 100;
            if (
                [
                    "video/mp4",
                    "video/ogg",
                    "video/flv",
                    "video/avi",
                    "video/wmv",
                    "video/rmvb",
                    "video/mov",
                    "video/quicktime",
                ].indexOf(file.type) == -1
            ) {
                this.$message.error("请上传正确的视频格式");
                return false;
            }
            if (!fileSize) {
                this.$message.error("视频大小不能超过10MB");
                return false;
            }
            this.isShowUploadVideo = false;
        },
        //进度条
        uploadVideoProcess(event, file, fileList, activeVideoIndex) {
            this.unit.content.videoFlag = true;
            this.unit.content.videoUploadPercent =
                file.percentage.toFixed(0) - 0;
            // this.videoFlag = true;
            // this.videoUploadPercent = file.percentage.toFixed(0) * 1;
        },
        //上传成功回调
        handleVideoSuccess(res, file, activeVideoIndex) {
            // this.isShowUploadVideo = true;
            this.unit.content.isShowUploadVideo = true;
            this.unit.content.videoFlag = false;
            this.unit.content.videoUploadPercent = 0;
            // this.videoFlag = false;
            // this.videoUploadPercent = 0;

            //前台上传地址
            if (file.status == "success") {
                // this.videoForm.showVideoPath = file.url;
                this.unit.content.url = file.url;
            } else {
                this.$message.error("上传失败，请重新上传");
            }

            //后台上传地址
            if (res.code == 0) {
                // this.videoForm.showVideoPath = res.data;
                this.unit.content.url = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.answer-video {
    margin-bottom: 16px;
    .respose-video {
        display: flex;
        justify-content: space-around;
        .video-avatar {
            border-radius: 5px;
        }
        .albumvideo {
            .pic_img {
                margin-bottom: 10px;
                margin: 0 auto;
                height: 148px;
                width: 248px;

                /deep/.el-upload {
                    height: 148px;
                    width: 248px;
                    background: #fafbff;
                    border: 1px solid #e0e6f7;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 148px;
                    font-size: 22px;
                    color: #87a9ff;
                }

                .el-upload:hover {
                    border: 1px dashed skyblue;
                }

                /deep/.el-icon-plus {
                    margin-top: 60px;
                    font-size: 28px;
                    color: #87a9ff;
                }
            }
        }

        .Upload_pictures {
            margin-top: 18px;
            color: #a9b3c6;
            font-size: 13px;
        }
    }
}
</style>