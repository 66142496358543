<template>
    <div id="addEntity">  
        <el-dialog id="addDialog" :close-on-click-modal="false" :title="isEdit?'编辑实体':'添加实体'" width="85%" :visible.sync="diavisible" >
            <ul class="entityInfo">
                <li>
                    <span class="title">实体名称</span>
                    <el-input v-model="entityObject.name" maxlength="32" :placeholder="entityType=='DICTIONARY'?consts.dicNamePlaceholder:consts.regNamePlaceholder"></el-input>
                </li>
                <li>
                    <span class="title">适用范围</span>
                    <el-select :disabled="isEdit" v-model="entityObject.skillId" placeholder="请选择">
                        <el-option
                                v-for="item in skills"
                                :key="item.skillId"
                                :label="item.skillName"
                                :value="item.skillId">
                        </el-option>
                    </el-select>
                </li>
                <li>
                    <span class="title">实体类型</span>
                    <el-radio-group size="medium" v-model="entityType" @change="changeEntityType">
                        <el-radio border label="DICTIONARY">关键词</el-radio>
                        <el-radio border label="REGULAR">正则表达式</el-radio>
                    </el-radio-group>
                </li>  
                <li v-if="entityType=='DICTIONARY'">
                    <span class="title ">
                        <span>添加实体</span>
                    </span>
                    <div class="entity-list">
                        <div class="entity-header"><span class="l">标准词，如：男</span><span class="r">同义词，多个词之间用逗号隔开（如：男性，男人）</span></div>
                        <div class="entity-rows" id="rows">

                            <div class="entity-row" v-for="(item,index) in entityObject.content" :key="index">
                                <input 
                                    class="l"
                                    maxlength="45"
                                    placeholder="标准词，如：女"
                                    v-model="item.standardWord"
                                    ref="inputStand"
                                    autofocus="autofocus"
                                >
                               <!-- <div class="keyword-recommend"> -->
                                <el-input 
                                    :class="['r','r'+index]"
                                    type="text"
                                    :autosize="{ minRows: 1, maxRows: 10}"
                                    placeholder="同义词，多个词之间用逗号隔开（如：女性，女人）"
                                    v-model.trim="item.synonyms"
                                    @input="changeInput($event,item,index)"
                                    @click.stop.native="changeInput($event,item,index,'focus')"
                                    ref="inputSynon"/>
                                <botKeywordRecommend 
                                    :key="'addEntity' + index"
                                    :showBotKeywordRecommend.sync="item.showBotKeywordRecommend"
                                    :dataObj="item"
                                    :keyword="item.synonyms"
                                    :dataIndex="index"
                                    :width="clientWidth" 
                                    :isFixed="true"
                                    :left="positionLeft"
                                    :positionTop="positionTop"
                                    @noBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                    @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                    @addToInput="addToInput($event,item,index)">
                                </botKeywordRecommend>
                               <!-- </div> -->
                                <span v-if="index!=0" class="el-icon-minus" @click="delWord(index)"/>
                                <span v-else class="el-icon-minus space" />
                            </div>
                        </div>
                        <div class="add-button"><span class="el-icon-circle-plus" @click="addWord"/></div>
                    </div>
                </li>
                <li v-if="entityType=='REGULAR'">
                    <span class="title">描述说明</span>
                    <el-input
                        type="textarea"
                        placeholder="请输入描述说明"
                        v-model="regInfo.description"
                        rows="3"
                        show-word-limit
                        >
                    </el-input>
                </li>
                <li v-if="entityType=='REGULAR'">
                    <span class="title">设置正则<br>
                        <span class="title-regular-desc">
                            <el-popover
                                placement="top-start"
                                title="说明"
                                width="600"
                                popper-class="regular-pop"
                                trigger="hover">
                                <div class="" >
                                    <p>正则实体指利用正则表达式识别出用户消息中的关键信息，作为实体值，可以由用户自行设置正则表达式。</p>
                                    <p>以下为一些常用的正则表达式，可直接复制使用。</p><br>
                                    <p>QQ号：[1-9][0-9]{4,}</p><br>
                                    <p>手机号：(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])[ -]?(\d{4})[ -]?(\d{4})</p><br>
                                    <p>微信号：^[a-zA-Z][a-zA-Z0-9_-]{5,19}$</p><br>
                                    <p>身份证号：^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$</p>
                                </div>
                                <i class="el-icon-info" slot="reference">示例</i>
                            </el-popover>
                            
                        </span>
                    </span>
                    <el-input
                        type="textarea"
                        placeholder="请输入正则表达式"
                        v-model="regInfo.pattern"
                        rows="3"
                        show-word-limit
                        >
                    </el-input>
                </li>
            </ul>
            <div slot="footer" class="dialog-footer">
                <el-button @click.stop="diavisible = false">取消</el-button>
                <el-button type="primary" @click.stop="submitAddEntity">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import botKeywordRecommend from '../intentQa/components/botKeywordRecommend.vue'
export default {
    components:{
        botKeywordRecommend
    },
    props: ["addIntentDiaVisible","skills","entity","isEdit"],
    data() {
        return {
            consts:{
                dicEntityType:"DICTIONARY",
                regEntityType:"REGULAR",
                dicNamePlaceholder:"请输入实体名称，如：性别",
                regNamePlaceholder:"请输入实体名称，如：手机号"
            },
            diavisible: false,
            entityName: "",
            intentDes: "",
            entityType:'DICTIONARY',
            entityObject: {
                content: [],
            },
            regInfo:{
                pattern:"",
                description:"",
                standardWord: "",
                synonyms: []
            },
            clientWidth:null,
            positionTop:0,
            positionLeft:0,
            isIntentFocus:false
        };
    },
    methods: {
        // 关闭近义词推荐弹框
         closeBotKeywordRecommend(item){
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
         //添加实体 关键词的近义词 , 单个添加
        addToInput(datas,item,index){   
            item.synonyms = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        // 添加实体 显隐推荐词下拉框
        changeInput(datas,item,index){
            console.log(2222);
            this.isIntentFocus = true;
            this.clientWidth = document.querySelector('.entity-row .el-input').clientWidth-35;
            this.entityObject.content.forEach((v,i) => {
                v.showBotKeywordRecommend = false;
            })
            if(item.synonyms !== ''){
                item.showBotKeywordRecommend = true;
                this.$nextTick(()=> {
                    let ele = document.querySelector('#addEntity .el-dialog__body .entity-row .r'+index + ' .el-input__inner');
                    this.positionTop = ele.getBoundingClientRect().top+45
                    this.positionLeft = ele.getBoundingClientRect().left;
                })
                
            } 
            this.$forceUpdate();
        },
        // 点击新建实体
        addWord() {
            this.entityObject.content.push({standardWord:'',synonyms:'',showBotKeywordRecommend:false})
            this.$nextTick(function() {
                let index = this.entityObject.content.length
                this.$refs.inputStand[index-1].focus()
                let container = this.$el.querySelector('#rows')
                container.scrollTop = container.scrollHeight - container.clientHeight
            })

        },
        delWord(index){
            this.entityObject.content.splice(index,1)
        },
        // 提交新建或保存实体
        submitAddEntity() {
            if(this.entityObject.name==null || this.entityObject.name==undefined || this.entityObject.name.trim().length==0){
                this.$message.error('实体名称不能为空');
                return;
            }
            if(this.entityObject.skillId==null || this.entityObject.skillId==undefined || this.entityObject.skillId==-1){
                this.$message.error('适用范围不能为空');
                return;
            }
            this.entityObject.content.forEach(i => {if( typeof(i.synonyms)==='string') {i.synonyms = i.synonyms.replace(/，/g, ',').split(',')}})
            this.entityObject.type=this.entityType;
            if(this.entityType==this.consts.regEntityType){
                this.entityObject.content=[this.regInfo];
            }
            if (this.entityObject.id){
                //修改
                this.FetchPut(this.requestUrl.entity.updateEntity,this.entityObject.id,this.entityObject)
                    .then( res =>{
                        if(res.code == 0 ){
                            this.$emit("afterSave", this.entityObject);
                            this.diavisible = false
                        }else{
                            console.log("submitAddEntity",res)
                            this.$message.error(res.message);
                        }
                    })
                    .catch( err => {
                        console.log(err)
                    })
            }else(
                //新增
                this.FetchPost(this.requestUrl.entity.updateEntity,this.entityObject)
                    .then( res =>{
                        if(res.code == 0 ){
                            this.entityObject.id = res.data
                            this.$emit("afterSave", this.entityObject);
                            this.diavisible = false
                        }else{
                            console.log("submitAddEntity",res)
                            this.$message.error(res.message);
                        }

                    })
                    .catch( err => {
                        console.log(err)
                    })
            )


        },
        changeEntityType(value){
            this.entityType = value;
            this.entity.type = value;
            this.regInfo.pattern="";
            this.regInfo.description="";
        }
    },
    updated() {
        this.$emit("changeAddIntentVisible", this.diavisible);
    },
    watch: {
        addIntentDiaVisible(n) {
            this.diavisible = this.addIntentDiaVisible;
            if(n){
                this.entityObject = JSON.parse(JSON.stringify(this.entity));
                this.entityType = this.entity.type;
                if(this.entityType==this.consts.regEntityType){
                    let entityContent=this.entityObject.content;
                    if(entityContent!=null&&entityContent.length>0){
                        this.regInfo.pattern=entityContent[0].pattern;
                        this.regInfo.description=entityContent[0].description;
                    }
                }else{
                    this.regInfo.pattern="";
                    this.regInfo.description="";
                }
            }
        },
        diavisible(n){
            if(n){
               this.$nextTick(() => {
                    document.querySelector('.entity-rows').style.height = 'auto';
                    document.querySelector('#addEntity .el-dialog__wrapper').addEventListener('click',() => {
                        if(this.isIntentFocus){
                            this.entityObject.content.forEach((item,index) => {
                                this.$set(item,'showBotKeywordRecommend',false)
                                item.showBotKeywordRecommend = false;
                            })
                            this.isIntentFocus = false;
                             document.querySelector('.entity-rows').style.height = 'auto';
                            this.$forceUpdate();
                        }
                    })
                    
               })
            } else {
                document.querySelector('#addEntity .el-dialog__wrapper').removeEventListener('click',() => {})
            }
            
        }
    }
};
</script>
<style lang="less" scoped>
@import "./../../assets/less/entity/addEntity.less";
</style>


