<template>
    <div class="condition-config">
        <div class="cc-cell">
            <span class="cc-cell-title">条件类型</span>
            <div class="cc-cell-content">
                <el-radio-group @change="conditionTypeChange" v-model="conditionType">
                    <el-radio
                        v-for="item in conditionTypesOptions"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.label }}</el-radio
                    >
                </el-radio-group>
            </div>
        </div>
        <div class="entity-content" v-if="conditionType === 'ENTITY'">
            <div class="select-cell-et">
                <!-- <el-cascader
                    placeholder="请搜索选择实体"
                    size="small"
                    key="CCscee"
                    v-model="entityValue"
                    :options="entityOptions"
                    @change="handleChangeEntity"
                    ref="cascader"
                    filterable
                >
                </el-cascader> -->
                <select-addentity
                    :showBorder="false"
                    :entityOptions="entityOptions"
                    :entityValue="entityValue"
                    :skillId="skillId"
                    entitySelectARef="conditionconfig_selectaddentity"
                    @handleChangeEntity="handleChangeEntity"
                    @getAllEntityBySkill="getAllEntityBySkill"
                ></select-addentity>
            </div>
            <div class="select-cell-eteq select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionValue"
                    placeholder="请选择类型"
                >
                    <el-option
                        v-for="item in conditionTypes[0].relations"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div class="select-cell-etv select-cell-inner">
                <el-autocomplete
                    size="small"
                    class="inline-input"
                    key="CCsceeA"
                    v-model="activeEntityValue"
                    :fetch-suggestions="querySearchEntityValue"
                    placeholder="请输入或选择实体值"
                ></el-autocomplete>
            </div>
        </div>
        <div
            class="intent-content"
            v-if="conditionType === 'WEB_HOOK_RESPONSE'"
        >
            <div class="select-cell-rp select-cell-inner">
                <el-cascader
                    :key="'__' + new Date()"
                    size="small"
                    placeholder="请选择返回值"
                    v-model="conditionResponseValue"
                    :options="expBaseCascList"
                    :props="{ expandTrigger: 'hover', label: 'name' }"
                ></el-cascader>
            </div>
            <div class="select-cell-rpt select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionResponseType"
                    placeholder="请选择类型"
                >
                    <el-option
                        v-for="item in [
                            { name: '个数', value: 'LENGTH' },
                            { name: '值', value: 'VALUE' },
                        ]"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div class="select-cell-rpeq select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionResponseCondit"
                    placeholder="请选择条件"
                >
                    <el-option
                        v-for="item in webhookConditionTypes"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div
                v-if="conditionResponseType == 'VALUE'"
                class="select-cell-rpv select-cell-inner"
            >
                <el-input
                    size="small"
                    v-model="conditionResponseNum"
                    placeholder="请输入值"
                ></el-input>
            </div>
            <div
                v-if="conditionResponseType == 'LENGTH'"
                class="select-cell-rpv select-cell-inner"
            >
                <el-input
                    size="small"
                    v-model="conditionResponseNum"
                    type="number"
                    placeholder="请输入数值"
                ></el-input>
            </div>
        </div>
        <div class="entity-content" v-if="conditionType === 'USER_ATTRIBUTE'">
            <div class="select-cell-attr select-cell-inner">
                <el-cascader
                    placeholder="请选择属性"
                    size="small"
                    key="CCscUa"
                    v-model="userAttributeType"
                    :options="userAttributeOptions"
                    @change="handleChangeUserAttributeType"
                >
                </el-cascader>
            </div>
            <div class="select-cell-attreq select-cell-inner">
                <el-select
                    size="small"
                    v-model="conditionValue"
                    @change="changeConditionValue"
                    placeholder="请选择类型"
                >
                    <el-option
                        v-for="item in attrRelations"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div
                @click="selectStaffCustomer"
                class="select-cell-attrv select-cell-inner"
                v-if="conditionValue !== 'EQUAL' && conditionValue !== 'NOT_EQUAL'"
            >
                <span
                    v-if="
                        userAttributeType.length != 0 &&
                        userAttributeNameValue.length == 0
                    "
                    class="select-cell-attrv-placeholder"
                    >请选择值</span
                >
                <div v-else class="attr-value">
                    <span
                        v-for="(
                            uavCell, uavCellIndex
                        ) in userAttributeObj"
                        :key="uavCellIndex"
                        class="attr-value-cell"
                        >
                        <span v-if="!uavCell.isThirdData">{{ uavCell.name }}</span> 
                        <open-data v-else :type="userAttributeType.indexOf('name') === -1 ? 'departmentName' : 'userName'" :openid="uavCell.name"></open-data>
                        
                        <em
                            v-show="
                                userAttributeObj.length !=
                                uavCellIndex + 1
                            "
                            >,</em
                        ></span
                    >
                </div>
            </div>
        </div>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name">选择员工</div>
            <div slot="popup-con" class="member-contanier">
                <staff-customer
                    :memberInfo4Client="memberInfo4Client"
                    :memberList="memberList"
                    :clientType="clientType"
                    :selectClient="customerList"
                    :clientValue="checkClientId"
                    @onNodeClick="onNodeClick"
                    :isMultiple="isMultiple"
                    :hasClientNext="hasClientNext"
                    :companyConfig="false"
                    @loadMore="lookMore"
                    ref="staffCustomer"
                    :isShowUpDown="isShowUpDown"
                    :isShowStaff="isShowStaff"
                ></staff-customer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogMemberTree = false" plain size="small"
                    >取 消</el-button
                >
                <el-button type="primary" @click="preserStaff" size="small"
                    >确定</el-button
                >
            </div>
        </popup>
        <popup v-if="selectDepartment" @closeEvent="selectDepartment = false">
            <div slot="popup-name">选择部门</div>
            <div slot="popup-con">
                <department-tree
                    :isMultiple="isMultipleDepartment"
                    :selectDepartment="selectDepartment"
                    :updownCheckedList="updownCheckedList"
                    :staffCheckedList="staffCheckedList"
                    ref="departmentTree"
                    :isShowUpDown="isShowUpDown"
                    :isShowStaff="isShowStaff"
                    :activeName="activeDepName"
                    @selectDepartmentNode="selectDepartmentNode"
                ></department-tree>
            </div>
            <div slot="dialog-footer">
                <el-button @click="selectDepartment = false" plain size="small"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveDepartment" size="small"
                    >确定</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import StaffCustomer from "./staffCustomer";
import OpenData from "@/components/openData";
import DepartmentTree from "./departmentTree";
import SelectAddentity from "./SelectAddentity.vue";
export default {
    props: ["skillId", "expBaseCascList", "isStartOrEnd"],
    components: { StaffCustomer, OpenData, popup, DepartmentTree, SelectAddentity },
    data() {
        return {
            dialogMemberTree: false,
            memberInfo4Client: {
                type: "",
                memberId: "",
                name: "",
                clientInfo: [],
            },
            memberList: [],
            checkMemberList: [],
            clientType: "",
            customerList: [],
            checkClientId: "",
            isMultiple: true,
            hasClientNext: false,
            isShowUpDown: true,
            isShowStaff: true,
            staffCustomer: {
                clientInfo: [],
                type: "",
            },
            // 咨询者属性
            userAttributeType: "",
            userAttributeValue: [],
            userAttributeObj: [],
            userAttributeNameValue: [],
            userAttributeOptions: [
                {
                    value: "staff",
                    label: "员工",
                    children: [
                        {
                            value: "name",
                            label: "姓名",
                        },
                        {
                            value: "department",
                            label: "部门",
                        },
                    ],
                },
                // {
                //     value: "updown",
                //     label: "上下游",
                //     children: [
                //         {
                //             value: "name",
                //             label: "姓名",
                //         },
                //         {
                //             value: "department",
                //             label: "部门",
                //         },
                //     ],
                // },
            ],
            userAttributeTypeNameObj: {
                staff: "员工",
                updown: "上下游",
                name: "姓名",
                department: "部门",
            },
            attrRelations: [
                { name: "为空", value: "EQUAL" },
                { name: "不为空", value: "NOT_EQUAL" },
                { name: "包含任意", value: "CONTAINS_ANY" },
                { name: "不包含任意", value: "NOT_CONTAINS_ANY" },
            ],
            userAttributeTypeValue: [],

            selectDepartment: false,
            isMultipleDepartment: true,
            updownCheckedList: [],
            staffCheckedList: [],

            conditionType: "ENTITY", // 条件配置类型
            conditionTypesOptions: [
                {
                    // 条件配置类型option
                    value: "ENTITY",
                    label: "实体",
                },
                {
                    value: "WEB_HOOK_RESPONSE",
                    label: "返回值",
                },
                {
                    value: "USER_ATTRIBUTE",
                    label: "咨询者属性",
                },
            ],
            entityValue: [],
            entityOptions: [],
            entityValueVal: "",
            conditionValue: "EQUAL",

            // 条件列表
            conditionTypes: [
                { relations: [] },
                { relations: [] },
                { relations: [] },
            ],

            // 实体
            allEntityValue: [],
            activeEntityValue: "",

            // 返回值配置
            webhookConditionTypes: [],
            conditionResponseValue: "",
            conditionResponseType: "VALUE",
            conditionResponseCondit: "",
            conditionResponseScene: "",
            conditionResponseNum: "",

            conditionEditing: {}, // 当前编辑条件obj
        };
    },
    mounted() {
        if (this.isStartOrEnd) {
            this.getAllEntityByBot();
        } else {
            this.getAllEntityBySkill();
        }

        // 条件列表
        // 条件列表
        this.FetchGet(this.requestUrl.chatFlowComponent.getConditionList, {})
            .then((res) => {
                if (res.code === "0") {
                    this.conditionTypes = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        this.FetchGet(this.requestUrl.chatFlowComponent.getConditionList, {
            scope: "WEB_HOOK_RESPONSE",
            type: "LENGTH",
        })
            .then((res) => {
                if (res.code === "0") {
                    this.webhookConditionTypes = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
    },
    methods: {
        changeConditionValue(val){
            console.log(val);
            this.userAttributeValue = [];
            this.userAttributeObj = [];
            this.userAttributeNameValue = [];
        },
        saveDepartment() {
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
            if (this.userAttributeType[0] == "staff") {
                this.staffCheckedList.forEach((item) => {
                    this.userAttributeValue.push(
                        item.id ? item.id : item.memberId
                    );
                    this.userAttributeNameValue.push(item.label);
                    item.name = item.label;
                    item.isThirdData = (item.isWeWorkThirdData || (item.originData && item.originData.wechatBotDTO && item.originData.wechatBotDTO.bindType===0&&item.originData.wechatBotDTO.wechatType===0))
                    this.userAttributeObj.push(item);
                });
            } else {
                this.updownCheckedList.forEach((item) => {
                    this.userAttributeValue.push(
                        item.id ? item.id : item.memberId
                    );
                    this.userAttributeNameValue.push(item.name);
                    this.userAttributeObj.push(item);
                });
            }

            this.selectDepartment = false;
        },
        selectDepartmentNode(list) {
            if (this.userAttributeType[0] == "staff") {
                this.staffCheckedList = [...list];
                console.log(this.staffCheckedList);
            } else {
                this.updownCheckedList = [...list];
            }
        },
        selectStaffCustomer() {
            if (this.userAttributeType.length == 0) {
                this.$message.error("请先选择属性");
            } else {
                if (
                    this.userAttributeType[0] == "staff" &&
                    this.userAttributeType[1] == "name"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = false;
                    this.isShowStaff = true;
                    this.memberInfo4Client.clientInfo = this.userAttributeObj;
                    this.memberInfo4Client.memberId = this.userAttributeValue;
                    this.dialogMemberTree = true;
                }
                if (
                    this.userAttributeType[0] == "staff" &&
                    this.userAttributeType[1] == "department"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = false;
                    this.isShowStaff = true;
                    this.activeDepName = "staffDept";
                    this.staffCheckedList = this.userAttributeObj;
                    this.selectDepartment = true;
                    console.log(this.staffCheckedList);
                    this.$nextTick(() => {
                        this.$refs.departmentTree.checkDepartmentList =
                            this.staffCheckedList;
                    });
                }
                if (
                    this.userAttributeType[0] == "updown" &&
                    this.userAttributeType[1] == "name"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = true;
                    this.isShowStaff = false;
                    this.memberInfo4Client.clientInfo = this.userAttributeObj;
                    this.memberInfo4Client.memberId = this.userAttributeValue;
                    this.dialogMemberTree = true;
                    // this.$nextTick(() => {
                    //     this.$refs.staffCustomer.checkMemberList = this.userAttributeObj;
                    // })
                }
                if (
                    this.userAttributeType[0] == "updown" &&
                    this.userAttributeType[1] == "department"
                ) {
                    this.clientType = "";
                    this.isShowUpDown = true;
                    this.isShowStaff = false;
                    this.activeDepName = "upDown";
                    this.updownCheckedList = this.userAttributeObj;
                    this.selectDepartment = true;
                    this.$nextTick(() => {
                        this.$refs.departmentTree.checkDepartmentList =
                            this.updownCheckedList;
                    });
                }
            }
        },
        handleChangeUserAttributeType(e) {
            console.log(e);
            this.memberInfo4Client.clientInfo = [];
            this.memberInfo4Client.memberId = [];
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
        },
        //关闭报单人弹窗
        preserStaff() {
            console.log(521, this.staffCustomer.clientInfo)
            let staffList = this.staffCustomer.clientInfo;
            this.userAttributeValue = [];
            this.userAttributeNameValue = [];
            this.userAttributeObj = [];
            staffList.forEach((item) => {
                this.userAttributeValue.push(item.id ? item.id : item.memberId);
                this.userAttributeNameValue.push(item.name);
                this.userAttributeObj.push(item);
            });
            this.dialogMemberTree = false;
        },
        onNodeClick(node, type, memberInfo) {
            console.log(533, node, type, memberInfo);
            let isThirdData = (memberInfo.isWeWorkThirdData || (memberInfo.originData.wechatBotDTO.bindType===0&&memberInfo.originData.wechatBotDTO.wechatType===0));
            this.staffCustomer.type = type;

            this.staffCustomer.clientInfo = [];
            let staffCustomer = {
                name: "",
                memberId: "",
                clientType: "",
            };
            let memberList = [];
            let clientInfo = [];
            if (!this.isMultiple) {
                this.$refs.staffCustomer.checkMemberList = [];
                if (type === "customer" || type === "updown") {
                    staffCustomer.name = node.name;
                    staffCustomer.memberId = node.id;
                    if (type === "updown") {
                        this.upDownStreamMemberInfo = memberInfo;
                        this.$emit("chooseUpdown", this.upDownStreamMemberInfo);
                    }
                } else {
                    staffCustomer.name = node.label;
                    staffCustomer.memberId = node.id;
                    staffCustomer.isThirdData = isThirdData;
                }
                staffCustomer.clientType =
                    node.type && node.type === "updown"
                        ? "ASKBOT_UP_DOWN_MEMBER"
                        : "";
                this.staffCustomer.clientInfo = staffCustomer;
            } else {
                // 多选
                let list = [];
                console.log(type, "type");
                if (type === "customer") {
                    this.$refs.staffCustomer.ergodicMemberTree2(
                        this.$refs.staffCustomer.selectClient,
                        ""
                    );
                    list = this.handlerArr(
                        this.$refs.staffCustomer.checkMemberList
                    );
                    memberList = list;
                    clientInfo = memberList.map((item) => {
                        let info = {
                            name: item.name || item.label,
                            memberId: item.id,
                            clientType:
                                item.type && item.type === "updown"
                                    ? "ASKBOT_UP_DOWN_MEMBER"
                                    : "",
                            isThirdData: isThirdData,
                        };
                        return info;
                    });
                } else if (type === "updown") {
                    this.$refs.staffCustomer.ergodicMemberTree2(
                        this.$refs.staffCustomer.updownDateTree,
                        ""
                    );
                    list = this.handlerArr(
                        this.$refs.staffCustomer.checkMemberList
                    );
                    this.checkMemberList =
                        this.$refs.staffCustomer.checkMemberList;
                    memberList = list;
                    console.log(
                        memberList,
                        "memberListmemberListmemberListmemberList"
                    );
                    clientInfo = memberList.map((item) => {
                        let info = {
                            isThirdData: isThirdData,
                            name: item.name || item.label,
                            memberId: item.id,
                            clientType:
                                item.type && item.type === "updown"
                                    ? "ASKBOT_UP_DOWN_MEMBER"
                                    : "",
                                
                        };
                        return info;
                    });
                } else {
                    let obj = JSON.parse(JSON.stringify(node));
                    console.log(479, obj);
                    obj.memberId = obj.id;
                    if (node.checked) {
                        this.memberInfo4Client.clientInfo.push(obj);
                    } else {
                        this.memberInfo4Client.clientInfo =
                            this.memberInfo4Client.clientInfo.filter(
                                (client) => {
                                    return client.id != obj.id;
                                }
                            );
                    }
                    clientInfo = this.memberInfo4Client.clientInfo.map(
                        (item) => {
                            let info = {
                                isThirdData: isThirdData,
                                name: item.label || item.name,
                                memberId: item.id,
                                id: item.id,
                                clientType:
                                    item.type && item.type === "updown"
                                        ? "ASKBOT_UP_DOWN_MEMBER"
                                        : "",
                            };
                            return info;
                        }
                    );
                }
                this.staffCustomer.clientInfo = clientInfo;
            }
            console.debug(" this.staffCustomer", this.staffCustomer);
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                }
            }
            return result;
        },
        lookMore(type) {
            if (type === "com") {
                let id =
                    this.tertiaryNode.company[
                        this.tertiaryNode.company.length - 1
                    ].id;
                this.getCompanyList(id);
            } else {
                let id = this.customerList[this.customerList.length - 1].id;
                this.getClientList(id);
            }
        },
        /**
         * 为element-ui的Select和Cascader添加弹层底部操作按钮
         * @param visible
         * @param refName  设定的ref名称
         * @param onClick  底部操作按钮点击监听
         */
        // visibleChange(visible, refName, onClick) {
        //     if (visible) {
        //         const ref = this.$refs[refName];
        //         let popper = ref.$refs.popper;
        //         if (popper.$el) popper = popper.$el;
        //         if (
        //             !Array.from(popper.children).some(
        //                 (v) => v.className === "el-cascader-menu__list"
        //             )
        //         ) {
        //             const el = document.createElement("ul");
        //             el.className = "el-cascader-menu__list";
        //             el.style =
        //                 "border-top: solid 1px #E4E7ED; padding:0; color: #606266;";
        //             el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px"><span class="el-cascader-node__label">创建实体</span>${this.entityValue}</li>`;
        //             popper.appendChild(el);
        //             el.onclick = () => {
        //                 // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
        //                 onClick && onClick();

        //                 // 以下代码实现点击后弹层隐藏 不需要可以删掉
        //                 if (ref.toggleDropDownVisible) {
        //                     ref.toggleDropDownVisible(false);
        //                 } else {
        //                     ref.visible = false;
        //                 }
        //             };
        //         }
        //     }
        // },
        // 条件配置 - 选择类型改变
        conditionTypeChange(value) {
            if (value === "USER_ATTRIBUTE") {
                this.conditionValue = "EQUAL";
            }
        },
        conditionEcho_chi(type, data, tampObj) {
            switch (type) {
                case "edit":
                    this.conditionEditing = {
                        type: type,
                        activeObj: tampObj,
                        index: data,
                    };
                    this.conditionType = tampObj.scope;
                    console.log(tampObj.scope);
                    this.conditionValue = tampObj.relation;
                    if (tampObj.scope === "ENTITY") {
                        this.entityValue = tampObj.ext.entityValue;
                        this.activeEntityValue = tampObj.value;
                    }
                    if (tampObj.scope === "WEB_HOOK_RESPONSE") {
                        this.conditionResponseNum = tampObj.value;
                        this.conditionResponseValue = tampObj.ext.variable;
                        this.conditionResponseCondit = tampObj.relation;
                    }
                    if (tampObj.scope === "USER_ATTRIBUTE") {
                        this.userAttributeType = tampObj.variable.split("_");
                        this.userAttributeValue = tampObj.userAttributeValue;
                        this.userAttributeNameValue =
                            tampObj.ext.userAttributeNameValue;
                        this.userAttributeObj = tampObj.ext.userAttributeObj;
                    }
                    break;
                case "addOr":
                    this.conditionType = "ENTITY";
                    this.entityValue = [];
                    this.conditionValue = "EQUAL";
                    this.activeEntityValue = "";
                    this.conditionResponseValue = "";
                    this.conditionResponseNum = "";
                    this.conditionResponseCondit = "";
                    this.conditionEditing = {
                        type: type,
                        activeObj: this.initConfigData,
                        index: data,
                    };
                    break;
                case "addAnd":
                    this.conditionType = "ENTITY";
                    this.entityValue = [];
                    this.conditionValue = "EQUAL";
                    this.activeEntityValue = "";
                    this.conditionResponseValue = "";
                    this.conditionResponseNum = "";
                    this.conditionResponseCondit = "";
                    this.conditionEditing = {
                        type: type,
                        activeObj: this.initConfigData,
                        index: data,
                    };
                    break;
            }
        },
        getRelationNameAttr(value) {
            let tampValue = "";
            this.attrRelations.forEach((cell) => {
                if (cell.value === value) {
                    tampValue = cell.name;
                }
            });
            return tampValue;
        },
        // 根据id返回意图分类名称
        getRelationName(value) {
            let tampValue = "";
            this.conditionTypes.forEach((item) => {
                item.relations.forEach((cell) => {
                    if (cell.value === value) {
                        tampValue = cell.name;
                    }
                });
            });
            return tampValue;
        },
        // 根据id获取webhook名称和返回值名称
        getWebhookNameAndResName(ids) {
            let obj = {
                webhook: "",
                response: "",
            };
            this.expBaseCascList.forEach((item) => {
                if (item.id == ids[0]) {
                    obj.webhook = item.name;
                    item.configs.forEach((cell) => {
                        if (cell.value == ids[1]) {
                            obj.response = cell.name;
                        }
                    });
                }
            });
            return obj;
        },
        confirmSaveCondiConfig_Chi() {
            let paramObj = { ext: {} };
            paramObj.scope = this.conditionType;
            paramObj.relation = this.conditionValue;

            if (this.conditionType === "ENTITY") {
                paramObj.variable = this.entityValue[1];
                paramObj.value = this.activeEntityValue;
                paramObj.ext = { entityValue: this.entityValue };
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionValue
                );
            }

            if (this.conditionType === "WEB_HOOK_RESPONSE") {
                paramObj.variable =
                    this.conditionResponseValue[0] +
                    "." +
                    this.conditionResponseValue[1];
                paramObj.value = this.conditionResponseNum;
                paramObj.relation = this.conditionResponseCondit;
                paramObj.scopeType = this.conditionResponseType;
                paramObj.ext = this.getWebhookNameAndResName(
                    this.conditionResponseValue
                );
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionResponseCondit
                );
                paramObj.ext.variable = this.conditionResponseValue;
            }

            if (this.conditionType === "USER_ATTRIBUTE") {
                console.log(this.userAttributeType);
                paramObj.variable = this.userAttributeType.join("_");
                paramObj.ext.userAttributeTypeName =
                    this.userAttributeTypeNameObj[this.userAttributeType[0]] +
                    this.userAttributeTypeNameObj[this.userAttributeType[1]];
                paramObj.value = JSON.stringify(this.userAttributeValue);
                paramObj.ext.userAttributeNameValue =
                    this.userAttributeNameValue;
                paramObj.ext.userAttributeObj = this.userAttributeObj;
                paramObj.ext.relationName = this.getRelationNameAttr(
                    this.conditionValue
                );
                if(paramObj.relation === "EQUAL" || paramObj.relation === "NOT_EQUAL") {
                    paramObj.value = "空";
                }
                console.log(paramObj);
            }

            let conditionEditing = this.conditionEditing;
            this.$emit("confirmSaveCondiConfig", {
                paramObj,
                conditionEditing,
            });
        },
        getAllEntityByBot() {
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill, {
                botId: this.skillId,
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取指定知识库下所有实体
        getAllEntityBySkill(entityId) {
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill, {
                skillId: this.skillId,
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                                if (entityId == cell.id) {
                                    this.handleChangeEntity([item.label, cell.label]);
                                }
                            });
                        });
                        this.entityOptions = [...tampArr];
                        console.log(927, this.entityOptions);
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 选择实体
        handleChangeEntity(e) {
            console.log(e);
            this.entityValue = e;
            let tampArr = [];
            this.entityOptions.forEach((item) => {
                if (item.value === e[0]) {
                    item.children.forEach((cell) => {
                        if (cell.value === e[1]) {
                            tampArr = cell.content;
                        }
                    });
                }
            });
            tampArr.forEach((item) => {
                item.value = item.standardWord;
                item.label = item.standardWord;
            });
            this.allEntityValue = [...tampArr];
            this.activeEntityValue = "";
        },
        // 实体值选择
        querySearchEntityValue(queryString, cb) {
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e = this.entityValue;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        loadAllEntity() {
            return [];
        },
    },
};
</script>
<style lang="less" scoped>
.condition-config {
    .cc-cell {
        margin-bottom: 12px;
        .cc-cell-title {
            margin-bottom: 6px;
            display: block;
            text-align: left;
        }
        .cc-cell-content {
            background-color: #fbfcfd;
            border-radius: 5px;
            padding: 6px;
            text-align: left;
        }
    }
    .select-cell-inner {
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
    }
    .entity-content {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        .select-cell-et {
            flex: none;
            width: 200px;
        }
        .select-cell-eteq {
            flex: none;
            width: 100px;
        }
        .select-cell-etv {
            flex: auto;
        }
        .select-cell-attr {
            flex: none;
            width: 110px;
            display: flex;
            align-items: center;
        }
        .select-cell-attreq {
            flex: none;
            width: 100px;
            display: flex;
            align-items: center;
        }
        .select-cell-attrv {
            flex: auto;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 6px;
            .attr-value {
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            .select-cell-attrv-placeholder {
                color: #c0c4cc;
                cursor: pointer;
            }
        }
    }
    .intent-content {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        .select-cell-rp {
            flex: auto;
        }
        .select-cell-rpt {
            flex: none;
            width: 60px;
        }
        .select-cell-rpeq {
            flex: none;
            width: 110px;
        }
        .select-cell-rpv {
            flex: none;
            width: 100px;
        }
    }
}
</style>