<template>
    <div class="feedbackDetailBox">
        <div class="feedbackDetailTitle">
            <el-button v-if="viewTypeParent == 'feedback'" @click="editFeedbackDetailA" size="mini" type="primary"
                >修改答案</el-button
            >
            <el-button @click="closeFeedbackDetailBox" size="mini"
                >关闭</el-button
            >
        </div>
        <div class="feedbackDetailView">
            <node-de v-if="viewTypeParent == 'feedback'" :activeItem="activeItem" />
            <DetailList :viewType="viewTypeParent" :activeItem="activeItem" />
        </div>
    </div>
</template>

<script>
import NodeDe from "./NodeDe.vue";
import DetailList from "./DetailList";
export default {
    props: ["activeItem", "viewTypeParent"],
    components: { NodeDe, DetailList },
    data() {
        return {};
    },
    watch: {},
    mounted() {
        // console.log(this.activeItem);
    },
    methods: {
        editFeedbackDetailA() {
            let item = JSON.parse(this.activeItem);
            this.$router.push({
                name: "chatFlow",
                query: {
                    id: item.botId,
                    bid: item.botId,
                    bName: item.botName,
                    intentId: item.intentId,
                    intentName: item.intentName,
                    refApiKey: item.apiKey,
                },
            });
        },
        closeFeedbackDetailBox() {
            this.$emit("closeFeedbackDetailBox");
        },
    },
};
</script>

<style lang="less">
.feedbackDetailBox {
    max-width: 100vw;
    position: absolute;
    right: 8px;
    // width: 100%;
    overflow: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .feedbackDetailTitle {
        margin: 16px 16px 0;
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
        height: 28px;
        flex: none;
    }
    .feedbackDetailView {
        height: calc(100vh - 120px);
        margin: 16px;
        flex: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>