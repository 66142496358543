<template>
    <div class="question-list-o">
        <el-table v-loading="loading" height="calc(100vh - 180px)" :data="tableData">
            <el-table-column
                show-overflow-tooltip
                label=""
                fixed
                :render-header="renderIntention"
                width="340"
                align="left"
                prop="intentName"
            >
                <template slot-scope="scope">
                    <span style="display: inline-block;padding-left: 16px">{{ scope.row.intentName }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="hits"
                label="回复答案"
                align="center"
                min-width="150"
            >
                <template slot-scope="scope">
                    <span>{{ formatActions(scope.row.actions) }} </span>
                    <el-button
                        type="text"
                        class="reset-tag"
                        icon="el-icon-view"
                        @click="viewAnswer(scope.row.actions)"
                    ></el-button>
                </template>
            </el-table-column>
            <el-table-column
                prop="showStaticDetail"
                label="反馈情况"
                align="center"
                width="280"
            >
                <template slot="header" slot-scope="scope">
                    <div>
                        <el-dropdown
                            szie="mini"
                            @command="handleCurrentSortType"
                        >
                            <span class="el-dropdown-link">
                                <span
                                    style="
                                        display: inline-block;
                                        margin-right: 12px;
                                    "
                                    >反馈情况</span
                                >
                                <span style="color: #909399; fontsize: 12px">{{
                                    currentSortTypeName
                                }}</span>
                                <i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="0"
                                    >按有用次数升序</el-dropdown-item
                                >
                                <el-dropdown-item command="1"
                                    >按有用次数降序</el-dropdown-item
                                >
                                <el-dropdown-item command="2"
                                    >按无用次数升序</el-dropdown-item
                                >
                                <el-dropdown-item command="3"
                                    >按无用次数降序</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
                <template slot-scope="scope">
                    <span>{{ scope.row.showStaticDetail }} </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="time"
                label="更新时间"
                :render-header="renderDate"
                align="center"
                width="150"
            >
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" width="96">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        class="reset-tag"
                        @click="handleItem(scope.row, 'view')"
                        >查看</el-button
                    >
                    <el-button
                        type="text"
                        class="reset-tag"
                        @click="handleItem(scope.row, 'edit')"
                        >修改</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :page-size="pageSize"
            :current-page="currentPage"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="pageChange"
        >
        </el-pagination>
        
        <el-dialog
            title="回复答案"
            :close-on-click-modal="false"
            :visible.sync="actionChatVisible"
            width="500px"
        >
            <div>
                <ActionChat :actions="activeActions" />
            </div>
        </el-dialog>
        <el-drawer size="1380px" direction="rtl" :visible.sync="drawer" :with-header="false">
            <div class="drawer-content-o" style="height: 100%;">
                <feedback-detail v-if="drawer" viewTypeParent="feedback" @closeFeedbackDetailBox="closeFeedbackDetailBox" :activeItem="activeItem" />
            </div>
        </el-drawer>
    </div>
</template>

<script>
import Tagging from "./tagging";
import TaggingPopover from "./taggingPopover";
import FeedbackDetail from "./component/FeedbackDetail";
import ActionChat from "./component/ActionChat";

export default {
    props: ["botId", "keyword", "apikey"],
    components: {
        Tagging,
        TaggingPopover,
        FeedbackDetail,
        ActionChat,
    },
    data() {
        return {
            direction: 'ltr',
            drawer: false,
            pageSize: 20,
            currentPage: 1,
            total: 0,
            queryType: "", // 全部问题1 不识别问题 2
            sort: false, // 相似问题排序
            orderType: 0, //  0：时间倒序 1：时间正序 2：频次倒序 3：频次正序 4：相似问法
            currentIntent: "", // 当前意图
            currentSortType: 5, // 0:有用升序 1:有用降序 2: 无用升序 3:无用降序
            currentSortTypeName: "选择排序规则",
            sortTypeOption: [
                "按有用次数升序",
                "按有用次数降序",
                "按无用次数升序",
                "按无用次数降序",
                "选择排序规则",
            ],
            intentionList: [],
            loading: false,
            orderBy: "time", // 意图：intent 有用：useful 无用：useless 时间：time
            orderByType: "desc", // 正序：asc 倒序：desc
            activeActions: [],
            actionChatVisible: false,
            activeItem: {}
        };
    },
    watch: {
        botId(n, o) {
            this.getIntentList();
        },
    },
    mounted() {
        // this.getIntentList();
        // this.getFeedbackList();
    },
    methods: {
        changeCurrentBotApikey(data){
            // this.getFeedbackList(data);
        },
        closeFeedbackDetailBox(){
            this.drawer = false;
        },
        // 查看答案配置
        viewAnswer(actions) {
            this.activeActions = actions;
            this.actionChatVisible = true;
        },
        // 回复答案 显示格式化
        formatActions(actions) {
            let showAction = "";
            if (actions.length == 1 && actions[0].type == "answer_text") {
                showAction = actions[0].content.list[0];
            } else {
                actions.forEach((item) => {
                    switch (item.type) {
                        case "answer_text":
                            if (showAction.indexOf("[文本]") == -1) {
                                showAction += " [文本]";
                            }
                            break;
                        case "answer_rich_text":
                            if (showAction.indexOf("[富文本]") == -1) {
                                showAction += " [富文本]";
                            }
                            break;
                        case "answer_image":
                            if (showAction.indexOf("[图片]") == -1) {
                                showAction += " [图片]";
                            }
                            break;
                        case "answer_video":
                            if (showAction.indexOf("[视频]") == -1) {
                                showAction += " [视频]";
                            }
                            break;
                        default:
                            if (showAction.indexOf("[其他]") == -1) {
                                showAction += " [其他]";
                            }
                            break;
                    }
                });
            }
            return showAction;
        },
        handleCurrentSortType(command) {
            this.currentSortType = command;
            this.sort = false;
            if (Number(command) <= 1) {
                this.orderBy = "useful";
            } else {
                this.orderBy = "useless";
            }
            if (Number(command) % 2 == 0) {
                this.orderByType = "asc";
            } else {
                this.orderByType = "desc";
            }
            this.currentSortTypeName = this.sortTypeOption[Number(command)];
            this.getFeedbackList();
        },
        // 获取机器人下的意图
        getIntentList() {
            this.FetchGet(
                this.requestUrl.trainList.intentTree +
                    "?bId=" +
                    // 483 +
                    this.botId +
                    "&filterType=1"
            ).then((res) => {
                if (res.code === "0") {
                    res.data.forEach((val) => {
                        let arr = [];
                        val.children.forEach((v) => {
                            arr = arr.concat(v.children);
                        });
                        val.children = arr;
                    });
                    this.intentionList = res.data;
                    this.getFeedbackList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取答案反馈统计
        getFeedbackList(data) {
            this.loading = true;
            this.FetchGet(this.requestUrl.feedback.answerFeedbackList, {
                // botId: this.botId,
                // apiKey: "3fb4df46359a4985ab66d80a1c634772",
                apiKey: data?data:this.apikey,
                intentId: this.currentIntent,
                orderBy: this.orderBy,
                orderByType: this.orderByType,
                page: this.currentPage,
                size: this.pageSize,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.tableData = [];
                        res.data.list.forEach((v) => {
                            // v.time = new Date(v.createTime).Format(
                            //     "yyyy-MM-dd hh:mm:ss"
                            // );
                            v.time = v.updateTime;
                            v.showStaticDetail =
                                "总次数：" +
                                v.totalCount +
                                " 有用：" +
                                v.usefulCount +
                                "(" +
                                v.usefulRate +
                                "%) " +
                                " 无用：" +
                                v.uselessCount +
                                "(" +
                                v.uselessRate +
                                "%) ";
                            this.tableData.push(v);
                        });
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        pageChange(curr) {
            this.currentPage = curr;
            this.getFeedbackList();
        },
        tagIntent(row, isRight, type) {},
        renderIntention(h, { column }) {
            return h("div", [
                h(
                    "el-select",
                    {
                        props: {
                            value: this.currentIntent,
                            size: "small",
                            filterable: true,
                        },
                        style: {
                            width: "120px",
                        },
                        class: "select-intention",
                        on: {
                            change: (val) => {
                                this.currentIntent = val;
                                this.getFeedbackList();
                            },
                        },
                    },
                    [
                        h("el-option", {
                            props: {
                                value: "",
                                label: "全部意图",
                                key: "",
                            },
                        }),
                        this.intentionList.map((item) => {
                            return h(
                                "el-option-group",
                                {
                                    props: {
                                        label: item.name,
                                        key: item.id,
                                    },
                                    style: {
                                        width: "300px",
                                    },
                                },
                                [
                                    item.children.map((v) => {
                                        return h("el-option", {
                                            props: {
                                                value: v.id,
                                                label: v.name,
                                                key: v.id,
                                            },
                                        });
                                    }),
                                ]
                            );
                        }),
                    ]
                ),
                h(
                    "div",
                    {
                        class: "open-sort-f",
                    },
                    [
                        h("span", "相似意图排序："),
                        h("el-switch", {
                            props: {
                                "active-text": "开",
                                "inactive-text": "关",
                                value: this.sort,
                            },
                            on: {
                                change: (val) => {
                                    this.sort = val;
                                    this.currentSortTypeName = this.sortTypeOption[0];
                                    this.orderBy = "intent";
                                    if (val === true) {
                                        this.orderByType = "desc";
                                    } else {
                                        this.orderByType = "asc";
                                    }
                                    this.getFeedbackList();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        renderDate(h) {
            return h("div", [
                h("span", "更新时间"),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    this.orderBy == "time" &&
                                    this.orderByType == "asc"
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    this.orderBy = "time";
                                    this.orderByType = "asc";
                                    this.currentSortTypeName = this.sortTypeOption[0];
                                    this.sort = false;
                                    this.getFeedbackList();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    this.orderBy == "time" &&
                                    this.orderByType == "desc"
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    this.orderBy = "time";
                                    this.orderByType = "desc";
                                    this.currentSortTypeName = this.sortTypeOption[0];
                                    this.sort = false;
                                    this.getFeedbackList();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        // 操作
        handleItem(item, type) {
            if (type == "view") {
                this.activeItem = "";
                this.activeItem = JSON.stringify(item);
                this.drawer = true;
            }
            if (type == "edit") {
                //跳转至意图流程设计页面
                this.$router.push({
                    name: "chatFlow",
                    query: {
                        id: item.botId,
                        bid: item.botId,
                        bName: item.botName,
                        intentId: item.intentId,
                        intentName: item.intentName,
                        refApiKey: item.apiKey,
                    },
                });
            }
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/main/common.less";
.question-list-o {
    // width: calc(100vw - 630px);
    // width: calc(100% - 12px);
    // margin-top: 12px;
    display: grid;
    .el-table .el-table__fixed{
        height: auto!important;
        bottom: 17px;
    }
    .el-table__fixed-body-wrapper{
        height:calc(100vh - 217px)!important;
    }
}
.drawer-content-o {
    display: flex;
}
.batch-title {
    display: flex;
    width: calc(100% - 30px);
    height: 37px;
    position: absolute;
    background: #fff;
    z-index: 999;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    line-height: 37px;
    .current-count {
        flex: 1;
        padding-right: 10px;
        text-align: right;
        i {
            padding: 0 5px;
            color: #366AFF;
            font-style: normal;
        }
    }
    .tagging {
        color: #366AFF;
        cursor: pointer;
    }
}
.el-table {
    th div {
        line-height: 34px;
    }
    th .cell {
        height: 34px;
        line-height: 34px;
    }
    .el-switch {
        display: inline;
        line-height: unset;
        height: 24px;
        padding: 0;
    }
    .el-select,
    .el-input {
        padding-left: 0;
    }
    .reset-tag,
    .view-info {
        padding: 0;
    }
    .open-sort-f {
        padding-left: 0;
        p {
            height: 20px;
            line-height: 20px;
        }
        .el-switch__label {
            line-height: 20px;
        }
    }
}
.select-question-f {
    width: 150px;
    .el-input {
        padding-right: 0;
    }
}
.select-intention {
    width: 180px;
    .el-input {
        padding-right: 0;
    }
}
</style>