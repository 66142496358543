<template>
    <div id="action-confirm-value">
        <div class="simple-cell-satisfaction">
            <div class="scs-title">
                <span class="pre-name">描述</span>
                <div
                    @click="templateConfigVisibleHandle('confirm-placeholder')"
                    class="insert-param"
                >
                    插入变量
                </div>
            </div>
            <div class="action-confirm-des">
                <div class="single-cell html-cell">
                    <div class="cell-in-content">
                        <ckeditor
                            :ref="'cked_' + unitIndex"
                            :editor="editorInline"
                            :config="editorConfig"
                            v-model="unit.content.template"
                            @focus="onEditorFocus"
                        ></ckeditor>
                    </div>
                </div>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <div class="scs-title">
                <span class="pre-name">如果用户确认</span>
            </div>
            <div class="inline-border-outer">
                <div class="left-select">
                    <el-select
                        v-model="unit.content.goToAction.scope"
                        size="small"
                        @change="jumpTypeValueChange"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>

                <div class="right-select">
                    <!-- <el-select
                        v-show="unit.content.goToAction.scope === 'NODE'"
                        v-model="unit.content.goToAction.value"
                        @change="nodeSelectChange"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select> -->
                    <SelectAddnode
                        v-if="unit.content.goToAction.scope === 'NODE'"
                        :nodeOptions="nodeOptions"
                        :nodeId="unit.content.goToAction.value"
                        :defaultParentNodeId="nodeId"
                        :nodeSelectARef="`actionForm_submit_e_${unitIndex}`"
                        :intentId="intentId"
                        @getNodeOptions="getNodeOptions"
                        @setAddNodeId="setAddNodeId"
                        :index="unitIndex"
                    ></SelectAddnode>
                    <el-cascader
                        v-if="unit.content.goToAction.scope === 'INTENT'"
                        v-model="unit.content.goToAction.value"
                        size="small"
                        :options="intentTree4Radio"
                        :key="'acv_single' + unitIndex"
                        placeholder="请选择跳转到意图名称"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddnode from "../cell/SelectAddnode.vue";
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
    name: "action-confirm-value",
    components: {
        SelectAddnode,
    },
    props: [
        "unit",
        "nodeOptions",
        "intentOptions",
        "unitIndex",
        "intentTree4Radio",
        "nodeId",
        "intentId",
    ],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            editorDataInline: "",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
        };
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId) {
            this.$set(this.unit.content.goToAction, "value", nodeId);
        },
        jumpTypeValueChange() {
            this.unit.content.goToAction.value = "";
        },
        templateConfigVisibleHandle(type) {
            let param = { type: type };
            param.unitIndex = this.unitIndex;
            param.lastEditor = lastEditor;
            this.$emit("templateConfigVisibleHandle", param);
        },
        onEditorFocus(zh, editor) {
            changeEditor(editor);
        },
        addConfirmDataResponse() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: "单个webhook返回值",
                    editable: false,
                    data: webHookDataHolder(51, "data.name"),
                },
            });
        },

        addConfirmDataEntity(data, editable) {
            console.log(205, currentEditor);
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: data.name,
                    editable: editable,
                    data: entityDataHolder(data.name),
                    type: data.inputType,
                    editable_class: "arsenal_icon arsenalkebianji",
                },
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                console.log(this.$refs['cked_' + this.unitIndex].$el);
                this.$refs['cked_' + this.unitIndex].$el.focus();
            }, 300)
        })
    },
};
</script>
<style lang="less" scoped>
#action-confirm-value {
    .inline-border-outer {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
        .left-select {
            width: 120px;
            flex: none;
        }
        .right-select {
            flex: auto;
            .el-select {
                width: 100%;
            }
        }
    }

    .simple-cell-satisfaction {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        .scs-title {
            margin-top: 8px;
            text-align: left;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .pre-name {
                display: inline-block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #616161;
            }
            .insert-param {
                text-align: center;
                width: 72px;
                height: 23px;
                line-height: 24px;
                border: 1px solid #a1b9ff;
                border-radius: 12px;
                color: #366aff;
                cursor: pointer;
            }
        }
        .action-confirm-des {
            border: 1px solid #e0e6f7;
            border-radius: 4px;
            .html-cell {
                border-radius: 6px;
                display: flex;
                background-color: white;
                min-height: 100px;
                .cell-in-content {
                    flex: auto;
                    display: flex;
                    justify-content: space-between;
                    .ck-content {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
