<template>
    <div class="action-feedback">
        <div class="action-feedback-positive">
            <div class="af-title">
                <span class="feedback-text-pre">如果反馈</span>
                <span class="feedback-text-p">{{
                    unit.content.positiveFeedback.text
                }}</span>
            </div>
            <div class="jump-cell-af">
                <el-select
                    style="width: 120px"
                    v-model="unit.content.positiveFeedback.scope"
                    size="small"
                    @change="jumpTypeValueChange($event, 'positive')"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in jumpTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <div class="right-select">
                    <!-- <el-select
                        v-if="unit.content.positiveFeedback.scope === 'NODE'"
                        v-model="unit.content.positiveFeedback.value"
                        @change="nodeSelectChange"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select> -->
                    <SelectAddnode
                        v-if="unit.content.positiveFeedback.scope === 'NODE'"
                        :nodeOptions="nodeOptions"
                        :nodeId="unit.content.positiveFeedback.value"
                        :defaultParentNodeId="nodeId"
                        :nodeSelectARef="`actionForm_feedback_p_${unitIndex}`"
                        :intentId="intentId"
                        @getNodeOptions="getNodeOptions"
                        @setAddNodeId="setAddNodeIdP"
                        :index="unitIndex"
                    ></SelectAddnode>
                    <el-cascader
                        v-if="unit.content.positiveFeedback.scope === 'INTENT'"
                        v-model="unit.content.positiveFeedback.value"
                        size="small"
                        :options="intentTree4Radio"
                        :key="'af_d_posi' + unitIndex"
                        placeholder="请选择跳转到意图名称"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    <el-input
                        v-if="unit.content.positiveFeedback.scope === 'TEXT'"
                        v-model="unit.content.positiveFeedback.value"
                        maxlength="2000"
                        size="small"
                        placeholder="请输入文字答案"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="action-feedback-negative">
            <div class="af-title">
                <span class="feedback-text-pre">如果反馈</span>
                <span class="feedback-text-n">{{
                    unit.content.negativeFeedback.text
                }}</span>
            </div>
            <div class="jump-cell-af">
                <el-select
                    style="width: 120px"
                    v-model="unit.content.negativeFeedback.scope"
                    size="small"
                    @change="jumpTypeValueChange($event, 'negative')"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in jumpTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <div class="right-select">
                    <!-- <el-select
                        v-if="unit.content.negativeFeedback.scope === 'NODE'"
                        v-model="unit.content.negativeFeedback.value"
                        @change="nodeSelectChange"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select> -->
                    <SelectAddnode
                        v-if="unit.content.negativeFeedback.scope === 'NODE'"
                        :nodeOptions="nodeOptions"
                        :nodeId="unit.content.negativeFeedback.value"
                        :defaultParentNodeId="nodeId"
                        :nodeSelectARef="`actionForm_feedback_n_${unitIndex}`"
                        :intentId="intentId"
                        @getNodeOptions="getNodeOptions"
                        @setAddNodeId="setAddNodeIdN"
                        :index="unitIndex"
                    ></SelectAddnode>
                    <el-cascader
                        v-if="unit.content.negativeFeedback.scope === 'INTENT'"
                        v-model="unit.content.negativeFeedback.value"
                        size="small"
                        :options="intentTree4Radio"
                        :key="'af_d_nega' + unitIndex"
                        placeholder="请选择跳转到意图名称"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    <el-input
                        size="small"
                        v-if="unit.content.negativeFeedback.scope === 'TEXT'"
                        v-model="unit.content.negativeFeedback.value"
                        maxlength="2000"
                        placeholder="请输入文字答案"
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddnode from "../cell/SelectAddnode.vue";
export default {
    name: "feedback",
    components: {
        SelectAddnode,
    },
    props: [
        "unit",
        "unitIndex",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "nodeId",
        "intentId",
    ],
    data() {
        return {
            name: "feedback",
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "TEXT",
                    label: "回复文本",
                },
                {
                    value: "NONE",
                    label: "无动作",
                },
            ],
        };
    },
    methods: {
        jumpTypeValueChange(e, type) {
            if (type == "positive") {
                this.unit.content.positiveFeedback.value = "";
            } else {
                this.unit.content.negativeFeedback.value = "";
            }
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeIdP(nodeId) {
            this.$set(this.unit.content.positiveFeedback, "value", nodeId);
        },
        setAddNodeIdN(nodeId) {
            this.$set(this.unit.content.negativeFeedback, "value", nodeId);
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.action-feedback-positive,
.action-feedback-negative {
    height: 99px;
    background: #fbfcfd;
    border-radius: 5px;
    margin-bottom: 12px;
    .af-title {
        display: flex;
        justify-content: flex-start;
        padding: 12px 16px;
        align-items: center;
        .feedback-text-pre {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            margin-right: 8px;
        }
        .feedback-text-p {
            height: 21px;
            background: #e7edff;
            border-radius: 1px;
            color: #616161;
            padding: 0 4px;
        }
        .feedback-text-n {
            height: 21px;
            background: #eaecee;
            border-radius: 1px;
            color: #616161;
            padding: 0 4px;
        }
    }
    .jump-cell-af {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        background-color: white;
        border-radius: 5px;
        margin: 0 16px;
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
        .left-select {
            width: 120px;
            flex: none;
        }
        .right-select {
            flex: auto;
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>