<template>
    <div class="intent-sort">
        <popup @closeEvent="closeIntentSort" v-if="visible" :isNeedScroll="false">
            <div slot="popup-name">意图排序</div>
            <div slot="popup-tip">拖拽可改变文件顺序</div>
            <div slot="popup-con">
                 <ul class="intent-sort-list">
                    <li
                        v-for="(cell, index) in intentList"
                        :key="index"
                        :class="['intent-sort-cell',index === 0 ? 'first' : '']"
                    >
                        <span class="intent-num">{{ index + 1 }}</span>
                        <span class="intent-name">{{ cell.name }}</span>
                        <!-- <span class="arsenal_icon arsenalpaixu icon-handle-sort"></span> -->
                        <i  class="iconfont guoran-a-16-10"></i>
                        <i  class="iconfont guoran-tongyichicun-16-16-paixuhover"></i>
                    </li>
                </ul>
            </div>
              <div slot="dialog-footer">
                <el-button @click="closeIntentSort"  plain class="cancel-btn">取消</el-button>
                <el-button type="primary"   @click="saveIntentSort"  class="confirm-btn">保存</el-button>
            </div>
        </popup>
       
    </div>
</template>
<script>
import Sortable from "sortablejs";
import popup from '../../../components/popupNew.vue'
export default {
    props:['visible'],
    components:{popup},
    data() {
        return {
            activeClass: "",
            intentList: [],
        };
    },
    methods: {
        // 获取意图列表
        getIntentList(activeClass) {
            this.activeClass = activeClass;
            let params = {
                typeId: activeClass,
            };
            this.FetchGet(this.requestUrl.intent.getIntentList, params).then(
                (res) => {
                    if (res.code === "0") {
                        this.intentList = res.data;
                        this.$nextTick(() => {
                            this.rowDrop();
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 保存排序
        saveIntentSort() {
            this.FetchPost(this.requestUrl.intent.intentSort, {
                typeId: this.activeClass,
                intentIds: this.intentList.map((item) => item.id),
            }).then((res) => {
                if (res.code === "0") {
                    this.$emit("closeIntentSort", { type: "save" });
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 关闭排序
        closeIntentSort() {
            this.$emit("closeIntentSort", { type: "close" });
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */
        rowDrop() {
            const box = document.querySelector(".intent-sort-list");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".iconfont",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.intentList.splice(oldIndex, 1);
                    _this.intentList.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.intentList = _this.intentList;
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.intent-sort {
    .tips{
        margin-bottom: 40px;
    }
    .intent-sort-list {
        height: 350px;
        overflow-y: auto;
        .intent-sort-cell {
            height: 41px;
            line-height: 42px;
            display: flex;
            justify-content: flex-start;
            border-bottom:  1px solid #E0E6F7;
            align-items: center;
            color: #616161;
            font-size: 14px;
            cursor: pointer;
            padding: 0 10px;
            &.first{
                border-top:  1px solid #E0E6F7;
            }
            .intent-num {
                text-align: center;
                height: 16px;
                width: 16px;
                background: #FFFFFF;
                border: 1px solid #366AFF;
                border-radius: 5px;
                line-height: 18px;
                background-color: #fff;
                color: #366AFF;
                margin-right: 35px;
                font-size: 12px;
            }
            .intent-name {
                flex: auto;
                text-align: left;
                padding-left: 8px;
                overflow: hidden;
                color: #616161;
            }
            .icon-handle-sort {
                font-size: 16px;
                color: #A9B3C6;
                cursor: pointer;
            }
            .guoran-a-16-10{
                color: #A9B3C6;
                display: inline-block;
            }
            .guoran-tongyichicun-16-16-paixuhover{
                display: none;
            }
            &:hover{
                background-color: #F6F8FD;
                .guoran-a-16-10{
                    display: none !important;
                }
                .guoran-tongyichicun-16-16-paixuhover{
                    display: inline-block !important;
                    color:#366AFF ;
                }
            }
        }
        .intent-sort-cell:hover {
            background-color: #F6F8FD;
            .icon-handle-sort{
                 color: #366AFF;
            }
        }
    }
    .dialog-footer {
        text-align: center;
        margin-top: 30px;
        .el-button{
            width: 99px;
            height: 37px;
            border-radius: 19px;
            border-color: #E0E6F7;
            padding: 0;
            text-align: center;
            font-size: 14px;
            &.el-button--primary{
                border-color: #366AFF;
                background-color: #366AFF;
            }

        }

    }
}
</style>