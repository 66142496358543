<template>
    <div id="form-submit">
        <!------提交表单-------->
        <div class="respose-text-content info_card-content">
            <div class="simple-cell-satisfaction">
                <div class="title">
                    <span>选择表单</span>
                </div>
                <div class="action_form_cascader">
                    <el-cascader
                        size="small"
                        v-model="unit.content.form"
                        :key="'fs_top_name' + unitIndex"
                        :options="formOptions"
                        :props="cascaderProps"
                        @change="formChange"
                        @focus="formFocus"
                    >
                        <template slot-scope="{ node, data }">
                            <span
                                style="color: #c0c4cc"
                                v-if="botVersion == 2 && node.value == 'CUSTOM'"
                                >{{ data.name }}</span
                            >
                            <span v-else>{{ data.name }}</span>
                        </template>
                    </el-cascader>
                </div>
            </div>
            <div class="simple-cell-satisfaction">
                <div class="title">
                    <i>* </i>
                    <span>表单显示名称{{unit.content.originForm.formShowName}}</span>
                </div>
                <div class="action_form_cascader">
                    <el-input
                        size="small"
                        v-model="unit.content.originForm.formShowName"
                        placeholder="请输入内容"
                        @input="changeFormShowName"
                    ></el-input>
                </div>
            </div>
            <div v-if="unit.content.relation.length > 0" class="key-relation">
                <div class="key-relation-title">
                    <span class="key-relation-title-name">字段</span>
                    <span class="key-relation-title-entity">智能填值 </span>
                    <span class="key-relation-title-visible">是否可见</span>
                </div>
                <div class="key-relation-content">
                    <div
                        v-for="(item, itemIndex) in filterForm(
                            unit.content.relation
                        )"
                        :key="itemIndex"
                        class="key-relation-cell"
                        v-show="
                            item.fieldId !== 'workorder_name' &&
                            item.formField.type !== 'CHANNEL' &&
                            item.formField.type !== 'CHAT_RECORD'
                        "
                    >
                        <span
                            class="key-relation-title-name"
                            v-if="
                                item.fieldId !== 'workorder_clientId' &&
                                item.fieldId !== 'workorder_description'
                            "
                            >{{ item.formField.name }}</span
                        >
                        <span
                            class="key-relation-title-name"
                            v-if="item.fieldId === 'workorder_clientId'"
                        >
                            报单人</span
                        >
                        <span
                            class="key-relation-title-name"
                            v-if="item.fieldId === 'workorder_description'"
                        >
                            问题描述
                        </span>
                        <div class="key-relation-title-entity">
                            <el-cascader
                                placeholder="请选择实体"
                                size="small"
                                :key="'f__' + itemIndex + new Date()"
                                v-if="isStartOrEnd"
                                clearable
                                v-model="item.entity"
                                :options="entityOptionsBot"
                            ></el-cascader>
                            <el-cascader
                                placeholder="请选择实体"
                                v-if="
                                    !isStartOrEnd &&
                                    item.paramName !== 'clientId'
                                "
                                :key="'f__' + itemIndex + new Date()"
                                clearable
                                size="small"
                                v-model="item.entity"
                                :options="entityOptions.filter(item => {
                                    if (item.children.length > 0) {
                                        return item;
                                    }
                                })"
                            ></el-cascader>
                            <div
                                v-if="
                                    !isStartOrEnd &&
                                    item.paramName === 'clientId'
                                "
                                class="client-tip"
                            >
                                如果是企业内部员工，会自动填写当前报单的员工
                            </div>
                        </div>
                        <span class="key-relation-title-visible">
                            <el-checkbox
                                style="padding: 0"
                                v-model="item.display"
                                :disabled="
                                    (item.required &&
                                        !item.value &&
                                        unit.content.form[0] !== 'CUSTOM') ||
                                    item.paramName === 'clientId'
                                        ? true
                                        : false
                                "
                            ></el-checkbox>
                        </span>
                    </div>
                </div>
            </div>
            <div class="simple-cell-satisfaction">
                <span class="title">如果用户提交了表单</span>
                <div class="inline-border-outer">
                    <div
                        class="
                            action_form_cascader action_form_cascader-relation
                        "
                    >
                        <el-select
                            v-model="unit.content.submitSuccess.scope"
                            size="small"
                            placeholder="请选择"
                            @change="submitSuccessChangeOptions"
                        >
                            <el-option
                                v-for="item in jumpTypeAddContentOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div
                        v-if="unit.content.submitSuccess.scope === 'INTENT'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <el-cascader
                            v-model="unit.content.submitSuccess.value"
                            size="small"
                            :options="intentTree4Radio"
                            :key="'f__intents' + itemIndex + new Date()"
                            placeholder="请选择跳转到意图"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                    <div
                        v-if="unit.content.submitSuccess.scope === 'NODE'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <!-- <el-select
                            v-model="unit.content.submitSuccess.value"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :key="item.id"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            :nodeOptions="nodeOptions"
                            :nodeId="unit.content.submitSuccess.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionForm_submit_s_${unitIndex}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeId"
                            :index="index"
                        ></SelectAddnode>
                    </div>
                    <div
                        v-if="unit.content.submitSuccess.scope === 'CONTENT'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <el-input
                            v-model="unit.content.submitSuccess.value"
                            maxlength="200"
                            size="small"
                            placeholder="请输入文字答案"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="simple-cell-satisfaction">
                <span class="title">如果提交表单失败</span>
                <div class="inline-border-outer">
                    <div
                        class="
                            action_form_cascader action_form_cascader-relation
                        "
                    >
                        <el-select
                            v-model="unit.content.submitError.scope"
                            size="small"
                            placeholder="请选择"
                            @change="submitErrorChangeOptions"
                        >
                            <el-option
                                v-for="item in jumpTypeAddContentOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div
                        v-if="unit.content.submitError.scope === 'INTENT'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <el-cascader
                            v-model="unit.content.submitError.value"
                            size="small"
                            :options="intentTree4Radio"
                            placeholder="请选择跳转到意图"
                            :key="'f__intentf' + itemIndex + new Date()"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                    <div
                        v-if="unit.content.submitError.scope === 'NODE'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <!-- <el-select
                            v-model="unit.content.submitError.value"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :key="item.id"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            :nodeOptions="nodeOptions"
                            :nodeId="unit.content.submitError.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionForm_submit_e_${unitIndex}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeIdE"
                            :index="index"
                        ></SelectAddnode>
                    </div>
                    <div
                        v-if="unit.content.submitError.scope === 'CONTENT'"
                        class="
                            action_form_cascader action_form_cascader-content
                        "
                    >
                        <el-input
                            size="small"
                            v-model="unit.content.submitError.value"
                            maxlength="2000"
                            placeholder="请输入文字答案"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip :tipIndex="tipIndex" />
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="versionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
import VersionTip from "../../../version/VersionTip.vue";
import SelectAddnode from "../cell/SelectAddnode.vue";
export default {
    name: "Form_submit",
    components: {
        VersionTip,
        Popup,
        SelectAddnode,
    },
    props: [
        "unit",
        "unitIndex",
        "intentSAkillTypeOptionsNoChildren",
        "nodeOptions",
        "isStartOrEnd",
        "entityOptionsBot",
        "entityOptions",
        "intentTree4Radio",
        "activeBot",
        "botVersion",
        "nodeId",
        "intentId",
    ],
    data() {
        return {
            versionTipDialog: false,
            tipIndex: 5,
            botVersion: "",
            preFormValue: "",

            formOptions: [],
            cascaderProps: {
                value: "processConfigId",
                label: "name",
            },
            jumpTypeAddContentOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "CONTENT",
                    label: "向用户发送内容",
                },
            ],
        };
    },

    methods: {
        changeFormShowName(val){
            this.unit.content.originForm.formShowName = val;
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId) {
            this.$set(this.unit.content.submitSuccess, "value", nodeId);
        },
        setAddNodeIdE(nodeId) {
            this.$set(this.unit.content.submitError, "value", nodeId);
        },
        filterForm(arr) {
            let tamp = [];
            arr.forEach((item) => {
                if (item.paramName === "clientId") {
                    this.$set(item, "display", false);
                }
                if (item.formField.type != "EXPLANATION") {
                    tamp.push(item);
                }
            });
            return tamp;
        },
        submitSuccessChangeOptions() {
            this.unit.content.submitSuccess.value = "";
        },
        submitErrorChangeOptions() {
            this.unit.content.submitError.value = "";
        },

        formFocus() {
            this.preFormValue = JSON.stringify(this.unit.content.form);
        },

        /**
         * 选择具体表单
         * @param {}
         * @return {}
         */
        formChange(value) {
            if (this.botVersion == 2 && value == "CUSTOM") {
                this.unit.content.form = JSON.parse(this.preFormValue);
                this.tipIndex = 5;
                this.versionTipDialog = true;
                return false;
            }
            this.formOptions.forEach((item) => {
                if (item.processConfigId == value[0]) {
                    item.children.forEach((cell) => {
                        if (cell.processConfigId == value[1]) {
                            this.unit.content.originForm = cell;
                            this.unit.content.relation = cell.formFieldRelation;
                            this.unit.content.relation.defaultValue =
                                cell.formFieldRelation.value;
                        }
                        // 默认赋值 表单显示名称
                        if (
                            value[0] === item.value &&
                            value[1] === cell.processConfigId
                        ) {
                            this.unit.content.originForm.formShowName = cell.name;
                        }
                    });
                }
            });
        },
        /**
         * 获取表单树并赋值
         * @param {}
         * @return {}
         */
        getFormTree() {
            this.FetchGet(
                this.requestUrl.form.formTree4bot + this.activeBot,
                {}
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.formOptions = [
                            {
                                value: "WORK_ORDER",
                                label: "系统工单",
                                name: "系统工单",
                                processConfigId: "WORK_ORDER",
                                children: res.data.系统工单,
                            },
                            {
                                value: "CUSTOM",
                                label: "自定义表单",
                                name: "自定义表单",
                                processConfigId: "CUSTOM",
                                children:
                                    this.botVersion == 2
                                        ? null
                                        : res.data.自定义表单,
                            },
                        ];
                        this.$nextTick(() => {
                            if(!this.unit.content.originForm.formShowName) {
                                if (this.unit.content.form[1] != '') {
                                    this.formOptions.forEach(item => {
                                        item.children.forEach(cell => {
                                            if (cell.processConfigId == this.unit.content.form[1]) {
                                                this.$set(this.unit.content.originForm, 'formShowName', cell.name);
                                                console.log(this.unit.content.originForm.formShowName);
                                            }
                                        })
                                    })
                                }
                            }
                        })
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    mounted() {
        this.getFormTree();
    },
};
</script>
<style lang="less" scoped>
#form-submit {
    .key-relation {
        background-color: white;
        margin-top: 8px;
        border-radius: 4px;
        .key-relation-title {
            margin-top: 12px;
            background: #f5f7fb;
            border-radius: 4px 4px 0 0;
            display: flex;
            justify-content: space-around;
            line-height: 40px;
            height: 40px;
            text-align: left;
            font-weight: 500;
            .key-relation-title-name {
                padding-left: 12px;
                flex: none;
                width: 160px;
            }
            .key-relation-title-entity {
                flex: auto;
            }
            .key-relation-title-visible {
                flex: none;
                width: 60px;
            }
        }
        .key-relation-content {
            background-color: #fbfcfd;
            padding: 3px 0;
            .key-relation-cell {
                display: flex;
                justify-content: space-around;
                padding: 6px 0;
                display: flex;
                align-items: center;

                .key-relation-title-name {
                    flex: none;
                    width: 150px;
                    padding: 0 12px;
                    text-align: left;
                }
                .key-relation-title-entity {
                    flex: auto;
                    text-align: left;
                    .el-cascader {
                        width: 100%;
                    }
                    .el-input {
                        border: none !important;
                    }
                    .el-input__inner {
                        border: none !important;
                    }
                    .client-tip {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 33px;
                        color: #cccccc;
                        text-align: left;
                    }
                }
                .key-relation-title-visible {
                    flex: none;
                    width: 60px;
                }
            }
        }
    }
    .simple-cell-satisfaction {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
        .inline-border-outer {
            display: flex;
            justify-content: flex-start;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            .action_form_cascader-relation {
                width: 120px;
                flex: none;
            }
            .action_form_cascader-content {
                flex: auto;
            }
            .action_form_cascader {
                /deep/.inline-input {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-cascader {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-input__inner {
                    border: none;
                    padding-right: 0;
                }
                /deep/.el-input__suffix {
                    display: none;
                }
            }
        }
        .title {
            margin-top: 8px;
            text-align: left;
            margin-bottom: 8px;
            i {
                color: red;
            }
        }
        .action_form_cascader {
            .el-cascader {
                width: 100% !important;
            }
            .el-select {
                width: 100% !important;
            }
        }
        .title-other {
            margin-bottom: 8px;
            color: grey;
            text-align: left;
        }
        .el-textarea {
            width: 100%;
            margin-bottom: 12px;
        }
        .option-sa {
            display: flex;
            justify-content: flex-start;
            .el-tag {
                margin-right: 8px;
            }
            margin-bottom: 12px;
        }
        .satisfaction-radio-box {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .el-tag {
                margin-right: 4px;
                cursor: pointer;
            }
        }
    }
}
</style>