<template>
    <div class="batch-import-intnet">
        <!-- 上传选择文件弹框 -->
        <popup @closeEvent="closeEvent" v-if="importFileVisible">
            <div slot="popup-name">批量上传意图</div>
                <div slot="popup-tip">导入Excel表格行数上限为10000行，文件大小上限为2Mb</div>
            <div slot="popup-con">
                <div class="content-import-file">
                    <div class="tips"> <span>1</span> <span>根据模板要求填写模板文件</span></div>
                    <div class="tips"> <span>2</span> <span>如果知识库中已存在的问法与表格中的问法完全匹配，导入的问法将被忽略。</span></div>
                    <div class="tips"> <span>3</span> <span>选择文件，开始导入</span></div>
                    <el-upload
                        v-show="!haveFile"
                        ref="importIntentUpload"
                        class="upload-demo"
                        action="/api/intent/intentImport"
                        accept=".xlsx,.xls"
                        :data="{
                            apiKey:apiKey
                        }"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-error="uploadError"
                        :on-success="uploadSuccess"
                        :on-change="beforeUpload">
                        <div class="choose-file" >选择文件</div>
                    </el-upload>
                    <div class="have-file" v-show="haveFile">
                        <el-input v-model="fileName" :disabled="disabled"></el-input>
                        <!-- <div class="iconfont guoran-a-16-15"></div> -->
                        <div class="iconfont guoran-a-18-13" @click="delFile"></div>
                    </div>
                    <div class="no-template">还没有模板？点击下载 <span @click="downloadTemplate">《意图批量上传模板》</span></div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">取消</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('')" round :class="[haveFile ? 'have' : 'no']" style="padding:0 !important;">开始上传</el-button>
            </div>
        </popup>
        <!-- 上传成功弹框 -->
        <popup @closeEvent="closeSuccessEvent" v-if="successDialog"  :haveTips="false" class="success-dialog">
            <div slot="popup-name">上传成功</div>
            <div slot="popup-con">
                <div class="success-import-intent">
                    <div class="left">
                      <div class="circle-box">
                            <div class="border">
                            <div class="circle-item">
                                <div class="icon-box"> <i class="iconfont guoran-tongyichicun-kaiqi1"></i> </div>
                                <span>{{successMsg.addIntentNum + successMsg.updateIntentNum}}</span>
                            </div>
                            <div class="mask-squer"></div>
                        </div>
                        <div class="bottom-text">导入意图</div>
                    
                      </div>
                        <div class="line-right"></div>
                    </div>
                    <div class="right">
                       <div class="left-add">
                            <div class="circle-item">
                                <div class="icon-box"> <i class="iconfont guoran-tongyichicun-kaiqi1"></i> </div>
                                <span>{{successMsg.addIntentNum}}</span>
                            </div>
                            <div class="bottom-text ">新增意图</div>
                       </div>
                       <div class="right-update">
                            <div class="circle-item last">
                                <div class="icon-box"> <i class="iconfont guoran-tongyichicun-kaiqi1"></i> </div>
                                <span>{{successMsg.updateIntentNum}}</span>
                            </div>
                            <div class="bottom-text">完善已有意图</div>
                       </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeSuccessEvent" plain round class="cancel-btn">我知道了</el-button>
            </div>
        </popup>
        <!-- 上传进度 -->
        <popup @closeEvent="cancelImport" v-if="progressDialog" :haveTips="false" :needCloseIcon="false">
            <div slot="popup-name">正在上传</div>
            <div slot="popup-con">
                <div class="progress-box">
                    <img src="../../../assets/images/import-excel.png" alt="">
                    <div class="file-detail">
                        <div class="file-name">{{fileName}}</div>
                        <el-progress :percentage="progress" :color="'#366AFF'"></el-progress>
                        <div class="progress-info">正在上传，大约{{surplusTime}}S</div>
                    </div>
                </div>
            </div>
            <!-- <div slot="dialog-footer">
                <el-button @click="cancelImport" plain round class="cancel-btn">取消上传</el-button>
            </div> -->
        </popup>
        <!--  上传错误时 , 可下载 -->
        <errorDialog
            class="import-error-dialog"
            @closeEvent="showErrorDialog = false"
            v-if="showErrorDialog"
            >
            <div slot="popup-container" class="popup-container">
               
                <div class="prompt-text" v-if="downLoadUrl && downLoadUrl !== '' ">上传失败</div>
                <div class="down-load-tips">文件填写格式有误，请完全按 <span @click="downloadTemplate">《意图批量上传模板》</span>填写后重新上传。</div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeErrorDialog"  plain round
                >{{downLoadUrl && downLoadUrl !== '' ? '关闭' : '我知道了'}}</el-button
                >
                <el-button
                class="down-load"
                v-if="downLoadUrl && downLoadUrl !== ''"
                @click="downLoad"
                round
                >下载结果诊断</el-button
                >
            </div>
        </errorDialog>
    </div>
</template>

<script>
import Popup from '../../../components/popup.vue';
import errorDialog from "../../../components/errorDialog.vue";
export default {
    data(){
        return {
            haveFile:false,
            fileName:'',
            disabled:false,
            successDialog:false,
            progressDialog:false,
            progress:0,
            successMsg:{},
            downLoadUrl:'', // 上传错误时 , 下载的链接
            showErrorDialog:false,
            timer:null,
            surplusTime:0,
        }
    },
    components:{
        Popup,
        errorDialog
    },
   props:{
		importFileVisible:{
			default:false,
			type:Boolean
		},
        successDialog:{
            default:false,
			type:Boolean
        },
        progressDialog:{
            default:false,
			type:Boolean
        },
        apiKey:{
            default:String,
            default:""
        },

	},
    watch:{
        importFileVisible(n,o){
            if(n){
                this.haveFile = false;
                this.disabled = false;
                this.fileName = '';
            }
        },
    },
    methods:{
        closeErrorDialog(){
            this.showErrorDialog = false;
        },
        downLoad(){
            window.location.href = this.downLoadUrl;
        },
        // 关闭成功的弹框
        closeSuccessEvent(){
            this.successDialog = false;
            this.$parent.getSkillList(this.$route.query.id, false,'search');
        },
        // 关闭进度弹框
        closeProprogressEvent(){
            this.progressDialog = false;
        },
        // 取消上传
        cancelImport(){
            // this.FetchGet(this.requestUrl.intent.cancelImport+'?apiKey=' + this.apiKey).then((res) => {
            //     if (res.code === "0") {
            //         clearInterval(this.timer);
            //         this.progressDialog = false;
            //         this.$message.success('导入任务取消');
            //     } else {
            //         this.$message.error(res.message);
            //     }
            // });
            this.progressDialog = false;
        },
        // 关闭弹框
        closeEvent(){
            this.$emit('update:importFileVisible',false);
        },
        // 获取文件后缀名
        getFileSuffix(url,arr) {
            let index1 = url.lastIndexOf(".")+1;
            let index2 = url.length;
            let postf = url.substring(index1, index2);//后缀名
            if (!arr || arr.indexOf(postf) == -1) {
                return false;
            } else {
                return true
            }
        },
        // 上传之前的钩子
        beforeUpload(file){
            this.disabled = true;
            console.log(file,'上传之前的钩子');
            if(file.status === 'ready'){
                if(!this.getFileSuffix(file.name,['xls','xlsx'])){
                    this.$message.error('请上传Excel文件');
                    return false;
                }
                //判断上传的视频大小
                if(file.size / 1024 /1024 > 2) {
                    this.$message.error('文件大小不能超过2Mb');
                    return false;
                }
                this.fileName = file.name;
                if(this.fileName !== ''){
                    this.haveFile = true;
                }
            } else if(file.status === 'fail'){
                
            } else if(file.status === 'success'){
                // this.disabled = false;
                // this.haveFile = false;
                // this.fileName = ''
            }
        },
        // 上传失败
        uploadError(){
            this.$message.error('文件上传失败，请重新上传');
            clearInterval(this.timer);
        },
        uploadSuccess(res){
            console.log(res.code,'成功');
            if(res.code === '0'){
                this.successMsg = res.data;
                this.$emit('update:importFileVisible',false);
                this.progress = 0;
                const loading = this.$loading({
                    lock: true,
                    text: '文件识别中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(255, 255, 255, 0.9)'
                });
                setTimeout(() => {
                    loading.close();
                    clearInterval(this.timer);
                    this.progressDialog = true;
                    this.timer = setInterval(() => {
                        this.FetchGet(this.requestUrl.intent.getImportProgress).then((res) => {
                            if (res.code === "0") {
                                this.progress = Math.ceil(res.data.progress) || 0;
                                this.surplusTime = res.data.surplusTime;
                                if(this.progress === 100 || this.surplusTime === 0){
                                    setTimeout(() => {
                                        clearInterval(this.timer);
                                        this.progressDialog = false;
                                        this.successDialog = true;
                                    },500)
                                }
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    },1000)
                }, 1000);
                   
            } else if (res.code === '-3') {
                this.$emit('update:importFileVisible',false);
                this.downLoadUrl = res.message;
                this.showErrorDialog = true;
            } else {
                this.$message.error(res.message);
                this.haveFile = false;
                this.disabled = false;
                this.fileName = ''
            }
        },
        // 删除文件
        delFile(){
            this.fileName = '';
            this.haveFile = false;
        },
        // 下载模板
        downloadTemplate(){
            window.location.href = 'https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/intent/%E6%84%8F%E5%9B%BE%E6%89%B9%E9%87%8F%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx';
        },
        // 保存
        submitForm(){
            let flag = true;
            if(this.fileName === ''){
                flag = false;
                this.$message.error('请选择文件');
				return false;
            }
            if(flag){
                this.$refs.importIntentUpload.submit();  // 手动上传
            } 
        }
    }

}
</script>

<style scoped lang="less">
    .batch-import-intnet{
        /deep/ #popup-assembly{
            width: 646px;
             .popup-header{
                padding: 55px 50px 50px !important;
            }
            .popup-container{
                overflow-y: inherit !important;
            }
        }
        /deep/ .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
        .content-import-file{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .tips{
                text-align: left;
                white-space: nowrap;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 36px;
                >span:first-child{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: #A1B9FF;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 20px;
                    color: #fff;
                    margin-right: 5px;
                }
            }
            .have-file{
                margin: 20px 0;
                display: flex;
                .el-input{
                    height: 38px;
                    width: 440px;
                }
                .iconfont{
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    text-align: center;
                    line-height: 34px;
                    font-size: 16px;
                    color: #366AFF;
                    border-radius: 5px;
                    cursor: pointer;
                    &.guoran-a-16-15{
                        background-color: #366AFF;
                        border: 1px solid #366AFF;
                        color: #fff;
                        margin: 0 10px;
                    }
                    &.guoran-a-18-13{
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        margin-left: 10px;
                    }
                        
                    
                }

            }
            .choose-file{
                width: 116px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #B7CAFF;
                border-radius: 19px;font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                text-align: center;
                line-height: 40px;
                margin: 25px 0;
            }
            .no-template{
                height: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                text-align: left; 
                padding-bottom: 26px;
                span{
                    color: #366AFF;
                    cursor: pointer;
                }
            }
            .upload-demo{
                display: flex;
                justify-content: flex-start;
            }
        }
        /deep/ .dialog-footer{
            padding-top: 46px !important;
            .el-button{
                width: 99px;
                height: 37px;
                padding: 0;
            }
            .have{
                background: #366AFF;
                border-color: #366AFF;
            }
            .no{
                background: #E0E5F0;
                border-color: #E0E5F0;
            }
            .down-load{
                width: 139px;
                height: 37px;
                background: #FFFFFF;
                border: 2px solid #C7D5FF;
                border-radius: 19px;
                font-size: 14px;
                color: #366AFF;
                cursor: pointer;
            }
        }
        .success-import-intent{
            display: flex;
            align-items: center;
            height: 140px;
            padding: 0 30px;
            .circle-item{
                width: 71px;
                height: 71px;
                background: #ECF1FF;
                border-radius: 50%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                font-size: 24px;
                .icon-box{
                    width: 24px;
                    height: 24px;
                    background: #749BFF;
                    border-radius: 50%;
                    position: absolute;
                    top: 4px;
                    right: -11px; 
                    color: #749BFF;
                    z-index: 2;
                    text-align: center;
                    line-height: 24px;
                    .iconfont{
                        color: #fff;
                        font-size: 13px;
                    }
                }
            }
            .left{
                display: flex;
                position: relative;
                margin-right: 50px;
                 height: 140px;
                .border{
                    width: 89px;
                    height: 89px;
                    border: 3px solid #D9E3FF;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .circle-item{
                        width: 69px;
                        height: 69px;
                        background: #366AFF;
                        border-radius: 50%;
                        border: 1px solid #D9E3FF;
                        border-radius: 50%;
                    }
                }
                .bottom-text{
                    margin-top: 22px;
                    color: #366AFF;
                    font-size: 16px;
                    white-space: nowrap;
                }
                .mask-squer{
                    position: absolute;
                    background-color: #fff;
                    width: 30px;
                    height: 40px;
                    bottom: 50px;
                    right: -17px;
                    border-radius: 12px;
                }
                .line-right{
                    width: 2px;
                    height: 57px;
                    background: #A2BAFF;
                    margin-left: 70px;
                    margin-top: 17px;
                }
            }
            .right{
                display: flex;
                align-items: center;
                height: 140px;
                margin-top: 10px;
                .circle-item{
                    background: #ECF1FF;
                    font-size: 20px;
                    color: #749BFF;
                    .icon-box{
                        right: -8px;
                    }
                }
                .left.right{
                    display: flex;
                    flex-direction: column;
                    width: 71px;
                    height: 71px;
                    .bottom-text{
                        margin-top: 30px;
                        color: #fff;
                        font-size: 16px;
                        white-space: nowrap;
                    }
                }
                .right-update{
                    margin-left: 100px;
                    
                }
                .bottom-text{
                    margin-top: 40px;
                    color: #616161;
                    font-size: 16px;
                    white-space: nowrap;
                }
            }
        }
        .progress-box{
            background: #FFFFFF;
            border: 1px dotted #E0E6F7;
            border-radius: 1px;
            padding: 20px 35px 12px 35px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 74px;
            img{
                width: 33px;
                height: 38px;
                margin-right: 11px;
                margin-top: -5px;
            }
            .file-detail{
                width: 100%;
                .file-name{
                    height: 15px;
                    line-height: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    text-align: left;
                    margin-bottom: 11px;
                }
                /deep/ .el-progress{
                    width: 100%;
                    text-align: left;
                    .el-progress__text{
                        position: absolute;
                        bottom: 20px;
                        right: 50px;
                        margin-left: 0;
                    }
                    .el-progress-bar__outer{
                        background-color:#E8EEFF;
                    }
                }
                .progress-info{
                    text-align: left;
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #A9B3C6;
                    line-height: 14px;
                }
            }
        }
        /deep/ #delete-dialog{
            #delete-popup-assembly{
                width: 646px !important; 
            }
            &.import-error-dialog{
                .down-load-tips{
                    font-size: 14px;
                    font-weight: 400;
                    color: #A3ADC6;
                    margin-top: 27px;
                    span{
                        color: #366AFF;
                        cursor: pointer;
                    }
                }
                .prompt-text{
                    font-size: 22px;
                    color: #000 !important;
                }
            }
        }
        
    }
</style>