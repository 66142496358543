<template>
    <div class="components-header">
        <div class="respose-text-title">
            <div class="type-text">
                <span>{{ compObj[unit.type] }}</span>
            </div>
            <el-button
                v-if="unit.conditionList.length === 0"
                @click="conditionEcho('addAnd', [index, 0, 0])"
                size="mini"
                type="text"
                icon="el-icon-plus"
                >添加条件</el-button
            >
            <el-button
                v-if="unit.effectiveTime.range == 'PERMANENT'"
                @click="addEffectiveTime(index, unit)"
                icon="el-icon-plus"
                size="mini"
                type="text"
                >添加生效时间</el-button
            >
            <div class="unit-handle">
                <!-- <el-tooltip content="上移" placement="bottom" effect="light">
                    <em
                        v-show="index !== 0"
                        @click="handleUnit('up', index)"
                        class="el-icon-top"
                    ></em>
                </el-tooltip>
                <el-tooltip content="下移" placement="bottom" effect="light">
                    <em
                        v-show="index !== actionList.length - 1"
                        @click="handleUnit('down', index)"
                        class="el-icon-bottom"
                    ></em>
                </el-tooltip>
                <el-tooltip content="置顶" placement="bottom" effect="light">
                    <em
                        v-show="index !== 0"
                        @click="handleUnit('top', index)"
                        class="el-icon-upload2"
                    ></em>
                </el-tooltip>
                <el-tooltip content="置底" placement="bottom" effect="light">
                    <em
                        v-show="index !== actionList.length - 1"
                        @click="handleUnit('bottom', index)"
                        class="el-icon-download"
                    ></em>
                </el-tooltip> -->
                <el-tooltip content="拖动" placement="bottom" effect="light">
                    <em
                        class="
                            iconfont
                            guoran-tongyichicun-16-10-paixu
                            icon-handle
                        "
                    ></em>
                </el-tooltip>
                <el-tooltip content="删除" placement="bottom" effect="light">
                    <em
                        @click="handleUnit('delete', index)"
                        class="iconfont guoran-tongyichicun-16-09-shanchu2"
                    ></em>
                </el-tooltip>
            </div>
        </div>
        <div class="condition-outer condition-outer-mt-10">
            <div
                v-if="unit.effectiveTime.range != 'PERMANENT'"
                class="effective-time condition-row text-a-l"
            >
                <div class="condition-cell inlineBlock">
                    <span
                        v-if="unit.effectiveTime.range === 'TIME'"
                        @click="addEffectiveTime(index, unit)"
                        size="mini"
                        type="warning"
                        >生效时间：{{
                            effectiveTimeFormatDateTime(
                                unit.effectiveTime.startTime
                            ).split(" ")[1]
                        }}
                        <span> 至 </span>
                        {{
                            effectiveTimeFormatDateTime(
                                unit.effectiveTime.endTime
                            ).split(" ")[1]
                        }}
                        <span class="inner-repeat">
                            <span> 重复：</span>
                            <span
                                v-for="(wekCell, wekCellIndex) in formatWekList(
                                    unit.effectiveTime.repeat
                                )"
                                :key="wekCell"
                                >{{ wekCell
                                }}{{
                                    wekCellIndex ==
                                        unit.effectiveTime.repeat.length - 1 ||
                                    unit.effectiveTime.repeat.length == 7 ||
                                    unit.effectiveTime.repeat.length == 0
                                        ? ""
                                        : ","
                                }}</span
                            >
                        </span>
                    </span>
                    <span
                        v-if="unit.effectiveTime.range === 'DATETIME'"
                        @click="addEffectiveTime(index, unit)"
                        size="mini"
                        type="warning"
                        >生效时间：{{
                            effectiveTimeFormatDateTime(
                                unit.effectiveTime.startTime
                            )
                        }}
                        <span> 至 </span>
                        {{
                            effectiveTimeFormatDateTime(
                                unit.effectiveTime.endTime
                            )
                        }}
                    </span>
                    <span
                        v-if="unit.effectiveTime.range === 'EFFECTIVE_TIME'"
                        @click="addEffectiveTime(index, unit)"
                        size="mini"
                        type="warning"
                        >生效时间：{{
                            effectiveTimeFormatDateTime(
                                unit.effectiveTime.startTime
                            )
                        }}
                        - 不限
                    </span>
                </div>
            </div>
        </div>
        <span
            class="dev-line"
            v-if="
                unit.effectiveTime.range != 'PERMANENT' &&
                unit.conditionList.length != 0
            "
        ></span>
        <div
            class="condition-outer"
            v-for="(cell, ind) in unit.conditionList"
            :key="ind"
        >
            <div v-if="ind !== 0" class="ralation-text-color-or">或</div>
            <div class="condition-row condition-row-c text-a-l">
                <div
                    v-for="(miniUnit, miniuIndex) in cell"
                    :key="miniuIndex"
                    class="condition-cell inlineBlock"
                >
                    <el-tag
                        v-if="miniUnit.scope === 'ENTITY'"
                        @click="conditionEcho('edit', [index, ind, miniuIndex])"
                        @close="deleteCondition(index, ind, miniuIndex)"
                        closable
                        size="mini"
                        >[ {{ miniUnit.variable }} ]
                        <span class="relation-name">{{
                            miniUnit.ext.relationName
                        }}</span>
                        [ {{ miniUnit.value }} ]</el-tag
                    >
                    <el-tag
                        v-if="miniUnit.scope === 'WEB_HOOK_RESPONSE'"
                        @click="conditionEcho('edit', [index, ind, miniuIndex])"
                        @close="deleteCondition(index, ind, miniuIndex)"
                        closable
                        size="mini"
                        >[{{ miniUnit.ext.response }}]
                        <span class="relation-name">{{
                            miniUnit.ext.relationName
                        }}</span>
                        [ {{ miniUnit.value }} ]</el-tag
                    >
                    <el-tag
                        v-if="miniUnit.scope === 'USER_ATTRIBUTE'"
                        @click="conditionEcho('edit', [index, ind, miniuIndex])"
                        @close="deleteCondition(index, ind, miniuIndex)"
                        closable
                        size="mini"
                        >[{{ miniUnit.ext.userAttributeTypeName }}]
                        <span class="relation-name">{{
                            miniUnit.ext.relationName
                        }}</span>
                        <span
                            v-if="
                                miniUnit.relation !== 'NOT_EQUAL' &&
                                miniUnit.relation !== 'EQUAL'
                            "
                            >[
                            <span v-for="(u_cell, u_cellindex) in getUserAttributeNameValueStr( miniUnit.ext.userAttributeObj)" :key="u_cellindex">
                                <span v-if="!u_cell.isThirdData">{{u_cell.name}}</span>
                                <open-data :type="miniUnit.ext.relationName.indexOf('部门') != -1 ? 'departmentName' : 'userName'" :openid="u_cell.name"></open-data>
                            </span>
                            <span v-if="miniUnit.ext.userAttributeObj.length > 1">...</span>
                            ]</span
                        ></el-tag
                    >
                    <span
                        class="ralation-text-color-and"
                        v-if="miniuIndex != cell.length - 1"
                        >并</span
                    >
                </div>
                <span
                    class="add-and-condition"
                    @click="conditionEcho('addAnd', [index, ind])"
                    ><i class="el-icon-plus"></i
                ></span>
            </div>

            <div class="add-or-condition text-a-l">
                <span
                    v-if="ind == unit.conditionList.length - 1"
                    @click="conditionEcho('addOr', [index])"
                    class="el-icon-plus-box"
                    ><i class="el-icon-plus"></i
                ></span>
                <el-button
                    v-if="ind == unit.conditionList.length - 1"
                    @click="conditionEcho('addOr', [index])"
                    size="mini"
                    type="text"
                    >添加或者条件</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import OpenData from "./../../../../components/openData";
export default {
    props: ["unit", "actionList", "index", "isStartOrEnd"],
    components: { OpenData },
    data() {
        return {
            compObj: {},
        };
    },
    methods: {
        getUserAttributeNameValueStr(list) {
            if (list.length <= 1) {
                return list;
            } else {
                return [list[0], list[1]]
            }
        },
        conditionEcho(type, list) {
            this.$emit("conditionEcho", type, list);
        },
        deleteCondition(index, ind, miniuIndex) {
            this.$emit("deleteCondition", index, ind, miniuIndex);
        },
        addEffectiveTime(index, unit) {
            this.$emit("addEffectiveTime", index, unit);
        },
        handleUnit(type, index) {
            this.$emit("handleUnit", type, index);
        },
        effectiveTimeFormatDateTime(value) {
            let date = new Date(value);
            let str = "";
            if (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                var h = date.getHours();
                h = h < 10 ? "0" + h : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                var second = date.getSeconds();
                second = second < 10 ? "0" + second : second;
                str =
                    y +
                    "-" +
                    m +
                    "-" +
                    d +
                    " " +
                    h +
                    ":" +
                    minute +
                    ":" +
                    second;
            }

            return str;
        },
        formatWekList(data) {
            let list = [],
                wekList = [
                    { lable: "周一", id: "1" },
                    { lable: "周二", id: "2" },
                    { lable: "周三", id: "3" },
                    { lable: "周四", id: "4" },
                    { lable: "周五", id: "5" },
                    { lable: "周六", id: "6" },
                    { lable: "周日", id: "7" },
                ];
            wekList.forEach((item) => {
                if (data.indexOf(item.id) != -1) {
                    list.push(item.lable);
                }
            });
            if (list.length == 7) {
                list = ["每天"];
            }
            if (list.length == 0) {
                list = ["无"];
            }
            return list;
        },
    },
    mounted() {
        // 答案类型列表
        this.FetchGet(this.requestUrl.chatFlowComponent.getComponentsTypes + "?version=preol-20221118", {})
            .then((res) => {
                if (res.code === "0") {
                    let compObj = {};
                    res.data.forEach((item) => {
                        item.components.forEach((cell) => {
                            compObj[cell.id] = cell.name;
                        });
                    });
                    this.compObj = compObj;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
    },
};
</script>
<style lang="less" scoped>
.components-header {
    .condition-outer-mt-10 {
        margin-top: 10px;
        margin-bottom: 6px;
    }
    .dev-line {
        margin: 12px 24px;
        display: block;
        width: 592px;
        border-top: 1px dashed #e0e6f7;
    }
    .condition-outer {
        padding: 0 24px;
        .effective-time {
            background-color: #ecf1ff;
            height: 36px;
            line-height: 36px;
            padding: 0 12px;
            .inner-repeat {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                background-color: white;
                font-size: 13px;
                padding: 0 6px;
                border-radius: 4px;
                margin-left: 16px;
                color: #366aff;
            }
        }
        .condition-row-c {
            padding: 4px 6px;
            background: #ffffff;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
        }
        .condition-row {
            border-radius: 4px;

            .condition-cell {
                margin-right: 8px;

                .el-tag {
                    cursor: pointer;
                    margin: 2px 8px 2px 0;
                    background-color: rgba(0, 0, 0, 0);
                    border-color: #a1b9ff;
                    color: #616161;
                    .relation-name {
                        color: #366aff;
                        font-weight: 600;
                    }
                    /deep/.el-tag__close {
                        font-weight: 700;
                    }
                }
            }

            .add-and-condition {
                display: inline-block;
                width: 22px;
                height: 22px;
                line-height: 22px;
                background: #366aff;
                border-radius: 3px;
                text-align: center;
                cursor: pointer;
                i {
                    font-size: 12px;
                    font-weight: 500;
                    color: white;
                }
            }

            .el-button {
                padding: 0;
                margin: 0px;
            }
        }

        .ralation-text-color-and {
            display: inline-block;
            text-align: center;
            width: 23px;
            height: 20px;
            line-height: 20px;
            background: #f2f2f2;
            border-radius: 1px;
            font-size: 12px;
        }
        .ralation-text-color-or {
            width: 17px;
            height: 17px;
            background: #366aff;
            border-radius: 50%;
            font-size: 12px;
            color: white;
            text-align: center;
        }

        .text-a-l {
            text-align: left;
        }
        .add-or-condition {
            .el-icon-plus-box {
                margin-top: 10px;
                margin-right: 6px;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background: #366aff;
                border-radius: 5px;
                color: white;
                i {
                    font-weight: 700;
                }
            }
        }

        .margin-t-8 {
            margin-top: 8px;
        }

        .inlineBlock {
            display: inline-block;
        }
    }
    .respose-text-title {
        display: flex;
        display: -ms-flexbox;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 50px;
        padding: 0 24px;
        border-bottom: #e0e6f7 1px solid;
        /deep/.el-button [class*="el-icon-"] + span {
            margin-left: 0px;
        }
        /deep/.el-button {
            font-size: 13px;
            font-weight: 600;
            margin-right: 12px;
            .el-icon-plus {
                font-weight: 700;
            }
        }

        .type-text {
            margin-right: 16px;
            font-size: 16px;
            .info_card_des {
                font-size: 14px;
                display: inline-block;
                color: orange;
                margin: 0 8px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .el-dropdown {
            height: 30px;
            line-height: 30px;
            margin-left: 20px;
        }

        .el-dropdown-menu,
        .el-popper {
            width: 300px;
            padding-left: 20px 10px 10px 10px;
        }

        .unit-handle {
            flex: auto;
            text-align: right;
            color: #366aff;
            font-size: 20px;
            font-weight: 500;

            em {
                display: inline-block;
                margin: 0 4px;
                cursor: pointer;
            }
        }
    }
}
</style>