<template>
    <div class="notice-drewer-content">
        <div class="notice-drewer-header">
            <div class="title">通知中心</div>
            <div class="close">
                <span @click="closeNoticeDrewer" class="el-icon-close"></span>
            </div>
        </div>
        <div class="notice-drewer-section">
            <div
                v-for="(item, noticeIndex) in noticeList"
                :key="noticeIndex"
                class="notice-cell"
            >
                <!-- <div class="notice-cell-time">{{ time(item.updateTime) }}</div> -->
                <div class="notice-cell-title">
                    <span v-if="item.operateType != 4">模板中意图</span>
                    <span v-if="item.operateType == 4">模板中新增意图</span>
                    <span class="intent-name"
                        >【{{ item.parentIntentName }}】</span
                    >
                    <span class="intent-type">{{
                        operateTypeName[item.operateType]
                    }}</span>
                    <span
                        v-if="
                            (item.operateType == 0 ||
                                item.operateType == 1 ||
                                item.operateType == 2) &&
                            item.syncStatus == 1
                        "
                        >，当前机器人中该意图</span
                    >
                    <span
                        v-if="
                            item.operateType != 3 &&
                            item.operateType != 4 &&
                            item.syncStatus != 1
                        "
                        >。</span
                    >
                    <span v-if="item.syncStatus == 1" class="intent-type">{{
                        operateTypeNameAs[item.operateType]
                    }}</span>
                    <span
                        v-if="
                            (item.operateType == 0 ||
                                item.operateType == 1 ||
                                item.operateType == 2) &&
                            item.syncStatus == 1
                        "
                        >。</span
                    >
                    <span v-if="(item.operateType == 3 || item.operateType == 4) && item.processSyncStatus"
                        >，请尽快同步意图流程~</span
                    >
                    <span v-if="(item.operateType == 3 || item.operateType == 4) && !item.processSyncStatus"
                        >，当前机器人中该流程已于模板断开了流程关联，因此无法同步该意图流程！</span
                    >
                </div>

                <div v-if="item.operateType != 3" class="notice-cell-des">
                    原因：{{ item.description }}
                </div>
                <div class="allowOperate">
                    <div class="notice-cell-time"> <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i> {{ time(item.updateTime) }}</div>
                    <div class="aoBtBox">
                        <el-button
                            class="del"
                            v-if="
                                item.allowOperateTypes.indexOf('DELETE') != -1
                            "
                            @click="deleteIntent(item)"
                            icon="iconfont guoran-a-18-13"
                            size="mini"
                            round
                            plain
                            >同步删除</el-button
                        >
                        <el-button
                            v-if="
                                item.allowOperateTypes.indexOf(
                                    'SYNC_PROCESS'
                                ) != -1
                            "
                            icon="iconfont guoran-tongyichicun-14-12-zidonghualiucheng"
                            @click="confirmAsyn(item)"
                            size="mini"
                            round
                            plain
                            >同步流程</el-button
                        >
                        <el-button
                            v-if="item.allowOperateTypes.indexOf('OPEN') != -1"
                            size="mini"
                            plain
                           
                            icon="iconfont guoran-tongyichicun-kaiqi1"
                            @click="confirmClose(item, true)"
                            round
                            >同步开启</el-button
                        >
                        <el-button
                            class="close"
                            v-if="item.allowOperateTypes.indexOf('CLOSE') != -1"
                            type="warning"
                            icon="iconfont guoran-tongyichicun-guanbi"
                            size="mini"
                            plain
                            @click="confirmClose(item, false)"
                            round
                            >同步关闭</el-button
                        >
                    </div>
                </div>
                <div class="timeline-content" v-if="item.children && item.children.length > 0">
                    <el-timeline >
                        <el-timeline-item
                            v-for="(cell, index) in showMoreFormat(
                                item.children,
                                item.showMore
                            )"
                            :key="index"
                            :class="[index+1 === item.children.length ? 'last' : '']"
                            placement="bottom">
                            <div>
                                <div class="notice-cell-title">
                                    <span v-if="cell.operateType != 4" >模板中意图</span>
                                    <span v-if="cell.operateType == 4">模板中新增意图</span>
                                    <span class="intent-name">【{{ cell.parentIntentName }}】</span>
                                    <span class="intent-type">{{operateTypeName[cell.operateType]}}</span>
                                    <span
                                        v-if="
                                            (cell.operateType == 0 ||
                                                cell.operateType == 1 ||
                                                cell.operateType == 2) &&
                                            cell.syncStatus == 1
                                        ">，当前机器人中该意图</span>
                                    <span
                                        v-if="
                                            cell.operateType != 3 &&
                                            cell.operateType != 4 &&
                                            cell.syncStatus != 1
                                        ">。</span>
                                    <span
                                        v-if="cell.syncStatus == 1"
                                        class="intent-type">{{operateTypeNameAs[cell.operateType]}}</span>
                                    <span
                                        v-if="
                                            (cell.operateType == 0 ||
                                                cell.operateType == 1 ||
                                                cell.operateType == 2) &&
                                            cell.syncStatus == 1
                                        "
                                        >。</span
                                    >
                                    <span
                                        v-if="
                                            cell.operateType == 3 ||
                                            cell.operateType == 4
                                        "
                                        >，请尽快同步意图流程~</span
                                    >
                                </div>
                                <div
                                    v-if="cell.operateType != 3"
                                    class="notice-cell-des">
                                    原因：{{ cell.description }}
                                </div>
                                <p class="self-timestamp"> <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i> {{time(cell.updateTime)}}</p>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div class="show-more">
                    <span
                        @click="showMore(item.showMore, noticeIndex)"
                        v-if="!item.showMore && item.children.length > 3">
                        <i  class="el-icon-refresh"></i>    
                        加载更多
                    </span >
                    <span
                        @click="showMore(item.showMore, noticeIndex)"
                        v-if="item.showMore && item.children.length > 3">收起</span>
                </div>
            </div>
        </div>
        <pagination :pageSize="20" :currentPage="currentNoticePage" :total="totalNotice" @currentChange="handleNoticeCurrentChange"></pagination>
    </div>
</template>
<script>
import pagination from '../../../components/Pagination.vue'
export default {
    components:{
        pagination
    },
    data() {
        return {
            currentNoticePage: 1,
            totalNotice: 0,
            noticeList: [],
            noticeTypeValue: 1,
            noticeOptions: [
                {
                    label: "未读知识库模板消息",
                    value: 1,
                },
                {
                    label: "已读知识库模板消息",
                    value: 2,
                },
            ],
            operateTypeName: ["被删除", "被开启", "被关闭", "流程变更", ""],
            operateTypeNameAs: [" 同步删除", " 同步开启", " 同步关闭", ""],
        };
    },
    methods: {
        confirmAsyn(item) {
            this.$emit("confirmAsyn", {
                id: item.intentId,
            });
        },
        confirmClose(item, value) {
            this.$emit("closeIntentNotice", value, item.intentId, false, {
                reasonText: "",
                reasonValue: [],
            });
        },
        deleteIntent(item) {
            this.$emit("deleteIntent", item.intentId, false, {
                reasonText: "",
                reasonValue: [],
            });
        },
        showMoreFormat(children, showMore) {
            let list = children;
            if (!showMore) {
                list = children.slice(0, 3);
            }
            return list;
        },
        showMore(showMore, noticeIndex) {
            this.noticeList[noticeIndex].showMore = !showMore;
            this.noticeList = [...this.noticeList];
        },
        handleNoticeCurrentChange(val) {
            this.currentNoticePage = val;
            this.getIntentNoticeList();
        },
        closeNoticeDrewer() {
            this.$emit("closeNoticeDrewer");
        },
        // 分页查询意图通知
        getIntentNoticeList() {
            this.FetchGet(this.requestUrl.intentNotice.getIntentNoticeList, {
                botId: this.$route.query.id,
                pageNo: this.currentNoticePage,
                pageSize: 20,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.totalNotice = res.data.total;
                        this.noticeList = res.data.list;
                        this.noticeList.forEach((item) => {
                            item.showMore = false;
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        time(val) {
            let date = new Date(val);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            var h = date.getHours();
            h = h < 10 ? "0" + h : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            var second = date.getSeconds();
            second = second < 10 ? "0" + second : second;
            return (
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second
            );
        },
    },
    mounted() {
        this.getIntentNoticeList();
    },
};
</script>
<style lang="less" scoped>
.notice-drewer-content {
    width: 561px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .allowOperate {
        display: flex;
        justify-content: space-around;
        margin-bottom: 12px;
        align-items: center;
        .aoBtBox {
            flex: auto;
            text-align: right;
            ::v-deep .el-button{
                color: #366AFF;
                border-color: #366AFF;
                align-items: center;
                flex: none;
                &.del{
                    color: #FE5965;
                    border-color: #FE5965;
                  
                }
                &.close{
                    color: #616161;
                    border-color: #DFE3E9;
                    background-color: #fff;
                    .guoran-tongyichicun-guanbi{
                        vertical-align: bottom;
                    }
                }
                i{
                   
                    vertical-align: top;
                    margin-right: 5px;
                    font-size: 14px;
                }
            }
           
        }
        .notice-cell-time {
            text-align: left;
            flex: none;
            
          
        }
    }
    .guoran-tongyichicun-12-09-shijianriqi{
        font-size: 14px;
        margin-left: 3px;
        color: #D2D8E3;
        margin-right: 5px;
    }
    .timeline-content{
        background: #FAFAFB;
        border-radius: 5px;
        padding: 15px;
        padding-bottom: 0;
        .self-timestamp{
            color: #616161;
        }
    }
    ::v-deep .el-timeline-item{
        &.last{
            padding-bottom: 0;
        }
        .el-timeline-item__node{
            background-color: #366AFF;
        }
        .el-timeline-item__tail{
            border: 1px solid #A7BEFF;
        }
    }
    
    .show-more {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 15px;
        color: #366AFF;
        span {
            flex: auto;
            text-align: center;
        }
    }
    .notice-drewer-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: none;
        height: 45px;
        background: #F6F8FD;
        padding: 0 20px;
        .title {
            flex: none;
            font-size: 16px;
        }
        .notice-type-box {
            width: 200px;
            flex: none;
        }
        .close {
            flex: auto;
            text-align: right;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .notice-drewer-section {
        flex: auto;
        padding: 18px;
        color: #606266;
        overflow-y: scroll;
        .notice-cell {
            margin-bottom: 20px;
            padding: 15px;
            text-align: left;
            font-size: 14px;
            background: #FFFFFF;
            box-shadow: 0px 0px 9px 0px rgba(29, 55, 129, 0.14);
            border-radius: 5px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
            .notice-cell-title {
                .intent-name {
                    font-weight: 700;
                    color: #366AFF;
                }
                .intent-type {
                    font-weight: 700;
                    color: #366AFF;
                }
            }
            .notice-cell-des {
                margin-top: 6px;
                color: #909399;
                margin-bottom: 8px;
            }
            .notice-cell-time {
                margin-top: 6px;
                color: #909399;
            }
        }
    }
}
</style>