            
            
<template>
    <div class="batch-intent-baili">
        <popup v-show="innerVisible" @closeEvent="closeMoveIntentDialog" id="move-intent-dialog">
            <div slot="popup-name" class="popup-name">将意图移动至</div>
            <div slot="popup-con">
                <div class="intent-selected-box">
                    <div
                        v-if="isDefaultIntents.length > 0"
                        class="no-opration-tips"
                    >
                        以下意图为官方意图，不可参与移动操作，已自动过滤。
                    </div>
                    <div class="is-default-intents" v-if="isDefaultIntents && isDefaultIntents.length > 0">
                        <span
                            v-for="(intent, index) in isDefaultIntents"
                            :key="index"
                            >{{ intent.showName }}</span
                        >
                    </div>
                    <el-cascader
                        v-model="deraction"
                        placeholder="请选择意图位置"
                        :options="skillList"
                        :props="optionProps"
                    ></el-cascader>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button  class="cancel-btn" plain @click="closeMoveIntentDialog"
                    >关闭</el-button
                >
                <el-button
                    type="primary"
                    class="confirm-btn"
                    @click="submitOpration('move')">确认</el-button
                >
            </div>
        </popup>
        <popup 
            id="batch-operation-diaVisible"
            v-show="batchOperationDiaVisible" 
            @closeEvent="$emit('update:batchOperationDiaVisible',false)" 
            :haveTips="false"
            :isNeedScroll="false"
            :isNeedFooter="false">
            <div slot="popup-name" class="popup-name">批量操作</div>
            <div slot="popup-con">
                <div class="batch-intent-content">
                    <div class="intent-tree-content">
                        <div class="intent-tree-box">
                            <el-tree
                                ref="allIntentTree"
                                :data="intentOptions"
                                :highlight-current="true"
                                show-checkbox
                                node-key="id"
                                empty-text="暂无可操作意图，请添加意图"
                                @node-click="nodeClick"
                                @check-change="handleCheckChange"
                                :current-node-key="currentNodeKey"
                                :default-expanded-keys="defaultExpandedKeys"
                                :props="defaultProps"
                            >
                                <span :class="['custom-tree-node8',data.children && data.children.length > 0 ? 'have-children' : 'no-children']" slot-scope="{ node, data }" >
                                    <span
                                        v-if="data.type == 'BOT'"
                                        class="pre-icon-type iconfont guoran-tongyichicun-jiqirentouxiang"
                                    ></span>
                                    <span
                                        v-if="data.type == 'SKILL'"
                                        class="pre-icon-type iconfont guoran-tongyichicun-zujianzhishiku"
                                    ></span>
                                    <span
                                        v-if="data.type == 'TYPE'"
                                        class="pre-icon-type iconfont guoran-tongyichicun-morenfenlei"
                                    ></span>
                                    <span
                                        v-if="data.type == 'INTENT'"
                                        class="pre-icon-type el-icon-tickets"
                                    ></span>
                                    <span>{{ node.label }}</span>
                                    <span v-if="data.type == 'INTENT' && data.ext.isDefault" class="guan style">官方</span>
                                    <span v-if="data.type == 'INTENT' && data.ext.isShare == '1'" class="share style">共享</span>
                                    <span v-if="data.type == 'INTENT' && !data.ext.isOpen" class="close style">已关闭</span>
                                </span>
                            </el-tree>
                        </div>
                        <div class="tips-box">注意：官方意图，不可参与删除/移动操作。</div>
                    </div>
                    <div class="intent-selected-content">
                        <div class="copy-intent-footer">
                            <el-button
                                @click="submitOpration('isShare')"
                                round
                                icon="iconfont guoran-tongyichicun-18-29-fenxiang"
                                size="small"
                                >共享</el-button
                            >
                            <el-button
                                @click="submitOpration('noShare')"
                                round
                                icon="iconfont guoran-tongyichicun-bugongxiang"
                                size="small"
                                >不共享</el-button
                            >
                            <el-button
                                @click="submitOpration('open')"
                                round
                                icon="iconfont guoran-tongyichicun-kaiqi"
                                size="small"
                                >开启</el-button
                            >
                            <el-button
                                @click="submitOpration('close')"
                            round
                            icon="iconfont guoran-tongyichicun-16_01-guanbi"
                                size="small"
                                >关闭</el-button
                            >
                            <el-button
                                @click="moveTo"
                            round
                            icon="iconfont guoran-tongyichicun-yidong"
                                size="small"
                                >移动</el-button
                            >
                            <el-button
                                @click="deleteTip"
                            round
                            icon="iconfont guoran-tongyichicun-18-13-shanchu"
                                size="small"
                                >删除</el-button
                            >
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>

<script>
import popup from "../../../components/popup.vue";

export default {
    components:{popup},
    props:['batchOperationDiaVisible'],
    data() {
        return {
            defaultExpandedKeys: [],
            intentOptions: [],
            skillList: [],
            deraction: [],
            innerVisible: false,
            innerVisibleDelete: false,
            optionProps: {
                value: "id",
                label: "name",
                children: "children",
                expandTrigger: "hover",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: [],
            isDefaultIntents: [],
            isParentIntents: [],
            checkIsOpen: {
                open: [],
                close: [],
            },
            showTipsDialog:false,
            recommendContent:'',
            params:{}
        };
    },
    watch:{
        innerVisible(n){
            if(n){
                this.deraction = [];
                this.getSkillListC(this.$route.query.id);
            }
        }
    },
    methods: {
        closeMoveIntentDialog(){
            this.innerVisible = false;
            // this.batchOperationDiaVisible = true;
            this.$emit('update:batchOperationDiaVisible',true)
        },
        handleCheckChange(data,node,e) {
             console.log(data,node,e);
            this.$nextTick(() => {
                let ids = this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "");
                this.filterIsOpenIntents(ids);
            });
        },
        // 点击节点
        nodeClick(data,node,e){
            if(data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1){
                console.log(data,node,e);
                this.$nextTick(()=>{
                    this.$refs['allIntentTree'].setChecked(data.id,!node.checked,true);
                })
            }
        },
        filterIsOpenIntents(intentIds) {
            let checkIsOpen = {
                open: [],
                close: [],
            };
            this.intentOptions[0].children.forEach((skill) => {
                if (skill.children != null) {
                    skill.children.forEach((_type) => {
                        if (_type.children != null) {
                            _type.children.forEach((intent) => {
                                if (intentIds.indexOf(intent.id) != -1) {
                                    if (intent.ext.isOpen) {
                                        checkIsOpen.open.push(intent.id);
                                    } else {
                                        checkIsOpen.close.push(intent.id);
                                    }
                                }
                            });
                        }
                    });
                }
            });
            this.checkIsOpen = JSON.parse(JSON.stringify(checkIsOpen));
        },
        filterIsDefaultIntents(intentIds) {
            let isDefaultIntents = [];
            let isParentIntents = [];
            this.intentOptions[0].children.forEach((skill) => {
                if (skill.children != null) {
                    skill.children.forEach((_type) => {
                        if (_type.children != null) {
                            _type.children.forEach((intent) => {
                                if (
                                    intentIds.indexOf(intent.id) != -1 &&
                                    intent.ext.isDefault
                                ) {
                                    intent.showName =
                                        skill.name +
                                        "/ " +
                                        _type.name +
                                        "/ " +
                                        intent.name;
                                    isDefaultIntents.push(intent);
                                }
                                if (intent.ext.isParent) {
                                    isParentIntents.push(intent.id);
                                }
                            });
                        }
                    });
                }
            });
            this.isParentIntents = isParentIntents;
            return isDefaultIntents;
        },
        oprationTips(type) {
            let tip = "";
            switch (type) {
                case "isShare":
                    tip = "设置共享后，所选意图将可以被复制，是否设置共享？";
                    break;
                case "noShare":
                    tip =
                        "设置不共享后，所选意图将不可以被复制，是否设置不共享？";
                    break;
                case "open":
                    tip =
                        "意图开启后，机器人将继续识别该意图并将该意图移出意图归档箱中，是否开启？";
                    break;
                case "close":
                    tip =
                        "意图关闭后，机器人将不再识别该意图并将已关闭的意图放置在意图归档箱中，是否关闭？";
                    break;
                case "move":
                    tip = "确认移动所选意图？";
                    break;
                case "delete":
                    tip = "确认删除所选意图？";
                    break;
                default:
                    break;
            }
            return tip;
        },
        moveTo() {
            if (
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length ==
                0
            ) {
                this.$message.warning("请至少选择一个意图");
                return false;
            }
            this.isDefaultIntents = this.filterIsDefaultIntents(
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "")
            );
            if (
                this.isDefaultIntents.length ==
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length
            ) {
                this.$message.warning(
                    "当前选择意图全部为官方意图, 不可进行移动操作！"
                );
            } else {
               
                let zIndex = window.getComputedStyle(document.querySelector('#batch-operation-diaVisible')).zIndex
                document.querySelector('#move-intent-dialog').style.zIndex = zIndex+1;
                this.innerVisible = true;
                this.$emit('update:batchOperationDiaVisible',false);
            }
        },
        deleteTip() {
            if (
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length ==
                0
            ) {
                this.$message.warning("请至少选择一个意图");
                return false;
            }
            this.isDefaultIntents = this.filterIsDefaultIntents(
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "")
            );
            if (
                this.isDefaultIntents.length ==
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length
            ) {
                this.$message.warning(
                    "当前选择意图全部为官方意图, 不可进行删除操作！"
                );
            } else {
                this.$emit(
                    "betchDeleteIntent",
                    this.$refs.allIntentTree
                        .getCheckedKeys(true)
                        .filter((item) => Number(item) + "" !== NaN + ""),
                    this.isParentIntents
                );
                // this.innerVisibleDelete = true;
            }
        },
        batchOperation(param) {
            this.FetchPost(this.requestUrl.intent.batchOperate, param).then(
                (res) => {
                    if (res.code === "0") {
                        this.$emit("closeBatchOperation");
                        this.$emit("switchSkillCopy");
                        this.innerVisible = false;
                        this.$message.success('操作成功');
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        submitOpration(type) {
            console.log(type);
            if (
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length ==
                0
            ) {
                this.$message.warning("请至少选择一个意图");
                return false;
            }
            if (type == "move" && this.deraction.length == 0) {
                this.$message.warning("请选择将要移动至的分类");
                return false;
            }
            let param = {
                intentIds: this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + ""),
            };
            switch (type) {

                case "isShare":
                    param.operateType = "SHARE";
                    param.state = true;
                    break;
                case "noShare":
                    param.operateType = "SHARE";
                    param.state = false;
                    break;
                case "open":
                    param.operateType = "CLOSE";
                    param.state = true;
                    break;
                case "close":
                    param.operateType = "CLOSE";
                    param.state = false;
                    break;
                case "move":
                    param.operateType = "MOVE";
                    param.targetTypeId = this.deraction[
                        this.deraction.length - 1
                    ].substring(1, 20);
                    break;
                case "delete":
                    param.operateType = "DELETE";
                    break;
                default:
                    break;
            }

            this.isDefaultIntents = this.filterIsDefaultIntents(
                param.intentIds
            );

            if (type == "move" || type == "delete") {
                this.batchOperation(param, this.isParentIntents);
            } else {
                if (type == "close") {
                    if (this.checkIsOpen.open.length == 0) {
                        this.$message.warning("请至少选择一个可关闭意图");
                    } else {
                        this.$emit(
                            "betchCloseIntent",
                            this.checkIsOpen.open,
                            this.isParentIntents
                        );
                    }
                } else if (type == "open") {
                    if (this.checkIsOpen.close.length == 0) {
                        this.$message.warning("请至少选择一个可开启意图");
                    } else {
                        this.$emit(
                            "betchOpenIntent",
                            this.checkIsOpen.close,
                            this.isParentIntents
                        );
                    }
                } else {
                    this.recommendContent = this.oprationTips(type);
                    this.params = param;
                    this.$emit('showTipsDialogFn',true,this.recommendContent)
                    // this.showTipsDialog = true;
                    // this.$confirm(this.oprationTips(type), "提示", {
                    //     confirmButtonText: "确定",
                    //     cancelButtonText: "取消",
                    //     type: "warning",
                    // })
                    //     .then(() => {
                    //         this.batchOperation(param);
                    //     })
                    //     .catch(() => {
                    //         this.$message({
                    //             type: "info",
                    //             message: "已取消",
                    //         });
                    //     });
                }
            }
        },
        confirmation(){
            this.batchOperation(this.params);
        },
        closeBatchOperation() {
            this.$emit("closeBatchOperation");
        },
        getIntentTree(bId) {
            this.FetchGet(this.requestUrl.intent.getIntentTree, {
                bId: bId,
                isContainsClose: true,
            }).then((res) => {
                if (res.code === "0") {
                    if (res.data.length == 0) {
                        this.intentOptions = [];
                    } else {
                        let data = [];
                        res.data.forEach((item) => {
                            if (item.name != "特殊规则") {
                                data.push(item);
                            }
                        });
                        this.intentOptions = [
                            {
                                id: bId,
                                name: "全部",
                                type: "BOT",
                                children: data,
                            },
                        ];
                        this.defaultExpandedKeys = [bId];
                        this.intentOptions.forEach((bot) => {
                            if (bot.children != null) {
                                bot.children.forEach((skill) => {
                                    // this.defaultExpandedKeys.push(skill.id);
                                    skill.type = "SKILL";
                                    if (skill.children != null) {
                                        skill.children.forEach((_type) => {
                                            _type.type = "TYPE";
                                            if (_type.children != null) {
                                                _type.children.forEach(
                                                    (intent) => {
                                                        intent.type = "INTENT";
                                                    }
                                                );
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        this.$emit("setBetchIntentOptions", this.intentOptions);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取技能list
        getSkillListC(botId) {
            this.FetchGet(this.requestUrl.intent.getIntentTree, {
                bId: botId,
            }).then((res) => {
                if (res.code === "0") {
                    this.skillList = [];
                    res.data.forEach((item) => {
                        if (item.name != "特殊规则") {
                            this.skillList.push(item);
                        }
                    });
                    this.skillList.forEach((item) => {
                        if (item.children != null) {
                            item.children.forEach((cell) => {
                                cell.children = null;
                            });
                        }
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
    mounted() {
        this.getIntentTree(this.$route.query.id);
        
    },
};
</script>

<style lang="less" scoped>
@import '../../../assets/less/main/common.less';
.batch-intent-baili {
    .is-default-intents {
        max-height: 150px;
        overflow: auto;
        margin-left: 8px;
        margin-bottom: 16px;
        background-color: #f2f6fc;
        padding: 6px;
        span {
            display: block;
            line-height: 28px;
        }
    }
    .no-opration-tips {
        font-weight: 700;
        line-height: 32px;
        height: 32px;
        text-align: left;
        margin-left: 8px;
    }
    .intent-selected-box {
        text-align: left;
        .inner-bottom-handle {
            margin-top: 16px;
            text-align: right;
        }
        /deep/ .el-cascader {
            width: 100%;
        }
    }

    /deep/ .el-tree-node__expand-icon.is-leaf {
        color: transparent;
        cursor: default
    }
    .custom-tree-node8 {
        .style{
            display: inline-block;
            margin-left: 6px;
            font-size: 12px;
            line-height: 18px;
            height: 18px;
            background: #FFFFFF;
            border-radius: 2px;
            padding: 0 5px;
            &.guan{
                background: #EFF2FF;
                border: 1px solid #DBE4FF;
                color: #366AFF;
            }
             &.share{
                background: #E9F9F8;
                border: 1px solid #D4F1F0;
                color: #00C2BB;
            }
             &.close{
                border: 1px solid #DFE3E9;
                color: #999999;
            }
        }
        .no-children{

        }
        
    }
    .batch-intent-content .popup-container{
        max-height: calc(84vh - 100px) !important;
    }
    .intent-tree-content {
        background: #FBFCFD;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
       
        /deep/ .el-tree{
            background-color: #FBFCFD;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #616161;
            .el-tree-node__expand-icon{
                color: #616161;
            }
            .el-tree-node__expand-icon.is-leaf {
                color: transparent;
                cursor: default
            }
        }
        .pre-icon-type {
            color: #A1B9FF;
            display: inline-block;
            margin: 0 8px;
            font-size: 14px;
        }
        .copy-intent-title {
            display: block;
            height: 32px;
            line-height: 32px;
            text-align: left;
            font-weight: 600;
        }
        .intent-tree-box {
            overflow: auto;
            padding: 20px;
            max-height: calc(84vh - 400px);
            min-height: 160px
        }
        .tips-box{
            width: 100%;
            height: 42px;
            background: #FFF8F4;
            border-radius: 5px;
            color: #FF9555;
            font-size: 12px;
            text-align: center;
            line-height: 42px;
        }
    }
    .intent-selected-content {
        margin-top: 20px;
        //  padding-bottom: 20px;
        background: #FBFCFD;
        border-radius: 5px;
        line-height: 52px;
        
        .copy-intent-title {
            display: block;
            height: 32px;
            line-height: 32px;
            text-align: left;
            font-weight: 600;
        }
        .copy-intent-footer {
            padding: 0 24px;
            height: 52px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            /deep/ .el-button{
                height: 29px;
                line-height: 29px;
                padding: 0;
                border-color: #A1B9FF;
                color: #366AFF;
                padding: 0 14px;
                font-size: 12px;
                display: flex;
                align-items: center;
                i{
                    margin-right: 5px;
                    // vertical-align: middle;
                    font-size: 14px;
                }
            }
        }
    }
    #move-intent-dialog{
        /deep/ .popup-container{
            padding-bottom: 30px !important;
        }
        
    }
}
</style>