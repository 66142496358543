<template>
    <div class="flow-component-list">
        <div class="cl-tab">
            <span
                v-for="(item, index) in componentsTypes"
                :key="index"
                :class="[
                    'cl-teb-cell',
                    activeTab === index ? 'cl-teb-cell-active' : '',
                    index == 1?'font-size-26':'',
                ]"
                @click="switchComponentsTypes(index)"
                ><i :class="item.icon"></i
            ></span>
        </div>
        <div class="cl-list">
            <div class="cl-list-name">
                {{ componentsTypes[activeTab].name }}
            </div>
            <div class="cl-list-content">
                <div
                    v-show="showComponent(cell.id, cell.disable, nodeId)"
                    v-for="(cell, cellIndex) in componentsTypes[activeTab]
                        .components"
                    :key="'com_' + cellIndex"
                    class="clc-cell"
                    :style="{
                        opacity:
                            cell.id == 'action_webhook' && botVersion == 2
                                ? 0.5
                                : 1,
                    }"
                    @click="addComponents(cell.id)"
                >
                    <div class="clc-cell-box">
                        <span class="clc-cell-img"
                            ><img width="32px" :src="cell['icon-image']" alt="" srcset=""></span
                        ><span class="clc-cell-name">{{ cell.name }}</span>
                    </div>
                    <!-- <span class="clc-cell-des">不支持微信小程序</span> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["nodeId"],
    data() {
        return {
            componentsTypes: [{ name: "", components: [] }],
            activeTab: 0,
        };
    },
    methods: {
        addComponents(type) {
            this.$emit("addComponents", type);
        },
        switchComponentsTypes(index) {
            this.activeTab = index;
        },
        showComponent(cellId, disable, nodeId) {
            if (disable == true) {
                return false;
            }

            if (nodeId == "start") {
                return (
                    cellId == "answer_welcometext" ||
                    cellId == "answer_image" ||
                    cellId == "answer_video" ||
                    cellId == "answer_radio" ||
                    cellId == "action_transfer" ||
                    cellId == "action_set_entity" ||
                    cellId == "action_webhook" ||
                    cellId == "action_random_jump" ||
                    cellId == "action_jump" ||
                    cellId == "action_fast_select" ||
                    cellId == "answer_intellect_guide"
                );
            } else if (nodeId == "end") {
                return (
                    cellId == "answer_image" ||
                    cellId == "answer_video" ||
                    cellId == "answer_text" ||
                    cellId == "answer_radio" ||
                    cellId == "action_satisfaction" ||
                    cellId == "action_set_entity" ||
                    cellId == "action_webhook" ||
                    cellId == "action_random_jump" ||
                    cellId == "action_jump" ||
                    cellId == "action_fast_select" ||
                    cellId == "answer_intellect_guide"
                );
            } else {
                if (cellId == "answer_welcometext") {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    mounted() {
        // 答案类型列表
        this.FetchGet(this.requestUrl.chatFlowComponent.getComponentsTypes + "?version=preol-20221118", {})
            .then((res) => {
                if (res.code === "0") {
                    this.componentsTypes = res.data;

                    // 模拟新增组件类型
                    // this.componentsTypes[0].components.push({
                    //     disable: false,
                    //     icon: [
                    //         "icon-big",
                    //         "iconfont",
                    //         "guoran-tongyichicun-16-07-liebiaokapianmoshi",
                    //     ],
                    //     id: "answer_intellect_guide",
                    //     name: "智能引导",
                    // });
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
    },
};
</script>
<style lang="less" scoped>
.flow-component-list {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    border-right: solid 1px #f0f1f7;
    .cl-tab {
        flex: none;
        width: 70px;
        padding-top: 30px;
        background: #f5f7fb;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .cl-teb-cell:hover {
            i {
                color: #366aff;
            }
        }
        .cl-teb-cell {
            margin-bottom: 4px;
            width: 42px;
            height: 42px;
            border: 1px solid #f5f7fb;
            border-radius: 5px;
            line-height: 42px;
            text-align: center;
            i {
                color: #96b1ff;
                font-size: 22px;
                font-weight: 400;
            }
        }
        .cl-teb-cell-active {
            border: 1px solid #a1b9ff;
            background-color: white;
            i {
                color: #366aff;
                font-size: 22px;
                font-weight: 500;
            }
        }
        .font-size-26{
            i {
                font-size: 28px;
            }
        }
    }
    .cl-list {
        flex: none;
        width: 230px;
        .cl-list-name {
            font-size: 16px;
            height: 55px;
            line-height: 55px;
            border-bottom: solid 1px #f0f1f7;
        }
        .cl-list-content {
            height: calc(100vh - 80px);
            margin: 10px auto;
            width: 210px;
            overflow-y: auto;
            text-align: left;
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: flex-start;

            .clc-cell {
                margin: 10px;
                cursor: pointer;
                display: inline-block;
                flex-direction: column;
                justify-items: center;
                .clc-cell-box {
                    width: 80px;
                    height: 80px;
                    background: #ffffff;
                    border: 1px solid #d0daf6;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .clc-cell-img{
                        margin-top: 14px;
                        margin-bottom: 6px;
                        height: 32px;
                        width: 32px;
                        i {
                            font-size: 24px;
                            color: #366aff;
                        }
                    }
                }
                .clc-cell-box:hover {
                    border: 1px solid #366aff;
                }
            }
        }
    }
}
</style>