<template>
    <div class="departmentTree">
        <div class="staff-customer-input">
            <el-input
                v-model="keyword"
                placeholder="搜索"
                size="small"
                @keyup.enter.native="searchDepartment"
                @input="isNullSearch"
            ></el-input>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane v-if="isShowStaff" label="员工部门" name="staffDept">
                <el-tree
                    class="filter-tree"
                    :props="{
                        isLeaf: 'leaf',
                        label: 'label',
                        children: 'children',
                    }"
                    :data="staffDataTree"
                    :load="loadStaffNode"
                    lazy
                    ref="staffDataTree"
                    node-key="id"
                >
                    <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                        @click.stop="onNodeClick(data, data, node, '')"
                    >
                        <span class="custom-tree-node-left">
                            <span
                                class="data-label"
                                v-if="
                                    data.isWeWorkThirdData ||
                                    isUseOpenData(node)
                                "
                            >
                                <open-data
                                    :type="'departmentName'"
                                    :openid="data.label"
                                ></open-data>
                            </span>
                            <span v-else class="data-label">
                                {{ data.label }}</span
                            >
                        </span>
                        <span v-if="data.checked">
                            <i :class="['el-icon-check']"></i>
                        </span>
                    </span>
                </el-tree>
                <span
                    v-if="keyword && staffHasNext"
                    class="hasNextPage"
                    @click="loadMore"
                >
                    查看更多
                </span>
            </el-tab-pane>
            <el-tab-pane label="选择上下游" name="upDown" v-if="isShowUpDown">
                <el-tree
                    class="filter-tree"
                    :props="{
                        isLeaf: 'leaf',
                        label: 'name',
                        children: 'children',
                    }"
                    :data="updownDateTree"
                    :load="loadNode"
                    lazy
                    ref="upDownTree"
                    node-key="id"
                >
                    <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                        @click.stop="onNodeClick(data, data, node, 'updown')"
                    >
                        <span class="custom-tree-node-left">
                            <span class="data-label">{{ data.name }}</span>
                        </span>
                        <span v-if="data.checked">
                            <i :class="['el-icon-check']"></i>
                        </span>
                    </span>
                </el-tree>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import OpenData from "./../../../../components/openData";
export default {
    name: "departmentTree",
    components: { OpenData },
    props: [
        "isMultiple",
        "updownCheckedList",
        "selectDepartment",
        "isShowUpDown",
        "isShowStaff",
        "staffCheckedList",
        "activeName",
    ],
    data() {
        return {
            defaultProps: {
                children: "children",
                label: "name",
            },
            updownDateTree: [],
            staffDataTree: [],
            keyword: "",
            corpId: "",
            pageSize: 20,
            currentPage: 1,
            nodeData: "",
            resolve: "",
            staffHasNext: false,
            updownResolve: "",
            updownNode: "",
            checkDepartmentList: [],
        };
    },
    methods: {
        // 递归获取所有节点
        getAllNodeId(moduleDataList, type) {
            console.log(moduleDataList, "moduleDataList");
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        // if (type){
                        if (!this.isMultiple) {
                            if (
                                this.updownCheckedList[0] &&
                                this.updownCheckedList[0].id
                            ) {
                                if (
                                    moduleDataList[i].id ==
                                    this.updownCheckedList[0].id
                                ) {
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            }
                        } else {
                            this.updownCheckedList.forEach((item) => {
                                if (moduleDataList[i].id == item.id) {
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            });
                        }
                        // }
                        if (
                            moduleDataList[i].children &&
                            moduleDataList[i].children.length > 0
                        ) {
                            this.getAllNodeId(moduleDataList[i].children, type);
                        }
                    }
                }
                resolve(true);
            });
        },
        getStaffNodeId(moduleDataList, type) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        // if (type){
                        if (!this.isMultiple) {
                            if (
                                this.staffCheckedList[0] &&
                                this.staffCheckedList[0].id
                            ) {
                                if (
                                    moduleDataList[i].id ==
                                    this.staffCheckedList[0].id
                                ) {
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            }
                        } else {
                            this.staffCheckedList.forEach((item) => {
                                if (moduleDataList[i].id == item.id) {
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            });
                        }
                        // }
                        if (
                            moduleDataList[i].children &&
                            moduleDataList[i].children.length > 0
                        ) {
                            this.getStaffNodeId(
                                moduleDataList[i].children,
                                type
                            );
                        }
                    }
                }
                resolve(true);
            });
        },
        //懒加载上下游员工
        loadNode(node, resolve) {
            console.log("懒加载");
            let uid = localStorage.getItem("_uid");
            if (node.level === 0) {
                this.updownNode = node;
                this.updownResolve = resolve;
                let datas = [];
                let url =
                    "/portal-api/upAndDown/selectOrganizationById" +
                    "?userId=" +
                    uid;
                this.FetchGet(url).then((res) => {
                    datas = res.data || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                        element.type = "updown";
                    });
                    this.updownDateTree = datas;
                    this.getAllNodeId(this.updownDateTree, this.isMultiple);
                    return resolve(datas);
                });
            }
            if (node.level >= 1) {
                let datas = [];
                let url =
                    "/portal-api/upAndDown/selectOrganizationById" +
                    "?id=" +
                    node.data.id +
                    "&userId=" +
                    uid;
                this.FetchGet(url).then((res) => {
                    datas = res.data || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                        element.type = "updown";
                    });
                    node.data.children = datas;
                    this.getAllNodeId(this.updownDateTree, this.isMultiple);
                    return resolve(datas);
                });
            }
        },
        //懒加载员工
        loadStaffNode(node, resolve) {
            let uid = localStorage.getItem("_uid");
            if (node.level === 0) {
                this.nodeData = node;
                this.resolve = resolve;
                let datas = [];
                let url =
                    "/portal-api/department/corpListLazy" +
                    "?userId=" +
                    uid +
                    "&needMember=false";
                this.FetchGet(url).then((res) => {
                    console.debug("corpListLazy", res);
                    datas = res || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                    });
                    this.staffDataTree = datas;
                    if (this.staffDataTree.length > 0) {
                        this.corpId = this.staffDataTree[0].originData
                            ? this.staffDataTree[0].originData.corpId
                            : "";
                    }
                    this.getStaffNodeId(this.staffDataTree, this.isMultiple);
                    return resolve(datas);
                });
            }
            if (node.level >= 1) {
                let datas = [];
                let url =
                    "/portal-api/department/corpListLazy" +
                    "?departId=" +
                    node.data.id +
                    "&userId=" +
                    uid +
                    "&needMember=false";
                this.FetchGet(url).then((res) => {
                    console.debug("datas", res);
                    datas = res || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                    });
                    node.data.children = datas;
                    this.getStaffNodeId(datas, this.isMultiple);
                    return resolve(datas);
                });
            }
        },
        //选择员工报单人
        async onNodeClick(data, item, row, type) {
            if (!this.isMultiple) {
                await this.ergodicDepartment(this.staffDataTree);
                await this.ergodicDepartment(this.updownDateTree);
                this.$set(data, "checked", true);
                this.$set(data, "isBind", this.isUseOpenData(row));
            } else {
                this.$set(data, "checked", !data.checked);
                this.$set(data, "isBind", this.isUseOpenData(row));
                let ids = this.checkDepartmentList.map((item) => {
                    return item.id;
                });
                if (data.checked) {
                    if (ids.indexOf(data.id) === -1) {
                        this.checkDepartmentList.push(data);
                    }
                } else {
                    this.checkDepartmentList = this.checkDepartmentList.filter(
                        (item) => item.id !== data.id
                    );
                }
            }
            console.log(this.checkDepartmentList);
            this.$emit("selectDepartmentNode", this.checkDepartmentList);
        },
        ergodicDepartment(list) {
            list.forEach((item) => {
                this.$set(item, "checked", false);
                if (item.children) {
                    this.ergodicDepartment(item.children);
                }
            });
        },
        searchChecked(list, member) {
            list.forEach((item) => {
                if (member.indexOf(item.id) !== -1) {
                    this.$set(item, "checked", true);
                }
                if (item.children) {
                    this.searchChecked(item.children, member);
                }
            });
        },
        isUseOpenData(node, data) {
            let obj = {};
            let bool = false;
            console.debug("node", node, node.level);
            if (node.level == 1) {
                obj = node;
            } else {
                for (let i = 0; i < node.level - 1; i++) {
                    if (!i) {
                        obj = node.parent;
                    } else {
                        obj = obj.parent;
                    }
                }
            }
            if (
                obj.data &&
                obj.data.originData &&
                obj.data.originData.wechatBotDTO &&
                obj.data.originData.wechatBotDTO.bindType === 0 &&
                obj.data.originData.wechatBotDTO.wechatType === 0
            ) {
                console.debug("最外层父级数据", obj.data);
                bool = true;
            }
            return bool;
        },
        //部门是否是纯数字
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        //搜索部门
        searchDepartment(type) {
            if (this.activeName === "staffDept" && this.keyword) {
                if (type !== "load") {
                    this.staffDataTree = [];
                    this.currentPage = 1;
                }
                let userId = localStorage.getItem("_uid");
                let url =
                    "/portal-api/department/corpListLazy-v2?corpId=" +
                    this.corpId +
                    "&keyword=" +
                    this.keyword +
                    "&page=" +
                    this.currentPage +
                    "&size=" +
                    this.pageSize + 
                    "&userId=" + 
                    userId;
                this.FetchGet(url).then((res) => {
                    console.debug("get member", res);
                    let list =
                            res && res.list ? res.list : [];
                    this.staffHasNext = res.hasNextPage;
                    let ids =
                        this.staffCheckedList &&
                        this.staffCheckedList.map((item) => {
                            return item.id;
                        });
                    list.forEach((item) => {
                        this.$set(item, "checked", false);
                        if (!item.label) {
                            item.label = item.name;
                        }
                        if (ids.includes(item.id)) {
                            this.$set(item, "checked", true);
                        }
                    });
                    this.staffDataTree = [...this.staffDataTree, ...list];
                    console.log(433, this.staffDataTree);
                });
            } else if (this.activeName === "upDown" && this.keyword) {
                this.updownDateTree = [];
                this.searchUpAndDown();
            }
        },
        //搜索上下游
        searchUpAndDown() {
            let userId = localStorage.getItem("_uid");
            let url =
                "/portal-api/upAndDown/searchUpDownMemberOrDepart?userId=" +
                userId +
                "&keyword=" +
                this.keyword;
            this.FetchGet(url).then((res) => {
                console.debug("get updown", res);
                if (res.code == 0) {
                    let list = res.data
                        ? res.data.filter((item) => item.type == 1)
                        : [];
                    list.forEach((item) => {
                        let leaf = false;
                        this.$set(item, "leaf", leaf);
                        item.type = "updown";
                        this.$set(item, "checked", false);
                    });
                    console.log(459, list);
                    this.updownDateTree = list;
                    this.getAllNodeId(this.updownDateTree, "show");
                }
            });
        },
        //监听输入框为空
        isNullSearch() {
            if (this.activeName === "staffDept" && !this.keyword) {
                this.staffHasNext = false;
                this.staffDataTree = [];
                this.loadStaffNode(this.nodeData, this.resolve);
            } else if (this.activeName === "upDown" && !this.keyword) {
                this.updownDateTree = [];
                this.loadNode(this.updownNode, this.updownResolve);
            }
        },
        //加载更多
        loadMore() {
            this.currentPage += 1;
            this.searchDepartment("load");
        },
    },
    watch: {
        updownCheckedList: {
            handler(n) {
                console.log(n, "nnnnn");
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {},
};
</script>

<style scoped lang="less">
.departmentTree {
    .custom-tree-node {
        display: flex;
        width: 100%;
        height: 26px;
        align-items: center;
        justify-content: space-between;
        .custom-tree-node-left {
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            .arsenalsitemap1 {
                margin-right: 6px;
            }
            .data-label {
                flex: 1;
                text-align: left;
            }
        }
        .el-icon-check {
            font-weight: 600;
            font-size: 16px;
        }
    }
    .hasNextPage {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #366aff;
        margin-top: 10px;
        cursor: pointer;
    }
}
</style>