var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff-customer"},[_c('div',{staticClass:"staff-customer-input"},[_c('el-input',{attrs:{"placeholder":"搜索","size":"small"},on:{"input":_vm.monitorSearchNull},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchEmployee.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.isShowStaff)?_c('el-tab-pane',{attrs:{"label":"选择员工","name":"staff"}},[_vm._l((_vm.treeData),function(item,iIndex){return _c('div',{key:iIndex},[_c('el-tree',{key:"selectOrganizationTreeLazy",ref:item.id + 'TreeLazy',refInFor:true,staticClass:"filter-tree",attrs:{"data":[item],"id":"selectOrganizationTreeLazy","node-key":"id","default-expanded-keys":_vm.defaultExpandedKeysMember,"expand-on-click-node":false},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){return _vm.onNodeClick(data, '', item, node)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(data.type == 1),expression:"data.type == 1"}],class:[
                                    'el-icon-caret-right',
                                    data.showChildren ? 'down' : 'right',
                                ],on:{"click":function($event){$event.stopPropagation();return _vm.nodeExpand(data, node)}}}),(
                                    data.isWeWorkThirdData ||
                                    item.isWeWorkThirdData ||
                                    (item.originData &&
                                        item.originData.wechatBotDTO &&
                                        item.originData.wechatBotDTO
                                            .bindType === 0 &&
                                        item.originData.wechatBotDTO
                                            .wechatType === 0)
                                )?_c('span',{staticClass:"data-label"},[_c('open-data',{attrs:{"type":data.type == 1 || data.type === 'DEPARTMENT'
                                            ? 'departmentName'
                                            : 'userName',"openid":data.label}})],1):_c('span',{staticClass:"data-label"},[_vm._v(_vm._s(data.label))]),(
                                    data.originData &&
                                    data.originData.departments &&
                                    _vm.keyword
                                )?_vm._l((data.originData
                                        .departments),function(cell,index){return _c('div',{key:index},[_c('el-tooltip',{attrs:{"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" ( "),(
                                                    cell.source === 1 &&
                                                    (data.isWeWorkThirdData ||
                                                        item.isWeWorkThirdData)
                                                )?_c('span',[_c('open-data',{attrs:{"type":'departmentName',"openid":cell.fullName}})],1):_c('span',{domProps:{"innerHTML":_vm._s(cell.fullName)}}),_vm._v(" ) ")]),(
                                                cell.source === 1 &&
                                                (data.isWeWorkThirdData ||
                                                    item.isWeWorkThirdData)
                                            )?_c('span',[_vm._v("( "),_c('open-data',{attrs:{"type":'departmentName',"openid":cell.name}}),_vm._v(" ) ")],1):_c('span',[_vm._v("("+_vm._s(cell.name)+")")])])],1)}):_vm._e()],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.checked),expression:"data.checked"}]},[_c('i',{class:['el-icon-check']})])])}}],null,true)})],1)}),(_vm.keyword && _vm.staffHasNext)?_c('span',{staticClass:"hasNextPage",on:{"click":_vm.loadStaffMore}},[_vm._v(" 查看更多 ")]):_vm._e()],2):_vm._e(),(_vm.clientType === 'STAFFANDCUSTOMER')?_c('el-tab-pane',{attrs:{"label":"选择客户","name":"customer"}},[_c('div',{staticClass:"customer-list"},_vm._l((_vm.selectClient),function(item){return _c('div',{key:item.id,staticClass:"customer-item",on:{"click":function($event){return _vm.onNodeClick(item, 'customer', '')}}},[_c('span',[_vm._v(_vm._s(item.name))]),(item.checked)?_c('span',[_c('i',{staticClass:"el-icon-check"})]):_vm._e()])}),0),(_vm.hasClientNext)?_c('div',{staticClass:"load-more-client",on:{"click":_vm.loadMore}},[_c('el-button',{attrs:{"type":"text","size":"small"}},[_vm._v("查看更多")])],1):_vm._e()]):_vm._e(),(_vm.isShowUpDown)?_c('el-tab-pane',{attrs:{"label":"选择上下游员工","name":"upDown"}},[_c('el-tree',{ref:"upDownTree",staticClass:"updown-tree",attrs:{"props":{
                    isLeaf: 'leaf',
                    label: 'name',
                    children: 'children',
                },"data":_vm.updownDateTree,"load":_vm.loadNode,"lazy":"","node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{class:[
                        'custom-tree-node',
                        !data.wxOpenId ? 'disabled' : '',
                    ],on:{"click":function($event){return _vm.onNodeClick(data, 'updown', data, node)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{staticClass:"data-label"},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('span',[_c('i',{class:['el-icon-check']})]):_vm._e()])}}],null,false,949530148)})],1):_vm._e(),(_vm.companyConfig)?_c('el-tab-pane',{attrs:{"label":"选择所有客户"}},[_c('div',{staticClass:"customer-list"},[_c('div',{staticClass:"customer-item",on:{"click":function($event){return _vm.onNodeClick('', 'allCustomer', '')}}},[_c('span',[_vm._v("选择所有客户"+_vm._s(_vm.companyConfig))]),(
                            _vm.memberInfo4Client.clientInfo.some((client) => {
                                return client.id == 'allCustomer';
                            })
                        )?_c('span',[_c('i',{staticClass:"el-icon-check"})]):_vm._e()])])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }