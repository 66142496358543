<template>
    <div id="radio-fonfig">
        <div class="radio-btn-list-header">
            <span class="radio-btn-list-header-name">点选名称</span>
            <span class="radio-btn-list-header-action">如果用户点击</span>
        </div>
        <div class="radio-btn-list">
            <div
                v-for="(rCell, rCellIndex) in activeRadioBtnList"
                :key="rCellIndex"
                class="radio-btn-list-cell"
            >
                <span class="select-cell-move icon-handle-radio-btn">
                    <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
                </span>
                <div class="select-cell select-cell-name">
                    <el-input
                        size="small"
                        v-model="rCell.name"
                        maxlength="40"
                        placeholder="请输入点选名称"
                    ></el-input>
                </div>
                <div class="select-cell select-cell-value">
                    <el-select
                        v-model="rCell.scope"
                        @change="answerRadioValueChange"
                        size="small"
                        :key="'rblcS' + rCellIndex"
                        placeholder="请选择"
                        class="select-type"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            v-show="
                                (isStartOrEnd && item.value != 'NODE') ||
                                !isStartOrEnd
                            "
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>

                    <SelectAddnode
                        v-if="rCell.scope === 'NODE'"
                        :nodeOptions="nodeOptions"
                        :nodeId="rCell.value"
                        :defaultParentNodeId="nodeId"
                        :nodeSelectARef="`actionEnforceAddnode_${activeRadioConfigIndex[0]}_${rCellIndex}`"
                        :intentId="intentId"
                        :selectAddnodeKey="'rblcSA' + rCellIndex"
                        @getNodeOptions="getNodeOptions"
                        @setAddNodeId="setAddNodeId"
                        :index="rCellIndex"
                    ></SelectAddnode>
                    <el-cascader
                        v-show="rCell.scope === 'INTENT'"
                        v-model="rCell.value"
                        placeholder="请选择意图名称"
                        size="small"
                        :options="intentTree4Radio"
                        :key="'rblcEc' + rCellIndex"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                </div>
                <span
                    v-if="rCellIndex === 0"
                    @click="addRCell"
                    class="answer-text-cell-handle-icon-add"
                >
                    <i class="el-icon-plus"></i>
                </span>
                <span
                    v-else
                    @click="deleteRCell(rCellIndex)"
                    class="answer-text-cell-handle-icon-delete"
                >
                    <i class="el-icon-close"></i
                ></span>
            </div>
        </div>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import SelectAddnode from "./../cell/SelectAddnode.vue";
import Sortable from "sortablejs";
export default {
    props: [
        "intentTree4Radio",
        "nodeOptions",
        "isStartOrEnd",
        "activeRadionConfigObj",
        "nodeId",
        "intentId",
        "activeRadioBtnList",
        "activeRadioConfigIndex",
    ],
    components: { popup, SelectAddnode },
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.sortClickBtn();
        });
    },
    methods: {
        saveAnswerRadioBtnConfig() {
            let flag = true;
            this.activeRadioBtnList.forEach((item) => {
                if (item.name.trim() === "") {
                    console.log(1234)
                    flag && this.$message.error("点选按钮名称不能为空！");
                    flag = false;
                } else {
                    if (item.value === "" && flag) {
                        flag && this.$message.error("请选择跳转到的意图或节点！");
                        flag = false;
                    }
                }
            });
            if (flag) {
                this.$emit(
                    "confirmSaveAnswerRadioBtn",
                    this.activeRadioBtnList,
                    this.activeRadioConfigIndex[0]
                );
            }
        },
        addRCell() {
            this.activeRadioBtnList.push({
                name: "",
                scope: this.isStartOrEnd ? "INTENT" : "NODE",
                value: "",
            });
        },
        deleteRCell(rCellIndex) {
            this.activeRadioBtnList.splice(rCellIndex, 1);
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            this.$set(this.activeRadioBtnList[index], "value", nodeId);
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        sortClickBtn() {
            const box = document.querySelector(".radio-btn-list");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-radio-btn",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeRadioBtnList.splice(oldIndex, 1);
                    _this.activeRadioBtnList.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.activeRadioBtnList = _this.activeRadioBtnList;
        },
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/main/new_ui.less";
.add-node-cell {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .add-node-cell-title {
        margin-bottom: 6px;
        text-align: left;
        .add-node-cell-title-pre {
            color: red;
        }
    }
    .add-node-cell-content {
        /deep/.el-select {
            width: 100%;
        }
    }
}
/deep/.el-cascader-node-createnode {
    display: flex !important;
    justify-content: flex-start !important;
    .el-cascader-node__label {
        flex: none;
        width: 60px;
        color: #366aff;
        padding: 0;
    }
    .el-cascader-node__prename {
        height: 38px;
        flex: auto;
    }
}
#radio-fonfig {
    border-radius: 5px;
    .radio-btn-list-header {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 8px;
        .radio-btn-list-header-name {
            margin-left: 22px;
            width: 170px;
            flex: none;
        }
        .radio-btn-list-header-action {
            flex: auto;
        }
    }
    .radio-btn-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        .select-cell-move {
            color: #366aff;
            font-size: 20px;
            font-weight: 500;
            margin-right: 8px;
        }
        .select-cell-name {
            width: 155px;
            flex: none;
        }
        .select-cell-value {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 15px;
            .select-type {
                width: 90px;
            }
            /deep/.inline-input {
                width: 170px;
                padding-right: 0;
            }
            /deep/.el-cascader {
                width: 170px;
                padding-right: 0;
            }
            /deep/.el-input__inner {
                border: none;
                padding-right: 0;
            }
            /deep/.el-input__suffix {
                display: none;
            }
        }
    }
}
</style>