<template>
    <el-dialog
        class="tagging-dialog"
        :close-on-click-modal="false"
        :visible.sync="show"
        width="500px"
        @close="close">
        <p class="select-title">选择意图</p>
        <!-- <el-select
            v-model="skillId"
            filterable
            style="width:100%"
            size="small"
            placeholder="请搜索或选择知识库"
            @change="getClass">
            <el-option v-for="(item, ind) in skillList" :key="ind" :value="item.id" :label="item.name" />
        </el-select>
        <el-input
            style="margin-top: 10px"
            v-model="keyWord"
            size="small"
            placeholder="请搜索或选择意图"
            @keyup.enter.native="searchIntent"
        >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchIntent"></i>
        </el-input> -->
        <!-- <div class="select-intent">
            <div class="knowledge-base">
                <p
                    v-for="(item, i) in classList"
                    :title="item.name.length > 8 ? item.name : ''"
                    :key="i"
                    :class="item.checked ? 'ischecked' : ''"
                    @click="selectClass(item)"
                >{{item.name}}</p>
            </div>
            <div class="intention">
                <p
                    v-for="(v, i) in intentList"
                    :title="v.name.length > 14 ? v.name : ''"
                    :key="i"
                    :class="v.checked ? 'ischecked' : ''"
                    @click="selectIntent(v)"
                >{{v.name}}</p>
            </div>
        </div> -->
        <div class="select-intent-content">
            <el-cascader
                v-model="intentId"
                size="small"
                :filterable="true"
                :options="intentTree4Radio"
                @change="changeIntent"
                placeholder="请搜索选择意图"
                :props="{
                    expandTrigger: 'hover',
                    emitPath: false,
                    label: 'name',
                    value: 'id',
                }"
            ></el-cascader>
        </div>
        <div class="intent-footer">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="tagIntent('dia')">标注</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props:[],
    data() {
        return {
            show: false,
            skillList: [],
            skillId: '',
            keyWord: '',
            intentId: '',
            classId: 1,
            intentList: [],
            classList: [],
            intentName: '',
            type: '',
            tId: '',
            isIdentify: '',
            isRight: '',
            loading: false,
            nodeTree: [],
            intentTree4Radio: [],
            botId: ""
        }
    },
    mounted() {
        this.getIntentList();
    },
    // 20210812 改版 
    methods: {
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId) {
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId })
                .then((res) => {
                    if (res.code === "0") {
                        this.nodeTree = [...res.data];
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        for (let i = nodeTree.length - 1; i >= 0; i--) {
                            for (
                                let j = nodeTree[i].children.length - 1;
                                j >= 0;
                                j--
                            ) {
                                for (
                                    let k =
                                        nodeTree[i].children[j].children
                                            .length - 1;
                                    k >= 0;
                                    k--
                                ) {
                                    for (
                                        let l =
                                            nodeTree[i].children[j].children
                                                .length - 1;
                                        l > -0;
                                        l--
                                    ) {
                                        let intent =
                                            nodeTree[i].children[j].children[l];
                                        nodeTree[i].children[j].children[l] = {
                                            id: intent.id,
                                            name: intent.name,
                                        };
                                    }
                                }
                            }
                            this.intentTree4Radio = nodeTree;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取意图名称
        changeIntent(val){
            this.intentTree4Radio.forEach(skill => {
                if (skill.children != null) {
                    skill.children.forEach(_class => {
                        if (_class.children != null) {
                            _class.children.forEach(intent => {
                                if (intent.id == val) {
                                    this.intentName = intent.name;
                                }
                            })
                        }
                    })
                }
            })
        },
        open(row, isRight, type, questionIds) {
            this.loading = true;
            this.show = true;
            this.getSkill();
            this.isRight = isRight;
            this.type = type;
            if (type) {
                this.tId = questionIds.join(',');
                this.isIdentify = row[0].isIdentify;
            } else {
                this.tId = row.id;
                this.isIdentify = row.isIdentify;
            }
        },
        close() {
            this.show = false;
            this.$emit('close');
            this.classList = [];
            this.intentList = [];
            this.skillList = [];
            this.keyWord = '';
            this.tId = '';
        },
        // 机器人知识库列表
        getSkill(id) {
            this.FetchGet(this.requestUrl.trainList.skill, {
                bid: this.botId
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((v, i) => {
                        v.checked = false;
                        if (i === 0) v.checked = true;
                    })
                    this.skillList = res.data;
                    this.skillId = id || res.data[0].id;
                    this.getClass();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        // 意图列表
        getIntentList() {
            this.classId !== 1 && this.FetchGet(this.requestUrl.trainList.intentList, {
                // keyWord: this.keyWord,
                typeId: this.classId
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((val, ind) => {
                        val.checked = false
                        // if (ind === 0) val.checked = true
                    });
                    this.intentList = res.data;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        // 选择意图
        selectIntent(item) {
            this.intentList.forEach(v => {
                v.checked = false;
                if (v.id === item.id) v.checked = true;
            })
            this.intentId = item.id;
            this.intentName = item.name;
        },
        // 搜索意图
        searchIntent() {
            this.FetchGet(this.requestUrl.trainList.skillIntent, {
                skillId: this.skillId,
                keyWord: this.keyWord
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((val, ind) => {
                        val.checked = false
                        if (ind === 0) {
                            val.checked = true;
                            this.classId = val.typeId;
                            // this.keyWord = '';
                        }
                        this.classList.forEach(v => {
                            v.checked = false;
                            if (v.id === this.classId) v.checked = true;
                        })
                    });
                    this.intentList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取分类
        getClass(id) {
            this.FetchGet(this.requestUrl.trainList.intentTypes, {
                skillId: this.skillId,
                // keyWord: this.keyWord
            }).then(res => {
                if (res.code === "0") {
                    this.classList = res.data;
                    this.selectClass(res.data[0])
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 选择分类下的意图
        selectClass(item) {
            this.classList.forEach(v => {
                v.checked = false;
                if (v.id === item.id) v.checked = true;
            })
            this.classId = item.id;
            this.getIntentList();
        },
        // 标注
        tagIntent(source) {
            // 单条标注
            if (this.type === 0) {
                if (this.intentId == "" && source == "dia") {
                    this.$message.error("请点击选择意图");
                } else {
                    this.FetchPost(this.requestUrl.trainList.mark, {
                        intentId: this.intentId,
                        intentName: this.intentName,
                        isIdentify: this.isIdentify,
                        isRight: this.isRight,
                        tId: this.tId
                    }).then(res => {
                        if (res.code === "0") {
                            this.intentId = "";
                            this.close();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
                
            // 批量标注
            } else {
                this.FetchPost(this.requestUrl.trainList.markBatch, {
                    intentId: this.intentId,
                    intentName: this.intentName,
                    isIdentify: this.isIdentify,
                    isRight: this.isRight,
                    tIds: this.tId
                }).then(res => {
                    if (res.code === "0") {
                        this.close();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        }
    }
}
</script>

<style lang="less">
.tagging-dialog{
    .select-intent-content{
        margin: 16px 0 32px;
        .el-cascader {
            width: 100%;
        }
    }
    .el-dialog__header{
        padding: 0!important;
    }
    .select-title{
        text-align: left;
        height: 32px;
        line-height: 32px;
    }
    .select-intent{
        display: flex;
        border: 1px solid #DCDFE6;
        margin-top: 2px;
        border-radius: 4px;
        text-align: left;
        > div{
            max-height: 50vh;
            overflow: auto;
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 32px;
                padding: 0 5px;
                line-height: 32px;
                border-bottom: 1px solid #DCDFE6;
                cursor: pointer;
            }
            p:hover{
                background: #366AFF;
                color: #ffffff;
            }
            p:last-child{
                border-bottom: none;
            }
            .ischecked{
                background: #366AFF;
                color: #ffffff;
            }
        }
        .knowledge-base{
            width: 150px;
            border-right: 1px solid #DCDFE6;
        }
        .intention{
            flex: 1;
            height: auto;
        }
    }
    .intent-footer{
        margin-top: 10px;
        text-align: right;
    }
}
</style>