<template>
    <ChatFlowComponent :isPart="0" :intentIds="['','']" :drawerSize="1000" />
</template>
<script>
import ChatFlowComponent from "./FlowComponent.vue";
// import ChatFlowComponent from "./TestFlowComponent.vue";
export default {
    components: { ChatFlowComponent },
    data() {
        return {};
    },
};
</script>
<style lang="less" scoped>
</style>
