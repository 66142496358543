<template>
    <div id="feedback">
        <div class="feedBackTitle">
            <span class="title">答案反馈</span>
           <div class="right">
                <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                <el-switch
                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                    @click.native.prevent="changefastFeedback('switch')"
                    :width="42"
                    v-model="feedbackSeetingObj.feedback"
                    active-color="#366AFF"
                    inactive-color="#E2E2E2">
                >
                </el-switch>
                <span
                class="switch-open-icon"
                @click="changefastFeedback('open')"
                v-if="feedbackSeetingObj.feedback"
                ><i class="iconfont guoran-a-16-17"></i
                ></span>
                <span
                class="switch-close-icon"
                    @click="changefastFeedback('close')"
                v-if="!feedbackSeetingObj.feedback"
                ><i class="arsenal_icon arsenalcuo1"></i
                ></span>
                </div>
                <i class="iconfont guoran-guanzhuren"></i>
                <span class="des">开启后，机器人自动在文本、图片、视频、富文本组件后加上答案反馈，如果意图在流程中单独配置了答案反馈组件，则优先走流程中配置的答案反馈</span>
           </div>
        </div>
       <div class="itemContent">
            <div class="mgt10 fb-simple-cell-satisfaction-inline">
                <div class="fb-title">
                    <span>如果反馈</span>
                    <span class="is-use" v-if="feedbackSeetingObj.positiveFeedback.text"> {{feedbackSeetingObj.positiveFeedback.text}}</span>
                </div>
            </div>
            <div class="fb-simple-cell-satisfaction">
                <div class="jump-cell">
                    <el-select
                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                        style="width: 320px"
                        v-model="feedbackSeetingObj.positiveFeedback.scope"
                        @change="jumpTypeValueChange($event, 'positive')"
                        placeholder="搜索或选择意图"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <div class="right-select">
                        <el-cascader
                            v-show="
                                feedbackSeetingObj.positiveFeedback.scope ===
                                'INTENT'
                            "
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            placeholder="搜索或选择意图"
                            v-model="feedbackSeetingObj.positiveFeedback.value"
                            :options="intentTree4Radio"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                        <el-input
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            rows="1"
                            show-word-limit
                            v-show="
                                feedbackSeetingObj.positiveFeedback.scope === 'TEXT'
                            "
                            type="textarea"
                            v-model="feedbackSeetingObj.positiveFeedback.value"
                            maxlength="2000"
                            placeholder="请输入文字答案"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="fb-simple-cell-satisfaction-inline mgt10">
                <div class="fb-title">
                    <span>如果反馈</span>
                    <span class="is-use">{{feedbackSeetingObj.negativeFeedback.text}}</span>
                </div>
            </div>
            <div class="fb-simple-cell-satisfaction">
                <div class="jump-cell">
                    <el-select
                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                        style="width: 320px"
                        v-model="feedbackSeetingObj.negativeFeedback.scope"
                        size="small"
                        @change="jumpTypeValueChange($event, 'negative')"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <div class="right-select">
                        <el-cascader
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            placeholder="搜索或选择意图"
                            v-show="
                                feedbackSeetingObj.negativeFeedback.scope ===
                                'INTENT'
                            "
                            v-model="feedbackSeetingObj.negativeFeedback.value"
                            :options="intentTree4Radio"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                        <el-input
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            rows="1"
                            show-word-limit
                            v-show="
                                feedbackSeetingObj.negativeFeedback.scope === 'TEXT'
                            "
                            type="textarea"
                            v-model="feedbackSeetingObj.negativeFeedback.value"
                            maxlength="2000"
                            placeholder="请输入文字答案"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="fb-simple-cell-satisfaction-inline mgt10">
                <div class="fb-title apply-intention">
                    <span>适用意图</span>
                    <span class="tips">选择答案反馈针对于哪些意图生效</span>
                </div>
            </div>
              <div class="fb-simple-cell-satisfaction">
                <div class="jump-cell">
                    <div class="right-select intent">
                        <div class="default-choose-all" v-if="!feedbackSeetingObj.takeEffectIntentId || feedbackSeetingObj.takeEffectIntentId.length === 0">
                            <span>全部意图</span>
                            <!-- <i class="el-icon-error" @click="delAll"></i> -->
                        </div>
                        <el-cascader
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            collapse-tags
                            v-model="feedbackSeetingObj.takeEffectIntentId"
                            filterable
                            placeholder=""
                            :options="intentTree4Radio"
                            :props="{
                                multiple:true,
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
export default {
    name: "feedback",
    props: ["feedbackSeetingObj"],
    data() {
        return {
            name: "feedback",
            intentTree4Radio: [],
            jumpTypeOptions: [
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "TEXT",
                    label: "回复文本",
                },
                {
                    value: "NONE",
                    label: "无动作",
                },
            ],
        };
    },
    methods: {
        delAll(){

        },
        changefastFeedback(datas){
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
            if(datas === 'open'){
                this.feedbackSeetingObj.feedback = false;
            } else if (datas === 'close'){
                this.feedbackSeetingObj.feedback = true;
            }
        },
        switchChange(){
            this.feedbackSeetingObj.feedback = !this.feedbackSeetingObj.feedback;
        },
        jumpTypeValueChange(e, type) {
            console.log(this.feedbackSeetingObj);
            if (type == "positive") {
                this.feedbackSeetingObj.positiveFeedback.value = "";
            } else {
                this.feedbackSeetingObj.negativeFeedback.value = "";
            }
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId) {
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId })
                .then((res) => {
                    if (res.code === "0") {
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        for (let i = nodeTree.length - 1; i >= 0; i--) {
                            for (
                                let j = nodeTree[i].children.length - 1;
                                j >= 0;
                                j--
                            ) {
                                for (
                                    let k =
                                        nodeTree[i].children[j].children
                                            .length - 1;
                                    k >= 0;
                                    k--
                                ) {
                                    for (
                                        let l =
                                            nodeTree[i].children[j].children
                                                .length - 1;
                                        l > -0;
                                        l--
                                    ) {
                                        let intent =
                                            nodeTree[i].children[j].children[l];
                                        nodeTree[i].children[j].children[l] = {
                                            id: intent.id,
                                            name: intent.name,
                                        };
                                    }
                                }
                            }
                            this.intentTree4Radio = nodeTree;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    mounted() {
        this.getIntentListByBotId(this.$route.query.id);
    },
    watch: {
        feedbackSeetingObj: {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue);
                this.$emit("changeSaveStatus");
            },
            deep: true,
        }
    },
};
</script>
<style lang="less" scoped>
#feedback {
     /deep/ .el-input,.el-cascader,.el-select,.el-textarea{
        .el-input__inner,.el-textarea__inner{
            height: 38px !important;
        }
    }
    .feedBackTitle {
         display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        .right{
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
        }
        .guoran-guanzhuren{
            margin:0 10px;
            color: #FF9555;
        }
        .feedBackTitlePre {
            width: 80px;
            flex: none;
            height: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #606266;
            margin-right: 16px;
        }
        .feedBackTitleAft {
            margin-left: 16px;
            text-align: left;
            color: #d7d7d7;
        }
        .title{
            white-space: nowrap;
            font-size: 16px;
            color: #000000;
            font-weight: 700;
        }
        .des{
            text-align: left;
            color: #999999;
            font-size: 14px;
        }
    }
     .itemContent{
        display: flex;
        flex-direction: column;
        background: #FBFCFD;
        border-radius: 5px;
        padding: 20px;
        font-size: 14px;
        
    }
    .fb-simple-cell-satisfaction-inline {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        margin-bottom: 4px;
        .fb-title {
            width: 100%;
            flex: none;
            text-align: left;
            line-height: 32px;
            height: 32px;
            &.apply-intention{
                display: flex;
                align-items: center;
                .tips{
                    color: #999999;
                    margin-left: 20px;
                }
            }
            .is-use{
                margin-left: 10px;
                display: inline-block;
                width: 46px;
                height: 21px;
                background: #E7EDFF;
                border-radius: 1px;
                color: #616161;
                font-size: 14px;
                text-align: center;
                line-height: 21px;
            }
        }
        .fb-action-confirm-des {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            
            .el-input {
                width: 150px;
            }
        }
    }
    .fb-simple-cell-satisfaction {
        .jump-cell {
            display: flex;
            justify-content: flex-start;
            /deep/ .el-select {
                flex: none;
                width: 150px;
            }
            .right-select {
                flex: auto;
                margin-left: 8px;
                &.intent{
                    margin-left: 0;
                    position: relative;
                    .default-choose-all{
                        position: absolute;
                        top: 8px;
                        left: 6px;
                        z-index: 2;
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                        text-overflow: ellipsis;
                        background: #f0f2f5;
                        height: 24px;
                        padding: 0 5px;
                        line-height: 24px;
                        border-radius: 3px;
                        span{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #909399;
                            font-size: 12px;
                        }
                        i{
                            color: #C0C4CC;
                            font-size: 13px;
                            cursor: pointer;
                            font-size: 12px;
                            top: -1px;
                            right: -5px;
                            margin-left: 5px;
                            &:hover{
                                color: #909399;
                            }
                        }
                    }
                }
                .el-select,
                .el-cascader {
                    width: 100%;
                    height: 38px;
                    line-height: 38px;
                }
                /deep/ .el-textarea{
                    height: 38px;
                    padding: 0;
                    .el-textarea__inner{
                        padding: 0 15px;
                        height: 38px;
                        line-height: 36px;
                    }
                }
            }
        }
    }
    .mgt10 {
        margin-top: 12px;
    }
    .evaluate-table-switch {
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            text-align: center;
            align-items: center;
    
            .el-switch {
                text-align: center;
            }
    
            .switch-open-icon {
                position: absolute;
                z-index: 999;
                top: 50%;
                transform: translateY(-50%);
                left: 3px;
    
                i {
                    color: white;
                    font-size: 16px;
                }
            }
    
            .switch-close-icon {
                position: absolute;
                z-index: 999;
                top: 50%;
                transform: translateY(-50%);
                left: 21px;
                i {
                    color: #6E6B7B;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
    
            i {
                color: #366AFF;
                font-size: 18px;
            }
            &.disabled{
                i,.el-switch__input,.el-switch__core{
                    cursor: no-drop;
                }
            }
        }
}
</style>