<template>
    <div id="botIntent" @click="clickPage">
        <content-header
            :headerTitle="activePageInfo.headerName"
            :isAutoBack="false"
            :showHelp="false"
            @goBack="goBack"
        >
            <div slot="content-header-right" class="leftInfo">
                <div class="header-r-box" v-show="serachIntentBox">
                    <div class="search-intent" v-if="currentTab === 'skill'">
                        <el-input
                            ref="searchIntent"
                            autocomplete="off"
                            new-password="true"
                            size="small"
                            prefix-icon="el-icon-search"
                            placeholder="请输入意图名称"
                            @click.stop.native="searchAllSkillFocus($event)"
                            @keyup.enter.native="searchAllSkillIntent"
                            @input="searchAllSkillIntent"
                            v-model="searchIntentName"
                        ></el-input>
                        <div class="search-intent-list" v-if="showSearchAllSkillIntent">
                            <div class="item" @click="switchSkillClass(item)" v-for="item in searchAllSkillIntentList" :key="item.id">{{item.name}}</div>
                        </div>
                        <div class="no-data" v-if="showSearchAllSkillIntentNoData">暂无数据~</div>
                    </div>
                   
                    <template v-if="!isShowTest && !isShowHeathly && !issearchAllSkillFocus">
                        <el-badge
                            v-if="currentTab == 'skill' && updateIntentBtn"
                            :max="99"
                            :value="updateIntentNum"
                            class="item">
                        <el-button
                            size="small"
                            icon="el-icon-refresh-right"
                            @click="updateIntentOpration"
                            type="primary"> 
                            更新意图流程
                        </el-button>
                        </el-badge>
                        <el-button
                            v-if="currentTab == 'skill'"
                            size="small"
                            icon="guoran-tongyichicun-piliangcaozuo iconfont"
                            round
                            plain
                            @click="batchOperationIntent"> 
                            批量操作
                        </el-button>
                        <el-button
                            v-if="isShowEntranceCopy && currentTab == 'skill'"
                            size="small"
                            plain
                            icon="guoran-tongyichicun-fuzhi iconfont"
                            @click="copyIntentDia"> 
                            从现有意图复制
                            </el-button>
                        <el-button
                            v-if="currentTab == 'skill'"
                            size="small"
                            icon="iconfont guoran-tongyichicun-piliangdaoru"
                            round
                            plain
                            @click="batchImportIntent"> 
                            批量导入
                        </el-button>
                        <div
                            @click="openNoticeDrewer"
                            class="notice-box">
                            <i class="iconfont guoran-tongyichicun-24_01-xiaoxitongzhi"></i>
                            <span>通知中心</span>
                        </div>
                        <div
                            v-show="noUpToStandardIntentTotal > 0 && currentTab === 'skill'"
                            class="unqualified-and-offline-box"
                            @click.stop="showUnqualifieOfflineIntent">
                            <i class="iconfont guoran-tongyichicun-weidabiaoyixiaxian"></i>
                            <span class="button-text">{{CompanyIsOld ? '未达标意图' : ' 未达标已下线意图'}}</span>
                            <div class="num">{{noUpToStandardIntentTotal}}</div>
                            <unqualifieOfflineIntent 
                                :isReaload.sync="isReaload"
                                :isShowUnqualifieOfflineIntent.sync="isShowUnqualifieOfflineIntent" 
                                :botId="$route.query.id" 
                                @noUpToStandardIntentTotal="noUpToStandardIntentTotalFn"
                                @goLocation="goLocation"
                                @popupView="popupViewFn"></unqualifieOfflineIntent>
                        </div>
                    </template>
                    <template v-if="(isShowTest && ! isShowHeathly) || (!isShowTest && isShowHeathly) || issearchAllSkillFocus">
                        <el-badge
                            v-if="currentTab == 'skill' && updateIntentBtn"
                            :max="99"
                            :value="updateIntentNum"
                            class="item"
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="有可以更新的意图流程"
                                placement="bottom"
                            >
                                <el-button
                                    size="small"
                                    icon="el-icon-refresh-right"
                                    @click="updateIntentOpration"
                                    type="primary"
                                    > </el-button>
                            </el-tooltip>
                        </el-badge>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="批量操作"
                            placement="bottom">
                           <el-button
                                v-if="currentTab == 'skill'"
                                size="small"
                                icon="guoran-tongyichicun-piliangcaozuo iconfont"
                                round
                                plain
                                @click="batchOperationIntent">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="从现有意图复制"
                            placement="bottom">
                           <el-button
                                v-if="isShowEntranceCopy && currentTab == 'skill'"
                                size="small"
                                plain
                                icon="guoran-tongyichicun-fuzhi iconfont"
                                @click="copyIntentDia">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="批量导入"
                            placement="bottom">
                           <el-button
                                v-if="currentTab == 'skill'"
                                size="small"
                                icon="iconfont guoran-tongyichicun-piliangdaoru"
                                round
                                plain
                                @click="batchImportIntent">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="通知中心"
                            placement="bottom">
                            <div
                                v-if="isShowEntrance"
                                @click="openNoticeDrewer"
                                class="notice-box">
                                <i class="iconfont guoran-tongyichicun-24_01-xiaoxitongzhi"></i>
                            </div>
                        </el-tooltip>
                        <div class="unqualified-and-offline-box show-test"  @click.stop="showUnqualifieOfflineIntent"   v-show="noUpToStandardIntentTotal > 0 && currentTab === 'skill'">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="CompanyIsOld ? '未达标意图' : ' 未达标已下线意图'"
                                placement="bottom">
                                <div>
                                    <i class="iconfont guoran-tongyichicun-weidabiaoyixiaxian"></i>
                                </div>
                            </el-tooltip>
                            <unqualifieOfflineIntent 
                                :isReaload.sync="isReaload"
                                :isShowUnqualifieOfflineIntent.sync="isShowUnqualifieOfflineIntent" 
                                :botId="$route.query.id" 
                                @noUpToStandardIntentTotal="noUpToStandardIntentTotalFn"
                                @goLocation="goLocation"
                                @popupView="popupViewFn"></unqualifieOfflineIntent>
                        </div>
                    </template>
                    <el-input
                        style="position: fixed; bottom: -999999px"
                    ></el-input>
                </div>
            </div>
        </content-header>
        <div class="botContent">
            <el-menu
                @select="handleClick"
                :default-active="defaultActive"
                class="el-menu-vertical-demo el-menu-vertical-bot-intent"
            >
                <el-menu-item style="min-width: 160px;" index="skill" v-if="RP_Visible('OPEN_SKILL_VIEW') && RP_Visible('OPEN_SKILL')">
                    <img style="margin-right: 6px;margin-top: -2px" v-show="currentTab !== 'skill'" width="14px" src="./../../assets/images/bot_intent_zhishiku.svg" alt="" srcset="">
                    <img style="margin-right: 6px;margin-top: -2px" v-show="currentTab === 'skill'" width="14px" src="./../../assets/images/bot_intent_zhishiku_ac.svg" alt="" srcset="">
                    <span slot="title">知识库</span>
                </el-menu-item>
                <el-menu-item index="second" v-if="RP_Visible('OPEN_PROCESS') && RP_Visible('OPEN_PROCESS_VIEW')">
                    <!-- <i class="iconfont guoran-tongyichicun-duihualiucheng"></i> -->
                    <img style="margin-right: 6px;margin-top: -2px" v-show="currentTab !== 'second'" width="14px" src="./../../assets/images/bot_intent_liucheng.svg" alt="" srcset="">
                    <img style="margin-right: 6px;margin-top: -2px" v-show="currentTab === 'second'" width="14px" src="./../../assets/images/bot_intent_liucheng_ac.svg" alt="" srcset="">
                    <span slot="title">对话流程设计</span>
                </el-menu-item>
                <el-submenu index="bot-setting" v-if="RP_Visible('OPEN_CONFIG_VIEW') && RP_Visible('OPEN_CONFIG')">
                    <template slot="title">
                        <span style="display: inline-block;margin-top: -4px;margin-right: 5px;margin-left: 3px;opacity: 0.6"
                            class="iconfont guoran-tongyichicun-jiqirenshezhi"
                        ></span>机器人设置</template
                    >
                    <el-menu-item style="min-width: 160px;padding-left: 30px" index="bot-setting-noDescernIntentON">
                        <span class="tips"></span>不识别推荐
                    </el-menu-item>
                    <el-menu-item style="min-width: 160px;padding-left: 30px" index="bot-setting-sessionKeep"
                        ><span class="tips"></span>会话保持</el-menu-item
                    >
                    <el-menu-item style="min-width: 160px;padding-left: 30px" index="bot-setting-keyWordRecommendIntents"
                        ><span class="tips"></span>关键词推荐<div class="red-spot" v-if="keywordRecommendHasNew"></div></el-menu-item
                    >
                    <el-menu-item style="min-width: 160px;padding-left: 30px" index="bot-setting-feedback"
                        ><span class="tips"></span>答案反馈</el-menu-item
                    >
                    <el-menu-item style="min-width: 160px;padding-left: 30px" index="bot-setting-fastSelect"
                        ><span class="tips"></span>快捷选择</el-menu-item
                    >
                </el-submenu>
            </el-menu>
            <div class="botIntent-content-right">
                <!-- 知识库 -->
                <div
                    class="outer-pane-bot"
                    v-if="RP_Visible('OPEN_SKILL') && currentTab === 'skill'"
                >
                    <div class="class-manage-zsk-1 skill-manageinner">
                        <ul class="class-list">
                            <li
                                v-for="item in skillList"
                                :class="[
                                    item.id === activeSkill
                                        ? 'active-skill'
                                        : 'list-skill',
                                ]"
                                :key="item.id"
                                @click="switchSkill(item.id,'switch')"
                            >
                                <el-badge
                                    :value="item.intentNum"
                                    class="item"
                                    :type="
                                        item.id == activeSkill ? 'primary' : ''
                                    "
                                >
                                    <span>
                                        {{ item.name }}
                                    </span>
                                </el-badge>
                            </li>
                        </ul>
                        <el-button
                            class="know-manage-btn"
                            @click="showSkillManage"
                            @mouseenter.native="showAcKnowManageBtn = true"
                            @mouseleave.native="showAcKnowManageBtn = false"
                            v-if="RP_Visible('OPEN_SKILL_UPDATE')"
                            plain
                            >
                            <img style="margin-right: 2px;margin-top: -2px" v-show="!showAcKnowManageBtn" width="14px" src="./../../assets/images/bot_intent_zhishiku.svg" alt="" srcset="">
                            <img style="margin-right: 2px;margin-top: -2px" v-show="showAcKnowManageBtn" width="14px" src="./../../assets/images/bot_intent_zhishiku_ac.svg" alt="" srcset="">
                            知识库管理
                            </el-button
                        >
                    </div>
                    <div v-show="!isOtherSkill" class="class-manage-zsk-1 class-manage-inner">
                        <ul class="class-list classifi">
                            <li
                                v-for="item in classList"
                                :class="[
                                    'classifi-item',
                                    item.id == activeClass
                                        ? 'active-class'
                                        : 'list-hover',
                                ]"
                                :key="item.id"
                                @click="switchClass(item.id)"
                            >
                                <span class="name">{{ item.name }}</span>
                                <span>{{ item.intentNum }}</span>
                            </li>
                        </ul>
                        <div class="button-box">
                            <el-button
                                @click="intentSort"
                                v-if="otherSkillId != activeSkill"
                                plain
                                class="know class-manage-btn"
                                icon="iconfont guoran-tongyichicun-yitupaixu"
                                >意图排序</el-button
                            >
                            <el-button
                                @click="clickClassHandle"
                                plain
                                class="know class-manage-btn"
                                v-if="RP_Visible('OPEN_SKILL_TYPE_UPDATE') && otherSkillId != activeSkill"
                                icon="iconfont guoran-tongyichicun-guanliwodezhishikumoban"
                                >分类管理</el-button
                            >
                        </div>
                    </div>
                    <div
                        v-if="classList.length !== 0 || true"
                        class="intentWrap_intent"
                    >
                        <!-- <div class="filing-box" @click="openIntentClosedBox">
                           <span class="filing-box-icon-outer">
                                <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                           </span>
                            <span class="filing-box-text-outer">意图归档箱</span>
                        </div> -->
                        <ul v-if="!isOtherSkill" ref="intentListContent" key="intentList" class="intent-list intent-list-content"  v-loading="loading" element-loading-custom-class="intent-list-loading">
                            <li
                                @click="addIntent"
                                ref="upAddIntent"
                                v-if="RP_Visible('OPEN_SKILL_INTENT_ADD') && otherSkillId != activeSkill"
                                :class="['addIntent', limitUse?'botLimitUse':'']"
                            >
                                <div class="add-intent-inner">
                                    <img src="../../assets/images/addBot.png" alt="" />
                                    <div class="addIntentBtn cursor">
                                      
                                        <i class="el-icon-plus"></i>
                                        <span class="addBotText">新增意图</span>
                                    </div>
                                    <div class="definitionIntent cursor">
                                        <div
                                            class="guide-desc"
                                            @click.stop="highlightIntentName()"
                                        >
                                            <span class="definitionIntentName"
                                                >什么是意图？</span
                                            >
                                        </div>
                                        <div
                                            class="guide-desc"
                                            @click.stop="highlightKill()"
                                        >
                                            <span class="definitionIntentName"
                                                >如何构建知识库？</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li
                                :class="['intent',activeIntent == item.id ? 'active' : '']"
                                v-for="(item, index) in intentList"
                                :key="item.id"
                                :id="'intent-item' + item.id"
                                v-show="item.isOpen"
                                @click.stop="gotoIntentHandle(item)"
                                :style="otherSkillId == activeSkill?'height:140px':'height:176px'"
                                ref="upIntent"
                            >
                                <img v-if="!item.onLine && item.onLine !== null && !CompanyIsOld" src="../../assets/images/unqualified-and-offline.png" alt="" class="unqualified-and-offline">
                                <img v-if="!item.upToStandard && item.upToStandard !== null && CompanyIsOld" src="../../assets/images/unqualified.png" alt="" class="unqualified">
                                <div class="intent-inner">
                                    <div class="intent-info">
                                        <el-tooltip :content="item.name" placement="top" effect="light">
                                            <span v-if="item.name.length > 12" class="intent-name">{{
                                                item.name
                                            }}</span>
                                        </el-tooltip>
                                        <span v-if="item.name.length <= 12" class="intent-name">{{
                                            item.name
                                        }}</span>
                                        <span v-show="otherSkillId != activeSkill" class="intent-desc">
                                            {{ item.exampleQuestion }}</span
                                        >
                                        <span v-if="!liningShow" class="intent-info-cell"><br></span>
                                        <div style="display:flex;justify-content: space-between;">
                                            <div class="left">
                                                <span class="intent-info-cell">
                                                    <i
                                                        class="iconfont guoran-tongyichicun-mingzhongcishu"
                                                    ></i>
                                                    <span class="intent-card-num-title">命中次数</span> 
                                                    <span>{{ item.hits }}</span>
                                                </span>
                                                <span v-if="liningShow" class="intent-info-cell">
                                                    <img style="margin-right: 4px;" src="./../../assets/images/bot_intent_rg_intent.svg" width="16px" alt="" srcset="">
                                                    <span class="intent-card-num-title">识别规则</span>
                                                    
                                                    <span>{{ item.ruleNum }}</span>
                                                </span>
                                            </div>
                                            <div class="right">
                                                <span class="intent-info-cell">
                                                    <i
                                                        class="iconfont guoran-tongyichicun-xiangsiwenfa"
                                                    ></i>
                                                    <span class="intent-card-num-title">相似问法</span>
                                                    
                                                    <span>{{ item.questionNum }}</span>
                                                </span>
                                                <span class="intent-info-cell super-intent">
                                                    <i
                                                        class="iconfont guoran-yitu"
                                                    ></i>
                                                    <span class="intent-card-num-title">超级意图</span>
                                                    <span class="super-intent-status">{{item.enableSuperIntent ? '已开启' : '已关闭'}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="intent-operation">
                                        <div v-if="otherSkillId == activeSkill" class="other-bottom-left-operation">
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                content="未配置对话流程"
                                                placement="bottom"
                                            >
                                                <i
                                                    v-if="
                                                        (!item.hasProcess &&
                                                            item.refAttrId ==
                                                                null) ||
                                                        (!item.hasProcess &&
                                                            item.refAttrId !=
                                                                null &&
                                                            !item.requireSyncProcess)
                                                    "
                                                    class="lack-1 btn-icon-1"
                                                    >缺流程</i
                                                >
                                            </el-tooltip>
                                            <em
                                                class="iconfont guoran-a-24-06"
                                                @click.stop="
                                                    gotoIntentHandle(item)
                                                "
                                            ></em>
                                        </div>
                                        <div v-if="otherSkillId != activeSkill" class="bottom-left-operation">
                                            <div
                                                class="top-tag-operation guan btn-icon"
                                                v-if="item.isDefault == 1"
                                            >
                                                官方
                                            </div>
                                            <div
                                                class="b-handle"
                                                style="height: 20px"
                                            >
                                                <div @click.stop>
                                                    <el-popconfirm
                                                        width="200px"
                                                        confirm-button-text="开启"
                                                        cancel-button-text="取消"
                                                        @onConfirm="
                                                            closeIntent(
                                                                true,
                                                                index,
                                                                item.isParent
                                                            )
                                                        "
                                                        icon="el-icon-info"
                                                        icon-color="red"
                                                        title="意图开启后，机器人将继续识别该意图并将该意图移出意图归档箱中，是否开启？"
                                                    >
                                                        <div
                                                            slot="reference"
                                                            class="icon-switch-box false"
                                                            v-if="
                                                                !item.isOpen &&
                                                                RP_Visible(
                                                                    'OPEN_SKILL_INTENT_CLOSE'
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="el-icon-close"
                                                            ></i>
                                                            <div></div>
                                                        </div>
                                                    </el-popconfirm>
                                                </div>
                                                <div
                                                    slot="reference"
                                                    class="icon-switch-box"
                                                    v-if="
                                                        item.isOpen &&
                                                        RP_Visible(
                                                            'OPEN_SKILL_INTENT_CLOSE'
                                                        )
                                                    "
                                                    @click.stop="
                                                        closeIntent(
                                                            false,
                                                            index,
                                                            item.isParent
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-check"
                                                    ></i>
                                                    <div></div>
                                                </div>
                                                <el-tooltip
                                                    class="item"
                                                    effect="dark"
                                                    content="未配置对话流程"
                                                    placement="bottom"
                                                >
                                                    <i
                                                        v-if="
                                                            (!item.hasProcess &&
                                                                item.refAttrId ==
                                                                    null) ||
                                                            (!item.hasProcess &&
                                                                item.refAttrId !=
                                                                    null &&
                                                                !item.requireSyncProcess)
                                                        "
                                                        class="lack btn-icon"
                                                        >缺流程</i
                                                    >
                                                </el-tooltip>
                                                <div @click.stop>
                                                    <el-popconfirm
                                                        confirm-button-text="同步"
                                                        cancel-button-text="取消"
                                                        @onConfirm="
                                                            confirmAsyn(item)
                                                        "
                                                        icon="el-icon-info"
                                                        icon-color="red"
                                                        title="可同步模板中对话流程。"
                                                    >
                                                        <i
                                                            v-if="
                                                                item.hasProcess &&
                                                                item.refAttrId !=
                                                                    null &&
                                                                item.requireSyncProcess
                                                            "
                                                            slot="reference"
                                                            class="btn-icon el-icon-refresh-right refersh-flow"
                                                            >更新流程</i
                                                        >
                                                    </el-popconfirm>
                                                </div>
                                                <div @click.stop>
                                                    <el-popconfirm
                                                        confirm-button-text="同步"
                                                        cancel-button-text="取消"
                                                        @onConfirm="
                                                            confirmAsyn(item)
                                                        "
                                                        icon="el-icon-info"
                                                        icon-color="red"
                                                        title="可同步模板中对话流程。"
                                                    >
                                                        <i
                                                            v-if="
                                                                !item.hasProcess &&
                                                                item.refAttrId !=
                                                                    null &&
                                                                item.requireSyncProcess
                                                            "
                                                            slot="reference"
                                                            class="btn-icon el-icon-refresh-right refersh-flow"
                                                            >同步流程</i
                                                        >
                                                    </el-popconfirm>
                                                </div>
                                            </div>
                                            <div
                                                class="top-tag-operation share btn-icon"
                                                v-if="item.isShare == 1"
                                            >
                                                共享
                                            </div>
                                            <div
                                                class="top-tag-operation multiwheelIntent"
                                                v-if="item.multiwheelIntent"
                                            >
                                                多轮
                                            </div>
                                        </div>

                                        <div
                                            class="intent-handle-box"
                                            v-if="item.editable && otherSkillId != activeSkill"
                                        >
                                          <div class="filde-icon">
                                                <em
                                                v-if="
                                                    RP_Visible(
                                                        'OPEN_SKILL_INTENT_UPDATE'
                                                    ) && !item.isDefault
                                                "
                                                class="iconfont guoran-a-24-06"
                                                @click.stop="
                                                    gotoIntentHandle(item)
                                                "
                                                ></em>
                                                <em
                                                    v-if="
                                                        RP_Visible(
                                                            'OPEN_SKILL_INTENT_UPDATE'
                                                        ) && !item.isDefault
                                                    "
                                                    class="iconfont guoran-a-18_huaban1fuben3"
                                                    @click.stop="editeIntent(item)"
                                                ></em>
                                                <em
                                                    v-if="
                                                        RP_Visible(
                                                            'OPEN_SKILL_INTENT_DELETE'
                                                        ) && !item.isDefault
                                                    "
                                                    class="iconfont guoran-tongyichicun-18-13-shanchu"
                                                    @click.stop="
                                                        deleteIntent(
                                                            item.id,
                                                            item.isParent
                                                        )
                                                    "
                                                ></em>
                                          </div>
                                           <el-tooltip
                                                class="tooltip-time"
                                                effect="dark"
                                                :content="
                                                    new Date(
                                                        item.createTime
                                                    ).Format(
                                                        'yyyy-MM-dd hh:mm:ss'
                                                    )
                                                "
                                                placement="bottom-end"
                                            >
                                                <em
                                                    class="arsenal_icon arsenalgengduo"
                                                ></em>
                                            </el-tooltip>
                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </li>
                            <li v-for="(emptyCell, index) in needAddNumArr" :key="'emptyCell_' + index" class="intent_empty"></li>
                        </ul>
                        <ul v-if="isOtherSkill" ref="intentListContent" key="intentList" class="intent-list intent-list-content"  v-loading="loading" element-loading-custom-class="intent-list-loading">
                            <li
                                :class="['intent',activeIntent == item.id ? 'active' : '']"
                                v-for="(item, index) in intentList"
                                :key="item.id"
                                :id="'intent-item' + item.id"
                                @click.stop="otherToChatFlow(item)"
                                ref="upIntent"
                                style="height: 112px;width: 200px;"
                            >
                                <div class="intent-inner">
                                    <div class="intent-info">
                                        <el-tooltip :content="item.name" placement="top" effect="light">
                                            <span v-if="item.name.length > 12" class="intent-name">{{
                                                item.name
                                            }}</span>
                                        </el-tooltip>
                                        <span v-if="item.name.length <= 12" class="intent-name">{{
                                            item.name
                                        }}</span>
                                        <span class="intent-desc">
                                            {{ item.description }}</span
                                        >
                                        <div style="display:flex;justify-content: space-between;">
                                            <div class="left">
                                                <span class="intent-info-cell">
                                                    <i
                                                        class="iconfont guoran-tongyichicun-mingzhongcishu"
                                                    ></i>
                                                    <span class="intent-card-num-title">命中次数</span> 
                                                    <span>{{ item.hits }}</span>
                                                </span>
                                            </div>
                                            <div class="right">
                                                <span style="color: #366aff;" class="iconfont guoran-a-24-06"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style="height: 112px;width: 200px;" v-for="(emptyCell, index) in needAddNumArr" :key="'emptyCell_' + index" class="intent_empty"></li>
                        </ul>
                    </div>
                    
                </div>
                <!-- 机器人设置 -->
                <div
                    class="outer-pane-bot bot-set-content"
                    v-if="
                        RP_Visible('OPEN_CONFIG') &&
                        currentTab.indexOf('bot-setting') !== -1
                    "
                >
                    <BotSettingNew :key="currentTab" :botSettingTab="botSettingTab" :currentTab="currentTab" @changeKeywordRecommendHasNew="changeKeywordRecommendHasNew"></BotSettingNew>
                </div>
            </div>
        </div>
        <div id="superIntentConfig-intent-add">
            <!-- 新建意图/编辑意图 -->
            <popup
                id="open-super-intnet"
                @closeEvent="addIntentDiaVisible = false"
                v-show="addIntentDiaVisible"
            >
                <div slot="popup-name">
                    {{ handleTypeC === "add" ? "新建意图" : "编辑意图" }}
                </div>
                <div slot="popup-tip">
                    在下方添加{{
                        handleTypeC === "add" ? "新建意图" : "编辑意图"
                    }}
                </div>
                <div slot="popup-con">
                    <div
                        v-if="handleTypeC === 'add'"
                        style="text-align: left"
                        class="copy-intent-btn"
                    >
                        <el-button
                            @click="copyIntentDia"
                            icon="guoran-tongyichicun-fuzhi iconfont"
                            size="mini"
                            plain
                            round
                            >从现有意图复制</el-button
                        >
                    </div>
                    <ul class="addIntentInfo">
                        <li class="item-config">
                            <div class="block-add-intent">
                                <span class="title">意图名称 <span class="red-text">*</span>  </span>
                                <el-input
                                    v-model="intentName"
                                    :maxlength="maxLengthLimit"
                                    placeholder="请输入意图名称"
                                ></el-input>
                            </div>
                        </li>
                        <li class="item-config">
                            <div class="block-add-intent">
                                <span class="title">示例问法<span class="red-text">*</span></span>
                                <el-input
                                    v-model="exampleQuestion"
                                    :maxlength="maxLengthLimit"
                                    :placeholder="'请输入示例问法，用于不识别时推荐意图的名称（必填，' + maxLengthLimit + '个字以内）'"
                                ></el-input>
                            </div>
                        </li>
                        <li class="item-config">
                            <div class="block-add-intent share-radio">
                                <span class="title">共享意图</span>
                                <el-radio-group v-model="isShare">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </div>
                        </li>
                        <li class="item-config">
                            <div class="classification">
                                <span class="title">所属知识库及分类</span>
                                <el-cascader
                                    :disabled="isDefault"
                                    v-if="addIntentDiaVisible"
                                    v-model="classification"
                                    style="width: 100%"
                                    :props="{ emitPath: false }"
                                    :options="classificationOption.filter(item => {
                                        return item.label !== '特殊规则';
                                    })"
                                    @expand-change="handleChange2"
                                ></el-cascader>
                            </div>
                        </li>
                    </ul>
                    <ul
                        class="superIntentConfig-add-inetent"
                        v-show="
                            this.handleTypeC == 'add' &&
                            RP_Visible('OPEN_INTENT_TEMPLATE_UPDATE')
                        "
                    >
                        <li class="item-config">
                            <div class="block-add-intent open-super-intent">
                                <span class="title">启用超级意图</span>
                                <div class="right">
                                    <div class="evaluate-table-switch">
                                        <el-switch
                                            @click.native.prevent="changeEnable('switch')"
                                            :width="42"
                                            v-model="currentSuperIntentList.enable"
                                            active-color="#366AFF"
                                            inactive-color="#E2E2E2">
                                        >
                                        </el-switch>
                                        <span
                                        class="switch-open-icon"
                                        @click="changeEnable('open')"
                                        v-if="currentSuperIntentList.enable"
                                        ><i class="iconfont guoran-a-16-17"></i
                                        ></span>
                                        <span
                                        class="switch-close-icon"
                                            @click="changeEnable('close')"
                                        v-if="!currentSuperIntentList.enable"
                                        ><i class="arsenal_icon arsenalcuo1"></i
                                        ></span>
                                    </div>
                                    <div class="desc">
                                        启用超级意图功能后，在您添加问法或规则较少的情况下，能提高识别能力
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="item-config">
                            <div
                                class="block-add-intent"
                                v-show="currentSuperIntentList.enable"
                            >
                                <span class="title">
                                    <i
                                        class="iconfont guoran-tongyichicun-shili"
                                    ></i>
                                    选择超级意图类别</span
                                >
                                <el-select
                                    
                                    :key="intentClassKey"
                                    v-model="selectData.name"
                                    @visible-change="
                                        selectVisibleChange($event)
                                    "
                                    @focus="superIntentFocus()"
                                    @change="selectTrigger()"
                                    filterable
                                    placeholder="请选择"
                                >
                                    <el-option
                                        :class="[clearStatus ? 'clearStatus' : 'no']"
                                        v-for="item in superIntentList"
                                        :key="item.id"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </li>
                        <li
                            class="exmplebox item-config"
                            v-show="
                                currentSuperIntentList.enable &&
                                selectData.examples != ''
                            "
                        >
                            <span class="title">超级意图问法示例</span>
                            <div class="exmple">
                                <div
                                    v-for="item in selectData.examples"
                                    :key="item.id"
                                    class="text"
                                >
                                    <div></div>
                                    {{ item }}
                                </div>
                            </div>
                        </li>
                        <li
                            class="item-config"
                            v-show="
                                currentSuperIntentList.enable &&
                                selectData.examples != ''
                            "
                        >
                            <div class="blockdes">
                                <span class="title">
                                    <i
                                        class="iconfont guoran-tongyichicun-shili"
                                    ></i>
                                    请按照示例造句，将相应的词填入输入框内
                                </span>
                                <span class="des"
                                    >（如词语存在近义词，也请填写，多个词之间用逗号分隔）</span
                                >
                            </div>
                            <div class="config">
                                <div
                                    class="pattern"
                                    v-for="(item, index) in selectData
                                        .templateConfig.pattern"
                                    :key="index"
                                >
                                <span class="keyword-recommend">
                                    <el-input
                                        @click.stop.native="changeInputSuper($event,item,index,'focus')"
                                        @input="changeInputSuper($event,item,index)"
                                        v-model.trim="item.value"
                                        size="small"
                                        placeholder="输入关键词"
                                        v-show="
                                            item.isFillIn == true &&
                                            item.type != 1
                                        "
                                    ></el-input>
                                    <botKeywordRecommend 
                                        :key="'superIntentConfigs' + index"
                                        :showBotKeywordRecommend.sync="item.showBotKeywordRecommend"
                                        :dataObj="item"
                                        :dataIndex="index"
                                        :keyword="item.value"
                                        :left="0"
                                        :positionTop="36"
                                        @showBotKeywordRecommend="showBotKeywordRecommendFn"
                                        @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                        @addToInput="addToInputSuper($event,item,index)">
                                    </botKeywordRecommend>
                                </span>
                                    <span
                                        class="word"
                                        v-show="
                                            item.type == 1 &&
                                            item.isFillIn == false
                                        "
                                        >{{ item.words[0] }}</span
                                    >
                                </div>
                            </div>
                            <div class="pattern config-example">
                                <div class="title"><span>配置示例</span></div>
                                <div class="bottom-example">
                                    <div
                                        :class="[
                                            'pattern',
                                            item.isFillIn ? 'border' : '',
                                        ]"
                                        v-for="(item, index) in selectData
                                            .templateConfig.example"
                                        :key="index"
                                    >
                                        <span
                                            :class="[
                                                item.type != '1'
                                                    ? 'word input'
                                                    : 'word',
                                            ]"
                                            v-for="(
                                                todo, cindex
                                            ) in selectData.templateConfig.example[
                                                index
                                            ].words.join(',')"
                                            :key="cindex"
                                            >{{ todo }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="addIntentDiaVisible = false"
                        plain
                        round
                        class="cancel-btn"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        @click="submitAddBot"
                        round
                        :loading="addIntentLoading"
                        class="confirm-btn"
                        >确定</el-button
                    >
                </div>
            </popup>

            <!-- 知识库管理 -->
            <popup
                @closeEvent="skillManageVisible = false"
                v-if="skillManageVisible"
                :haveTips="false"
                :isNeedScroll="false"
                id="skill-manage"
            >
                <div slot="popup-name">知识库管理</div>
                <div slot="popup-con">
                    <SkillManage
                        ref="skillManage"
                        :isShowHeathly="isShowHeathly"
                        @getSkillListSkillManage="getSkillListSkillManage"
                        @changeSkillVersionTipDialog="changeSkillVersionTipDialog"
                    ></SkillManage>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="skillManageVisible = false"
                        plain
                        round
                        class="cancel-btn"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        :disabled="!RP_Visible('OPEN_SKILL_UPDATE')"
                        class="confirm-btn"
                        @click="saveSkillManage"
                        round
                        >确定</el-button
                    >
                </div>
            </popup>
            <!-- 分类管理 -->
            <popup
                @closeEvent="classHandleVisible = false"
                v-if="classHandleVisible"
                id="diaHandleClass"
                :haveTips="false"
                :isNeedScroll="false"
            >
                <div slot="popup-name">分类管理</div>
                <div slot="popup-con">
                    <div
                        v-if="RP_Visible('OPEN_SKILL_TYPE_UPDATE')"
                        class="tar">
                    </div>
                    <div class="classListBox">
                        <ul id="classList" class="classList">
                            <li
                                :class="[index+1 === classListHandle.length ? 'last' : '']"
                                v-for="(item, index) in classListHandle"
                                :key="index"
                            >
                                <span class="num">{{ index + 1 }}</span>
                                <div class="content">
                                    <span v-show="!item.isEdit">{{
                                        item.name
                                    }}</span>
                                    <span v-show="item.isEdit">
                                        <el-input
                                            size="small"
                                            v-model.trim="item.name"
                                            maxlength="15"
                                        ></el-input>
                                    </span>
                                </div>
                                <div
                                    v-if="
                                        RP_Visible('OPEN_SKILL_TYPE_UPDATE')
                                    "
                                    class="classHandle"
                                >
                                    <em
                                        @click="editClass(index)"
                                        v-show="
                                            RP_Visible(
                                                'OPEN_SKILL_TYPE_UPDATE'
                                            ) && !item.isEdit
                                        "
                                        class="iconfont guoran-a-18_huaban1fuben3"
                                    ></em>
                                    <em
                                        v-if="
                                            RP_Visible(
                                                'OPEN_SKILL_TYPE_UPDATE'
                                            )
                                        "
                                        @click="deleteClass(item,index)"
                                        :class="['guoran-tongyichicun-16-09-shanchu2 iconfont',index === 0 && classListHandle.length === 1 ? 'disabled' : '']"
                                    ></em>
                                    <em
                                        v-if="
                                            RP_Visible(
                                                'OPEN_SKILL_TYPE_UPDATE'
                                            ) && classListHandle.length > 1
                                        "
                                        class="iconfont guoran-a-16-10"
                                    ></em>
                                    <em
                                        v-if="
                                            RP_Visible(
                                                'OPEN_SKILL_TYPE_UPDATE'
                                            ) && classListHandle.length > 1
                                        "
                                        class="iconfont guoran-tongyichicun-16-16-paixuhover"
                                    ></em>
                                </div>
                            </li>
                        </ul>
                        <div class="add-class-btn" @click="addClass">
                            <i class="el-icon-plus"></i> 新建分类
                        </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="classHandleVisible = false"
                        plain
                        class="cancel-btn"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        @click="saveClassHandle"
                        class="confirm-btn"
                        >保存</el-button
                    >
                </div>
            </popup>
          
            <!-- 复制意图 -->
            <CopyIntent
                :visible.sync="copyIntentDialogVisible"
                @closeCopyIntent="closeCopyIntent"
                @switchSkillCopy="switchSkillCopy"
            />
           
            <!-- 可更新流程意图如下 -->
            <popup
                id="refresh-flow"
                @closeEvent="updateIntentDiaVisible = false"
                v-if="updateIntentDiaVisible"
                :haveTips="false"
                :isNeedScroll="false"
                :isNeedFooter="false"
                class="no-footer" >
                <div slot="popup-name">可更新流程意图如下</div>
                <div slot="popup-con">
                    <UpdateIntent
                        ref="updateIntent"
                        @getIntentSyncProcessTree="getIntentSyncProcessTree"
                        @switchSkillCopy="switchSkillCopy"
                        @closeUpdateIntentOpration="closeUpdateIntentOpration"
                    />
                </div>
            </popup>

            <!-- 意图排序 -->
            <IntentSort
                :visible.sync="intentSortDiaVisible"
                ref="intentsort"
                @getIntentList="getIntentList"
                @closeIntentSort="closeIntentSort"
            />
            <!-- 提示  批量操作中的开启 -->
            <popup
                @closeEvent="closeEvent"
                v-show="relationIntentListVisible"
                class="no-footer"
                :isNeedFooter="false"
                id="intent-tips-dialog"
                :haveTips="false"
            >
                <div slot="popup-name">
                    <i
                        class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"
                    ></i>
                    <div class="tips-dialog-title">提示</div>
                </div>

                <div slot="popup-con" class="popup-con">
                    <div>
                        <RelationIntentList
                            :relationIntentListVisible="relationIntentListVisible"
                            :operationType.sync="operationType"
                            :intentOptions="intentOptions"
                            :isParentIntents="isParentIntents"
                            :relationIntentListHandleType="
                                relationIntentListHandleType
                            "
                            @confirmCloseIntent="confirmCloseIntent"
                            @confirmDeleteSIntent="confirmDeleteSIntent"
                            @closeRealtionIntentList="closeRealtionIntentList"
                            @closeBatchOperation="closeBatchOperation"
                            @switchSkillCopy="switchSkillCopy"
                            @getIntentSyncProcessTree="getIntentSyncProcessTree"
                            :intentIds="checkProcessIntentIds"
                            :sHandleHasChildren="sHandleHasChildren"
                            :apiKey="settingList.apiKey"
                            ref="relationIntentList"
                        />
                    </div>
                </div>
            </popup>
            <!-- 批量操作 -->
            <div class="no-footer intent-batch-operation">
                <BatchOperation
                    ref="batchop"
                    :batchOperationDiaVisible.sync="batchOperationDiaVisible"
                    @setBetchIntentOptions="setBetchIntentOptions"
                    @betchDeleteIntent="betchDeleteIntent"
                    @betchCloseIntent="betchCloseIntent"
                    @betchOpenIntent="betchOpenIntent"
                    @switchSkillCopy="switchSkillCopy"
                    @batchOperationIntent="batchOperationIntent"
                    @closeBatchOperation="closeBatchOperation"
                    @showTipsDialogFn="showTipsDialogFn"
                />
            </div>
            <delete-dialog
                @closeEvent="showTipsDialog = false"
                v-if="showTipsDialog"
                >
                <div slot="popup-container" class="popup-container">
                    <div class="prompt-text">
                    {{ recommendContent }}
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="closeTipsDialog"  plain round
                    >取消</el-button
                    >
                    <el-button
                    @click="confirmation"
                    round
                    style="background-color: #fe5965; color: white"
                    >确认</el-button
                    >
                </div>
            </delete-dialog>
            <!-- 可能与已有的以下意图相似 -->
            <popup
                @closeEvent="closeSimilIntentDialogVisible"
                v-show="similIntentDialogVisible"
                :haveTips="false"
                id="same-intnet-go-look">
                <div slot="popup-name">提示</div>
                <div slot="popup-con">
                        <div class="intent-link-title">
                            可能与已有的以下意图相似
                        </div>
                        <div>
                            <div
                                v-for="intent in similIntentList"
                                :key="intent.id"
                                class="intent-link-cell"
                            >
                                <span class="intent-link-cell-name"
                                    >{{ intent.name }}{{ intent.intentID }}</span
                                >
                                <el-button
                                    @click="gotoNewIntentPage(intent)"
                                    type="text"
                                    >去看看</el-button
                                >
                            </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button
                        @click="closeSimilIntentDialogVisible"
                        plain
                        class="cancel-btn"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        @click="addIntentExiSimil"
                        class="confirm-btn"
                        >继续创建</el-button
                    >
                </div>
            </popup>
        </div>
        <!-- 通知中心 -->
        <el-drawer
            key="notice"
            title="通知中心"
            size="560px"
            show-close
            :visible.sync="noticeDrawerVisible"
            :close-on-press-escape="false"
            :with-header="false"
        >
            <div class="notice-drewer-box">
                <NoticeDrewer
                    ref="noticeDrewer"
                    @closeNoticeDrewer="closeNoticeDrewer"
                    @confirmAsyn="confirmAsyn"
                    @confirmCloseIntent="confirmCloseIntent"
                    @confirmDeleteSIntent="confirmDeleteSIntent"
                    @deleteIntent="deleteIntent"
                    @closeIntentNotice="closeIntentNotice"
                />
            </div>
        </el-drawer>
          <!-- 意图归档箱 -->
            <el-drawer
            class="drawer-intent-box"
            key="intent"
            title="意图归档箱"
            size="590px"
            show-close
            direction="rtl"
            :visible.sync="intentClosedBox"
            :close-on-press-escape="false"
            :with-header="false"
            >
                <div class="notice-drewer-header">
                    <div class="title">意图归档箱</div>
                    <div class="close">
                        <span @click="handleClose" class="el-icon-close"></span>
                    </div>
                </div>
                <div id="intent-closed-box" class="botContent drawer">
                    <ul class="intent-list intention-drawer" key="drawerIntentBox">
                        <li
                            class="intent notice-drewer-intent"
                            v-for="(item, index) in closedIntentList"
                            :key="item.id"
                            v-show="!item.isOpen"
                        >
                            <img v-if="!item.onLine && item.onLine !== null" src="../../assets/images/unqualified-and-offline.png" alt="" class="unqualified-and-offline">
                            <img v-if="!item.upToStandard && item.upToStandard !== null" src="../../assets/images/unqualified.png" alt="" class="unqualified">
                            <div class="intent-inner">
                                <div class="intent-info">
                                    <div class="top-tag"></div>
                                    <span class="intent-name">{{
                                        item.name
                                    }}</span>
                                 <div class="first-line">
                                            <span class="intent-info-cell">
                                                <i
                                                    class="iconfont guoran-tongyichicun-mingzhongcishu"
                                                ></i>
                                                <span class="intent-card-num-title">命中次数</span> 
                                                <span>{{ item.hits }}</span>
                                            </span>
                                            <span class="intent-info-cell">
                                                <i
                                                    class="iconfont guoran-tongyichicun-xiangsiwenfa"
                                                ></i>
                                                <span class="intent-card-num-title">相似问法</span>
                                                
                                                <span>{{ item.questionNum }}</span>
                                            </span>
                                        </div>
                                        
                                       <div class="second-line">
                                            <span v-if="liningShow" class="intent-info-cell">
                                                <img style="margin-right: 4px;" src="./../../assets/images/bot_intent_rg_intent.svg" width="16px" alt="" srcset="">
                                                <span class="intent-card-num-title">识别规则</span>
                                                
                                                <span>{{ item.ruleNum || 0 }}</span>
                                            </span>
                                             <span class="intent-info-cell super-intent">
                                                <i
                                                    class="iconfont guoran-yitu"
                                                ></i>
                                                <span class="intent-card-num-title">超级意图</span>
                                                <span class="super-intent-status">{{item.enableSuperIntent ? '开启' : ''}}</span>
                                            </span>
                                       </div>   
                                </div>

                                <div class="intent-operation">
                                    <div class="b-handle" style="height: 20px">
                                        <div @click.stop>
                                            <el-popconfirm
                                                width="200px"
                                                confirm-button-text="开启"
                                                cancel-button-text="取消"
                                                @onConfirm="
                                                    confirmCloseIntent(
                                                        true,
                                                        item.id,
                                                        item.isParent,
                                                        {
                                                            reasonText: '',
                                                            reasonValue: [],
                                                        }
                                                    )
                                                "
                                                icon="el-icon-info"
                                                icon-color="red"
                                                title="意图开启后，机器人将继续识别该意图并将该意图移出意图归档箱中，是否开启？"
                                            >
                                                <div
                                                    slot="reference"
                                                    class="icon-switch-box false"
                                                    v-if="
                                                        !item.isOpen &&
                                                        RP_Visible(
                                                            'OPEN_SKILL_INTENT_CLOSE'
                                                        ) &&
                                                        !item.isParent
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-close"
                                                    ></i>
                                                    <div></div>
                                                </div>
                                            </el-popconfirm>
                                        </div>

                                        <div
                                            slot="reference"
                                            class="icon-switch-box false"
                                            @click.stop="openIntentNew(index)"
                                            v-if="
                                                !item.isOpen &&
                                                RP_Visible(
                                                    'OPEN_SKILL_INTENT_CLOSE'
                                                ) &&
                                                item.isParent
                                            "
                                        >
                                            <i class="el-icon-close"></i>
                                            <div></div>
                                        </div>
                                        <div
                                            class="top-tag-operation guan btn-icon"
                                            v-if="item.isDefault"
                                        >
                                            官方
                                        </div>
                                        <!-- <div
                                            class="top-tag-operation share btn-icon"
                                            v-if="item.isShare == 1"
                                        >
                                            共享
                                        </div>
                                        <div
                                            class="top-tag-operation multiwheelIntent btn-icon"
                                            v-if="item.multiwheelIntent"
                                        >
                                            多轮
                                        </div> -->
                                        <!-- <el-tooltip
                                            class="item"
                                            effect="dark"
                                            content="未配置对话流程"
                                            placement="bottom"
                                        > -->
                                            <i
                                                v-if="
                                                    (!item.hasProcess &&
                                                        item.refAttrId ==
                                                            null) ||
                                                    (!item.hasProcess &&
                                                        item.refAttrId !=
                                                            null &&
                                                        !item.requireSyncProcess)
                                                "
                                                class="lack btn-icon"
                                                >缺流程</i
                                            >
                                        <!-- </el-tooltip> -->
                                        <div @click.stop>
                                            <el-popconfirm
                                                confirm-button-text="同步"
                                                cancel-button-text="取消"
                                                @onConfirm="confirmAsyn(item)"
                                                icon="el-icon-info"
                                                icon-color="red"
                                                title="可同步模板中对话流程。"
                                            >
                                                <i
                                                    v-if="
                                                        item.hasProcess &&
                                                        item.refAttrId !=
                                                            null &&
                                                        item.requireSyncProcess
                                                    "
                                                    slot="reference"
                                                    class="btn-icon el-icon-refresh-right refersh-flow"
                                                    >更新流程</i
                                                >
                                            </el-popconfirm>
                                        </div>
                                        <div @click.stop>
                                            <el-popconfirm
                                                confirm-button-text="同步"
                                                cancel-button-text="取消"
                                                @onConfirm="confirmAsyn(item)"
                                                icon="el-icon-info"
                                                icon-color="red"
                                                title="可同步模板中对话流程。"
                                            >
                                              
                                                <i
                                                    v-if="
                                                        !item.hasProcess &&
                                                        item.refAttrId !=
                                                            null &&
                                                        item.requireSyncProcess
                                                    "
                                                    slot="reference"
                                                    class="btn-icon el-icon-refresh-right btn-icon refersh-flow"
                                                    >同步流程</i
                                                >
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                     
                                    <div
                                        class="intent-handle-box"
                                        v-if="item.editable"
                                    >
                                        <em
                                            v-if="
                                                RP_Visible(
                                                    'OPEN_SKILL_INTENT_UPDATE'
                                                ) && !item.isDefault
                                            "
                                            class="iconfont guoran-a-18_huaban1fuben3"
                                            @click.stop="editeIntent(item)"
                                        ></em>
                                        <em
                                            v-if="
                                                RP_Visible(
                                                    'OPEN_SKILL_INTENT_DELETE'
                                                ) && !item.isDefault
                                            "
                                            class="iconfont guoran-tongyichicun-18-13-shanchu"
                                            @click.stop="
                                                deleteIntent(
                                                    item.id,
                                                    item.isParent
                                                )
                                            "
                                        ></em>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="add-class-btn" @click="addClass">新建分类</div> -->
                </div>
            </el-drawer>


        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip tipIndex="6"/>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="versionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>

        <!-- 版本功能不可用提示 -->
        <popup v-if="skillVersionTipDialog" @closeEvent="skillVersionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip tipIndex="0"/>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="skillVersionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="skillVersionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>
        <batchImportIntnet 
            :importFileVisible.sync="importFileVisible" 
            :apiKey="settingList.apiKey">
        </batchImportIntnet>
        <div class="suspension-operation-icon" v-if="RP_Visible('OPEN_SKILL') && currentTab === 'skill'">
            <div class="filing-box" @click="openIntentClosedBox"  v-if="!isOtherSkill">
                <span class="filing-box-icon-outer">
                    <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                </span>
                <span class="filing-box-text-outer">归档</span>
            </div>
            <div class="filing-box-test" @click="showTest">
                <span class="filing-box-icon-outer">
                    <i class="iconfont guoran-tongyichicun-ceshi"></i>
                </span>
                <span class="filing-box-text-outer">测试</span>
            </div>
            <div class="filing-box-statistics" @click="openStatistics">
                <span class="filing-box-icon-outer">
                    <i class="arsenal_icon arsenalzhandianshujutongji"></i>
                </span>
                <span class="filing-box-text-outer">统计</span> 
            </div>
            <div class="filing-box-heathly" @click="openHeathlyDrawer">
                <span class="filing-box-icon-outer">
                    <svg t="1665319278665" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29088" width="16" height="16">
                        <path 
                            d="M512 0C229.376 0 0 229.376 0 512c0 70.656 14.336 137.728 39.936 199.168l264.704-339.456 185.856 190.464 130.56-164.864L541.184 317.44l318.464-85.504-85.504 318.464-80.384-80.384-199.68 242.176-180.736-185.344-219.136 280.576C187.392 938.496 339.456 1024 512 1024c282.624 0 512-229.376 512-512S794.624 0 512 0z" 
                            p-id="29089"
                            fill="#ffffff">
                        </path>
                    </svg>
                </span>
                <span class="filing-box-text-outer heathly-text">提高健康度</span> 
            </div>
       </div>
       <intentStatistics 
            :drawerStatistics.sync="drawerStatistics">
        </intentStatistics>
        <!-- <botHeathlyDrawer 
            :botHeathlyDrawerVisible.sync="botHeathlyDrawerVisible"
            :apiKey="settingList.apiKey"
            :botId="$route.query.id"
            @addIntent="addIntent"></botHeathlyDrawer> -->
    </div>
</template>
<script>
import DeleteDialog from "../../components/errorDialog.vue";
import popup from "../../components/popupNew.vue";
import BotNameAndTolick from "./../../components/BotNameAndToLink";
import BotChatFlow from "../ChatFlow/ChatFlow";
// import BotLink from "./BotLink";
import BotSettingNew from "./BotSettingNew.vue";
import Driver from "driver.js"; // import driver.js
import { apiKeys } from "../../const/SpecialApiKey.js";
import "driver.js/dist/driver.min.css"; // import driver.js css
import steps from "./guide/BotIntent";
import { skillIds } from "../../const/SpecialApiKey";
import SkillManage from "./component/skillManage.vue";
import { rowDrop } from "./../../utils/el_drop";
import CopyIntent from "./component/CopyIntent";
import BatchOperation from "./component/BatchOperation";
import UpdateIntent from "./component/UpdateIntent";
import IntentSort from "./component/IntentSort.vue";
import RelationIntentList from "./component/RelationIntentList.vue";
import NoticeDrewer from "./component/NoticeDrewer.vue";
import contentHeader from "../../components/content-top-header.vue";
import Sortable from "sortablejs";
import VersionTip from "./../version/VersionTip.vue";
import elementResizeDetectorMaker from "element-resize-detector";
import batchImportIntnet from './component/batchImportIntnet.vue'
import botKeywordRecommend from '../intentQa/components/botKeywordRecommend.vue'
import intentStatistics from './component/intentStatistics.vue'
import unqualifieOfflineIntent from './component/unqualifieOfflineIntent.vue';
// import botHeathlyDrawer from './component/botHeathlyDrawer.vue'
import Qs from 'qs';
export default {
    data() {
        return {
            liningShow: true,
            showAcKnowManageBtn: false,
            defaultOpenedsList: [],
            needAddNum: 0,
            needAddNumArr: [],
            listLen: 0,
             activePage: "skill",
            versionTipDialog: false,
            skillVersionTipDialog: false,
            limitUse: true,
            isParentIntents: [],
            isShowEntrance: false, // 是否显示通知入口
            hasNew: false,
            noticeDrawerVisible: false,
            sHandleHasChildren: false, // 单个操作意图，本意图是否有子意图
            similIntentDialogVisible: false,
            similIntentList: [],
            intentOptions: [],
            relationIntentListVisible: false, // 删除/关闭意图是否有关联意图展示
            relationIntentListHandleType: "deleteS", // 单个删除 deleteS 批量删除 deleteB 单个关闭 closeS 批量关闭 closeB 单个开启 openS  批量开启 openB
            checkProcessIntentIds: [],
            intentSortDiaVisible: false, // 意图排序弹窗
            updateIntentNum: 0, // 待更新意图数量
            updateIntentBtn: false, // 是否展示更新意图按钮
            updateIntentDiaVisible: false, // 更新流程
            batchOperationDiaVisible: false, // 批量操作
            copyIntentDialogVisible: false,
            // 知识库管理
            skillManageVisible: false,
            isShare: 0,
            //
            currentTab: "skill",
            botSettingTab: 1,
            activePageInfo: {
                // 当前页跳转信息
                name: "返回全部机器人",
                gotoPageName: "botIndex",
                botId: 1,
                headerName: "营销线索机器人",
            },
            activeSkill: "",
            skillList: [],
            searchIntentName: "",
            intentName: "",
            intentName1: "",
            exampleQuestion: "",
            exampleQuestion1: "",
            isDefault: false,
            activeClass: "1",
            classList: [],
            intentList: [],
            closedIntentList: [],
            stretchValue: false,
            disabled_input: true,
            addIntentDiaVisible: false, // 点击添加意图，弹窗状态
            handleTypeC: "add", // 意图操作弹窗类型
            editIntentInfo: "", // 编辑意图信息
            intentTableData: [],
            loading: true,
            // 分页
            currentPage: 1,
            pageSize: 15,
            total: 50,
            // 操作分类
            classHandleVisible: false,
            classListHandle: [],
            driver: null,
            refApiKey: "8f68f1c4f5c949b387e2ed32aa3fa6f4",
            guidTaskId: null,
            serachIntentBox: true,
            newIntentInfo: [],
            allIntentList: [{ id: "", typeId: "" }],
            fromIntentId: "",
            allSkillList: [],
            superIntentList: [{ id: "", name: "" }],
            currentSuperIntentList: {
                corpusTemplate: { examples: [""], example: "" },
                name: "diyige",
            },
            selectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            startSelectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            isActiveSuperIntent: false,
            isSaveEnable: false,
            settingList: { id: "", apiKey: "" },
            superIntentInputWords: [],
            emptySuperIntent: false,
            intentClosedBox: false,
            isIntentCloseBoxEmpty: true,
            classification: "",
            classificationOption: [],
            addIntentLoading: false,
            inentTypeId: "",
            intentRadioList: [
                { value: "问答意图", label: "QA" },
                { value: "训练意图", label: "TRAINING" },
            ],
            intentRadio: "QA",
            isShowEntranceCopy: false,
            direction: "rtl",
            searchAllSkillIntentList:[], // 搜索列表
            showSearchAllSkillIntent:false, // 搜索列表显隐
            showSearchAllSkillIntentNoData:false, // 无数据显隐
            activeIntent:null, // 搜索列表选中的意图id
            activeItem:{},
            isSearch:false,
            recommendContent:'',
            showTipsDialog:false,
            operationType:'card',
            isShowTest: false,
            importFileVisible:false,
            delTypeNames:[],
            isIntentFocus:false,
            intentClassKey:1,
            clearStatus:true,
            keywordRecommendHasNew:false,
            drawerStatistics:false,
            dateValue:'',
            suspensionOperationIconTop:null,
            testDrawerVisibleP:false,
            otherSkillId: "", // 其他 知识库id
            maxLengthLimit:30,
            defaultActive:"skill",
            botHeathlyDrawerVisible:false, // 健康度抽屉显隐
            noUpToStandardIntentTotal:0,
            isShowUnqualifieOfflineIntent:false,
            popupView:false,
            defaultActive:"skill",
            CompanyIsOld:false,
            nowOptimize:0,
            isShowHeathly:false,
            isReaload:false,
            oldClassListHandle:[], // 分类管理--老数据
            isFlag:false,
            timer:null,
            issearchAllSkillFocus:false
        };
    },
    created () {
        
    },
    components: {
        BotNameAndTolick,
        // BotLink,
        BotSettingNew,
        SkillManage,
        CopyIntent,
        BatchOperation,
        UpdateIntent,
        IntentSort,
        RelationIntentList,
        NoticeDrewer,
        contentHeader,
        popup,
        VersionTip, 
        DeleteDialog,
        batchImportIntnet,
        botKeywordRecommend,
        intentStatistics,
        unqualifieOfflineIntent,
        // botHeathlyDrawer

    },
    methods: {
        otherToChatFlow(item) {
            sessionStorage.setItem("_activeSkill", this.activeSkill);
            sessionStorage.setItem("_activeClass", this.activeClass);
            this.$router.push({
                name: "chatFlow",
                query: {
                    id: this.$route.query.id,
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: item.id,
                    intentName: item.name,
                    isDefault: item.isDefault,
                    isOtherSkill: true,
                    refApiKey: this.$router.currentRoute.query.refApiKey,
                }
            });
        },
        // 关闭意图后 , 未达标意图和健康度的意图详情需要更新
        updateHeathlyIntent(){
            console.log('需要更新健康度相关数据');
            this.isReaload = true;
            this.$eventBus.$emit("bot-heathly-visible", {
                flag:this.isShowHeathly,
                type:"updateIntentList"
            });
        },
        // mounted生命周期中代码提取
       mountedFn(){
           this.nowOptimize = Number(this.$route.query.nowOptimize) || 0;
            if(this.nowOptimize === 1){
                console.log('nowOptimize');
                this.$eventBus.$emit("test-window-visible-intent", false);
                this.$eventBus.$emit("bot-heathly-visible", {
                    flag:true,
                    apiKey:'',
                    botId:this.$route.query.id,
                    type:'nowOptimize',
                    route:"skill"
                });
                
            }
            this.getLimitMsg();
            this.activePageInfo.headerName = this.$route.query.name;
            this.activePageInfo.botId = this.$route.query.id;
            let tabIndex = this.$route.query.tabIndex;
            let botSettingTab = this.$route.query.botSettingTab;
            if (tabIndex == null) {
                tabIndex = "skill";
            }
            this.currentTab = tabIndex;
            if (botSettingTab == null) {
                botSettingTab = 1;
            }
            this.botSettingTab = botSettingTab;
            if(botSettingTab == 1){
                this.currentTab = 'skill'
                this.defaultActive = this.currentTab;
            } else {
                if(botSettingTab === 'KEYWORD_REC'){
                    // 机器人设置-高级设置-关键词推荐
                    this.currentTab = 'bot-setting-keyWordRecommendIntents'
                } else if(botSettingTab === 'ANSWER_FEEDBACK'){
                    // 机器人设置-高级设置-答案反馈"
                this.currentTab = 'bot-setting-feedback'
            } else if(botSettingTab === 'FAST_SELECT'){
                    // 机器人设置-高级设置-快捷选择"
                    this.currentTab = 'bot-setting-fastSelect'
                }else if(botSettingTab === 'bot-setting-noDescernIntentON'){
                    // 机器人设置-高级设置-不识别推荐"
                    this.currentTab = 'bot-setting-noDescernIntentON'
                }
                
            }
            this.defaultActive = this.currentTab;
            let mainId = localStorage.getItem("_mainId");
            if (mainId === "43ca904b7c6c40e98cc053e42d2931ab") {
                this.liningShow = false;
                if (JSON.parse(localStorage.getItem("userInfo")).username === "lining@guoranbot.com") {
                    this.liningShow = true;
                }
            }

            this.getBotInfo();
            //引导层
            this.driver = new Driver({
                className: "scoped-class", // className to wrap driver.js popover
                animate: true, // Animate while changing highlighted element
                opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
                padding: 10, // Distance of element from around the edges
                allowClose: true, // Whether clicking on overlay should close or not
                overlayClickNext: true, // Should it move to next step on overlay click
                doneBtnText: "完成", // Text on the final button
                closeBtnText: "关闭", // Text on the close button for this step
                nextBtnText: "下一步", // Next button text for this step
                prevBtnText: "上一步", // Previous button text for this step
                // Called when moving to next step on any step
            });
        // 获取机器人技能列表
            this.activeSkill = "";    
            this.activeClass =  "";
            this.$eventBus.$on("bot-heathly-visible", (visible) => {
                this.isShowHeathly = visible.flag;
                if(this.isShowHeathly){
                    this.isShowTest = false;
                }
                if(visible.type && visible.type === 'addIntent'){
                    this.addIntent();
                } else if (visible.type === 'goLocationIntent'){
                    console.log('------', this.isFlag);
                    if(!this.isFlag){
                        this.isFlag = true;
                        console.log('goLocationIntent------', this.isFlag);
                        if (Number(sessionStorage.getItem("_activeSkill")) > 0) {
                            this.echoList();
                        } else {
                            this.getSkillList(this.$route.query.id, true,'switch'); //  修改点击返回机器人 , 分类不加载的bug
                        }
                        setTimeout(() => {
                            this.isFlag = false;
                        }, 500);
                    }
                }
            });
            console.log('------', this.isFlag);
            if(!this.isFlag){
                console.log(2041, Number(sessionStorage.getItem("_activeSkill")));
                if (Number(sessionStorage.getItem("_activeSkill")) > 0) {
                    this.echoList();
                } else {
                    this.getSkillList(this.$route.query.id, true,'switch'); //  修改点击返回机器人 , 分类不加载的bug
                }
            }
            console.log('知识库的-----mounted');
            this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
            this.showEntranceCopy();
            this.getIntentSyncProcessTree();
            this.getNoticeEntranceStatus();
            // 获取意图列表
            this.intentTableData = [
                {
                    date: "2016-05-02",
                    name: "询问穿什么尺码",
                    type: "问答意图",
                    number: 1202,
                    id: 10,
                },
                {
                    date: "2016-05-04",
                    name: "询问商品价格",
                    type: "闲聊意图",
                    number: 102,
                    id: 15,
                },
            ];
            setTimeout(() => {
                this.disabled_input = false;
                // this.autoClickClassType();
            }, 300);
            let refApiKey = this.$router.currentRoute.query.refApiKey;
            let that = this;
            this.$nextTick(() => {
                // 确保dom异步加载完毕
                if (
                    apiKeys[process.env.VUE_APP_CONFIG_ENV].SAMPLE_BOT_API_KEY ===
                    refApiKey
                ) {
                    that.guidTaskId = setTimeout(() => {
                        this.guide();
                    }, 4000);
                }
                setTimeout(() => {
                    this.watchSize();
                }, 1000)
            });
            this.$eventBus.$on("test-window-visible", (visible) => {
                this.isShowTest = visible;
                if(this.isShowTest){
                    this.isShowHeathly = false;
                }
                setTimeout(() => {
                    this.watchSize();
                }, 300)
            });
            this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
            
            this.keywordRecommendHasNew = sessionStorage.getItem('keywordRecommendHasNew') == 'true' ? true : false;
            // this.suspensionOperationIconTop = document.querySelector('.addIntent').offsetHeight + 6;
           
       },
        showUnqualifieOfflineIntent(){
            // if(this.popupView){
            //     this.isShowUnqualifieOfflineIntent = true;
            // } else {
                
            // }
            this.isShowUnqualifieOfflineIntent = true;
        },
        popupViewFn(value){
            this.popupView = value;
        },
        //定位到具体意图
        goLocation(data){
            this.switchSkillClass(data,'goLocationHeathly')
        },
        noUpToStandardIntentTotalFn(num){
            this.noUpToStandardIntentTotal = num;
            this.$forceUpdate()
        },
        // 打开健康度抽屉
        openHeathlyDrawer(){
            // this.botHeathlyDrawerVisible = true;
            this.$eventBus.$emit("test-window-visible-intent", false);
            this.$eventBus.$emit("bot-heathly-visible", {
                flag:true,
                apiKey:this.settingList.apiKey,
                botId:this.$route.query.id,
                route:'skill'
            });

        },
        // 取消小红点
        changeKeywordRecommendHasNew(){
            this.keywordRecommendHasNew = false;
        },
        showTest(){
            console.log('showTest');
            this.$eventBus.$emit("test-window-visible-intent", true);
            this.$eventBus.$emit("bot-heathly-visible", {
                flag:false,
                apiKey:'',
                botId:''
            });
        },
        openStatistics(){
            this.drawerStatistics = true;
        },  
          // 批量导入
       batchImportIntent(){
           this.importFileVisible = true;
       },
        showBotKeywordRecommendFn(){
            this.$nextTick(() => {
                let ele =  document.querySelector('#superIntentConfig-intent-add .popup-container-new');
                ele.scrollTop = ele.scrollTop + 100;
            })  
        },
         // 超级意图 显隐推荐词下拉框
        changeInputSuper(datas,item,index){
            this.isIntentFocus = true;
            this.selectData.templateConfig.pattern.forEach((v,i) => {
                v.showBotKeywordRecommend = false;
            })
            if(item.value !== ''){
                item.showBotKeywordRecommend = true;
            } 
            this.$forceUpdate();
        },
        closeBotKeywordRecommend(item){
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        //超级意图 关键词的近义词 , 单个添加
        addToInputSuper(datas,item,index){
            item.value = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        closeSimilIntentDialogVisible(){
            this.similIntentDialogVisible = false;
            this.addIntentDiaVisible = true;
        },
        confirmation(){
            this.$refs.batchop.confirmation();
        },
        // 批量操作  二次弹框打开
        showTipsDialogFn(flag,recommendContent){
            this.batchOperationDiaVisible = false;
            this.recommendContent = recommendContent;
            this.showTipsDialog = flag;
            
        },
        //批量操作   二次弹框关闭
        closeTipsDialog(){
            this.showTipsDialog = false;
            this.batchOperationDiaVisible = true;
        },
        openSubNav(){
            this.defaultOpenedsList = ["bot-setting"];
        },
        closeSubNav(){
            this.defaultOpenedsList = [];
        },
        watchSize() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            if (this.$refs.intentListContent != undefined) {
                erd.listenTo(this.$refs.intentListContent, (element) => {
                    let height = element ? element.offsetHeight : 0;
                    _this.$nextTick(() => {
                        // 这里填写监听改变后的操作
                        // console.log(height, "+_+_+_+_+_+");
                        // this.reduceHeight = height;
                        if (_this.listLen !== 0) {
                            _this.addEmptyEl(_this.listLen);
                        }
                    })
                });
            }
            
        },
        addEmptyEl(listLen){
            let isOther = false; // 区分是否是其他分类（其他分类下不展示添加意图按钮）
            // console.log(this.$refs.intentListContent.offsetWidth, this.$refs.intentListContent.offsetHeight, listLen);
            if (this.$refs.intentListContent != undefined && this.$refs.upAddIntent) {
                let outerW = this.$refs.intentListContent.offsetWidth; // 容器宽度

                let cellW = 0;
                if (isOther) {
                    cellW = this.$refs.upIntent[0].offsetWidth; // 每个cell宽度
                } else {
                    cellW = this.$refs.upAddIntent.offsetWidth; // 每个cell宽度
                }
                // console.log(outerW, cellW);
                // console.log(Math.floor(outerW/cellW)) 
                let evNum = Math.floor(outerW/cellW); // 每行个数
                let needAddNum = 0;
                if (isOther) {
                    needAddNum = (evNum - ((listLen)%evNum)) === evNum?0:(evNum - ((listLen)%evNum)); // 最后一行需要补空意图数
                } else {
                    needAddNum = (evNum - ((listLen  + 1)%evNum)) === evNum?0:(evNum - ((listLen  + 1)%evNum)); // 最后一行需要补空意图数
                }
                // console.log(needAddNum)
                this.needAddNum = needAddNum;
                this.needAddNumArr = "11111111111".substring(0,needAddNum).split("");
            }
            
            // console.log(this.needAddNumArr)
        },
        // 点击页面时,隐藏搜索的下拉框
       clickPage(){
            if(this.isSearch){
                this.showSearchAllSkillIntent = !this.showSearchAllSkillIntent;
                this.isSearch = false;
                this.$refs.searchIntent.blur();
            } 
            if(this.showSearchAllSkillIntentNoData){
                this.showSearchAllSkillIntentNoData = false;
                this.showSearchAllSkillIntent = false;
            }
        },
        // 点击/聚焦/
        searchAllSkillFocus(e){
            document.querySelector('.search-intent').classList.add('max-search-input');
           if(this.searchIntentName !== '' &&  !this.showSearchAllSkillIntent){
                this.showSearchAllSkillIntent = true;
                this.showSearchAllSkillIntentNoData = false;
                this.isSearch = true;
           }
            this.issearchAllSkillFocus = true;
            // if(!this.isShowTest && !this.isShowHeathly){
            //     this.isShowTest = true; 
            // }
            // if(!this.isShowTest && this.isShowHeathly){
            //     this.isShowTest = true; 
            // }

        //    this.isShowTest = true; 
        },
          // 获取搜索意图列表
        searchAllSkillIntent(){
            if(this.searchIntentName === '') {
                this.searchAllSkillIntentList = [];
                this.showSearchAllSkillIntentNoData = true;
            } else {
                this.FetchGet(
                    this.requestUrl.intent.searchAllSkillIntent + '?apiKey=' +  this.settingList.apiKey + '&keyWord='+this.searchIntentName +'&needOther=true')
                    .then((res) => {
                        if (res.code === "0") {
                        this.searchAllSkillIntentList = [...[],...res.data || []];
                        if(this.searchAllSkillIntentList.length > 0){
                            this.showSearchAllSkillIntent = true;
                            this.showSearchAllSkillIntentNoData = false;
                        } else {
                            this.showSearchAllSkillIntentNoData = true;
                        }
                        this.isSearch = true;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            }
            
        },
        //  点击搜索意图列表 , 切换到对应的知识库和分类
        switchSkillClass(item,type){
            if(type && type === 'goLocationHeathly'){
                this.activeIntent = item.intentId;
                this.activeClass = item.intentTypeId;
            } else {
                this.activeIntent = item.id;
                this.activeClass = item.typeId;
                this.activeItem = item;
                this.searchIntentName = item.name;
            }
           
            
            this.switchSkill(item.skillId,'search');
            this.showSearchAllSkillIntent = false;
            this.showSearchAllSkillIntentNoData = false;
            this.isSearch = false;
        },
        changeEnable(datas){
            if(datas === 'open'){
                this.currentSuperIntentList.enable = false;
            } else if (datas === 'close'){
                this.currentSuperIntentList.enable = true;
            }
            this.intentClassKey++
            this.getSuperIntentList();
        },
        changeswitch(status) {
            // this.currentSuperIntentList.enable = status;
            this.$set(this.currentSuperIntentList,'enable',status)
            this.$forceUpdate();
        },
        goBack() {
            sessionStorage.removeItem("saveActiveKnow");

            this.$router.push({ name:'botIndex'});
        },
        handleClose() {
            this.intentClosedBox = false;
        },
        closeEvent() {
            this.relationIntentListVisible = false;
        },
        changeSkillVersionTipDialog(){
            this.skillVersionTipDialog = true;
        },
        // 选择导航栏
        menuSelect(index,indexPath){
            this.currentTab = index
             if (index != "skill") {
                clearTimeout(this.guidTaskId);
            }
            if (index == "second") {
                // 触发‘ChatFlow’事件
                this.toChatFlow();
            }
        },
        getLimitMsg(){
            this.FetchGet(
                this.requestUrl.bot.selectLimitMsg + '?uid=' + localStorage.getItem("_uid")
                ).then((res) => {
                    if (res.code === "0") {
                        this.limitUse = !res.data.canCreateIntent;
                        localStorage.setItem("_limitMsg", JSON.stringify(res.data));
                    }
                });
        },
        // 是否展示意图修改通知入口
        getNoticeEntranceStatus() {
            this.FetchGet(
                this.requestUrl.intentNotice.isShowNoticeEntrance +
                    this.activePageInfo.botId
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.hasNew = res.data.hasNew;
                        this.isShowEntrance = res.data.isShow;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        closeNoticeDrewer() {
            this.noticeDrawerVisible = false;
        },
        openNoticeDrewer() {
            this.noticeDrawerVisible = true;
            this.FetchPut(
                this.requestUrl.intentNotice.noticeRead,
                this.$route.query.id
            ).then((res) => {
                if (res.code === "0") {
                    this.hasNew = false;
                }
            });
        },
        setBetchIntentOptions(intentOptions) {
            this.intentOptions = intentOptions;
        },
        // 关闭校验意图关联接口
        closeRealtionIntentList(flag) {
            this.relationIntentListVisible = false;
            this.batchOperationDiaVisible = flag;
        },
        // 意图排序
        intentSort() {
            this.intentSortDiaVisible = true;
            this.$nextTick(() => {
                this.$refs.intentsort.getIntentList(this.activeClass);
            });
        },
        // close
        closeIntentSort(data) {
            if (data.type == "save") {
                this.getIntentList();
                this.getClosedIntentList();
            }
            this.intentSortDiaVisible = false;
        },
        // 批量更新意图
        updateIntentOpration() {
            this.updateIntentDiaVisible = true;
        },
        getIntentSyncProcessTree() {
            let bId = this.$route.query.id;
            this.FetchGet(
                this.requestUrl.intent.syncProcessTree + bId,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    this.updateIntentBtn = res.data.isShow;
                    if (this.updateIntentBtn) {
                        this.updateIntentNum = res.data.intentCount;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeUpdateIntentOpration() {
            this.updateIntentDiaVisible = false;
        },
        //
        showEntranceCopy() {
            this.FetchGet(
                this.requestUrl.intent.showEntranceCopy + this.$route.query.id,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    this.isShowEntranceCopy = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 批量操作意图
        batchOperationIntent() {
            this.batchOperationDiaVisible = true;
            this.$nextTick(() => {
                this.$refs.batchop.getIntentTree(this.$route.query.id);
            });
        },
        closeBatchOperation() {
            this.batchOperationDiaVisible = false;
            this.showTipsDialog = false;
            this.updateHeathlyIntent();
        },
        // 点击从现有意图复制
        copyIntentDia() {
            // 获取共享意图
            this.FetchGet(this.requestUrl.bot.botSkillIntentTree, {
                isShare: 1,
            }).then((res) => {
                if (res.code === "0") {
                    let data = [];
                    res.data.forEach(item => {
                        if (item.id != this.$route.query.id) {
                            data.push(item);
                        }
                    })
                    if(data.length === 0 ){
                        this.$message.error('暂无共享意图');
                    } else {
                        this.addIntentDiaVisible = false;
                        this.$nextTick(() => {
                            this.copyIntentDialogVisible = true;
                        });
                    }
                 } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeCopyIntent() {
            this.copyIntentDialogVisible = false;
        },
        confirmAsyn(item) {
            this.FetchPost(
                this.requestUrl.intent.syncIntent + item.id,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("同步成功！");
                    this.getIntentList();
                    this.getIntentSyncProcessTree();
                    this.getClosedIntentList();
                    this.noticeDrawerVisible &&
                        this.$refs.noticeDrewer.getIntentNoticeList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        /**
         * 展示知识库管理弹窗,并初始化其中内容
         * @method showSkillManage
         * @param {}
         * @return {}
         */
        showSkillManage() {
            this.skillManageVisible = true;
            this.$nextTick(() => {
                this.$refs.skillManage.getSkillList();
            });
        },

        /**
         * 保存知识库管理内容
         * @method saveSkillManage
         * @param {}
         * @return {}
         */
        saveSkillManage() {
            this.$refs.skillManage.saveSkillManage();
        },
        goTo() {
            this.$router.push({
                name: this.activePageInfo.gotoPageName,
            });
        },
        //tabs中点击意图流程设计跳转
        handleClick(name) {
            console.log(name);
            if(name.indexOf('bot-setting') !== -1){
                this.$eventBus.$emit("test-window-visible-intent", false);
            } else {
                if(name === 'skill'){
                    this.$eventBus.$emit("bot-heathly-visible", {
                        flag:true,
                        apiKey:'',
                        botId:this.$route.query.id,
                        route:"skill"
                    });
                }
            }
            this.currentTab = name;
            sessionStorage.removeItem("saveActiveKnow");
            if (name != "skill") {
                clearTimeout(this.guidTaskId);
            }
            if (name == "second") {
                // sessionStorage.setItem("_activeSkill", this.activeSkill);
                // sessionStorage.setItem("_activeSkillList", JSON.stringify(this.skillList));
                // sessionStorage.setItem("_activeClass", this.activeClass);
                // sessionStorage.setItem("_activeClassList", JSON.stringify(this.classList));
                this.sessionActive();
                // 触发‘ChatFlow’事件
                this.toChatFlow();
            }
        },
        //添加意图说明
        highlightIntentName() {
            const driver = new Driver();
            driver.highlight({
                element: ".add-intent-inner",
                popover: {
                    position: "right",
                    title: "什么是意图",
                    description:
                        "我们在提问的时候，同一个意思会有不同的提问方式，那么这同一个意思我们就归纳总结为意图<br><br><b>意图 ：</b>询问天气怎么样？<br><b>问法1 ：</b>今天天气怎么样<br><b>问法2 ：</b>今天天气冷吗<br><b>问法3 ：</b>今天天气情况告诉我一下<br><b>问法......<br><br>什么是意图你明白了吗？：）</b>",
                },
            });
        },
        //添加意图说明
        highlightKill() {
            const driver = new Driver();
            this.$nextTick(() => {
                 driver.highlight({
                    element: ".add-intent-inner",
                    popover: {
                        title: "如何构建知识库",
                        description:
                            "<div class='descripVideo'><video src='https://guoranbot-static.oss-cn-beijing.aliyuncs.com/video/knowledgeBase.mp4'  controls='controls'  style='width:400px;margin-bottom:20px;' ></video><b>您也可以下载模板进行梳理，后面再配置到平台：</b><a href='https://guoranbot-static.oss-cn-beijing.aliyuncs.com/file/skillmanager.xlsx' style='color:#366AFF'>下载</a></div>",
                        /*description:
                "对话机器人的知识库构建需要提前进行规划，规划清晰的知识库结构既方便运营人员管理，同时也能便于机器人更好的识别用户的问题，<br><b>1、如何梳理知识库结构</b><br>AskBot Platform的知识库体系采用树状三级结构，最末端就是用户提问的一个场景，即意图，一类的问题，可以通过分类去管理，一大类的问题可以放在同一个知识库下，具体以企业内部服务机器人知识库为例说明：<br>企业内部服务机器人从用户提问角度，可能会涉及到不同部门的问题，比如IT，财务，HR，营销等，那么这几类可以放到不同知识库，IT下面可能还有很多问题，可通过业务场景，设备类型等等再进行分类，放入不同的分类管理，比如：OA系统问题，CRM系统问题等<br><br><b>2、如何定义一个意图</b><br>意图的定义需要把握粒度，如果粗粒度则不太能精细识别用户问题，如果太过细的粒度则不便于模型识别，所以要适中。具体以企业内部IT问题为例进行说明：<br>比如企业内部打印机故障这个场景，会有很多种故障的类型，可以将：打印机卡纸；打印机打印不清晰；打印机没墨了；打印机灯异常这些类型定义为意图，用户如果提问了这些场景可以精细的解答，同时也要做几个入口的意图，比如：打印机故障，打印机无响应这些需要进一步询问才能解决的意图，这样粗细结合，才能更好的解决用户的问题，体验也会更好。<br><br><b>您也可以下载模板进行梳理，后面再配置到平台：</b><a href='https://guoranbot-static.oss-cn-beijing.aliyuncs.com/file/skillmanager.xlsx'>下载</a>",
            */ position: "right",
                    },
                });
            })
        },
        //添加官方意图说明
        highlightOfficialIntent() {
           this.$nextTick(() => {
                const driver = new Driver();
                driver.highlight({
                    element: "#officialIntent",
                    popover: {
                        title: "官方意图",
                        description:
                            "官方意图是指官方已经提供了基础识别能力的意图，同时您需要要补充更多的问法，不断提升识别能力",
                    },
                });
            })
        },
        openIntentNew(index) {
            this.sHandleHasChildren = true;
            let tampList = [];
            if (this.intentClosedBox) {
                tampList = this.closedIntentList;
            } else {
                tampList = this.intentList;
            }
            this.checkProcessIntentIds = [tampList[index].id];
            this.relationIntentListHandleType = "openS";
            this.relationIntentListVisible = true;
            this.operationType = 'card'
        },
        // 关闭开启意图
        closeIntent(e, index, isParent) {
            this.sHandleHasChildren = isParent;
            let tampList = [];
            if (this.intentClosedBox) {
                tampList = this.closedIntentList;
            } else {
                tampList = this.intentList;
            }
            this.checkProcessIntentIds = [tampList[index].id];
            this.relationIntentListHandleType = "closeS";
            this.relationIntentListVisible = true;
            this.operationType = 'card'
        },
        // 关闭开启意图
        closeIntentNotice(e, id, isParent) {
            this.sHandleHasChildren = isParent;
            let tampList = [];
            if (this.intentClosedBox) {
                tampList = this.closedIntentList;
            } else {
                tampList = this.intentList;
            }
            this.checkProcessIntentIds = [id];
            if (e) {
                this.relationIntentListHandleType = "openS";
            } else {
                this.relationIntentListHandleType = "closeS";
            }
            this.operationType = 'card'

            this.relationIntentListVisible = true;
            this.$nextTick(() => {
                document.querySelector(
                    ".popup-floating-layer-new"
                ).style.zIndex = 2111;
            });
        },
        // 批量关闭意图
        betchCloseIntent(ids, isParentIntents) {
            this.checkProcessIntentIds = ids;
            this.relationIntentListHandleType = "closeB";
            this.isParentIntents = isParentIntents;
            this.relationIntentListVisible = true;
            this.batchOperationDiaVisible = false;
        },
        // 批量开启意图
        betchOpenIntent(ids, isParentIntents) {
            this.checkProcessIntentIds = ids;
            this.relationIntentListHandleType = "openB";
            this.isParentIntents = isParentIntents;
            this.relationIntentListVisible = true;
            this.batchOperationDiaVisible = false;
        },
        // 批量操作   删除
        betchDeleteIntent(ids, isParentIntents) {
            this.checkProcessIntentIds = ids;
            this.relationIntentListHandleType = "deleteB";
            this.isParentIntents = isParentIntents;
            this.relationIntentListVisible = true;
            this.batchOperationDiaVisible = false;
        },
        // 关闭意图
        confirmCloseIntent(e, id, isParent, reason) {
            this.FetchPut(this.requestUrl.intent.updateIntent, id, {
                isOpen: e,
                apiKey:this.settingList.apiKey,
                operateIntentInfo: {
                    description: reason.reasonText,
                    intentId: id,
                    reasonInfos: reason.reasonValue,
                },
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("意图操作成功！");
                    this.getSkillList(this.$route.query.id, false); // 第二个参数 是否更新默认分类
                    this.showEntranceCopy();
                    this.getIntentSyncProcessTree();
                    this.noticeDrawerVisible &&
                        this.$refs.noticeDrewer.getIntentNoticeList();
                        this.updateHeathlyIntent('close');
                } else {
                    this.$message.error(res.message);
                }
                this.relationIntentListVisible = false;
            });
        },
        openIntentClosedBox() {
            if (this.isIntentCloseBoxEmpty) {
                this.$message({
                    message: "意图归档箱为空！",
                    type: "warning",
                });
            } else {
                this.intentClosedBox = true;
            }
        },
        isOpenIntentList() {
            if (this.closedIntentList.length > 0) {
                this.isIntentCloseBoxEmpty = false;
            } else {
                this.isIntentCloseBoxEmpty = true;
            }
        },
        //获取机器人设置状态
        getBotInfo() {
            this.settingList.id = this.$route.query.id;
            this.FetchGet(
                this.requestUrl.bot.getBotInfo + "/" + this.settingList.id
            ).then((res) => {
                if (res.code === "0") {
                    let data = res.data;
                    this.settingList = data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取超级意图列表
        superIntentSwitch() {
            this.getSuperIntentList();
        },
        // 超级意图列表获取焦点后清空所选内容
        superIntentFocus() {
            this.selectData = this.startSelectData;
            this.getSuperIntentList();
        },
        selectVisibleChange(callback) {
            
            if (callback) {
                this.clearStatus = true;
                this.selectData = this.startSelectData;
            } else {
                this.clearStatus = false;
                this.selectTrigger();
            }
        },
        // 选中超级意图执行方法
        selectTrigger(id) {
            let that = this;
            this.clearStatus = false;
            for (var i = 0; i < that.superIntentList.length; i++) {
                if (that.superIntentList[i].name == that.selectData.name) {
                    that.selectData = that.superIntentList[i];
                }
            }
            if(this.currentSuperIntentList.enable){
                this.$nextTick(() => {
                    let ele =  document.querySelector('#superIntentConfig-intent-add .popup-container-new');
                    ele.scrollTop = ele.scrollTop + 100;
                })
            }
        },
        // 获取超级意图列表
        getSuperIntentList() {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }
            );
        },
        //跳转至意图流程设计页面
        toChatFlow(botId, botName) {
            this.$router.push({
                name: "chatFlow",
                query: {
                    id: this.$route.query.id,
                    name: this.$route.query.name,
                },
            });
        },
        addIntentExiSimil() {
           
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let templateId = this.currentSuperIntentList.enable ? this.selectData.id : '';
            
            this.FetchPost(this.requestUrl.intent.addIntent, {
                name: intentName,
                exampleQuestion: exampleQuestion,
                isShare: this.isShare,
                tid: this.classification,
                apiKey: this.settingList.apiKey,
                templateId: templateId,
                config: JSON.stringify(this.superIntentInputWords),
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("意图添加成功！");
                    this.addIntentDiaVisible = false;
                    this.similIntentDialogVisible = false;
                    this.intentName = "";
                    this.exampleQuestion = "";
                    this.isShare = 1;
                    this.intentDes = "";
                    // this.activeClass = this.classification;
                    this.getIntentList("goto");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        gotoNewIntentPage(item) {
            let routeUrl = this.$router.resolve({
                path: "/main/intentQa",
                query: {
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: this.$router.currentRoute.query.refApiKey,
                    isDefault: item.isDefault,
                    apiKey:this.settingList.apiKey
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                    "/#" +
                    routeUrl.resolved.fullPath,
                "_blank"
            );
        },
        // 提交新建意图
        submitAddBot() {
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let isShare = this.isShare;
            let pattern = this.selectData.templateConfig.pattern;
             this.superIntentInputWords = [];
            // return
            if (this.currentSuperIntentList.enable == true) {
                for (var i = 0; i < pattern.length; i++) {
                    if (pattern[i].words.length != 0) {
                        let content = pattern[i].words[0].split(" ");
                      
                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: content,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (pattern[i].value) {
                        let value = pattern[i].value
                            .replace(/，/gi, ",")
                            .split(",");
                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: value,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (this.selectData.id == "") {
                        this.$message.error("请选择超级意图类别！");
                        return;
                    } else {
                        this.$message.error(
                            "填空处不能为空，请填写内容后再保存！"
                        );
                        return;
                    }
                }
            }

            if (Boolean(intentName.length) && Boolean(exampleQuestion && exampleQuestion.length)) {
                // 提交意图信息
                if (this.handleTypeC === "add") {
                    this.addIntentLoading = true;
                    this.FetchGet(this.requestUrl.intent.getSimilarIntent, {
                        name: intentName,
                        exampleQuestion: exampleQuestion,
                        tid: this.classification,
                        apiKey: this.settingList.apiKey,
                    })
                        .then((res) => {
                            if (res.code === "0") {
                                this.updateHeathlyIntent()
                                if (res.data.length == 0) {
                                    this.addIntentExiSimil();
                                } else {
                                    this.similIntentList = res.data;
                                    this.addIntentDiaVisible = false;
                                    this.similIntentDialogVisible = true;
                                }
                                // this.activeClass = this.classification;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                        .finally(() => {
                            this.addIntentLoading = false;
                        });
                }

                this.handleTypeC === "edit" &&
                    this.FetchPut(
                        this.requestUrl.intent.updateIntent,
                        this.editIntentInfo.id,
                        {
                            name: intentName,
                            exampleQuestion: exampleQuestion,
                            isShare: isShare,
                            apiKey:this.settingList.apiKey
                        }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.updateHeathlyIntent()
                            if (this.inentTypeId !== this.classification) {
                                this.FetchPost(
                                    "/api/intent/move?intentId=" +
                                        this.editIntentInfo.id +
                                        "&typeId=" +
                                        this.classification
                                ).then((res) => {
                                    if (res.code === "0") {
                                        // this.activeClass = this.classification;
                                        this.$message.success("移动分类成功");
                                        this.getTypeListChildren(
                                            this.activeSkill,'edit'
                                        );
                                        this.getIntentList();
                                        this.getClosedIntentList();
                                        this.FetchGet(
                                            this.requestUrl.skill.getSkillList,
                                            {
                                                bid: this.$route.query.id
                                            }
                                        ).then((res) => {
                                            if (res.code === "0") {
                                                this.skillList = res.data;

                                            }
                                        });
                                    }
                                });
                            } else {
                                this.$message.success("意图更新成功！");
                                // this.activeClass = this.classification;
                                this.getIntentList();
                                this.getClosedIntentList();
                            }

                            this.addIntentDiaVisible = false;
                            this.intentName = "";
                            this.exampleQuestion = "";
                            this.intentDes = "";
                            this.isShare = 1;
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            } else {
                this.$message.error("意图名称与示例问法不能为空！");
            }
        },
        getSkillListSkillManage(datas) {
            if(datas && datas === 'isStart'){
                this.activeClass = '';
                // this.activeSkill = ''
                this.getSkillList(this.$route.query.id, true, "isStart");
            } else {
                this.getSkillList(this.$route.query.id);
            }
            this.skillManageVisible = false;
        },
        // 获取技能list
        getSkillList(botId, setDefaSkill, type) {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: botId,
                filterInvisible: false,
            }).then((res) => {
                if (res.code === "0") {
                    this.skillList = res.data;
                    console.log(this.skillList);
                    let list = res.data.map((res) => {
                        return { label: res.name, value: res.id, children: [] };
                    });
                    this.classificationOption = JSON.parse(JSON.stringify(list))
                    if (this.skillList.length !== 0) {
                        if (setDefaSkill) {
                            this.activeSkill = this.skillList[0].id;
                        }
                        this.getTypeList(
                            this.activeSkill,
                            setDefaSkill,
                            type
                        );
                        if (this.$route.query.fromIntentID == null) {
                            
                        } else {
                            if (setDefaSkill) {
                                this.autoClickClassType();
                            }
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        
        // 切换技能
        switchSkill(skillId,search) {
            this.loading = true;
            this.activeSkill = skillId;
            this.getTypeList(skillId, true,search);
        },
        switchSkillCopy() {
            this.getSkillListSkillManage();
        },
        // 获取分类list
        getTypeList(skillId, setDefaClass, type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: skillId,
            }).then((res) => {
                if (res.code === "0") {
                    this.classList = res.data;
                    for (let i = 0; i < this.classificationOption.length; i++) {
                        for (let j = 0; j < this.classList.length; j++) {
                            if (
                                this.classificationOption[i].value === skillId
                            ) {
                                let test = this.classList.map((res) => {
                                    return { label: res.name, value: res.id };
                                });
                                this.classificationOption[i].children = JSON.parse(JSON.stringify(test));
                            }
                        }
                    }
                    let classListHandleString = JSON.stringify(res);
                    this.classListHandle = JSON.parse(
                        classListHandleString
                    ).data;
                    this.classListHandle.forEach((item) => {
                        item.isEdit = false;
                    });
                    this.oldClassListHandle = JSON.parse(JSON.stringify( this.classListHandle))
                    if (this.classList.length !== 0) {
                        if (setDefaClass) {
                            if (type === "isStart") {
                                this.activeClass = this.classList[0].id;
                            } else if(type === 'search'){
                                

                            } else if(type === 'switch'){
                                this.activeClass = this.classList[0].id;
                            } else {
                                // this.activeClass = this.classList[0].id;
                            }
                        }
                        this.getIntentList();
                        this.getClosedIntentList();
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 切换分类
        switchClass(classId) {
            this.loading = true;
            this.activeClass = classId;
            this.getIntentList();
            this.getClosedIntentList();
            // this.classification = classId;
            this.exampleQuestion = "";
        },
        // 新增分类
        addType(skillId) {
            this.FetchPost(this.requestUrl.intent.addIntentType, {
                skillId: skillId,
                name: "办公软件",
            }).then((res) => {
                if (res.code === "0") {
                    this.getTypeList(skillId, true);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取关闭意图列表
        getClosedIntentList(type) {
            let params = {
                typeId: this.activeClass,
            };
            if (type === 1) {
                // 搜索
                params.keyWord = this.searchIntentName;
            }
            this.FetchGet(
                this.requestUrl.intent.getClosedIntentList,
                params
            ).then((res) => {
                if (res.code === "0") {
                    this.closedIntentList = res.data;
                    this.closedIntentList.forEach((item) => {
                        item.status = true;
                        item.isDefa = true;
                        item.hasFlow = true;
                        // item.isParent = true;
                    });
                    // this.loading = false;
                    if (this.closedIntentList.length == 0) {
                        this.intentClosedBox = false;
                    }
                    this.isOpenIntentList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取意图列表
        getIntentList(type) {
            let params = {
                typeId: this.activeClass,
            };
            if (type === 1) {
                // 搜索
                this.getClosedIntentList(1);
                params.keyWord = this.searchIntentName;
            } 
            this.FetchGet(this.requestUrl.intent.getIntentList, params).then(
                (res) => {
                    if (res.code === "0") {
                        this.intentList = res.data;
                        // let activeIntentItem = {};
                        this.newIntentInfo = res.data[res.data.length - 1];
                        this.intentList.forEach((item,index) => {
                            item.status = true;
                            item.isDefa = true;
                            item.hasFlow = true;
                            // item.isParent = true;
                            // if(this.activeItem.id === item.id){
                            //     activeIntentItem = item;
                            // }
                        });
                        this.loading = false;
                        if (type == "goto") {
                            this.$nextTick(() => {
                                this.autoGotoIntentHandle();
                            });
                        } 
                        this.isOpenIntentList();
                        // 搜索定位
                        this.$nextTick(() => {
                            if(this.activeItem.id || sessionStorage.getItem('openIntentWeb') == 1){
                                let activeIntentId = sessionStorage.getItem('openIntentWeb') == 1 ? sessionStorage.getItem('openIntentId') : this.activeItem.id;
                                console.log(activeIntentId,'activeIntentId');
                                
                                // let height = document.querySelector('.botIntent-content-right').clientHeight;
                                // let width = document.querySelector('.botIntent-content-right').clientWidth;
                                let activeDom = document.querySelector('#intent-item' + activeIntentId);
                                console.log(activeDom,'activeDom-------');
                                let num = activeDom.offsetTop-180 > 0 ? activeDom.offsetTop-180 : 0;
                                if(sessionStorage.getItem('openIntentWeb') == 1){
                                    this.activeIntent = activeIntentId;
                                }
                                this.$forceUpdate();
                                console.log(this.activeIntent,'this.activeIntent');
                                console.log(num,'滚动的距离--');
                                document.querySelector('.botIntent-content-right').scrollTop = num;
                                sessionStorage.removeItem("openIntentWeb");
                                sessionStorage.removeItem("openIntentId");
                                sessionStorage.removeItem('_activeSkillList');
                                sessionStorage.removeItem('_activeClassList');
                                sessionStorage.removeItem('_activeSkill');
                                sessionStorage.removeItem('_activeClass');
                                sessionStorage.removeItem('IntentIsRet');
                                this.$forceUpdate();
                            }
                            setTimeout(() => {
                                this.activeItem = {};
                                this.watchSize();
                            },1000)
                        })
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 编辑意图
        editeIntent(item) {
            this.handleTypeC = "edit";
            this.editIntentInfo = item;
            this.intentName = item.name;
            this.isShare = item.isShare == null ? 0 : item.isShare;
            this.intentName1 = item.name;
            this.exampleQuestion = item.exampleQuestion;
            this.isDefault = item.isDefault;
            this.exampleQuestion1 = item.exampleQuestion;
            this.addIntentDiaVisible = true;
            this.classification = item.typeId;
            this.inentTypeId = item.typeId;
        },
        // 删除意图
        deleteIntent(id, isParent) {
            this.checkProcessIntentIds = [id];
            this.relationIntentListHandleType = "deleteS";
            this.operationType = 'card';
            this.sHandleHasChildren = isParent;
            this.relationIntentListVisible = true;
            this.$nextTick(() => {
                document.querySelector(
                    ".popup-floating-layer-new"
                ).style.zIndex = 2111;
            });
        },
        confirmDeleteSIntent(id, isParent, reason) {
            this.FetchPost(this.requestUrl.intent.deleteIntentWithReason + id, {
                description: reason.reasonText,
                reasonInfos: reason.reasonValue,
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("删除成功！");
                    this.searchIntentName = ''
                    this.getLimitMsg();
                    this.getIntentList();
                    this.getClosedIntentList();
                    this.noticeDrawerVisible &&
                        this.$refs.noticeDrewer.getIntentNoticeList();
                    this.relationIntentListVisible = false;
                    this.skillList.forEach((item) => {
                        if (item.id == this.activeSkill) {
                            item.intentNum = item.intentNum > 0 ? item.intentNum - 1 : 0;
                        }
                    });
                    this.classList.forEach((item) => {
                        if (item.id == this.activeClass) {
                            item.intentNum = item.intentNum > 0 ? item.intentNum - 1 : 0;
                        }
                    });
                    this.updateHeathlyIntent('delete');
                    this.updateHeathlyIntent()
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        botHandleCommand() {},
        gotoBotIntent() {},
        // 添加意图
        addIntent() {
            if (this.limitUse) {
                this.versionTipDialog = true;
            } else {
                this.intentName = "";
                this.exampleQuestion = "";
                this.isDefault = false;
                this.handleTypeC = "add";
                this.currentSuperIntentList.enable = false;
                this.selectData.name = '';
                this.clearStatus = true;
                this.intentClassKey = 1;
                this.selectData.examples = [];
                let templateConfig = {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                }
                this.selectData.templateConfig = templateConfig;
                this.addIntentDiaVisible = true;
                let mainId = localStorage.getItem("_mainId");
                if (
                    (mainId === "90df4764122240de939331d372546c28" ||
                        mainId === "e2f80aecaf7c11eabdcd506b4b2f3acc" ||
                        mainId === "1419b09cb2ce11ea8f0900163e0964d5") &&
                    this.settingList.refApiKey
                ) {
                    this.isShare = 1;
                } else {
                    this.isShare = 0;
                }
                this.getSuperIntentList();
            }
            
        },
        changeAddIntentVisible(value) {
            this.addIntentDiaVisible = value;
            if (!value) {
                this.getIntentList();
            }
        },
        sessionActive(){
            sessionStorage.setItem("_activeSkill", this.activeSkill);
            sessionStorage.setItem("_activeSkillList", JSON.stringify(this.skillList));
            sessionStorage.setItem("_activeClass", this.activeClass);
            sessionStorage.setItem("_activeClassList", JSON.stringify(this.classList));
        },
        // 跳转意图操作页
        gotoIntentHandle(item) {
            let that = this;
            this.sessionActive();
            let process = 1; // 1 缺流程   2 不缺流程/正常
            let heathlyType = 0; // 0 正常    1 未达标   2 未达标已下线
            if( (!item.hasProcess &&item.refAttrId ==null) ||(!item.hasProcess &&item.refAttrId !=null &&!item.requireSyncProcess)){
                process = 1;
            }  else {
                process = 2;
            }  
            if(!item.onLine && item.onLine !== null){
                heathlyType = 2;
            } else {
                heathlyType = 0;
            }
            if(!item.upToStandard && item.upToStandard !== null){
                heathlyType = 1;
            } else {
                heathlyType = 0;
            }
            this.$router.push({
                name: "intentQa",
                query: {
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: that.$router.currentRoute.query.refApiKey,
                    isDefault: item.isDefault,
                    // process:process,
                    // heathlyType:heathlyType,
                    apiKey:this.settingList.apiKey,
                },
            });
        },
        // 创建完意图后自动跳转意图操作页
        autoGotoIntentHandle() {
            let that = this;
            this.sessionActive();
            if (
                this.RP_Visible("OPEN_INTENT") ||
                this.RP_Visible("OPEN_PROCESS")
            ) {
                this.$router.push({
                    name: "intentQa",
                    query: {
                        bid: this.$route.query.id,
                        bName: this.$route.query.name,
                        intentId: this.newIntentInfo.id,
                        intentName: this.newIntentInfo.name,
                        refApiKey: that.$router.currentRoute.query.refApiKey,
                        apiKey:this.settingList.apiKey
                    },
                });
            }
        },
        // 分页操作
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        // 点击分类管理
        clickClassHandle() {
            this.delTypeNames = [];
            this.classHandleVisible = true;
            this.getTypeList(this.activeSkill, true);
            this.$nextTick(() => {
                this.rowDrop();
            });
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */
        rowDrop() {
            const box = document.querySelector(".classList");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".iconfont",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.classListHandle.splice(oldIndex, 1);
                    _this.classListHandle.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.classListHandle = _this.classListHandle;
        },
        // 新建分类
        addClass() {
            this.classListHandle.push({
                isEdit: true,
                name: "",
            });
            this.$nextTick(() => {
                let ele = document.getElementById("classList");
                ele.scrollTop = ele.scrollHeight + 100;
            });
        },
        // 编辑分类
        editClass(index) {
            this.classListHandle[index].isEdit = true;
            this.classListHandle = [...this.classListHandle];
        },
        // 删除分类
        deleteClass(item,index) {
            if(index === 0 && this.classListHandle.length === 1)return;
            //判断分类下是否存在意图
            if(this.classListHandle[index].id){
                let params = {
                    typeId: this.classListHandle[index].id,
                };
                this.FetchGet(this.requestUrl.intent.getIntentList, params).then(
                    (res) => {
                        let typeIntentNum = res.data.length;
                        if (typeIntentNum === 0) {
                            this.classListHandle.splice(index, 1);
                            if(item.id){
                                // this.delTypeNames.push(item.name)
                                this.delTypeNames.push(item)
                            }
                        } else {
                            this.$message.error(
                                "当前分类下存在意图，请删除意图后再进行分类删除！"
                            );
                        }
                    }
                );
            } else {
                this.classListHandle.splice(index, 1);
            }
           
        },
        // 保存分类
        saveClassHandle() {
            //判断新建的分类名称是否为空
             let newClassName = ''
            if(this.classListHandle.length > 0){
                let lastName = this.classListHandle.length - 1;
                newClassName = this.classListHandle[lastName];
            }
            if (newClassName.name.length != 0) {
                this.FetchPost(this.requestUrl.intent.getIntentTypeList, {
                    skillId: this.activeSkill,
                    types: this.classListHandle,
                    delTypeNames:this.delTypeNames
                }).then((res) => {
                    if (res.code === "0") {
                        this.getTypeList(this.activeSkill, true);
                        this.classHandleVisible = false;
                        // classListHandle  oldClassListHandle
                        let list = [];
						this.classListHandle.forEach((item,index) => {
							this.oldClassListHandle.forEach((oldItem,oldIndex) => {
								if(item.id === oldItem.id){
									if(item.name !== oldItem.name){
										list.push(item);
									}
								}
							})
						})
                        if(this.isShowHeathly){
                            sessionStorage.setItem('differentClass',JSON.stringify(list));
                            this.$eventBus.$emit("bot-heathly-visible", {
                                flag:this.isShowHeathly,
                                type:"updateIntentClass"
                            });
                        }
						
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                this.$message.error("分类名称不能为空哦，请填写后保存！");
            }
        },
        //初次登陆引导层
        guide() {
            this.driver.defineSteps(steps);
            this.driver.start();
            var driver = document.getElementById("driver-page-overlay");
            var driver2 = document.getElementById(
                "driver-highlighted-element-stage"
            );
            //driver.style.cssText = "z-index:10000!important;"
            //driver2.style.cssText = "z-index:1000!important;"
        },
        echoList(){
            // this.skillList = JSON.parse(sessionStorage.getItem("_activeSkillList"));
            // this.classList = JSON.parse(sessionStorage.getItem("_activeClassList"));
            // this.getSkillList(this.$route.query.id,false,'search')
            this.$nextTick(() => {
                this.activeSkill = Number(sessionStorage.getItem("_activeSkill"));
                this.activeClass = Number(sessionStorage.getItem("_activeClass"));
                this.getSkillList(this.$route.query.id,false,'search')
                // this.getIntentList();
                // this.getClosedIntentList();
                // sessionStorage.removeItem('_activeSkillList');
                // sessionStorage.removeItem('_activeClassList');
                // sessionStorage.removeItem('_activeSkill');
                // sessionStorage.removeItem('_activeClass');
                // sessionStorage.removeItem('IntentIsRet');
            })
        },
        //某个意图问法页面返回自动切换到该意图所在分类
        autoClickClassType() {
            let botId = this.$route.query.id;
            let that = this;
            //获取技能列表
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: botId,
            }).then((res) => {
                if (res.code === "0") {
                    that.allSkillList = res.data;
                    for (var i = 0; i < this.allSkillList.length; i++) {
                        let skillId = this.allSkillList[i].id;
                        this.autoClickClassTypeTwo(skillId);
                    }
                }
            });
        },
        //某个意图问法页面返回自动切换到该意图所在分类
        autoClickClassTypeTwo(skillId) {
            //获取技能下的所有意图
            let params = {
                skillId: skillId,
            };
            //
            this.FetchGet(this.requestUrl.trainList.skillIntent, params).then(
                (res) => {
                    if (res.code === "0") {
                        this.allIntentList = res.data;
                        let that = this;
                        for (var i = 0; i < this.allIntentList.length; i++) {
                            let aaa = this.allIntentList[i].id;
                            if (this.$route.query.fromIntentID == aaa) {
                                let fromIntentClassTypeId =
                                    this.allIntentList[i].typeId;
                                if (skillId != this.activeSkill) {
                                    this.autoSwitchSkill(
                                        skillId,
                                        fromIntentClassTypeId
                                    );
                                } else {
                                    this.switchClass(fromIntentClassTypeId);
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 自动切换技能
        autoSwitchSkill(skillId, classId) {
            this.loading = true;
            this.activeSkill = skillId;
            this.getTypeList(skillId, true);
            setTimeout(() => {
                this.activeClass = "";
                this.activeClass = classId;
                this.getIntentList();
                this.getClosedIntentList();
            }, 300);
        },
        getTypeListChildren(id,type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    if(type && type === 'edit'){
                        this.classList = res.data;
                    }
                    this.classificationOption.forEach((item,index) => {
                         if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    })
                }
            });
        },
        
        handleChange2(value) {
            this.getTypeListChildren(value[0]);
        },
    },
    created(){
        this.CompanyIsOld = sessionStorage.getItem('CompanyIsOld') == 'true' ? true : false;
    },
    mounted() {
        this.mountedFn();
    },
    watch: {
        searchIntentName: function (newValue, oldValue) {
            if (newValue == "" && oldValue != "") {
                this.getIntentList();
                this.getClosedIntentList();
            }
        },
        activeClass: {
            handler(a) {
                this.classification = a;
            },
        },
        intentList: function(newValue, oldValue){
            this.$nextTick(() => {
                this.listLen = newValue.length;
                this.addEmptyEl(newValue.length);
            })
           
            
        },
        // // 单个删除 deleteS 批量删除 deleteB 单个关闭 closeS 批量关闭 closeB 单个开启 openS
        // relationIntentListHandleType(n){
        //     // closeB  openB deleteB
        //     if((n === 'closeB' || n === 'openB' || n === 'deleteB') && this.relationIntentListVisible){
        //         document.querySelector('#intent-tips-dialog').style.zIndex = 2600;
        //     } else {
        //         document.querySelector('#intent-tips-dialog').style.zIndex = 2500;
        //     }
        //     console.log(n,'类型');
        // }

        
        addIntentDiaVisible(n){
            if(n){
               this.$nextTick(() => {
                    document.querySelector('#superIntentConfig-intent-add .popup-floating-layer-new').addEventListener('click',() => {
                        if(this.isIntentFocus){
                             this.selectData.templateConfig.pattern.forEach((item,index) => {
                                this.$set(item,'showBotKeywordRecommend',false)
                                item.showBotKeywordRecommend = false;
                            })
                            this.isIntentFocus = false;
                            this.$forceUpdate();
                        }
                    })
               })
            } else {
                document.querySelector('#superIntentConfig-intent-add .popup-floating-layer-new').removeEventListener('click',() => {})
            }
        },
         "$route": function (to, oldVal) {
            this.mountedFn();
            // this.showIsActive(to);
        },
        isShowHeathly(n){
            console.log(n,'isShowHeathly');
        },
        isShowTest(n){
            console.log(n,'isShowTest');
        }

    },
    destroyed() {
        clearTimeout(this.guidTaskId);
    },
    computed:{
        isOtherSkill:{
            //当第一次调用fullName 或者 fullName引用的数据被修改时，调用get()函数
            get(){
                let isOther = false;
                this.skillList.forEach(item => {
                    if (item.id === this.activeSkill && item.name === "特殊规则") {
                        isOther = true;
                    }
                })
                return isOther;
            },
            //当改变fullName计算属性时，调用set方法
            set(value){
                let isOther = false;
                this.skillList.forEach(item => {
                    if (item.id === this.activeSkill && item.name === "特殊规则") {
                        isOther = true;
                    }
                })
                return isOther;
            }
        }
    }
};
</script>
<style lang="less">
@import "./../../assets/less/botIndex/botIntent.less";
.botLimitUse {
    opacity: 0.5;
}

// div#driver-popover-item {
//     min-width: 400px !important;
//     max-width: 400px !important;
//     padding: 30px 64px !important;
//     font-family: Microsoft YaHei !important;
//     .driver-popover-title{
//         width: 100%;
//         display: flex !important;
//         justify-content: center !important;
//         font-size: 26px;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         color: #000000;
//         margin-bottom: 30px;
//     }
//     .descripVideo {
//         video::-webkit-media-controls-fullscreen-button {
//             display: none;
//         }
//     }
//     // .driver-close-only-btn
//     .driver-popover-description{
//         // text-align: center;
//     }
//     .driver-popover-footer{
//         width: 100%;
//         display: flex !important;
//         justify-content: center !important;
//         margin-top: 30px;
//         button{
//             width: 99px;
//             height: 37px;
//             padding: 0;
//             text-align: center;
//             line-height: 37px;
//             color: #fff !important;
//             background: #366AFF !important;
//             font-size: 14px;
//             border-radius: 17px !important;
//             font-family: Microsoft YaHei !important;
//             text-shadow:none !important;
//         }
//     }
    
// }
div#driver-popover-item {
    min-width: 400px !important;
}
.intent-link-title {
    margin: 16px 0;
    text-align: left;
    font-weight: 800;
}
.intent-link-cell {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
}
.intent-info {
    position: relative;
    .top-tag {
        position: absolute;
        top: 0;
        left: 0;
    }
}
.el-dialog__wrapper {
    overflow: auto !important;
}


.el-popconfirm__main {
    width: 200px !important;
    margin-bottom: 15px;
}

.class-manage-zsk-1 {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: solid 1px #e0e6f7;
    border-radius: 5px 0 0 0;
    height: auto;
    padding-right: 10px;
    .class-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
            height: 50px;
            line-height: 50px;
            color: @system_fontcolor_general;
            // border-bottom: solid 1px @system_bordercolor_3;
            padding: 0 12px;
            margin-bottom: -2px;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
            // text-overflow: ellipsis;
            .el-badge__content {
                top: 13px;
                right: 15px;
                padding: 0 5px;
                height: 16px;
                text-align: center;
                line-height: 16px;
            }
        }
        .active-class:hover {
            // color: @system_fontcolor_red;
        }
        .active-class {
            color: @system_fontcolor_red;
            border-bottom: solid 2px @system_fontcolor_red;
        }
        .active-skill {
            border-bottom: solid 3px @system_fontcolor_red;
            .el-badge__content {
                position: relative;
                background-color: #749bff;
                right: 20px;
            }
            .el-badge span{
                color:#366AFF;
            }
        }
    }
    .know {
        margin-top: 10px;
        height: 30px !important;
        line-height: 30px !important;
    }
    .classifi {
        .classifi-item {
            color: #616161;
            height: 33px;
            background: #ffffff;
            border: 1px solid #c7d7ff;
            border-radius: 17px;
            text-align: center;
            margin: 7px 0px 7px 10px;
            justify-content: space-between;
            display: flex;
            align-items: center;
            font-size: 12px;
            box-sizing: border-box;
            &:hover {
                font-weight: 600;
                color: #366aff;
            }
            .name {
                margin-right: 5px;
            }
            &.active-class {
                background-color: @system_bgc_red;
                color: #fff;
            }
        }
    }
    .list-skill {
        color: #616161;
        margin-right: 10px;
        span{
            color: #616161;
        }
        &:hover {
            font-weight: 600;
        }
        .el-badge__content {
            position: relative;
            background-color: #ffffff;
            color: #616161;
            border-color: #C7D7FF;
            height: 16px;
            text-align: center;
            line-height: 16px;
            padding: 0 3px;

        }
    }
    .el-button {
        border-radius: 5px;
        min-width: 101px;
        height: 33px;
        padding: 0 6px;
        line-height: 33px;
        span {
            margin-left: 5px;
            vertical-align: top;
        }
    }
    .button-box {
        display: flex;
        flex-wrap: nowrap;
        .class-manage-btn{
            right: 5px;
            height: 30px;
            margin: 5px;
            &:last-child{
                margin-right: 0;
            }
            &.el-button+.el-button{
                margin-left: 0;
            }
        }
       
    }
}
.el-scrollbar{
    .el-select-dropdown__list{
        .el-select-dropdown__item.selected{
            &.clearStatus{
                color: #606266;
                font-weight:normal;
                background-color: transparent;
            }
        }
    }
    
}
</style>