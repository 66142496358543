<template>
    <transition name="el-zoom-in-top">
        <div class="intent-node-list">
            <div :class="['intent-node-first', nodeItem.children.length == 1 ? '' : 'intent-node-first-border-l']"
                v-for="(nodeItem, nodeIndex) in JSON.parse(intentItem).children" :key="nodeIndex"
                v-show="JSON.parse(intentItem).show">
                <div v-if="nodeItem.children.length == 1" class="intent-node-first-pre-line"></div>
                <div class="intent-node-first-top-content">
                    <div class="node-pre-first"></div>
                    <div @click="
                        configAction(
                            JSON.parse(intentItem).id,
                            nodeItem.id,
                            skillItem.id,
                            nodeItem.name,
                            [
                                skillIndex,
                                classIndex,
                                intentIndex,
                                nodeIndex,
                            ],
                            0
                        )
                    " class="node-node-name-first">
                        <span class="node-name-c">节点</span>
                        <div class="name-text csp">
                            {{ nodeItem.name }}
                        </div>
                    </div>
                    <div class="first-node-handle">
                        <span v-show="
                            !(
                                (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                    !(
                                        !skillItem.display &&
                                        skillItem.name == '其他'
                                    )) ||
                                (!skillItem.display &&
                                    skillItem.name == '其他' &&
                                    RP_Visible('OPEN_PROCESS_OTHER'))
                            )
                        " class="first-node-handle-cell add-text-forb"><i class="el-icon-plus"></i></span>
                        <span v-show="
                            !nodeItem.isAddEditing &&
                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !(
                                    !skillItem.display &&
                                    skillItem.name == '其他'
                                )) ||
                                (!skillItem.display &&
                                    skillItem.name == '其他' &&
                                    RP_Visible('OPEN_PROCESS_OTHER')))
                        " class="first-node-handle-cell add-text" @click="
    addNodeHandleFirst(
        JSON.parse(intentItem).id,
        JSON.parse(intentItem).id,
        [skillIndex, classIndex, intentIndex],
        'first'
    )
">
                            <el-tooltip content="添加子节点" effect="light" placement="top">
                                <i class="el-icon-plus"></i>
                            </el-tooltip>
                        </span>
                        <div v-show="nodeItem.isAddEditing" class="add-input-first">
                            <el-input :ref="'add_input_' + JSON.parse(intentItem).id" v-model="nodeNameValue"
                                maxlength="20" size="small" placeholder="请输入子节点名称" @keyup.enter.native="
                                    confirmAddNodeHandleFirst(
                                        JSON.parse(intentItem).id,
                                        nodeItem.id,
                                        [skillIndex, classIndex, intentIndex],
                                        nodeNameValue,
                                        'first'
                                    )
                                "></el-input>
                            <span class="el-icon-success add-icon" @click="
                                confirmAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    nodeItem.id,
                                    [skillIndex, classIndex, intentIndex],
                                    nodeNameValue,
                                    'first'
                                )
                            "></span>
                            <span class="el-icon-error delete-icon" @click="
                                concelAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    JSON.parse(intentItem).id,
                                    [skillIndex, classIndex, intentIndex],
                                    'first'
                                )
                            "></span>
                        </div>
                    </div>
                </div>

                <recursive :list="JSON.stringify(nodeItem.children)" :indexArr="
                    JSON.stringify([
                        skillIndex,
                        classIndex,
                        intentIndex,
                        nodeIndex,
                    ])
                " :nodeTree="nodeTree" :isPart="isPart" :activeBot="activeBot"
                    :intentId="JSON.parse(intentItem).id" :skillId="skillItem.id" :isOtherSkill="
    !skillItem.display && skillItem.name == '其他'
                    "></recursive>
            </div>
        </div>
    </transition>
</template>
<script>
import recursive from "./chatFlowNodeComponent";
export default {
    props: [
        "skillIndex",
        "classIndex",
        "intentIndex",
        "intentItem",
        "classItem",
        "skillItem",
        "isPart",
        "nodeTree"
    ],

    data() {
        return {
            nodeNameValue: "",
        };
    },
    components: {
        recursive,
    },
    methods: {
        addNodeHandleFirst(id, _id, indexArr, pre) {
            this.$emit("addNodeHandleFirst", id, _id, indexArr, pre);
            this.$nextTick(() => {
                this.$refs[`add_input_${id}`][0].focus();
            })
        },
        confirmAddNodeHandleFirst(id, _id, indexArr, nodeNameValue, pre) {
            this.$emit(
                "confirmAddNodeHandleFirst",
                id,
                _id,
                indexArr,
                nodeNameValue,
                pre
            );
            this.nodeNameValue = "";
        },
        concelAddNodeHandleFirst(id, _id, indexArr, pre) {
            this.$emit("concelAddNodeHandleFirst", id, _id, indexArr, pre);
            this.nodeNameValue = "";
        },
        configAction(
            intentId,
            nodeId,
            skillId,
            nodeName,
            indexArr,
            isInitNode
        ) {
            this.$emit(
                "configAction",
                intentId,
                nodeId,
                skillId,
                nodeName,
                indexArr,
                isInitNode
            );
        },
    },
};
</script>
<style lang="less" scoped>
.intent-node-first-border-l {
    border-left: solid 2px #749bff;
}

.intent-node-first {
    border-radius: 0 0 0 0;
    margin: 124px 20px 0 -273px;
    position: relative;

    .intent-node-first-pre-line {
        width: 2px;
        background-color: #749bff;
        height: 40px;
        position: absolute;
        margin-left: -2px;
        margin-top: 18px;
    }

    .intent-node-first-top-content:hover {
        .first-node-handle {
            display: flex;
        }
    }

    .intent-node-first-top-content {
        display: flex;
        justify-content: flex-start;
        margin-left: -75px;

        .node-pre-first {
            height: 10px;
            padding-top: 40px;
            width: 48px;
            margin-bottom: -80px;
            border-left: solid 2px #749bff;
            border-bottom: solid 2px #749bff;
            text-align: center;
            color: #aaa;
            font-size: 12px;
            border-radius: 0px 0 0 23px;
            position: relative;
            top: -53px;
        }

        .node-node-name-first {
            cursor: pointer;
            padding-right: 16px;
            margin-bottom: 0px;
            margin-top: -24px;
            height: 41px;
            line-height: 41px;
            border: solid 1px rgba(116, 155, 255, 0.4);
            background-color: white;
            border-radius: 23px;
            max-width: 364px;
            display: flex;
            justify-content: flex-start;

            .node-name-c {
                font-size: 12px;
                font-weight: 500;
                display: inline-block;
                height: 30px;
                line-height: 30px;
                width: 30px;
                margin-left: 4px;
                margin-top: 3px;
                text-align: center;
                border: solid 2px #749bff;
                border-radius: 21px 21px 21px 21px;
                background-color: #749bff;
                color: #fff;
            }

            .name-text {
                flex: auto;
                padding-left: 8px;
            }

            .delete-icon-node {
                margin-top: 10px;
                margin-right: -70px;
                font-size: 20px;
                color: #749bff;
                cursor: pointer;
            }
        }

        // .first-node-handle:hover {
        //     display: flex;
        // }
        .first-node-handle {
            padding-left: 4px;
            // display: none;
            display: flex;
            margin-top: -16px;
            justify-content: flex-start;
            cursor: pointer;

            .first-node-handle-cell {
                width: 29px;
                height: 29px;
                line-height: 29px;
                color: #749bff;
                text-align: center;
                background: #ffffff;
                border: 1px solid rgba(116, 155, 255, 0.4);
                border-radius: 50%;
                margin-right: 4px;

                i {
                    font-weight: 700;
                }
            }

            .add-input-first {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 34px;
                margin-top: -5px;
                border: solid 1px rgba(116, 155, 255, 0.4);
                border-radius: 5px;
                padding-right: 6px;
                background-color: white;

                // margin-top: -46px;
                /deep/.el-input {
                    margin-right: 6px;

                    .el-input__inner {
                        border: none;
                    }
                }

                .delete-icon {
                    color: #fe5965;
                    font-size: 20px;
                    cursor: pointer;
                }

                .add-icon {
                    font-size: 20px;
                    color: #749bff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>