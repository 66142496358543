<template>
    <div>
        <popup id="add-entity-flow" :popWidth="900" @closeEvent="addEntityDiaVisible = false"
            v-if="addEntityDiaVisible">
            <div slot="popup-name">添加实体</div>
            <div slot="popup-tip">在下方添加新建实体</div>
            <div id="add-entity-flow-content" slot="popup-con">
                <ul class="entityInfo">
                    <li>
                        <span class="title">实体名称 <span class="title-need">*</span> </span>
                        <el-input size="small" v-model="entityObject.name" maxlength="32"
                            :placeholder="entityType == 'DICTIONARY' ? consts.dicNamePlaceholder : consts.regNamePlaceholder">
                        </el-input>
                    </li>
                    <li>
                        <span class="title">适用范围 <span class="title-need">*</span></span>
                        <el-select size="small" :disabled="isEdit" v-model="entityObject.skillId" placeholder="请选择">
                            <el-option v-for="item in skills" :key="item.skillId" :label="item.skillName"
                                :value="item.skillId">
                            </el-option>
                        </el-select>
                    </li>
                    <li>
                        <span class="title">实体类型</span>
                        <div class="entity-type-content">
                            <el-radio-group size="medium" v-model="entityType" @change="changeEntityType">
                                <el-radio label="DICTIONARY">关键词</el-radio>
                                <el-radio label="REGULAR">正则表达式</el-radio>
                            </el-radio-group>
                        </div>
                    </li>
                    <li class="entity-value-content" v-if="entityType == 'DICTIONARY'">
                        <span class="title ">
                            <span>实体值</span>
                        </span>
                        <div class="entity-list">
                            <div class="entity-header"><span class="l">标准词</span><span class="r">同义词</span></div>
                            <div class="entity-rows" id="rows">

                                <div class="entity-row" v-for="(item, index) in entityObject.content" :key="index">
                                    <el-input size="small" class="l" maxlength="45" placeholder="标准词，如：女"
                                        v-model="item.standardWord" ref="inputStand" autofocus="autofocus" />
                                    <!-- <div class="keyword-recommend"> -->
                                    <el-popover
                                        popper-class="add-entity-pop"
                                        :ref="'addEntityPopover' + index"
                                        placement="bottom"
                                        :width="514"
                                        trigger="manual"
                                        :visible-arrow="false"
                                        v-model="item.showBotKeywordRecommend">
                                        <el-input slot="reference" size="small" :class="['r', 'r' + index]" type="text"
                                            :autosize="{ minRows: 1, maxRows: 10 }" placeholder="同义词，多个词之间用逗号隔开（如：女性，女人）"
                                            v-model.trim="item.synonyms" @input="changeInput($event, item, index)"
                                            @click.stop.native="changeInput($event, item, index, 'focus')"
                                            ref="inputSynon" />
                                        <botKeywordRecommend  type="entity" id="add-e-intent-list-close" :key="'add-entity-flow' + index"
                                            v-clickoutside:[classList]="closeIconBlock"
                                            :showBotKeywordRecommend.sync="item.showBotKeywordRecommend" :dataObj="item"
                                            :keyword="item.synonyms" :dataIndex="index" :width="514" :isFixed="true"
                                            :left="positionLeft" :positionTop="positionTop"
                                            @showBotKeywordRecommend="showBotKeywordRecommendFn(item,index)"
                                            @noBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                            @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                            @addToInput="addToInput($event, item, index)">
                                        </botKeywordRecommend>
                                    </el-popover>
                                    <!-- </div> -->
                                    <span v-if="entityObject.content.length > 1"
                                        class="delete-entity iconfont guoran-tongyichicun-16-09-shanchu2"
                                        @click="delWord(index)"> </span>
                                </div>
                            </div>
                            <div class="add-button"><span @click="addWord">
                                     + </span></div>
                        </div>
                    </li>
                    <li class="entity-value-content-b" v-if="entityType == 'REGULAR'">
                        <span class="title">描述说明</span>
                        <el-input type="textarea" placeholder="请输入描述说明" v-model="regInfo.description" rows="3"
                            show-word-limit>
                        </el-input>
                    </li>
                    <li class="entity-value-content-b" v-if="entityType == 'REGULAR'">
                        <span class="title">设置正则<br>
                            <span class="title-regular-desc">
                                <el-popover placement="top-start" title="说明" width="600" popper-class="regular-pop"
                                    trigger="click">
                                    <div class="">
                                        <p>正则实体指利用正则表达式识别出用户消息中的关键信息，作为实体值，可以由用户自行设置正则表达式。</p>
                                        <p>以下为一些常用的正则表达式，可直接复制使用。</p><br>
                                        <p>QQ号：[1-9][0-9]{4,}</p><br>
                                        <p>手机号：(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])[
                                            -]?(\d{4})[ -]?(\d{4})</p><br>
                                        <p>微信号：^[a-zA-Z][a-zA-Z0-9_-]{5,19}$</p><br>
                                        <p>身份证号：^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$</p>
                                    </div>
                                    <i class="el-icon-info" slot="reference">示例</i>
                                </el-popover>

                            </span>
                        </span>
                        <el-input type="textarea" placeholder="请输入正则表达式" v-model="regInfo.pattern" rows="3"
                            show-word-limit>
                        </el-input>
                    </li>
                </ul>
            </div>
            <div slot="dialog-footer">
                <el-button @click="addEntityDiaVisible = false" plain round class="cancel-btn">取 消</el-button>
                <el-button type="primary" @click="submitAddEntity" round :loading="addEntityLoading"
                    class="confirm-btn">确 定</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import botKeywordRecommend from '@/views/intentQa/components/botKeywordRecommend.vue';
export default {
    components: { popup, botKeywordRecommend },
    props: ["addIntentDiaVisible", "entity", "isEdit", "isAdd"],
    data() {
        return {
            classList: ['add-e-intent-list-close'],
            addEntityLoading: false,
            skills: [],

            consts: {
                dicEntityType: "DICTIONARY",
                regEntityType: "REGULAR",
                dicNamePlaceholder: "请输入实体名称，如：性别",
                regNamePlaceholder: "请输入实体名称，如：手机号"
            },
            addEntityDiaVisible: true,
            entityName: "",
            intentDes: "",
            entityType: 'DICTIONARY',
            entityObject: {
                content: [],
                name: "",
                skillId: 0,
            },
            regInfo: {
                pattern: "",
                description: "",
                standardWord: "",
                synonyms: []
            },
            clientWidth: null,
            positionTop: 0,
            positionLeft: 0,
            isIntentFocus: false
        };
    },

    methods: {
        showBotKeywordRecommendFn(item,index){
            this.$refs['addEntityPopover'+index][0].updatePopper()
            item.showBotKeywordRecommend = true;
        },
        closeIconBlock(){
            this.entityObject.content.forEach(item => {
                item.showBotKeywordRecommend = false;
            })
        },
        getEntitySkills() {
            this.FetchGet(this.requestUrl.entity.getEntitySkills, {
                isDefault: false,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.skills = [];
                        let isOther = true;
                        res.data.forEach((item) => {
                            if (item.skillId == this.entityObject.skillId) {
                                isOther = false;
                                this.skills.push(item);
                            }
                            if (item.skillId == 0) {
                                this.skills.push(item);
                            }
                        })
                        if (isOther) {
                            this.entityObject.skillId = 0;
                        }

                        // if (this.skills) {
                        //   if (this.activeSkill == null || this.activeSkill==-1) {
                        //     this.activeSkill = this.skills[0].skillId;
                        //   }
                        //   this.getEntityList();
                        // }
                    } else {
                        console.log("getEntitySkills", res);
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 关闭近义词推荐弹框
        closeBotKeywordRecommend(item) {
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        //添加实体 关键词的近义词 , 单个添加
        addToInput(datas, item, index) {
            item.synonyms = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        // 添加实体 显隐推荐词下拉框
        changeInput(datas, item, index) {
            this.isIntentFocus = true;
            this.clientWidth = document.querySelector('.entity-row .el-input').clientWidth - 35;
            this.entityObject.content.forEach((v, i) => {
                v.showBotKeywordRecommend = false;
            })
            if (item.synonyms !== '') {
                item.showBotKeywordRecommend = true;
                this.$nextTick(() => {
                    let ele = document.querySelector('#add-entity-flow .entity-row .r' + index + ' .el-input__inner');
                    this.positionTop = ele.getBoundingClientRect().top + 45
                    this.positionLeft = ele.getBoundingClientRect().left;
                })

            }
            this.$forceUpdate();
        },
        // 点击新建实体
        addWord() {
            this.entityObject.content.push({ standardWord: '', synonyms: '', showBotKeywordRecommend: false })
            this.$nextTick(function () {
                let index = this.entityObject.content.length
                this.$refs.inputStand[index - 1].focus()
                let container = this.$el.querySelector('#rows')
                container.scrollTop = container.scrollHeight - container.clientHeight
            })

            this.$nextTick(() => {
                this.$el.querySelector('.popup-container-new').scrollTop = 9999999;
            })

        },
        delWord(index) {
            this.entityObject.content.splice(index, 1)
        },
        // 提交新建或保存实体
        submitAddEntity() {
            if (this.entityObject.name == null || this.entityObject.name == undefined || this.entityObject.name.trim().length == 0) {
                this.$message.error('实体名称不能为空');
                return;
            }
            if (this.entityObject.skillId == null || this.entityObject.skillId == undefined || this.entityObject.skillId == -1) {
                this.$message.error('适用范围不能为空');
                return;
            }
            this.entityObject.content.forEach(i => { if (typeof (i.synonyms) === 'string') { i.synonyms = i.synonyms.replace(/，/g, ',').split(',') } })
            this.entityObject.type = this.entityType;
            if (this.entityType == this.consts.regEntityType) {
                this.entityObject.content = [this.regInfo];
            }
            if (this.entityObject.id && !this.isAdd) {
                //修改
                this.FetchPut(this.requestUrl.entity.updateEntity, this.entityObject.id, this.entityObject)
                    .then(res => {
                        if (res.code == 0) {
                            this.$emit("afterSave", this.entityObject);
                            this.addEntityDiaVisible = false
                        } else {
                            console.log("submitAddEntity", res)
                            this.$message.error(res.message);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else (
                //新增
                this.FetchPost(this.requestUrl.entity.updateEntity, this.entityObject)
                    .then(res => {
                        if (res.code == 0) {
                            this.entityObject.id = res.data
                            this.$emit("afterSave", this.entityObject);
                            this.addEntityDiaVisible = false
                        } else {
                            console.log("submitAddEntity", res)
                            this.$message.error(res.message);
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    })
            )


        },
        changeEntityType(value) {
            this.entityType = value;
            this.entity.type = value;
            this.regInfo.pattern = "";
            this.regInfo.description = "";
        }
    },
    mounted() {
        this.getEntitySkills();
        this.addWord();
        this.$nextTick(() => {

        })
    },
    updated() {
        this.$emit("changeAddIntentVisible", this.addEntityDiaVisible);
    },
    watch: {

    }
};
</script>
<style>
.add-entity-pop{
    z-index: 3333 !important;
}
</style>
<style lang="less" scoped>
#add-entity-flow {
    @button-color: rgba(64, 158, 255, 1);

    #add-entity-flow-content {
        .entityInfo {
            .entity-value-content {
                background: #FBFCFD;
                margin-top: -12px !important;

                .title {
                    height: 38px;
                    line-height: 38px;
                    padding: 0 12px;
                    margin-bottom: 0;
                }
            }

            .entity-value-content-b {
                background: #FBFCFD;
                margin-top: -12px !important;
                padding: 0 12px;

                .title {
                    width: 300px;
                    height: 38px;
                    line-height: 38px;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                }

                .title-regular-desc {
                    color: #366AFF;
                    margin-left: 8px;
                    .el-popover {
                        z-index: 999999;
                        background-color: #333333 !important;
                        color: #ffffff !important;
                    }
                }
            }

            li {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: 12px 0px;

                .el-radio-group {
                    flex: 1;
                    display: flex;
                }

                .entity-type-content {
                    height: 38px;
                    background: #FBFCFD;
                    padding: 0 12px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px dashed #E0E6F7;
                    ;
                }

                .title {
                    text-align: left;
                    flex: none;
                    width: 100px;
                    margin-bottom: 8px;

                    .title-need {
                        color: orangered;
                    }
                }

                .titleDes {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    em {
                        text-align: right;
                        margin-right: 45px;
                        font-size: 12px;
                        color: red;
                    }
                }

                .el-select {
                    flex: 1;
                }

                .entity-list {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    border-radius: 5px;
                    font-family: 'Arial Normal', 'Arial';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    text-align: left;

                    .entity-header {
                        display: flex;
                        height: 38px;
                        line-height: 38px;
                        padding: 0 12px;
                        background: #F6F8FD;
                        font-weight: 700;

                        .l {
                            flex: none;
                            width: 200px;
                        }

                        .r {
                            flex: auto;
                        }
                    }

                    .entity-rows {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin: 10px;
                        min-height: 43px;
                        // max-height: 258px;
                        // overflow: hidden;
                        // overflow-y: auto;


                        .entity-row {
                            display: flex;
                            align-content: center;
                            min-height: 38px;
                            margin-bottom: 3px;
                            position: relative;

                            .l {
                                flex: none;
                                width: 192px;
                                height: 33px;
                                border-width: 1px;
                                border-radius: 5px;
                            }

                            .r {
                                color: #366AFF;
                                margin-left: 10px;
                                padding: 0;
                                height: 33px;
                                flex: auto;
                                width: calc(100% - 20px);
                            }
                            span{
                                width: calc(100% - 20px);
                            }
                            .delete-entity {
                                width: auto;
                                color: #366AFF;
                                font-size: 16px;
                                line-height: 33px;
                                margin-left: 12px;
                                cursor: pointer;
                            }
                            .keyword-recommend {
                                position: relative;
                                // flex:1;
                            }
                        }
                    }

                    .add-button {
                        font-size: 30px;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;

                        span {
                            width: 31px;
                            height: 31px;
                            line-height: 31px;
                            background: #FFFFFF;
                            border: 1px solid #D5E0FF;
                            border-radius: 50%;
                            text-align: center;
                            color: #366AFF;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

}
</style>