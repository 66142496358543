<template>
    <div id="ChatFlowOld" v-loading="loading">
        <el-container class="config-drawer">
            <el-drawer
                id="ans-content"
                title
                :visible.sync="drawer"
                :wrapperClosable="false"
                :direction="direction"
                :modal="isPart == 0"
                :size="1000"
            >
                <!-- 组件选列表 -->
                <div v-if="isPart == 0" class="grid-content components">
                    <el-tabs :tab-position="tabPosition">
                        <el-tab-pane
                            v-for="(item, index) in componentsTypes"
                            :key="index"
                        >
                            <span slot="label">
                                <i :class="item.icon"></i>
                            </span>
                            <div class="component-tab-con">
                                <el-button
                                    v-show="
                                        showComponent(
                                            cell.id,
                                            cell.disable,
                                            nodeId
                                        )
                                    "
                                    :style="{opacity: ((cell.id == 'action_webhook') && botVersion == 2) ? 0.5: 1}"
                                    @click="addComponents(cell.id)"
                                    v-for="(cell, ind) in item.components"
                                    :key="ind"
                                    type="primary"
                                    plain
                                >
                                    <i :class="cell.icon"></i>
                                    <p>{{ cell.name }}</p>
                                </el-button>
                                <!-- <div class="ex-button">
                                    <el-button
                                        v-show="
                                            showComponent(
                                                cell.id,
                                                cell.disable,
                                                nodeId
                                            ) &&
                                            cell.id == 'answer_rich_text' &&
                                            index == 0
                                        "
                                        @click="addComponents(cell.id)"
                                        v-for="(cell, ind) in item.components"
                                        :key="ind"
                                        type="primary"
                                        plain
                                    >
                                        <i :class="cell.icon"></i>
                                        <p>{{ cell.name }}</p>
                                        <span class="tip">暂不支持小程序</span>
                                    </el-button>
                                </div> -->
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 流程配置 -->
                <div class="grid-content interaction-config">
                    <div class="interaction-config-header">
                        <div class="header-wrapper">
                            <div style="text-align: left;" v-if="isPart == 1" class="interaction-name">
                                {{activeNodeName}}
                            </div>
                            <div
                                style="text-align: right; width: 100%"
                                v-if="isPart == 1"
                            >
                                <span style="color: #E6A23C">注：该页面修改对话流程不会生效！</span>
                                <el-button
                                    size="small"
                                    slot="reference"
                                    @click="closePartDrewer"
                                    >关 闭</el-button
                                >
                            </div>
                            <el-input
                                v-if="isPart == 0"
                                type="text"
                                v-model="activeNodeName"
                                spellcheck="false"
                                class="interaction-name"
                                prefix-icon="el-icon-edit"
                                maxlength="20"
                            />

                            <div v-if="isPart == 0" class="interaction-save">
                                <el-popover
                                    placement="left"
                                    width="260"
                                    trigger="manual"
                                    v-model="cancelMoveNodeSaveVisible"
                                >
                                    <p>
                                        建议先保存再变更节点关系，否则会导致已配置的内容丢失！
                                    </p>
                                    <div
                                        style="
                                            text-align: right;
                                            margin: 0;
                                            padding: 10px;
                                        "
                                    >
                                        <el-button
                                            size="mini"
                                            type="text"
                                            @click="
                                                cancelMoveNodeSaveVisible = false
                                            "
                                            >取 消</el-button
                                        >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            plain
                                            @click="showChangeNoderelation"
                                            >直接变更</el-button
                                        >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            @click="saveAndChangeNoderelation"
                                            >保存并变更</el-button
                                        >
                                    </div>
                                    <el-button
                                        v-if="
                                            !isStartOrEnd &&
                                            !isInitNode &&
                                            !(
                                                (nodeId == 'start' &&
                                                    !RP_Visible(
                                                        'OPEN_PROCESS_START'
                                                    )) ||
                                                (nodeId == 'end' &&
                                                    !RP_Visible(
                                                        'OPEN_PROCESS_END'
                                                    )) ||
                                                (!isStartOrEnd &&
                                                    !RP_Visible(
                                                        'OPEN_PROCESS_OTHER'
                                                    ) &&
                                                    isOtherSkill) ||
                                                (!isStartOrEnd &&
                                                    !isOtherSkill &&
                                                    !RP_Visible(
                                                        'OPEN_PROCESS_CUSTOMIZE'
                                                    ))
                                            )
                                        "
                                        @click="checkActionListChangedMoveNode"
                                        slot="reference"
                                        size="small"
                                        type="primary"
                                        >变更节点关系</el-button
                                    >
                                </el-popover>
                                <el-popover
                                    placement="top"
                                    width="160"
                                    trigger="manual"
                                    v-model="cancelSaveVisible"
                                >
                                    <p>
                                        配置已更改，当前没有保存，是否继续关闭？
                                    </p>
                                    <div
                                        style="
                                            text-align: right;
                                            margin: 0;
                                            padding: 10px;
                                        "
                                    >
                                        <el-button
                                            size="mini"
                                            type="text"
                                            @click="cancelSaveVisible = false"
                                            >取 消</el-button
                                        >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            @click="cancelSaveConfirm"
                                            >确 定</el-button
                                        >
                                    </div>
                                    <el-button
                                        size="small"
                                        slot="reference"
                                        @click="checkActionListChanged"
                                        >关 闭</el-button
                                    >
                                </el-popover>

                                <el-button
                                    @click="saveChatFlow"
                                    size="small"
                                    v-if="
                                        !(
                                            (nodeId == 'start' &&
                                                !RP_Visible(
                                                    'OPEN_PROCESS_START'
                                                )) ||
                                            (nodeId == 'end' &&
                                                !RP_Visible(
                                                    'OPEN_PROCESS_END'
                                                )) ||
                                            (!isStartOrEnd &&
                                                !RP_Visible(
                                                    'OPEN_PROCESS_OTHER'
                                                ) &&
                                                isOtherSkill) ||
                                            (!isStartOrEnd &&
                                                !isOtherSkill &&
                                                !RP_Visible(
                                                    'OPEN_PROCESS_CUSTOMIZE'
                                                ))
                                        )
                                    "
                                    type="primary"
                                    >保 存</el-button
                                >
                            </div>
                        </div>
                        <div
                            v-show="
                                (nodeId == 'start' &&
                                    !RP_Visible('OPEN_PROCESS_START')) ||
                                (nodeId == 'end' &&
                                    !RP_Visible('OPEN_PROCESS_END')) ||
                                (!isStartOrEnd &&
                                    !RP_Visible('OPEN_PROCESS_OTHER') &&
                                    isOtherSkill) ||
                                (!isStartOrEnd &&
                                    !isOtherSkill &&
                                    !RP_Visible('OPEN_PROCESS_CUSTOMIZE'))
                            "
                            class="node-rp-tips"
                        >
                            <span class="el-icon-warning"></span>
                            <span>暂无编辑/保存当前节点权限</span>
                        </div>
                        <div class="interaction-content" ref="grContent">
                            <div
                                class="response-text"
                                v-for="(unit, index) in actionList"
                                :key="index"
                            >
                                <div class="respose-text-title">
                                    <div class="type-text">
                                        <span v-if="unit.type === 'action_mail'"
                                            >发送邮件</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'answer_card_template'
                                            "
                                            >卡片模版</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_set_entity'
                                            "
                                            >设置实体</span
                                        >
                                        <span v-if="unit.type === 'answer_text'"
                                            >文本</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'answer_rich_text'
                                            "
                                            >富文本</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'action_webhook'
                                            "
                                            >webHook</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'answer_rich_text_pro'
                                            "
                                            >消息卡片</span
                                        >
                                        <span
                                            @click="infoCardDesVisible = true"
                                            class="info_card_des"
                                            v-if="
                                                unit.type ===
                                                'answer_rich_text_pro'
                                            "
                                            >(使用说明)</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'answer_welcometext'
                                            "
                                            >欢迎语</span
                                        >
                                        <span v-if="unit.type === 'action_jump'"
                                            >跳转</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'action_question'
                                            "
                                            >反问</span
                                        >
                                        <span
                                            v-if="unit.type === 'answer_image'"
                                            >图片</span
                                        >
                                        <span
                                            v-if="unit.type === 'answer_video'"
                                            >视频</span
                                        >
                                        <span
                                            v-if="unit.type === 'answer_radio'"
                                            >点选</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_satisfaction'
                                            "
                                            >满意度反馈</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'action_transfer'
                                            "
                                            >转人工</span
                                        >
                                        <span v-if="unit.type === 'action_form'"
                                            >提交表单</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_confirm_value'
                                            "
                                            >确认值</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_alert_iframe'
                                            "
                                            >弹窗</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'action_feedback'
                                            "
                                            >答案反馈</span
                                        >
                                        <span
                                            v-if="
                                                unit.type === 'action_enforce'
                                            "
                                            >强制跳转</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_random_jump'
                                            "
                                            >随机跳转</span
                                        >

                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_fast_select'
                                            "
                                            >快捷选择</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'action_transfer_2_ewechat'
                                            "
                                            >转企微</span
                                        >
                                        <span
                                            v-if="
                                                unit.type ===
                                                'answer_intellect_guide'
                                            "
                                            >智能引导</span
                                        >
                                    </div>
                                    <el-button
                                        v-if="
                                            unit.conditionList.length === 0 &&
                                            !isStartOrEnd
                                        "
                                        @click="
                                            conditionEcho('addAnd', [
                                                index,
                                                0,
                                                0,
                                            ])
                                        "
                                        size="mini"
                                        type="primary"
                                        >添加条件</el-button
                                    >
                                    <el-button
                                        v-if="
                                            unit.effectiveTime.range ==
                                            'PERMANENT'
                                        "
                                        @click="addEffectiveTime(index, unit)"
                                        size="mini"
                                        type="primary"
                                        >添加生效时间</el-button
                                    >
                                    <div class="unit-handle">
                                        <el-tooltip
                                            content="上移"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                v-show="index !== 0"
                                                @click="handleUnit('up', index)"
                                                class="el-icon-top"
                                            ></em>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="下移"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                v-show="
                                                    index !==
                                                    actionList.length - 1
                                                "
                                                @click="
                                                    handleUnit('down', index)
                                                "
                                                class="el-icon-bottom"
                                            ></em>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="置顶"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                v-show="index !== 0"
                                                @click="
                                                    handleUnit('top', index)
                                                "
                                                class="el-icon-upload2"
                                            ></em>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="置底"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                v-show="
                                                    index !==
                                                    actionList.length - 1
                                                "
                                                @click="
                                                    handleUnit('bottom', index)
                                                "
                                                class="el-icon-download"
                                            ></em>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="拖动"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                class="el-icon-rank icon-handle"
                                            ></em>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="删除"
                                            placement="bottom"
                                            effect="light"
                                        >
                                            <em
                                                @click="
                                                    handleUnit('delete', index)
                                                "
                                                class="el-icon-delete"
                                            ></em>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="condition-outer">
                                    <div class="condition-row text-a-l">
                                        <div
                                            v-if="
                                                unit.effectiveTime.range !=
                                                'PERMANENT'
                                            "
                                            class="condition-cell inlineBlock"
                                        >
                                            <el-tag
                                                v-if="
                                                    unit.effectiveTime.range ===
                                                    'TIME'
                                                "
                                                @click="
                                                    addEffectiveTime(
                                                        index,
                                                        unit
                                                    )
                                                "
                                                size="medium"
                                                type="warning"
                                                >生效时间:{{
                                                    effectiveTimeFormatDateTime(
                                                        unit.effectiveTime
                                                            .startTime
                                                    ).split(" ")[1]
                                                }}
                                                -
                                                {{
                                                    effectiveTimeFormatDateTime(
                                                        unit.effectiveTime
                                                            .endTime
                                                    ).split(" ")[1]
                                                }}
                                                <span> 重复：</span>
                                                <span
                                                    v-for="(
                                                        wekCell, wekCellIndex
                                                    ) in formatWekList(
                                                        unit.effectiveTime
                                                            .repeat
                                                    )"
                                                    :key="wekCell"
                                                    >{{ wekCell
                                                    }}{{
                                                        wekCellIndex ==
                                                            unit.effectiveTime
                                                                .repeat.length -
                                                                1 ||
                                                        unit.effectiveTime
                                                            .repeat.length ==
                                                            7 ||
                                                        unit.effectiveTime
                                                            .repeat.length == 0
                                                            ? ""
                                                            : ","
                                                    }}</span
                                                >
                                            </el-tag>
                                            <el-tag
                                                v-if="
                                                    unit.effectiveTime.range ===
                                                    'DATETIME'
                                                "
                                                @click="
                                                    addEffectiveTime(
                                                        index,
                                                        unit
                                                    )
                                                "
                                                size="medium"
                                                type="warning"
                                                >生效时间:{{
                                                    effectiveTimeFormatDateTime(
                                                        unit.effectiveTime
                                                            .startTime
                                                    )
                                                }}
                                                -
                                                {{
                                                    effectiveTimeFormatDateTime(
                                                        unit.effectiveTime
                                                            .endTime
                                                    )
                                                }}
                                            </el-tag>
                                            <el-tag
                                                v-if="
                                                    unit.effectiveTime.range ===
                                                    'EFFECTIVE_TIME'
                                                "
                                                @click="
                                                    addEffectiveTime(
                                                        index,
                                                        unit
                                                    )
                                                "
                                                size="medium"
                                                type="warning"
                                                >生效时间:{{
                                                    effectiveTimeFormatDateTime(
                                                        unit.effectiveTime
                                                            .startTime
                                                    )
                                                }}
                                                - 不限
                                            </el-tag>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="condition-outer"
                                    v-for="(cell, ind) in unit.conditionList"
                                    :key="ind"
                                >
                                    <div
                                        v-if="ind !== 0"
                                        class="text-a-l ralation-text-color"
                                    >
                                        或者
                                    </div>
                                    <div class="condition-row text-a-l">
                                        <div
                                            v-for="(
                                                miniUnit, miniuIndex
                                            ) in cell"
                                            :key="miniuIndex"
                                            class="condition-cell inlineBlock"
                                        >
                                            <el-tag
                                                v-if="
                                                    miniUnit.scope === 'ENTITY'
                                                "
                                                @click="
                                                    conditionEcho('edit', [
                                                        index,
                                                        ind,
                                                        miniuIndex,
                                                    ])
                                                "
                                                @close="
                                                    deleteCondition(
                                                        index,
                                                        ind,
                                                        miniuIndex
                                                    )
                                                "
                                                closable
                                                size="medium"
                                                >[ {{ miniUnit.variable }} ]
                                                {{ miniUnit.ext.relationName }}
                                                [ {{ miniUnit.value }} ]</el-tag
                                            >
                                            <el-tag
                                                v-if="
                                                    miniUnit.scope === 'INTENT'
                                                "
                                                @click="
                                                    conditionEcho('edit', [
                                                        index,
                                                        ind,
                                                        miniuIndex,
                                                    ])
                                                "
                                                @close="
                                                    deleteCondition(
                                                        index,
                                                        ind,
                                                        miniuIndex
                                                    )
                                                "
                                                closable
                                                size="medium"
                                                >[ {{ miniUnit.variable }} ]
                                                {{ miniUnit.ext.relationName }}
                                                [
                                                {{
                                                    miniUnit.ext
                                                        .intentTypeNameArr[1]
                                                }}
                                                ]</el-tag
                                            >
                                            <el-tag
                                                v-if="
                                                    miniUnit.scope ===
                                                    'USER_WAITING_TIME'
                                                "
                                                @click="
                                                    conditionEcho('edit', [
                                                        index,
                                                        ind,
                                                        miniuIndex,
                                                    ])
                                                "
                                                @close="
                                                    deleteCondition(
                                                        index,
                                                        ind,
                                                        miniuIndex
                                                    )
                                                "
                                                closable
                                                size="medium"
                                                >[ 用户静默时间 ]
                                                {{ miniUnit.ext.relationName }}
                                                [
                                                {{
                                                    miniUnit.ext
                                                        .silentTimeValue +
                                                    miniUnit.ext
                                                        .silentTimeUnitName
                                                }}
                                                ]</el-tag
                                            >
                                            <el-tag
                                                v-if="
                                                    miniUnit.scope ===
                                                    'WEB_HOOK_RESPONSE'
                                                "
                                                @click="
                                                    conditionEcho('edit', [
                                                        index,
                                                        ind,
                                                        miniuIndex,
                                                    ])
                                                "
                                                @close="
                                                    deleteCondition(
                                                        index,
                                                        ind,
                                                        miniuIndex
                                                    )
                                                "
                                                closable
                                                size="medium"
                                                >[{{ miniUnit.ext.response }}]
                                                {{ miniUnit.ext.relationName }}
                                                [ {{ miniUnit.value }} ]</el-tag
                                            >
                                            <span
                                                class="
                                                    relation-text
                                                    ralation-text-color
                                                "
                                                v-if="
                                                    miniuIndex !=
                                                    cell.length - 1
                                                "
                                                >并且</span
                                            >
                                        </div>
                                        <el-button
                                            @click="
                                                conditionEcho('addAnd', [
                                                    index,
                                                    ind,
                                                ])
                                            "
                                            size="mini"
                                            type="primary"
                                            >添加并且条件</el-button
                                        >
                                    </div>

                                    <div class="text-a-l margin-t-8">
                                        <el-button
                                            v-if="
                                                ind ==
                                                unit.conditionList.length - 1
                                            "
                                            @click="
                                                conditionEcho('addOr', [index])
                                            "
                                            size="mini"
                                            type="primary"
                                            >添加或者条件</el-button
                                        >
                                    </div>
                                </div>
                                <div
                                    class="rich-text-content"
                                    v-if="unit.type === 'answer_rich_text'"
                                >
                                    <!-- <vue-ueditor-wrap
                                        v-model="unit.content.html"
                                        :config="editorConfig"
                                    ></vue-ueditor-wrap> -->
                                    <UEditorRichText :unit="unit"></UEditorRichText>
                                    <cRecommend
                                        :unit="unit"
                                        :intentSAkillTypeOptionsNoChildren="
                                            intentSAkillTypeOptionsNoChildren
                                        "
                                    />
                                </div>
                                <!------webhook答案-------->
                                <div
                                    class="respose-text-content tal webhook"
                                    v-if="unit.type === 'action_webhook'"
                                >
                                    <el-tabs type="border-card">
                                        <el-tab-pane
                                            label="一、配置WebHook参数"
                                        >
                                            <div class="tab-desc">
                                                <div class="title">
                                                    配置说明
                                                </div>
                                                <div class="desc">
                                                    WebHook组件是对话机器人和业务系统的连接器，配置前请确认在WebHook主菜单中已经将相应的WebHook方法配置好，请在下方设置执行WebHook所需参数的获取方法。
                                                </div>
                                            </div>
                                            <div class="region-name">
                                                <div class="cell-title">
                                                    1.选择webhook
                                                </div>
                                            </div>
                                            <div class="cell-content">
                                                <el-select
                                                    v-model="
                                                        unit.content.webHookId
                                                    "
                                                    size="small"
                                                    @change="
                                                        showWebhookDetail(
                                                            index,
                                                            unit.content
                                                                .webHookId
                                                        )
                                                    "
                                                    no-data-text="暂无WebHook，请先添加WebHook"
                                                    placeholder="请选择"
                                                >
                                                    <el-option
                                                        v-for="item in webhookApiList"
                                                        :key="item.id"
                                                        :label="item.label"
                                                        :value="item.id"
                                                    ></el-option>
                                                </el-select>
                                            </div>
                                            <div
                                                class="region-name"
                                                v-if="
                                                    unit.content.webHookId != ''
                                                "
                                            >
                                                <div class="cell-title">
                                                    2.配置参数
                                                </div>
                                            </div>
                                            
                                            <div
                                                class="param"
                                                v-if="
                                                    unit.content.webHookId != ''
                                                "
                                            >
                                                <div
                                                    class="param-list"
                                                    v-if="
                                                        unit.content.params
                                                            .length > 0
                                                    "
                                                >
                                                    <div
                                                        v-for="(
                                                            paramCell,
                                                            paramCellIndex
                                                        ) in unit.content
                                                            .params"
                                                        :key="paramCell.key"
                                                        class="param-cell"
                                                    >
                                                        <div
                                                            class="
                                                                first-line
                                                                background-blue
                                                            "
                                                        >
                                                            <div
                                                                class="
                                                                    title
                                                                    color-white
                                                                "
                                                            >
                                                                参数名称
                                                            </div>
                                                            <div
                                                                class="
                                                                    value
                                                                    color-white
                                                                "
                                                            >
                                                                {{
                                                                    paramCell.key
                                                                }}
                                                            </div>
                                                            <div
                                                                class="
                                                                    title
                                                                    color-white
                                                                "
                                                            >
                                                                默认值
                                                            </div>
                                                            <div
                                                                style="
                                                                    white-space: nowrap;
                                                                    text-overflow: ellipsis;
                                                                    max-width: 380px;
                                                                "
                                                                class="
                                                                    value
                                                                    color-white
                                                                "
                                                            >
                                                                {{
                                                                    paramCell.defaultValue
                                                                }}
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="first-line">
                                                            <div class="title">
                                                                获取途径
                                                            </div>
                                                            <div class="value">
                                                                <div
                                                                    class="
                                                                        param-top-content-cell
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            param-title-left
                                                                        "
                                                                    >
                                                                        <el-select
                                                                            @change="
                                                                                paramSetWChange(
                                                                                    $event,
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                            v-model="
                                                                                paramCell.type
                                                                            "
                                                                            size="small"
                                                                            placeholder="请选择"
                                                                        >
                                                                            <el-option
                                                                                v-for="item in [
                                                                                    {
                                                                                        label: '关联实体',
                                                                                        value: 'ENTITY',
                                                                                    },
                                                                                    {
                                                                                        label: 'WebHook返回值',
                                                                                        value: 'WEB_HOOK_RESPONSE',
                                                                                    },
                                                                                ]"
                                                                                :key="
                                                                                    item.value
                                                                                "
                                                                                :label="
                                                                                    item.label
                                                                                "
                                                                                :value="
                                                                                    item.value
                                                                                "
                                                                            ></el-option>
                                                                        </el-select>
                                                                    </div>
                                                                    <div
                                                                        v-if="
                                                                            paramCell.type ===
                                                                            'WEB_HOOK_RESPONSE'
                                                                        "
                                                                        class="
                                                                            param-title-mid
                                                                        "
                                                                    >
                                                                        <el-cascader
                                                                            size="small"
                                                                            v-model="
                                                                                paramCell.webHookShowInfo
                                                                            "
                                                                            :options="
                                                                                expBaseCascList
                                                                            "
                                                                            :props="{
                                                                                expandTrigger:
                                                                                    'hover',
                                                                                label: 'name',
                                                                            }"
                                                                        ></el-cascader>
                                                                    </div>
                                                                    <div
                                                                        v-if="
                                                                            paramCell.type ===
                                                                            'ENTITY'
                                                                        "
                                                                        class="
                                                                            param-title-mid
                                                                        "
                                                                    >
                                                                        <el-cascader
                                                                            placeholder="请选择实体"
                                                                            size="small"
                                                                            v-if="
                                                                                isStartOrEnd
                                                                            "
                                                                            v-model="
                                                                                paramCell.entities
                                                                            "
                                                                            :options="
                                                                                entityOptionsBot
                                                                            "
                                                                            @change="
                                                                                webhookHandleEntityChange
                                                                            "
                                                                            filterable
                                                                        ></el-cascader>
                                                                        <el-cascader
                                                                            placeholder="请选择实体"
                                                                            v-if="
                                                                                !isStartOrEnd
                                                                            "
                                                                            size="small"
                                                                            v-model="
                                                                                paramCell.entities
                                                                            "
                                                                            :options="
                                                                                entityOptions
                                                                            "
                                                                            @change="
                                                                                webhookHandleEntityChange
                                                                            "
                                                                            filterable
                                                                        ></el-cascader>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="first-line">
                                                            <div class="title">
                                                                未获取到
                                                            </div>
                                                            <div class="value">
                                                                <span
                                                                    class="
                                                                        param-title-left
                                                                    "
                                                                >
                                                                    <el-select
                                                                        style="
                                                                            width: 150px;
                                                                        "
                                                                        v-if="
                                                                            paramCell.value !=
                                                                            ''
                                                                        "
                                                                        v-model="
                                                                            paramCell
                                                                                .actionNoParam
                                                                                .type
                                                                        "
                                                                        size="small"
                                                                        placeholder="请选择"
                                                                        @change="
                                                                            actionNoParamChange(
                                                                                $event,
                                                                                index,
                                                                                paramCellIndex
                                                                            )
                                                                        "
                                                                    >
                                                                        <el-option
                                                                            v-for="item in noGetParamOptions"
                                                                            :key="
                                                                                item.id
                                                                            "
                                                                            :label="
                                                                                item.name
                                                                            "
                                                                            :value="
                                                                                item.id
                                                                            "
                                                                        ></el-option>
                                                                    </el-select>
                                                                    <el-select
                                                                        v-if="
                                                                            paramCell.value ==
                                                                            ''
                                                                        "
                                                                        v-model="
                                                                            paramCell
                                                                                .actionNoParam
                                                                                .type
                                                                        "
                                                                        size="small"
                                                                        style="
                                                                            width: 150px;
                                                                        "
                                                                        placeholder="请选择"
                                                                        @change="
                                                                            actionNoParamChange(
                                                                                $event,
                                                                                index,
                                                                                paramCellIndex
                                                                            )
                                                                        "
                                                                    >
                                                                        <el-option
                                                                            v-for="item in noGetParamOptionsRequire"
                                                                            :key="
                                                                                item.id
                                                                            "
                                                                            :label="
                                                                                item.name
                                                                            "
                                                                            :value="
                                                                                item.id
                                                                            "
                                                                        ></el-option>
                                                                    </el-select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="sub-config">
                                                            <div
                                                                class="
                                                                    sub-config-title
                                                                "
                                                            >
                                                                组件配置
                                                            </div>
                                                            <div
                                                                class="
                                                                    sub-config-content
                                                                "
                                                            >
                                                                <div
                                                                    class="
                                                                        param-title-last
                                                                    "
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            paramCell
                                                                                .actionNoParam
                                                                                .type ==
                                                                            'QUESTION'
                                                                        "
                                                                        class="
                                                                            param-title-right
                                                                        "
                                                                        >询问次数</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="
                                                                        param-title-last
                                                                    "
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            paramCell
                                                                                .actionNoParam
                                                                                .type ==
                                                                            'QUESTION'
                                                                        "
                                                                        class="
                                                                            param-title-right
                                                                        "
                                                                    >
                                                                        <el-select
                                                                            v-model="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .questionContent
                                                                                    .questionNum
                                                                            "
                                                                            size="small"
                                                                            placeholder="请选择"
                                                                            @change="
                                                                                questionNumChange(
                                                                                    $event,
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                        >
                                                                            <el-option
                                                                                v-for="item in noGetParamQNumber"
                                                                                :key="
                                                                                    item.id
                                                                                "
                                                                                :label="
                                                                                    item.name
                                                                                "
                                                                                :value="
                                                                                    item.id
                                                                                "
                                                                            ></el-option>
                                                                        </el-select>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'QUESTION'
                                                                    "
                                                                    class="
                                                                        cell-title
                                                                    "
                                                                >
                                                                    反问
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'QUESTION'
                                                                    "
                                                                    class="
                                                                        webhook-ask
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            rhetorical-question-input
                                                                        "
                                                                    >
                                                                        <div
                                                                            v-for="(
                                                                                textCell,
                                                                                textCellInd
                                                                            ) in paramCell
                                                                                .actionNoParam
                                                                                .questionContent
                                                                                .questions"
                                                                            :key="
                                                                                textCellInd
                                                                            "
                                                                            class="
                                                                                rhetorical-question-input-cell
                                                                            "
                                                                        >
                                                                            <el-input
                                                                                :autosize="{
                                                                                    minRows: 2,
                                                                                    maxRows: 20,
                                                                                }"
                                                                                type="textarea"
                                                                                v-model="
                                                                                    unit
                                                                                        .content
                                                                                        .params[
                                                                                        paramCellIndex
                                                                                    ]
                                                                                        .actionNoParam
                                                                                        .questionContent
                                                                                        .questions[
                                                                                        textCellInd
                                                                                    ]
                                                                                "
                                                                                maxlength="2000"
                                                                                placeholder="请输入文字答案"
                                                                                @input="
                                                                                    webhookQQtextCellChange(
                                                                                        $event,
                                                                                        index,
                                                                                        paramCellIndex,
                                                                                        textCellInd
                                                                                    )
                                                                                "
                                                                            ></el-input>
                                                                            <span
                                                                                v-if="
                                                                                    textCellInd ==
                                                                                    0
                                                                                "
                                                                                class="
                                                                                    el-icon-circle-plus
                                                                                    handle-icon
                                                                                "
                                                                                @click="
                                                                                    addWebhookQText(
                                                                                        index,
                                                                                        paramCellIndex,
                                                                                        textCellInd
                                                                                    )
                                                                                "
                                                                            ></span>
                                                                            <span
                                                                                v-if="
                                                                                    textCellInd !==
                                                                                    0
                                                                                "
                                                                                class="
                                                                                    el-icon-circle-close
                                                                                    handle-icon
                                                                                    handle-icon-delete
                                                                                "
                                                                                @click="
                                                                                    deleteWebhookQText(
                                                                                        index,
                                                                                        paramCellIndex,
                                                                                        textCellInd
                                                                                    )
                                                                                "
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'RADIO'
                                                                    "
                                                                    class="
                                                                        cell-title
                                                                    "
                                                                >
                                                                    点选
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'RADIO'
                                                                    "
                                                                    class="
                                                                        respose-text-content
                                                                        tal
                                                                    "
                                                                >
                                                                    <div
                                                                        class="
                                                                            cell-content
                                                                        "
                                                                    >
                                                                        <el-input
                                                                            type="textarea"
                                                                            autosize
                                                                            placeholder="请输入点选介绍，如：“您可能更想问：”"
                                                                            @change="
                                                                                webhookactionNoParamDescriptionChange(
                                                                                    $event,
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                            v-model="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .radioContent
                                                                                    .description
                                                                            "
                                                                        ></el-input>
                                                                    </div>
                                                                    <div
                                                                        class="
                                                                            cell-content
                                                                            answer-radio-box
                                                                        "
                                                                    >
                                                                        <el-tag
                                                                            :key="
                                                                                radioTextIndex
                                                                            "
                                                                            v-for="(
                                                                                tag,
                                                                                radioTextIndex
                                                                            ) in unit
                                                                                .content
                                                                                .params[
                                                                                paramCellIndex
                                                                            ]
                                                                                .actionNoParam
                                                                                .radioContent
                                                                                .options"
                                                                            closable
                                                                            effect="dark"
                                                                            :disable-transitions="
                                                                                false
                                                                            "
                                                                            @close="
                                                                                webhookDeleteAnswerRadio(
                                                                                    index,
                                                                                    paramCellIndex,
                                                                                    radioTextIndex
                                                                                )
                                                                            "
                                                                            @click="
                                                                                webhookShowAnswerRadioEdite(
                                                                                    index,
                                                                                    paramCellIndex,
                                                                                    radioTextIndex
                                                                                )
                                                                            "
                                                                            >{{
                                                                                tag.name
                                                                            }}</el-tag
                                                                        >
                                                                        <el-button
                                                                            size="mini"
                                                                            @click="
                                                                                webhookAddAnswerRadio(
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                            type="primary"
                                                                            plain
                                                                            round
                                                                            >添加点选</el-button
                                                                        >
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'GOTO'
                                                                    "
                                                                    class="
                                                                        cell-title
                                                                    "
                                                                >
                                                                    跳转
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        paramCell
                                                                            .actionNoParam
                                                                            .type ==
                                                                        'GOTO'
                                                                    "
                                                                    class="
                                                                        jump-cell
                                                                    "
                                                                >
                                                                    <el-select
                                                                        style="
                                                                            width: 115px;
                                                                        "
                                                                        v-model="
                                                                            paramCell
                                                                                .actionNoParam
                                                                                .gotoContent
                                                                                .type
                                                                        "
                                                                        @change="
                                                                            webhookJumpTypeValueChange(
                                                                                $event,
                                                                                index,
                                                                                paramCellIndex
                                                                            )
                                                                        "
                                                                        size="small"
                                                                        placeholder="请选择"
                                                                    >
                                                                        <el-option
                                                                            v-for="item in jumpTypeOptions"
                                                                            :key="
                                                                                item.value
                                                                            "
                                                                            :label="
                                                                                item.label
                                                                            "
                                                                            :value="
                                                                                item.value
                                                                            "
                                                                        ></el-option>
                                                                    </el-select>
                                                                    <div
                                                                        class="
                                                                            right-select
                                                                        "
                                                                    >
                                                                        <el-select
                                                                            v-show="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .gotoContent
                                                                                    .type ===
                                                                                'NODE'
                                                                            "
                                                                            v-model="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .gotoContent
                                                                                    .value
                                                                            "
                                                                            @change="
                                                                                webhookJumpNodeSelectChange(
                                                                                    $event,
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                            :popper-append-to-body="
                                                                                false
                                                                            "
                                                                            filterable
                                                                            size="small"
                                                                            placeholder="请输入选择名称"
                                                                        >
                                                                            <el-option
                                                                                v-for="item in nodeOptions"
                                                                                :popper-class="
                                                                                    'pop_my' +
                                                                                    item.classPop +
                                                                                    item.parentNum
                                                                                "
                                                                                :key="
                                                                                    item.id
                                                                                "
                                                                                :label="
                                                                                    item.name
                                                                                "
                                                                                :value="
                                                                                    item.id
                                                                                "
                                                                            ></el-option>
                                                                        </el-select>
                                                                        <el-cascader
                                                                            v-show="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .gotoContent
                                                                                    .type ===
                                                                                'INTENT'
                                                                            "
                                                                            @change="
                                                                                webhookJumpIntentSelectChange(
                                                                                    $event,
                                                                                    index,
                                                                                    paramCellIndex
                                                                                )
                                                                            "
                                                                            v-model="
                                                                                paramCell
                                                                                    .actionNoParam
                                                                                    .gotoContent
                                                                                    .value
                                                                            "
                                                                            size="small"
                                                                            :options="
                                                                                intentTree4Radio
                                                                            "
                                                                            :props="{
                                                                                expandTrigger:
                                                                                    'hover',
                                                                                emitPath: false,
                                                                                label: 'name',
                                                                                value: 'id',
                                                                            }"
                                                                        ></el-cascader>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="二、设置异常情况">
                                            <div class="tab-desc">
                                                <div class="title">
                                                    配置说明
                                                </div>
                                                <div class="desc">
                                                    在调用WebHook过程中，可能一些特殊原因会导致无法执行，在对话中需给用户一个反馈，请按照下方进行相应设置。
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    unit.content.webHookId !=
                                                        '' &&
                                                    unit.content.response &&
                                                    unit.content.response.length
                                                "
                                                class="split-line"
                                            ></div>
                                            <div
                                                v-if="
                                                    unit.content.webHookId !=
                                                        '' &&
                                                    unit.content.response &&
                                                    unit.content.response.length
                                                "
                                                style="
                                                    margin-top: 16px;
                                                    margin-bottom: 16px;
                                                "
                                            >
                                                <div class="jump-cell">
                                                    <el-select
                                                        v-model="
                                                            unit.content
                                                                .failedAction
                                                                .scope
                                                        "
                                                        @change="
                                                            webhookfailedActionTypeValueChange(
                                                                $event,
                                                                index
                                                            )
                                                        "
                                                        size="small"
                                                        placeholder="请选择"
                                                    >
                                                        <el-option
                                                            v-for="item in jumpTypeOptions"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value"
                                                        ></el-option>
                                                    </el-select>
                                                    <div class="right-select">
                                                        <el-select
                                                            v-show="
                                                                unit.content
                                                                    .failedAction
                                                                    .scope ===
                                                                'NODE'
                                                            "
                                                            v-model="
                                                                unit.content
                                                                    .failedAction
                                                                    .value
                                                            "
                                                            @change="
                                                                webhookfailedActionNodeSelectChange(
                                                                    $event,
                                                                    index
                                                                )
                                                            "
                                                            :popper-append-to-body="
                                                                false
                                                            "
                                                            filterable
                                                            size="small"
                                                            placeholder="请输入选择名称"
                                                        >
                                                            <el-option
                                                                v-for="item in nodeOptions"
                                                                :popper-class="
                                                                    'pop_my' +
                                                                    item.classPop +
                                                                    item.parentNum
                                                                "
                                                                :key="item.id"
                                                                :label="
                                                                    item.name
                                                                "
                                                                :value="item.id"
                                                            ></el-option>
                                                        </el-select>
                                                        <el-cascader
                                                            v-show="
                                                                unit.content
                                                                    .failedAction
                                                                    .scope ===
                                                                'INTENT'
                                                            "
                                                            v-model="
                                                                unit.content
                                                                    .failedAction
                                                                    .value
                                                            "
                                                            size="small"
                                                            :options="
                                                                intentTree4Radio
                                                            "
                                                            :props="{
                                                                expandTrigger:
                                                                    'hover',
                                                                emitPath: false,
                                                                label: 'name',
                                                                value: 'id',
                                                            }"
                                                        ></el-cascader>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="三、查看返回值">
                                            <div class="tab-desc">
                                                <div class="title">
                                                    配置说明
                                                </div>
                                                <div class="desc">
                                                    当前选择的WebHook有以下返回值可使用，需再添加一个消息模板组件，以合适的形式在对话中展现。
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    unit.content.webHookId !=
                                                        '' &&
                                                    unit.content.response &&
                                                    unit.content.response.length
                                                "
                                                class="split-line"
                                            ></div>
                                            <div
                                                v-if="
                                                    unit.content.webHookId !=
                                                        '' &&
                                                    unit.content.response &&
                                                    unit.content.response.length
                                                "
                                                class="webhook-response-list"
                                            >
                                                <div
                                                    class="
                                                        webhook-response-list-cell
                                                    "
                                                    v-for="(
                                                        respCell, respCellIndex
                                                    ) in unit.content.response"
                                                    :key="respCellIndex"
                                                >
                                                    <span
                                                        class="
                                                            webhook-response-list-cell-pre
                                                        "
                                                        >返回值：</span
                                                    >
                                                    <span
                                                        class="
                                                            webhook-response-list-cell-content
                                                        "
                                                        >{{
                                                            respCell.name
                                                        }}</span
                                                    >
                                                    <span
                                                        v-if="
                                                            respCell.multivalued
                                                        "
                                                        >（可能为多值）</span
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    unit.content.webHookId !=
                                                        '' &&
                                                    unit.content.response &&
                                                    unit.content.response.length
                                                "
                                                class="split-line"
                                            ></div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>

                                <!------发送邮件-------->
                                <aMail
                                    :ref="`action_mail${index}`"
                                    :unit="unit"
                                    :unitIndex="index"
                                    :intentOptions="intentOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    v-if="unit.type === 'action_mail'"
                                ></aMail>
                                <!------发送邮件-------->
                                <!------弹窗答案-------->
                                <aAlertIframe
                                    :ref="`action_alert_iframe${index}`"
                                    :unit="unit"
                                    :unitIndex="index"
                                    :intentOptions="intentOptions"
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    @templateConfigVisibleHandle="
                                        templateConfigVisibleHandle
                                    "
                                    v-if="unit.type === 'action_alert_iframe'"
                                ></aAlertIframe>
                                <!------弹窗答案-------->
                                <!------确认值答案-------->
                                <aCconfirmvalue
                                    :ref="`action_confirm_value${index}`"
                                    :unit="unit"
                                    :unitIndex="index"
                                    :intentOptions="intentOptions"
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    @templateConfigVisibleHandle="
                                        templateConfigVisibleHandle
                                    "
                                    v-if="unit.type === 'action_confirm_value'"
                                ></aCconfirmvalue>
                                <!------确认值答案-------->
                                <!------提交表单答案-------->
                                <aFormsubmit
                                    :ref="`form_submit${index}`"
                                    :unit="unit"
                                    :activeBot="activeBot"
                                    :unitIndex="index"
                                    :botVersion="botVersion"
                                    :intentSAkillTypeOptionsNoChildren="
                                        intentSAkillTypeOptionsNoChildren
                                    "
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    @updateFormUnit="updateFormUnit"
                                    v-if="unit.type === 'action_form'"
                                ></aFormsubmit>
                                <!------提交表单答案-------->
                                <!------卡片模版答案-------->
                                <aRichtextpro
                                    :ref="`arichtextpro${index}`"
                                    :rich_text_pro_info="unit"
                                    :webhookMultivaluedCascList_="
                                        webhookMultivaluedCascList
                                    "
                                    :activeARichtextproIndex="index"
                                    @rtpAddBottondiaVisible="
                                        rtpAddBottondiaVisible
                                    "
                                    @templateConfigVisibleHandle="
                                        templateConfigVisibleHandle
                                    "
                                    v-if="unit.type === 'answer_card_template'"
                                ></aRichtextpro>
                                <!------卡片模版答案-------->
                                <!------快捷选择-------->
                                <aFastselect
                                    :ref="`aFastSelect${index}`"
                                    :unit="unit"
                                    :isStartOrEnd="isStartOrEnd"
                                    :jumpTypeAddSendOptions="
                                        jumpTypeAddSendOptions
                                    "
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    v-if="unit.type === 'action_fast_select'"
                                ></aFastselect>
                                <!------快捷选择-------->
                                <!------智能引导-------->
                                <aIntellectGuide
                                    :ref="`aIntellectGuide${index}`"
                                    :unit="unit"
                                    :compIndex="index"
                                    @saveAigConfig="saveAigConfig"
                                    :isStartOrEnd="isStartOrEnd"
                                    :jumpTypeAddSendOptions="
                                        jumpTypeAddSendOptions
                                    "
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    v-if="unit.type === 'answer_intellect_guide'"
                                ></aIntellectGuide>
                                <!------智能引导-------->
                                <!------答案反馈-------->
                                <aFeedback
                                    :ref="`aFeedback${index}`"
                                    :unit="unit"
                                    :intentOptions="intentOptions"
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    v-if="unit.type === 'action_feedback'"
                                ></aFeedback>
                                <!------答案反馈-------->
                                <!------转企微-------->
                                <aTransQw
                                    :ref="`aTransQw${index}`"
                                    :unit="unit"
                                    :intentOptions="intentOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    v-if="
                                        unit.type ===
                                        'action_transfer_2_ewechat'
                                    "
                                ></aTransQw>
                                <!------转企微-------->
                                <!------强制跳转/随机跳转-------->
                                <aEnforce
                                    :unit="unit"
                                    :intentOptions="intentOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    :type="unit.type"
                                    v-if="
                                        unit.type === 'action_enforce' ||
                                        unit.type === 'action_random_jump'
                                    "
                                ></aEnforce>
                                <!------强制跳转/随机跳转-------->
                                <!------消息卡片答案-------->
                                <div
                                    class="
                                        respose-text-content
                                        info_card-content
                                    "
                                    v-if="unit.type === 'answer_rich_text_pro'"
                                >
                                    <div class="info_card-des-text">
                                        <span
                                            >单返回值输入框中可插入WebHook返回的唯一值，比如订单数量；</span
                                        >
                                        <span
                                            >多返回值输入框中可插入WebHook返回的多个值，比如多个订单号。</span
                                        >
                                    </div>
                                    <div
                                        class="
                                            rhetorical-question-input
                                            info_card-content-cell-input
                                        "
                                    >
                                        <ckeditor
                                            :editor="ckeditor.editor"
                                            :config="ckeditor.editorConfig"
                                            v-model="unit.content.value"
                                        ></ckeditor>
                                    </div>
                                    <div
                                        v-if="false"
                                        class="info_card-content-handle-bottom"
                                    >
                                        <el-button
                                            @click="
                                                selectInfoCardData('BUTTON')
                                            "
                                            size="small"
                                            plain
                                            type="primary"
                                            >添加按钮</el-button
                                        >
                                        <el-button
                                            @click="
                                                selectInfoCardData(
                                                    'PLACEHOLDER-entity'
                                                )
                                            "
                                            size="small"
                                            plain
                                            type="primary"
                                            >实体占位符</el-button
                                        >
                                        <el-button
                                            @click="
                                                selectInfoCardData(
                                                    'PLACEHOLDER-webhook'
                                                )
                                            "
                                            size="small"
                                            plain
                                            type="primary"
                                            >webhook返回值占位符</el-button
                                        >
                                        <el-button
                                            @click="
                                                selectInfoCardData(
                                                    'LOOP-NOT-ROOT'
                                                )
                                            "
                                            size="small"
                                            plain
                                            type="primary"
                                            >非根循环</el-button
                                        >
                                        <el-button
                                            @click="
                                                selectInfoCardData(
                                                    'LOOP-IS-ROOT'
                                                )
                                            "
                                            size="small"
                                            plain
                                            type="primary"
                                            >根循环</el-button
                                        >
                                    </div>
                                </div>
                                <!------文本答案-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'answer_text'"
                                >
                                    <div class="rhetorical-question-input">
                                        <div
                                            v-for="(
                                                textCell, textCellInd
                                            ) in unit.content.list"
                                            :key="textCellInd"
                                            class="
                                                rhetorical-question-input-cell
                                            "
                                        >
                                            <el-input
                                                autosize
                                                type="textarea"
                                                v-model="
                                                    unit.content.list[
                                                        textCellInd
                                                    ]
                                                "
                                                maxlength="2000"
                                                placeholder="请输入文字答案"
                                            ></el-input>
                                            <span
                                                v-if="textCellInd == 0"
                                                class="
                                                    el-icon-circle-plus
                                                    handle-icon
                                                "
                                                @click="
                                                    addText(index, textCellInd)
                                                "
                                            ></span>
                                            <span
                                                v-if="textCellInd !== 0"
                                                class="
                                                    el-icon-circle-close
                                                    handle-icon
                                                    handle-icon-delete
                                                "
                                                @click="
                                                    deleteText(
                                                        index,
                                                        textCellInd
                                                    )
                                                "
                                            ></span>
                                        </div>
                                        <cRecommend
                                            :unit="unit"
                                            :intentSAkillTypeOptionsNoChildren="
                                                intentSAkillTypeOptionsNoChildren
                                            "
                                        />
                                    </div>
                                </div>
                                <!------欢迎语答案-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'answer_welcometext'"
                                >
                                    <div class="rhetorical-question-input">
                                        <div
                                            v-for="(
                                                textCell, textCellInd
                                            ) in unit.content.list"
                                            :key="textCellInd"
                                            class="
                                                rhetorical-question-input-cell
                                            "
                                        >
                                            <el-input
                                                autosize
                                                type="textarea"
                                                v-model="
                                                    unit.content.list[
                                                        textCellInd
                                                    ]
                                                "
                                                maxlength="2000"
                                                placeholder="请输入文字答案"
                                            ></el-input>
                                            <span
                                                v-if="textCellInd == 0"
                                                class="
                                                    el-icon-circle-plus
                                                    handle-icon
                                                "
                                                @click="
                                                    addText(index, textCellInd)
                                                "
                                            ></span>
                                            <span
                                                v-if="textCellInd !== 0"
                                                class="
                                                    el-icon-circle-close
                                                    handle-icon
                                                    handle-icon-delete
                                                "
                                                @click="
                                                    deleteText(
                                                        index,
                                                        textCellInd
                                                    )
                                                "
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                                <!------设置实体-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'action_set_entity'"
                                >
                                    <div class="simple-cell-satisfaction">
                                        <span class="title">将实体</span>
                                        <el-cascader
                                            placeholder="请搜索选择实体"
                                            size="small"
                                            v-if="!isStartOrEnd"
                                            v-model="unit.content.entity"
                                            :options="entityOptions"
                                            @change="handleChangeEntity"
                                            filterable
                                        ></el-cascader>
                                        <el-cascader
                                            placeholder="请搜索选择实体"
                                            v-if="isStartOrEnd"
                                            size="small"
                                            v-model="unit.content.entity"
                                            :options="entityOptionsBot"
                                            @change="handleChangeEntity"
                                            filterable
                                        ></el-cascader>
                                    </div>
                                    <br />
                                    <div class="simple-cell-satisfaction">
                                        <span class="title">设置为</span>
                                        <div>
                                            <el-autocomplete
                                                size="small"
                                                class="inline-input"
                                                v-model="unit.content.value"
                                                :fetch-suggestions="
                                                    entityFetchSuggestions
                                                "
                                                placeholder="请输入文字描述"
                                            ></el-autocomplete>
                                        </div>
                                    </div>
                                </div>
                                <!------跳转答案-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'action_jump'"
                                >
                                    <div class="jump-cell">
                                        <el-select
                                            v-model="unit.content.scope"
                                            @change="
                                                jumpTypeValueChange(index, unit)
                                            "
                                            size="small"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in filterJumpTypeOptions(
                                                    jumpTypeOptions
                                                )"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                        <div class="right-select">
                                            <el-select
                                                v-show="
                                                    unit.content.scope ===
                                                    'NODE'
                                                "
                                                v-model="unit.content.value"
                                                @change="nodeSelectChange"
                                                :popper-append-to-body="false"
                                                filterable
                                                size="small"
                                                placeholder="请输入选择名称"
                                            >
                                                <el-option
                                                    v-for="item in nodeOptions"
                                                    :popper-class="
                                                        'pop_my' +
                                                        item.classPop +
                                                        item.parentNum
                                                    "
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                            <el-cascader
                                                v-show="
                                                    unit.content.scope ===
                                                    'INTENT'
                                                "
                                                v-model="unit.content.value"
                                                :options="intentTree4Radio"
                                                size="small"
                                                :props="{
                                                    expandTrigger: 'hover',
                                                    emitPath: false,
                                                    label: 'name',
                                                    value: 'id',
                                                }"
                                            ></el-cascader>
                                        </div>
                                    </div>
                                </div>
                                <!------反问答案-------->
                                <aQuestion
                                    :ref="`aQuestion${index}`"
                                    :index="index"
                                    :unit="unit"
                                    :intentOptions="intentOptions"
                                    :entityOptionsBot="entityOptionsBot"
                                    :entityOptions="entityOptions"
                                    :isStartOrEnd="isStartOrEnd"
                                    :nodeOptions="nodeOptions"
                                    :intentTree4Radio="intentTree4Radio"
                                    :noGetParamQNumber="noGetParamQNumber"
                                    v-if="unit.type === 'action_question'"
                                ></aQuestion>

                                <!------满意度组件-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'action_satisfaction'"
                                >
                                    <div class="simple-cell-satisfaction">
                                        <span class="title">满意度描述</span>
                                        <div>
                                            <el-input
                                                autosize
                                                type="textarea"
                                                v-model="
                                                    unit.content.satisfactionDes
                                                "
                                                placeholder="请输入文字描述"
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div class="simple-cell-satisfaction">
                                        <span class="title">满意度选择</span>
                                        <div class="option-sa">
                                            <el-tag type="info">满意</el-tag>
                                            <el-tag type="info">不满意</el-tag>
                                        </div>
                                    </div>
                                    <div class="simple-cell-satisfaction">
                                        <span class="title"
                                            >如果满意时回复</span
                                        >
                                        <div>
                                            <el-input
                                                autosize
                                                type="textarea"
                                                v-model="
                                                    unit.content
                                                        .satisfactoryAnswer
                                                "
                                                placeholder="请输入满意时机器人的回复..."
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div class="simple-cell-satisfaction">
                                        <span class="title"
                                            >如果不满意时回复</span
                                        >
                                        <div>
                                            <el-input
                                                autosize
                                                type="textarea"
                                                v-model="
                                                    unit.content
                                                        .unsatisfiedAnswer
                                                "
                                                placeholder="请输入不满意时机器人的回复..."
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div class="simple-cell-satisfaction">
                                        <span class="title-other"
                                            >如果想了解咨询者不满意的原因，可配置下方点选</span
                                        >
                                        <div class="satisfaction-radio-box">
                                            <el-tag
                                                :key="answerRadioIndex"
                                                v-for="(
                                                    item, answerRadioIndex
                                                ) in unit.content.answerRadio"
                                                closable
                                                :disable-transitions="false"
                                                @click="
                                                    editSatisfactionRadio(
                                                        index,
                                                        answerRadioIndex,
                                                        item
                                                    )
                                                "
                                                @close="
                                                    handleCloseSaRadio(
                                                        index,
                                                        answerRadioIndex
                                                    )
                                                "
                                                >{{ item.name }}</el-tag
                                            >
                                            <el-button
                                                size="mini"
                                                type="primary"
                                                @click="
                                                    addSatisfactionRadio(index)
                                                "
                                                >添加点选</el-button
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!------关闭对话组件-------->
                                <div class="respose-text-content" v-if="false">
                                    <div class="simple-cell">
                                        <span
                                            class="
                                                arsenal_icon
                                                arsenalsms-solid
                                            "
                                        ></span>
                                        <div class="simple-cell-content">
                                            <span
                                                >机器人终止会话,30分钟内，同一个用户再次发起咨询，不处理也不进行转发操作</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!------转人工组件-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'action_transfer'"
                                >
                                    <div class="artificial-send-text-value">
                                        <div class="tal artificial-text-title">
                                            请选择转人工方式：
                                        </div>
                                        <div class="rhetorical-type-box">
                                            <el-radio-group
                                                v-model="unit.content.type"
                                                size="mini"
                                                style="
                                                    width: 100%;
                                                    display: flex;
                                                "
                                            >
                                                <el-radio-button label="1"
                                                    >随机客服</el-radio-button
                                                >
                                                <el-radio-button label="2"
                                                    >点选客服组</el-radio-button
                                                >
                                                <el-radio-button label="3"
                                                    >直连客服组</el-radio-button
                                                >
                                            </el-radio-group>
                                        </div>
                                    </div>

                                    <div
                                        v-if="unit.content.type == 1"
                                        class="artificial-send-text-value"
                                    >
                                        <div class="tal artificial-text-title">
                                            转人工文案
                                        </div>
                                        <div
                                            class="
                                                rhetorical-question-input-cell
                                            "
                                        >
                                            <el-input
                                                type="textarea"
                                                :rows="2"
                                                v-model="
                                                    unit.content.random.text
                                                "
                                                placeholder="请输入转人工文案"
                                            ></el-input>
                                        </div>
                                    </div>
                                    <div
                                        v-if="unit.content.type == 2"
                                        class="artificial-send-text-value"
                                    >
                                        <div class="tal artificial-text-title">
                                            转人工文案
                                        </div>
                                        <div
                                            class="
                                                rhetorical-question-input-cell
                                            "
                                        >
                                            <el-input
                                                type="textarea"
                                                :rows="2"
                                                v-model="
                                                    unit.content.select.text
                                                "
                                                placeholder="请输入转人工文案"
                                            ></el-input>
                                        </div>
                                        <div
                                            class="
                                                cell-content
                                                answer-radio-box
                                            "
                                        >
                                            <el-tag
                                                :key="radioTextIndex"
                                                v-for="(
                                                    tag, radioTextIndex
                                                ) in unit.content.select
                                                    .options"
                                                closable
                                                effect="dark"
                                                :disable-transitions="false"
                                                @close="
                                                    deleteSelectCustRadio(
                                                        index,
                                                        radioTextIndex
                                                    )
                                                "
                                                @click="
                                                    showSelectCustRadioEdite(
                                                        index,
                                                        radioTextIndex
                                                    )
                                                "
                                                >{{ tag.name }}</el-tag
                                            >
                                            <el-button
                                                size="mini"
                                                @click="
                                                    addSelectCustRadio(index)
                                                "
                                                type="primary"
                                                plain
                                                round
                                                >添加点选</el-button
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-if="unit.content.type == 3"
                                        class="artificial-send-text-value"
                                    >
                                        <div class="tal artificial-text-title">
                                            转人工文案
                                        </div>
                                        <div
                                            class="
                                                rhetorical-question-input-cell
                                            "
                                        >
                                            <el-input
                                                type="textarea"
                                                :rows="2"
                                                v-model="
                                                    unit.content.direct.text
                                                "
                                                placeholder="请输入转人工文案"
                                            ></el-input>
                                        </div>
                                        <div class="tal artificial-text-title">
                                            直连客服组
                                        </div>
                                        <div
                                            class="
                                                rhetorical-question-input-cell
                                                rhetorical-question-input-cell-select
                                            "
                                        >
                                            <el-select
                                                v-model="
                                                    unit.content.direct.customer
                                                "
                                                size="small"
                                                placeholder="请选择"
                                                width="100%"
                                                no-data-text="暂无分组信息"
                                            >
                                                <el-option
                                                    v-for="item in custonerOptions"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div class="simple-cell-ifOffline">
                                        <span class="title"
                                            >如果人工客服不在线</span
                                        >
                                        <div class="action_form_cascader">
                                            <el-select
                                                v-model="
                                                    unit.content.ifOffline.scope
                                                "
                                                size="small"
                                                placeholder="请选择"
                                                @change="
                                                    submitErrorChangeOptions(
                                                        $event,
                                                        index
                                                    )
                                                "
                                            >
                                                <el-option
                                                    v-for="item in jumpTypeAddContentOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            unit.content.ifOffline.scope ===
                                            'INTENT'
                                        "
                                        class="simple-cell-ifOffline"
                                    >
                                        <span class="title">选择意图</span>
                                        <div class="action_form_cascader">
                                            <el-cascader
                                                v-model="
                                                    unit.content.ifOffline.value
                                                "
                                                size="small"
                                                :options="intentTree4Radio"
                                                :props="{
                                                    expandTrigger: 'hover',
                                                    emitPath: false,
                                                    label: 'name',
                                                    value: 'id',
                                                }"
                                            ></el-cascader>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            unit.content.ifOffline.scope ===
                                            'NODE'
                                        "
                                        class="simple-cell-ifOffline"
                                    >
                                        <span class="title">选择节点</span>
                                        <div class="action_form_cascader">
                                            <el-select
                                                v-model="
                                                    unit.content.ifOffline.value
                                                "
                                                :popper-append-to-body="false"
                                                filterable
                                                size="small"
                                                placeholder="请输入选择名称"
                                            >
                                                <el-option
                                                    v-for="item in nodeOptions"
                                                    :key="item.id"
                                                    :popper-class="
                                                        'pop_my' +
                                                        item.classPop +
                                                        item.parentNum
                                                    "
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            unit.content.ifOffline.scope ===
                                            'CONTENT'
                                        "
                                        class="simple-cell-ifOffline"
                                    >
                                        <span class="title">发送内容</span>
                                        <div class="action_form_cascader">
                                            <el-input
                                                type="textarea"
                                                :autosize="{
                                                    minRows: 2,
                                                    maxRows: 10,
                                                }"
                                                v-model="
                                                    unit.content.ifOffline.value
                                                "
                                                maxlength="2000"
                                                placeholder="请输入文字答案"
                                            ></el-input>
                                        </div>
                                    </div>
                                </div>
                                <!------图片答案-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'answer_image'"
                                >
                                    <div class="respose-image">
                                        <el-upload
                                            :action="importFileUrl"
                                            :file-list="
                                                unit.content == null ||
                                                unit.content.url == ''
                                                    ? []
                                                    : [
                                                          {
                                                              url: unit.content
                                                                  .url,
                                                          },
                                                      ]
                                            "
                                            list-type="picture-card"
                                            :on-preview="
                                                handlePictureCardPreview(index)
                                            "
                                            :before-upload="beforeImageMUpload"
                                            :on-success="
                                                (response, file, fileList) =>
                                                    handlePictureUploadSuccess(
                                                        response,
                                                        file,
                                                        fileList,
                                                        unit.content
                                                    )
                                            "
                                            :multiple="false"
                                            :limit="1"
                                        >
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        
                                        <p class="des">
                                            请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！
                                        </p>
                                    </div>
                                    <cRecommend
                                        :unit="unit"
                                        :intentSAkillTypeOptionsNoChildren="
                                            intentSAkillTypeOptionsNoChildren
                                        "
                                    />
                                </div>
                                <!------视频答案-------->
                                <div
                                    class="respose-text-content"
                                    v-if="unit.type === 'answer_video'"
                                >
                                    <div class="respose-video">
                                        <div class="album albumvideo">
                                            <div>
                                                <div class="pic_img">
                                                    <div class="pic_img_box">
                                                        <el-upload
                                                            class="
                                                                avatar-uploader
                                                            "
                                                            :action="
                                                                importFileUrl
                                                            "
                                                            v-bind:data="{}"
                                                            v-bind:on-progress="
                                                                (
                                                                    event,
                                                                    file,
                                                                    fileList
                                                                ) =>
                                                                    uploadVideoProcess(
                                                                        event,
                                                                        file,
                                                                        fileList,
                                                                        index
                                                                    )
                                                            "
                                                            :on-success="
                                                                (res, file) =>
                                                                    handleVideoSuccess(
                                                                        res,
                                                                        file,
                                                                        index
                                                                    )
                                                            "
                                                            :before-upload="
                                                                (file) =>
                                                                    beforeUploadVideo(
                                                                        file,
                                                                        index
                                                                    )
                                                            "
                                                            v-bind:show-file-list="
                                                                false
                                                            "
                                                        >
                                                            <video
                                                                width="248px"
                                                                height="148px"
                                                                controlslist="nodownload"
                                                                object-fit="fill"
                                                                v-if="
                                                                    unit.content
                                                                        .url !=
                                                                        '' &&
                                                                    !unit
                                                                        .content
                                                                        .videoFlag
                                                                "
                                                                v-bind:src="
                                                                    unit.content
                                                                        .url
                                                                "
                                                                muted="true"
                                                                class="
                                                                    avatar
                                                                    video-avatar
                                                                "
                                                                controls="controls"
                                                            >
                                                                您的浏览器不支持视频播放
                                                            </video>
                                                            <i
                                                                v-else-if="
                                                                    unit.content
                                                                        .url ==
                                                                        '' &&
                                                                    !unit
                                                                        .content
                                                                        .videoFlag
                                                                "
                                                                class="
                                                                    el-icon-plus
                                                                    avatar-uploader-icon
                                                                "
                                                            ></i>
                                                            <el-progress
                                                                v-if="
                                                                    unit.content
                                                                        .videoFlag ==
                                                                    true
                                                                "
                                                                type="circle"
                                                                :percentage="
                                                                    unit.content
                                                                        .videoUploadPercent
                                                                "
                                                                style="
                                                                    margin-top: 7px;
                                                                "
                                                            ></el-progress>
                                                        </el-upload>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="Upload_pictures">
                                                <span></span>
                                                <span
                                                    >最多可以上传1个视频，小于10M，格式mp4、avi、mov等（可点击已上传视频重新上传！）</span
                                                >
                                            </p>
                                        </div>
                                    </div>
                                    <cRecommend
                                        :unit="unit"
                                        :intentSAkillTypeOptionsNoChildren="
                                            intentSAkillTypeOptionsNoChildren
                                        "
                                    />
                                </div>
                                <!------点选答案-------->
                                <div
                                    class="respose-text-content tal"
                                    v-if="unit.type === 'answer_radio'"
                                >
                                    <span class="cell-title">点选介绍</span>
                                    <div class="cell-content">
                                        <el-input
                                            type="textarea"
                                            autosize
                                            placeholder="请输入点选介绍，如：“您可能更想问：”"
                                            v-model="unit.content.description"
                                        ></el-input>
                                    </div>
                                    <span class="cell-title">点选按钮</span>
                                    <div class="cell-content answer-radio-box">
                                        <div :id="getId('custom-column-field', index)" class="btn-list-box">
                                            <div :key="radioTextIndex"
                                            v-for="(tag, radioTextIndex) in unit
                                                .content.options" class="btn-list-cell">
                                                <span @click="
                                                showAnswerRadioEdite(
                                                    index,
                                                    radioTextIndex
                                                )
                                            " class="btn-list-cell-text">{{tag.name}}</span>
                                                <span class="sortIcon my-handle-btn iconfont guoran-a-16-10"
                                                      @mouseenter="sortClickBtn($event, tag, index)">
                                                </span>
                                                <span @click="deleteAnswerRadio(
                                                    index,
                                                    radioTextIndex)" class="my-handle-close iconfont guoran-a-16-09">
                                                </span>
                                            </div>
                                            <el-button
                                                style="margin-top: 10px"
                                                size="mini"
                                                @click="addAnswerRadio(index)"
                                                type="primary"
                                                plain
                                                round
                                                >添加点选</el-button
                                            >
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-drawer>
        </el-container>
        <div v-if="isPart == 0" class="content-header">
            <div class="back-button">
                <a
                    href="javascript:void(0)"
                    @click="tobotIntent()"
                    title="返回"
                >
                    <i class="el-icon-back"></i>
                </a>
            </div>
            <div class="leftInfo cursor">
                <el-select
                    v-model="activeBot"
                    placeholder="请选择机器人"
                    @change="selectBot"
                >
                    <el-option
                        v-for="item in botList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div v-if="isPart == 1" class="isPart">
            <div class="class-node1 box-card">
                <intentNodeComponent
                    :skillIndex="0"
                    :classIndex="0"
                    :intentIndex="0"
                    :intentItem="JSON.stringify(intentItemTamp)"
                    :skillItem="skillItemTamp"
                    :isPart="true"
                    @addNodeHandleFirst="addNodeHandleFirst"
                    @confirmAddNodeHandleFirst="confirmAddNodeHandleFirst"
                    @concelAddNodeHandleFirst="concelAddNodeHandleFirst"
                    @configAction="configAction"
                    @deleteNodeHandleFirst="deleteNodeHandleFirst"
                />
            </div>
            <div class="class-node1 class-node2 box-card">
                <intentNodeComponent
                    :skillIndex="0"
                    :classIndex="0"
                    :intentIndex="0"
                    :intentItem="JSON.stringify(parentIntentItemTamp)"
                    :skillItem="skillItemTamp"
                    :isPart="true"
                    @addNodeHandleFirst="addNodeHandleFirst"
                    @confirmAddNodeHandleFirst="confirmAddNodeHandleFirst"
                    @concelAddNodeHandleFirst="concelAddNodeHandleFirst"
                    @configAction="configAction"
                    @deleteNodeHandleFirst="deleteNodeHandleFirst"
                />
            </div>
        </div>

        <div v-if="isPart == 0" class="skill-tree-list-outer">
            <div
                id="start-info"
                class="start-info csp"
                @click="configAction(0, 'start', 0, '开始')"
            >
                开始
            </div>
            <div class="skill-tree-content">
                <div
                    class="outer-box"
                    v-for="(skillItem, skillIndex) in nodeTree"
                    :key="skillIndex"
                >
                    <div class="class-pre" style="margin-top: 8px">
                        如果进入
                    </div>
                    <div
                        :class="[
                            'skill-node-name',
                            {
                                'skill-node-name-first':
                                    skillItem.children.length === 1,
                            },
                        ]"
                    >
                        <div
                            class="handle"
                            @click="closeSkillNodeList(skillIndex)"
                        >
                            <span v-show="!skillItem.show">+</span>
                            <span v-show="skillItem.show">-</span>
                        </div>
                        <div class="name-show">
                            <span class="node-type-show" id="skillName"
                                >技能</span
                            >
                            {{ skillItem.name }}
                        </div>
                    </div>
                    <div class="skill-node-list" v-show="skillItem.show">
                        <div
                            :class="[
                                'skill-node',
                                {
                                    'border-l-none':
                                        classIndex ==
                                        skillItem.children.length - 1,
                                },
                            ]"
                            v-for="(
                                classItem, classIndex
                            ) in skillItem.children"
                            :key="classIndex"
                        >
                            <div
                                class="last-space-left-bd"
                                v-if="
                                    classIndex == skillItem.children.length - 1
                                "
                            ></div>
                            <div
                                class="class-pre"
                                style="
                                    line-height: 90px;
                                    border-radius: 0px 0 0 25px;
                                "
                            >
                                如果进入
                            </div>
                            <div
                                :class="[
                                    'class-node-name',
                                    {
                                        'border-left-gree':
                                            !classItem.isOpenLast,
                                    },
                                ]"
                            >
                                <div
                                    v-if="classItem.show"
                                    class="left-border-replace"
                                ></div>
                                <div
                                    class="handle"
                                    @click="
                                        closeClassNodeList(
                                            skillIndex,
                                            classIndex
                                        )
                                    "
                                >
                                    <span v-show="!classItem.show">+</span>
                                    <span v-show="classItem.show">-</span>
                                </div>
                                <div class="name-show">
                                    <span class="node-type-show" id="typeName"
                                        >分类</span
                                    >
                                    {{ classItem.name }}
                                </div>
                            </div>

                            <div class="class-node-list">
                                <div
                                    class="class-node"
                                    v-for="(
                                        intentItem, intentIndex
                                    ) in classItem.children"
                                    :key="intentIndex"
                                    v-if="classItem.show"
                                >
                                    <div class="intent-pre class-space">
                                        <div class></div>
                                        <div>识别为</div>
                                    </div>
                                    <intentComponent
                                        :skillIndex="skillIndex"
                                        :classIndex="classIndex"
                                        :intentIndex="intentIndex"
                                        :isPart="false"
                                        :intentItem="JSON.stringify(intentItem)"
                                        @closeIntentNodeList="
                                            closeIntentNodeList
                                        "
                                        @tobotIntentQa="tobotIntentQa"
                                    />
                                    <intentNodeComponent
                                        :skillIndex="skillIndex"
                                        :classIndex="classIndex"
                                        :intentIndex="intentIndex"
                                        :intentItem="JSON.stringify(intentItem)"
                                        :classItem="classItem"
                                        :skillItem="skillItem"
                                        :isPart="false"
                                        @addNodeHandleFirst="addNodeHandleFirst"
                                        @confirmAddNodeHandleFirst="
                                            confirmAddNodeHandleFirst
                                        "
                                        @concelAddNodeHandleFirst="
                                            concelAddNodeHandleFirst
                                        "
                                        @configAction="configAction"
                                        @deleteNodeHandleFirst="
                                            deleteNodeHandleFirst
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="endNode"
                class="start-info csp"
                @click="configAction(0, 'end', 0, '结束')"
            >
                结束
            </div>
        </div>

        <el-dialog
            :title="conditionConfigTitle"
            :visible.sync="conditionConfigVisible"
            :close-on-click-modal="false"
            width="550px"
        >
            <!-----条件配置弹框 start------->
            <div id="conditionPop">
                <div class="select-cell">
                    <span class="select-cell-title">选择类型</span>
                    <el-select
                        size="small"
                        v-model="conditionType"
                        @change="conditionTypeChange"
                        placeholder="请选择类型"
                    >
                        <el-option
                            v-for="item in conditionTypesOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="entity-content" v-if="conditionType === 'ENTITY'">
                    <div class="select-cell">
                        <span class="select-cell-title">选择实体</span>
                        <el-cascader
                            placeholder="请搜索选择实体"
                            size="small"
                            v-model="entityValue"
                            :options="entityOptions"
                            @change="handleChangeEntity"
                            filterable
                        ></el-cascader>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择条件</span>
                        <el-select
                            size="small"
                            v-model="conditionValue"
                            placeholder="请选择类型"
                        >
                            <el-option
                                v-for="item in conditionTypes[0].relations"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择或输入实体值</span>
                        <el-autocomplete
                            size="small"
                            class="inline-input"
                            v-model="activeEntityValue"
                            :fetch-suggestions="querySearchEntityValue"
                            placeholder="请输入或选择实体值"
                        ></el-autocomplete>
                    </div>
                </div>
                <div
                    class="intent-content"
                    v-if="conditionType === 'WEB_HOOK_RESPONSE'"
                >
                    <div class="select-cell">
                        <span class="select-cell-title">返回值</span>
                        <el-cascader
                            :key="'__' + new Date()"
                            size="small"
                            v-model="conditionResponseValue"
                            :options="expBaseCascList"
                            :props="{ expandTrigger: 'hover', label: 'name' }"
                        ></el-cascader>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择返回值类型</span>
                        <el-select
                            size="small"
                            v-model="conditionResponseType"
                            placeholder="请选择类型"
                        >
                            <el-option
                                v-for="item in [
                                    { name: '个数', value: 'LENGTH' },
                                    { name: '值', value: 'VALUE' },
                                ]"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择条件</span>
                        <el-select
                            size="small"
                            v-model="conditionResponseCondit"
                            placeholder="请选择条件"
                        >
                            <el-option
                                v-for="item in webhookConditionTypes"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div
                        v-if="conditionResponseType == 'VALUE'"
                        class="select-cell"
                    >
                        <span class="select-cell-title">输入值</span>
                        <el-input
                            size="small"
                            v-model="conditionResponseNum"
                            placeholder="请输入值"
                        ></el-input>
                    </div>
                    <div
                        v-if="conditionResponseType == 'LENGTH'"
                        class="select-cell"
                    >
                        <span class="select-cell-title">输入数值</span>
                        <el-input
                            size="small"
                            v-model="conditionResponseNum"
                            type="number"
                            placeholder="请输入数值"
                        ></el-input>
                    </div>
                </div>
                <div class="intent-content" v-if="conditionType === 'INTENT'">
                    <div class="select-cell">
                        <span class="select-cell-title">意图情况</span>
                        <el-select
                            size="small"
                            v-model="activeIntentStatus"
                            placeholder="请选择意图情况"
                        >
                            <el-option
                                v-for="item in intentStatus"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择条件</span>
                        <el-select
                            size="small"
                            v-model="conditionValue"
                            placeholder="请选择类型"
                        >
                            <el-option
                                v-for="item in conditionTypes[1].relations"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">选择意图</span>
                        <el-cascader
                            size="small"
                            @change="intentSelectedValue"
                            v-model="intentValue"
                            placeholder="请搜索或选择意图"
                            :options="intentSAkillTypeOptionsNoChildren"
                            filterable
                        ></el-cascader>
                    </div>
                </div>
                <div
                    class="silent-content"
                    v-if="conditionType === 'USER_WAITING_TIME'"
                >
                    <div class="select-cell">
                        <span class="select-cell-title">选择条件</span>
                        <el-select
                            size="small"
                            v-model="conditionValue"
                            placeholder="请选择类型"
                        >
                            <el-option
                                v-for="item in conditionTypes[2].relations"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">时间值</span>
                        <div class="silent-content-time">
                            <el-input
                                maxlength="8"
                                size="small"
                                oninput="value=value.replace(/[^\d.]/g,'')"
                                v-model="silentTimeValue"
                                placeholder="请输入时间：如 30"
                            />
                            <el-select
                                size="small"
                                v-model="silentTimeUnit"
                                placeholder="请选择时间"
                            >
                                <el-option label="秒" value="1s"></el-option>
                                <el-option label="分" value="1m"></el-option>
                                <el-option label="时" value="1h"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="bott-handle">
                    <el-button size="small" @click="cancelSaveCondiConfig" plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveCondiConfig"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----条件配置弹框 end------->
        </el-dialog>
        <el-dialog
            title="点选按钮配置"
            :visible.sync="webhookAnswerRadioConfigVisible"
            :close-on-click-modal="false"
            width="400px"
        >
            <!-----webhook点选配置弹框 start------->
            <div class="answer-radio-cell">
                <div class="select-cell">
                    <span class="select-cell-title">点选名称</span>
                    <el-input
                        size="small"
                        v-model="webhookActiveRadionConfigObj.name"
                        maxlength="40"
                        placeholder="请输入名称"
                    ></el-input>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">如果用户点击</span>
                    <el-select
                        value="向机器人发送内容"
                        disabled
                        size="small"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in [
                                {
                                    label: '向机器人发送内容',
                                    value: '向机器人发送内容',
                                },
                            ]"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">发送内容</span>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 10 }"
                        placeholder="请输入发送内容"
                        v-model="webhookActiveRadionConfigObj.content"
                    ></el-input>
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelSaveAnswerRadioConfig"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="webhookConfirmSaveAnswerRadioConfig"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----webhook点选配置弹框 end------->
        </el-dialog>
        <el-dialog
            title="点选按钮配置"
            :visible.sync="answerRadioConfigVisible"
            :close-on-click-modal="false"
            width="400px"
        >
            <!-----点选配置弹框 start------->
            <div class="answer-radio-cell">
                <div class="select-cell">
                    <span class="select-cell-title">点选名称</span>
                    <el-input
                        size="small"
                        v-model="activeRadionConfigObj.name"
                        :maxlength="maxLengthLimit"
                        placeholder="请输入名称"
                    ></el-input>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">如果用户点击</span>
                    <el-select
                        v-model="activeRadionConfigObj.scope"
                        @change="answerRadioValueChange"
                        size="small"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            v-show="
                                (isStartOrEnd && item.value != 'NODE') ||
                                !isStartOrEnd
                            "
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select-cell">
                    <span
                        class="select-cell-title"
                        v-show="
                            activeRadionConfigObj.scope === 'NODE' &&
                            !isStartOrEnd
                        "
                        >选择节点</span
                    >
                    <span
                        class="select-cell-title"
                        v-show="activeRadionConfigObj.scope === 'INTENT'"
                        >选择意图</span
                    >
                    <el-select
                        v-show="
                            activeRadionConfigObj.scope === 'NODE' &&
                            !isStartOrEnd
                        "
                        v-model="activeRadionConfigObj.value"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :key="item.id"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                    <el-cascader
                        v-show="activeRadionConfigObj.scope === 'INTENT'"
                        v-model="activeRadionConfigObj.value"
                        :options="intentTree4Radio"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelSaveAnswerRadioConfig"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveAnswerRadioConfig"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----点选配置弹框 end------->
        </el-dialog>
        <el-dialog
            title="点选按钮配置"
            :visible.sync="satiAnswerRadioVisible"
            :close-on-click-modal="false"
            width="400px"
        >
            <!-----满意度组件点选配置弹框 start------->
            <div class="sa-answer-radio-cell">
                <div class="select-cell">
                    <span class="select-cell-title">点选名称</span>
                    <el-input
                        size="small"
                        v-model="activeSatiAnswerRadioObj.name"
                        maxlength="40"
                        placeholder="请输入名称"
                    ></el-input>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">如果用户点击回复</span>
                    <el-input
                        size="small"
                        v-model="activeSatiAnswerRadioObj.answer"
                        maxlength="200"
                        placeholder="请输入用户点击时机器人的回复"
                    ></el-input>
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelSatiAnswerRadioVisible"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveSatiAnswerRadioVisible"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----满意度组件点选配置弹框 end------->
        </el-dialog>
        <el-dialog
            title="客服组点选按钮配置"
            :visible.sync="selectCustRadioVisible"
            :close-on-click-modal="false"
            width="400px"
        >
            <!-----转人工点选客服组配置弹框 start------->
            <div class="sa-answer-radio-cell">
                <div class="select-cell">
                    <span class="select-cell-title">点选名称</span>
                    <el-input
                        size="small"
                        v-model="selectCustRadioObj.name"
                        maxlength="40"
                        placeholder="请输入点选名称"
                    ></el-input>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">如果用户点击转接给</span>
                    <el-select
                        v-model="selectCustRadioObj.customerId"
                        size="small"
                        no-data-text="暂无分组信息"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in custonerOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelSelectCustrRadioVisible"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmselectCustRadioVisible"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----转人工点选客服组配置弹框 end------->
        </el-dialog>

        <el-dialog
            title="变更节点关系"
            :visible.sync="changeNoderelationVisible"
            :close-on-click-modal="false"
            width="30%"
        >
            <!-----变更节点关系 start------->
            <div class="sa-answer-radio-cell">
                <div class="select-cell">
                    <span class="select-cell-title">父节点</span>
                    <el-select
                        v-model="activeMoveToNode"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in moveToOptions"
                            :key="item.id"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelchangeNoderelation"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmchangeNoderelation"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----变更节点关系 end------->
        </el-dialog>
        <el-dialog
            title="消息卡片使用说明"
            :visible.sync="infoCardDesVisible"
            :close-on-click-modal="false"
            width="30%"
        >
            <!-----消息卡片使用说明 start------->
            <div class="sa-answer-radio-cell">
                <div class="select-cell">消息卡片使用说明</div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="infoCardDesVisible = false"
                        plain
                        >关 闭</el-button
                    >
                </div>
            </div>
            <!-----消息卡片使用说明 end------->
        </el-dialog>

        <el-dialog
            title="添加按钮配置"
            :visible.sync="addBottondiaVisible"
            :close-on-click-modal="false"
            width="450px"
        >
            <!-----消息卡片添加按钮配置弹框 start------->
            <div class="answer-radio-cell">
                <div
                    class="select-cell"
                    v-if="addBottondiaVisibleType == 'simple'"
                >
                    <span class="select-cell-title">按钮名称</span>
                    <el-input
                        size="small"
                        v-model="infoCardActiveConfigObj.name"
                        maxlength="20"
                        placeholder="请输入名称"
                    ></el-input>
                </div>
                <div class="select-cell">
                    <span class="select-cell-title">如果用户点击</span>
                    <el-select
                        v-if="addBottondiaVisibleType == 'simple'"
                        v-model="infoCardActiveConfigObj.scope"
                        @change="infoCardActiveConfigObjScopeChange"
                        size="small"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <el-select
                        v-if="addBottondiaVisibleType != 'simple'"
                        v-model="infoCardActiveConfigObj.scope"
                        @change="infoCardActiveConfigObjScopeChange"
                        size="small"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in jumpTypeAddSendOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select-cell">
                    <span
                        class="select-cell-title"
                        v-show="infoCardActiveConfigObj.scope === 'NODE'"
                        >选择节点</span
                    >
                    <span
                        class="select-cell-title"
                        v-show="infoCardActiveConfigObj.scope === 'INTENT'"
                        >选择意图</span
                    >
                    <span
                        class="select-cell-title"
                        v-show="infoCardActiveConfigObj.scope === 'LINK'"
                        >输入链接</span
                    >
                    <span
                        class="select-cell-title"
                        v-show="
                            infoCardActiveConfigObj.scope ===
                                'SEND_MSG_TO_BOT' ||
                            infoCardActiveConfigObj.scope === 'SEND_MSG_TO_USER'
                        "
                        >输入发送内容</span
                    >
                    <div
                        class="template-config-link-cell"
                        v-if="
                            infoCardActiveConfigObj.scope ===
                                'SEND_MSG_TO_BOT' ||
                            infoCardActiveConfigObj.scope ===
                                'SEND_MSG_TO_USER' ||
                            infoCardActiveConfigObj.scope === 'LINK'
                        "
                    >
                        <ckeditor
                            :editor="editorInline"
                            v-model="infoCardActiveConfigObj.value"
                            @focus="onEditorFocus"
                        ></ckeditor>
                        <span
                            @click="showSelectPlaceholder"
                            class="insert-placeholder"
                            >插入变量</span
                        >
                    </div>

                    <el-select
                        v-if="infoCardActiveConfigObj.scope === 'NODE'"
                        v-model="infoCardActiveConfigObj.value"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :key="item.id"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                    <el-cascader
                        v-show="infoCardActiveConfigObj.scope === 'INTENT'"
                        v-model="infoCardActiveConfigObj.value"
                        size="small"
                        :options="intentTree4Radio"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    
                </div>
                <div class="com-select">
                    <div class="com-select-title">
                        <span class="title_left">将返回值</span>
                        <span class="title_right">赋值给</span>
                        <span class="title_empty"></span>
                    </div>
                    <div
                        v-for="(
                            toEntityCell, toEntityCellIndex
                        ) in infoCardActiveConfigObj.assignments"
                        :key="toEntityCellIndex"
                        class="com-select-cell"
                    >
                        <el-cascader
                            size="small"
                            v-model="toEntityCell.webhookId_key"
                            :options="webhookBaseCascList"
                            :props="{ expandTrigger: 'hover', label: 'name' }"
                        ></el-cascader>

                        <el-cascader
                            size="small"
                            v-model="toEntityCell._full_entity_entity"
                            :options="entityOptions"
                            :props="{ expandTrigger: 'hover' }"
                        ></el-cascader>

                        <div class="d-a-handle-box">
                            <span
                                v-if="toEntityCellIndex == 0"
                                class="el-icon-circle-plus handle-icon"
                                @click="addToEntityCell(toEntityCellIndex)"
                            ></span>
                            <span
                                v-if="toEntityCellIndex != 0"
                                class="
                                    el-icon-circle-close
                                    handle-icon handle-icon-delete
                                "
                                @click="deleteToEntityCell(toEntityCellIndex)"
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="cancelSaveAddBottonConfig"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveAddBottonConfig"
                        type="primary"
                        >保 存</el-button
                    >
                </div>
            </div>
            <!-----消息卡片添加按钮配置弹框 end------->
        </el-dialog>

        <el-dialog
            title="选择数据列表"
            :visible.sync="infoCardSelectDatadiaVisible"
            :close-on-click-modal="false"
            width="750px"
        >
            <!-----消息卡片选择数据列表配置弹框 start------->
            <div class="answer-radio-cell select-data-box">
                <div
                    class="select-data-left"
                    v-if="infoCardSelectDataType == 'PLACEHOLDER-entity'"
                >
                    <div class="select-data-left-webhook">
                        <span class="select-data-left-webhook-cell">分类</span>
                        <span
                            @click="selectInfoCardEntity(wCell)"
                            v-for="(wCell, wCellIndex) in webhookEntityOptions"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectEntity === wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div class="select-data-left-response">
                        <span class="select-data-left-webhook-cell"
                            >实体列表</span
                        >
                        <span
                            @click="selectInfoCardEntityCell(wCell, wCellIndex)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectEntityOptions"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectEntityCell ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                </div>
                <div
                    v-if="
                        infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                        infoCardSelectDataType == 'LOOP-IS-ROOT' ||
                        infoCardSelectDataType == 'PLACEHOLDER-webhook'
                    "
                    class="select-data-left"
                >
                    <div
                        class="select-data-left-webhook"
                        v-if="infoCardSelectDataType == 'LOOP-IS-ROOT'"
                    >
                        <span class="select-data-left-webhook-cell"
                            >WebHook</span
                        >
                        <span
                            @click="selectInfoCardWebhook(wCell)"
                            v-for="(wCell, wCellIndex) in webhookApiList"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhook ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div
                        class="select-data-left-webhook"
                        v-if="infoCardSelectDataType == 'PLACEHOLDER-webhook'"
                    >
                        <span class="select-data-left-webhook-cell"
                            >WebHook</span
                        >
                        <span
                            @click="selectInfoCardWebhookPlaceH(wCell)"
                            v-for="(wCell, wCellIndex) in webhookApiList"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhook ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div
                        v-if="
                            infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                            infoCardSelectDataType == 'LOOP-IS-ROOT'
                        "
                        class="select-data-left-response"
                    >
                        <span class="select-data-left-webhook-cell"
                            >数据列表</span
                        >
                        <span
                            @click="selectInfoCardWebhookResp(wCell)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectWebhookRespListArr"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhookResp ===
                                        wCell.value,
                                },
                            ]"
                            >{{ wCell.name }}</span
                        >
                    </div>
                    <div
                        v-if="infoCardSelectDataType == 'PLACEHOLDER-webhook'"
                        class="select-data-left-response"
                    >
                        <span class="select-data-left-webhook-cell"
                            >数据列表</span
                        >
                        <span
                            @click="selectInfoCardWebhookResp(wCell)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectWebhookRespListArr"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhookResp ===
                                        wCell.value,
                                },
                            ]"
                            >{{ wCell.name }}</span
                        >
                    </div>
                </div>
                <div
                    v-if="
                        infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                        infoCardSelectDataType == 'LOOP-IS-ROOT'
                    "
                    class="select-data-right"
                >
                    <span class="select-data-left-webhook-cell">数据预览</span>
                    <el-tree
                        :data="selectInfoCardTreData"
                        :props="defaultProps"
                        default-expand-all
                        @node-click="selectInfoCardHandleNodeClick"
                    ></el-tree>
                </div>
            </div>
            <div class="answer-radio-cell">
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="infoCardSelectDatadiaVisible = false"
                        plain
                        >取 消</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveAddinfoCardSelectData"
                        type="primary"
                        >确 定</el-button
                    >
                </div>
            </div>
            <!-----消息卡片选择数据列表配置弹框 end------->
        </el-dialog>
        <div>
            <el-dialog
                title="选择变量"
                :visible.sync="templateConfigPlaceholderVisible"
                :close-on-click-modal="false"
                width="700px"
            >
                <!-----模版配置 start------->
                <div class="template-config-cell">
                    <div class="select-cell select-data-box">
                        <div class="select-data-p">
                            <div
                                v-if="
                                    templateConfigParams.type ==
                                    'confirm-placeholder'
                                "
                                class="select-data-left-webhook"
                            >
                                <span class="select-data-left-webhook-cell"
                                    >变量类型</span
                                >
                                <span
                                    @click="
                                        selectTemplatePlaceholderType(wCell)
                                    "
                                    v-for="(wCell, wCellIndex) in [
                                        { label: '实体', id: 'entity' },
                                    ]"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                templateActivePlaceholderType ===
                                                wCell.id,
                                        },
                                    ]"
                                    >{{ wCell.label }}</span
                                >
                            </div>
                            <div
                                v-if="
                                    templateConfigParams.type !=
                                        'confirm-placeholder' ||
                                    templateConfigParams.type ==
                                        'alert-placeholder'
                                "
                                class="select-data-left-webhook"
                            >
                                <span class="select-data-left-webhook-cell"
                                    >变量类型</span
                                >
                                <span
                                    @click="
                                        selectTemplatePlaceholderType(wCell)
                                    "
                                    v-for="(wCell, wCellIndex) in [
                                        { label: '实体', id: 'entity' },
                                        {
                                            label: 'webhook返回值',
                                            id: 'response',
                                        },
                                    ]"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                templateActivePlaceholderType ===
                                                wCell.id,
                                        },
                                    ]"
                                    >{{ wCell.label }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="select-data-left template-select-data-left"
                            v-if="templateActivePlaceholderType == 'entity'"
                        >
                            <div class="select-data-left-webhook">
                                <span class="select-data-left-webhook-cell"
                                    >分类</span
                                >
                                <span
                                    @click="templateSelectInfoCardEntity(wCell)"
                                    v-for="(wCell, wCellIndex) in entityOptions"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                activeTemplateInfoCardSelectEntity ===
                                                wCell.value,
                                        },
                                    ]"
                                    >{{ wCell.label }}</span
                                >
                            </div>
                            <div class="select-data-left-response">
                                <span class="select-data-left-webhook-cell"
                                    >实体列表</span
                                >
                                <span
                                    @click="
                                        selectTemplateInfoCardEntityCell(
                                            wCell,
                                            wCellIndex
                                        )
                                    "
                                    v-for="(
                                        wCell, wCellIndex
                                    ) in activeTemplateInfoCardSelectEntityOptions"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                wCell.isSelected == true,
                                        },
                                    ]"
                                    >{{ wCell.label }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="select-data-left template-select-data-left"
                            v-if="templateActivePlaceholderType == 'response'"
                        >
                            <div class="select-data-left-webhook">
                                <span class="select-data-left-webhook-cell"
                                    >WebHook</span
                                >
                                <span
                                    @click="
                                        selectTemplateInfoCardWebhook(
                                            wCell,
                                            'click'
                                        )
                                    "
                                    v-for="(
                                        wCell, wCellIndex
                                    ) in webhookApiList"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                activeTemplateInfoCardSelectWebhook ===
                                                wCell.id,
                                        },
                                    ]"
                                    >{{ wCell.label }}</span
                                >
                            </div>
                            <div class="select-data-left-response">
                                <span class="select-data-left-webhook-cell"
                                    >数据列表</span
                                >
                                <span
                                    @click="
                                        selectTemplateInfoCardWebhookResp(
                                            wCell,
                                            wCellIndex
                                        )
                                    "
                                    v-for="(
                                        wCell, wCellIndex
                                    ) in activeInfoCardSelectWebhookRespListArr"
                                    :key="wCellIndex"
                                    :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                wCell.isSelected == true,
                                        },
                                    ]"
                                    >{{ wCell.name }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            templateConfigParams.type == 'confirm-placeholder'
                        "
                        class="confirmPlaceholderEditabel"
                    >
                        <el-checkbox v-model="confirmPlaceholderEditabel"
                            >用户是否能编辑</el-checkbox
                        >
                    </div>
                    <div class="answer-radio-cell">
                        <div class="bott-handle">
                            <el-button
                                size="small"
                                @click="
                                    templateConfigPlaceholderVisible = false
                                "
                                plain
                                >取 消</el-button
                            >
                            <el-button
                                v-if="
                                    templateConfigParams.type ==
                                        'placeholder' ||
                                    templateConfigParams.type ==
                                        'placeholder-pre'
                                "
                                size="small"
                                @click="confirmSaveTemplateConfig"
                                type="primary"
                                >确 定</el-button
                            >
                            <el-button
                                v-if="
                                    templateConfigParams.type ==
                                        'confirm-placeholder' ||
                                    templateConfigParams.type ==
                                        'alert-placeholder'
                                "
                                size="small"
                                @click="
                                    confirmValueSaveTemplateConfig(
                                        templateConfigParams.type
                                    )
                                "
                                type="primary"
                                >确 定</el-button
                            >
                            <el-button
                                v-if="
                                    templateConfigParams.type == 'link' ||
                                    templateConfigParams.type == 'url'
                                "
                                size="small"
                                @click="confirmSaveLinkPlacConfig"
                                type="primary"
                                >确 定</el-button
                            >
                        </div>
                    </div>
                </div>
                <!-----模版配置 end------->
            </el-dialog>
        </div>

        <el-dialog
            :title="
                templateConfigParams.type == 'link' ||
                templateConfigParams.type == 'url'
                    ? '插入链接'
                    : '插入图标'
            "
            :visible.sync="templateConfigLiIcVisible"
            :close-on-click-modal="false"
            width="400px"
        >
            <!-----模版配置 start------->
            <div class="template-config-cell">
                <div
                    class="select-cell select-data-box"
                    v-if="templateConfigParams.type == 'link'"
                >
                    <div class="template-config-link">
                        <div class="template-config-link-cell">
                            <span class="template-config-link-title"
                                >链接文本:</span
                            >
                            <el-input
                                size="mini"
                                v-model="templateConfigLinkName"
                            ></el-input>
                        </div>
                        <div class="template-config-link-cell">
                            <span class="template-config-link-title"
                                >链接:</span
                            >
                            <ckeditor
                                :editor="editorInline"
                                v-model="templateConfigLink"
                                @focus="onEditorFocus"
                            ></ckeditor>
                            <span
                                @click="showSelectPlaceholder"
                                class="insert-placeholder"
                                >插入变量</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="select-cell select-data-box"
                    v-if="templateConfigParams.type == 'url'"
                >
                    <div class="template-config-link">
                        <div class="template-config-link-cell">
                            <span class="template-config-link-title"
                                >链接:</span
                            >
                            <ckeditor
                                :editor="editorInline"
                                v-model="templateConfigLink"
                                @focus="onEditorFocus"
                            ></ckeditor>
                            <span
                                @click="showSelectPlaceholder"
                                class="insert-placeholder"
                                >插入变量</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="select-cell select-data-box"
                    v-if="templateConfigParams.type == 'icon'"
                >
                    <div class="template-config-link">
                        <div class="upload-icon-box">
                            <div class="img-box">
                                <img
                                    v-if="templateConfigIconUrl != ''"
                                    height="50px"
                                    :src="templateConfigIconUrl"
                                    alt=""
                                    srcset=""
                                />
                            </div>
                            <el-upload
                                class="rich-text-image-upload"
                                :show-file-list="showFileList"
                                :action="importFileUrl"
                                :before-upload="beforeImageMUpload"
                                :on-success="
                                    (response, file, fileList) =>
                                        handleIconUploadSuccess(
                                            response,
                                            file,
                                            fileList
                                        )
                                "
                            >
                                <i class="el-icon-upload"></i>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div class="answer-radio-cell">
                    <div class="bott-handle">
                        <el-button
                            size="small"
                            @click="templateConfigLiIcVisible = false"
                            plain
                            >取 消</el-button
                        >
                        <el-button
                            size="small"
                            @click="confirmSaveTemplateConfig"
                            type="primary"
                            >确 定</el-button
                        >
                    </div>
                </div>
            </div>
            <!-----模版配置 end------->
        </el-dialog>
        <effectiveTime
            ref="effectiveTime"
            @addEffectiveTimeVisibleHandle="addEffectiveTimeVisibleHandle"
            :addEffectiveTimeVisible="addEffectiveTimeVisible"
            :effectiveTimeObjP="effectiveTimeObjP"
            :timeTimeRange="timeTimeRange"
            :timeRepeat="timeRepeat"
        ></effectiveTime>

        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip :tipIndex="tipIndex"/>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="versionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}
import { lastEditor, currentEditor, changeEditor } from "./ChartFlowContext";
// import {lastEditor} from "./ChartFlowContext";

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import Popup from "@/components/popup";
import VersionTip from "./../version/VersionTip.vue";

import Sortable from "sortablejs";
import recursive from "./chatFlowNodeComponent";
import intentComponent from "./IntentComponent.vue";
import intentNodeComponent from "./intentNodeComponent.vue";
import Bus from "./bus.js";
import { apiKeys } from "../../const/SpecialApiKey.js";
import Driver from "driver.js"; // import driver.js
import "driver.js/dist/driver.min.css"; // import driver.js css
import { skillItem, intentItem } from "./tampData";
// answerInit
import { answerInit } from "./utils/answerInit";
// 发送邮件
import aMail from "./component/action/Action_mail";
// 消息卡片V2
import aRichtextpro from "./component/answer/Rich_text_pro_v2";
// 快捷选择
import aFastselect from "./component/action/Action_fast_select.vue";

// 智能引导
import aIntellectGuide from "./component/answer/Answer_intellect_guide.vue";
// 提交表单模板
import aFormsubmit from "./component/answer/Form_submit";
// 确认值答案
import aCconfirmvalue from "./component/answer/Action_confirm_value";
// 弹窗答案
import aAlertIframe from "./component/action/Action_alert_iframe";
// 答案反馈
import aFeedback from "./component/action/Action_feedback";
// 反问3.0
import aQuestion from "./component/action/Action_question";
// 强制执行
import aEnforce from "./component/action/Action_enforce";
// 转企业微信
import aTransQw from "./component/action/Action_trans_qywxx";

// 生效时间设置
import effectiveTime from "./condition/EffectiveTime";

// 关联推荐意图
import cRecommend from "./component/other/Recommend";

import UEditorRichText from "./component/answer/UEditorRichText";

// import "../../../public/zh_CN";

import VueUeditorWrap from "vue-ueditor-wrap";

// import ClassicEditor from "ckeditor";
import CKEDITOR from "ckeditor";
import { filterSingleWithScope } from "./filter/WebHookResponseFilter";

export default {
    props: ["isPart", "drawerSize", "intentIds"],
    data() {
        return {
            versionTipDialog: false,
            tipIndex: 2,
            botVersion: '',

            skillItemTamp: skillItem,
            intentItemTamp: intentItem,
            parentIntentItemTamp: intentItem,
            isOtherSkill: false,
            entityOptionsBot: [],
            confirmPlaceholderEditabel: true,
            // 卡片模版
            templateConfigPlaceholderVisible: false,
            templateConfigLiIcVisible: false,
            templateConfigParams: {},
            templateActivePlaceholderType: "entity",
            activeTemplateInfoCardSelectEntity: "",
            activeTemplateInfoCardSelectEntityOptions: [],
            tampActTemplateInfoCardSelectEntityOptions: [],
            activeTemplateInfoCardSelectEntityCell: "",
            activeTemplateInfoCardSelectEntityObj: {},
            activeTemplateInfoCardSelectWebhook: "",
            activeTemplateInfoCardSelectWebhookObj: {},
            activeTemplateInfoCardSelectWebhookResp: "",
            activeTemplateInfoCardSelectWebhookRespObj: {},
            templateConfigLinkName: "",
            templateConfigLink: "",
            templateConfigIconUrl: "",
            // ckeditor
            currentEditor: null,
            editorInline: CKEDITOR.InlineEditor,
            ckeditor: {
                currentCallBack: () => {}, // 当前回调
                // editor: ClassicEditor,
                editor: CKEDITOR.ClassicEditor,
                editorConfig: {
                    toolbar: [
                        "undo",
                        "redo",
                        "|",
                        "insertNotifyButton",
                        "insertNotifyList",
                        "insertNotifyPlaceholder4Entity",
                        "insertNotifyPlaceholder4Webhook",
                    ],
                    askPluginListener: [
                        {
                            event: "BUTTON",
                            process: this.beforeButtonInsert,
                        },
                        {
                            event: "PLACEHOLDER",
                            process: this.beforePlaceholderInsert,
                        },
                        {
                            event: "LOOP",
                            process: this.beforeLoopBlockInsert,
                        },
                    ], // 注册监听
                },
            },

            // 消息卡片
            addBottondiaVisible: false,
            addBottondiaVisibleType: "simple",
            infoCardActiveConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
                assignments: [{ webhookId_key: "", _full_entity_entity: "" }],
                currentWebHookSCope: null,
            },
            infoCardSelectDatadiaVisible: false,
            activeInfoCardSelectWebhook: "",
            activeInfoCardSelectWebhookObj: {},
            activeInfoCardSelectWebhookRespList: [],
            activeInfoCardSelectWebhookRespListArr: [],
            tampActiveInfoCardSelectWebhookRespListArr: [],
            activeInfoCardSelectWebhookResp: "",
            activeInfoCardSelectWebhookRespObj: {},
            selectInfoCardTreData: [],
            defaultProps: {
                children: "children",
                label: "label",
            },
            infoCardLoopIsRoot: true,
            infoCardSelectDataType: "BUTTON",
            activeInfoCardSelectEntity: "",
            activeInfoCardSelectEntityOptions: [],
            activeInfoCardSelectEntityCell: "",
            activeInfoCardSelectEntityObj: {},
            placeHolderActiveEvent: {},
            //
            loading: false,
            botList: [],
            activeBot: "",
            nodeNameValue: "",
            activeNodeName: "",
            loginSuccessTimes: "",
            guidTaskId: null,
            nodeTree: [],
            skillId: "",
            intentId: "",
            bid: "",
            id: "",
            name: "",
            bName: "",
            rollToId: "",
            nodeId: "",
            showNodeName: "",
            //节点名称验证规则对象
            nodeName_rules: {
                //验证节点名称是否合法
                name: [
                    {
                        min: 5,
                        max: 20,
                        message: "节点民初长度在 3 到 20 个字符",
                        trigger: "blur",
                    },
                ],
            },
            // 取消保存确认
            cancelSaveVisible: false,
            cancelMoveNodeSaveVisible: false,
            // 组件类型列表
            componentsTypes: [],
            // 条件列表
            conditionTypes: [
                { relations: [] },
                { relations: [] },
                { relations: [] },
            ],
            // 意图情况
            intentStatus: [
                { name: "当前意图", id: "CURRENT" },
                { name: "上一个意图", id: "LAST" },
                { name: "是否识别过意图", id: "RECOGNITION" },
                { name: "意图是否已经结束", id: "CLOSED" },
            ],
            activeIntentStatus: "当前意图",
            intentValue: [],
            // 实体
            allEntityValue: [],
            activeEntityValue: "",
            // 初始化组件条件添加
            conditionConfigTitle: "组件操作",
            initConfigData: {
                conditionType: "ENTITY",
                entityValue: [],
                conditionValue: "EQUAL",
                activeEntityValue: "",
                ext: {},
            },
            // 流程json
            actionList: [],
            // 条件配置
            conditionEditing: {}, // 当前编辑条件obj
            conditionConfigVisible: false,
            conditionConfigPosition: {
                right: 0,
                top: 0,
            },
            conditionType: "ENTITY", // 条件配置类型
            conditionTypesOptions: [
                {
                    // 条件配置类型option
                    value: "ENTITY",
                    label: "实体",
                },
                {
                    value: "INTENT",
                    label: "意图",
                },
                {
                    value: "USER_WAITING_TIME",
                    label: "用户静默时间",
                },
                {
                    value: "WEB_HOOK_RESPONSE",
                    label: "返回值",
                },
            ],
            entityValue: [],
            entityOptions: [],
            entityValueVal: "",
            conditionValue: "EQUAL",
            // 等待时间
            silentTimeValue: "",
            silentTimeUnit: "1s",
            // 返回值配置
            webhookConditionTypes: [],
            conditionResponseValue: "",
            conditionResponseType: "VALUE",
            conditionResponseCondit: "",
            conditionResponseScene: "",
            conditionResponseNum: "",
            // 答案抽屉
            drawer: false,
            direction: "rtl",
            showTip: false,
            activeIndex: "1",
            activeIndex2: "1",
            tabPosition: "left",
            options: [],
            textarea1: "",
            // 反问实体
            value: "",
            input: "",
            allEntitys: [],
            state1: "",
            state2: "",
            entityHandleOptions: [
                {
                    value: 0,
                    label: "不再反问",
                },
                {
                    value: 1,
                    label: "继续反问",
                },
            ],
            entityHandleValue: "QUIT_Q",
            // 跳转
            jumpTypeValue: "NODE",
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            jumpTypeAddContentOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "CONTENT",
                    label: "向用户发送内容",
                },
            ],
            jumpTypeAddSendOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_BOT",
                    label: "向机器人发送内容",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
                {
                    value: "LINK",
                    label: "跳转链接",
                },
            ],
            // activeJumpValue: "",
            nodeOptions: [],
            moveToOptions: [],
            motoInitNode: [],
            intentOptions: [],
            intentSAkillTypeOptions: [],
            intentSAkillTypeOptionsNoChildren: [],
            // 点选配置
            answerRadioConfigVisible: false,
            activeRadionConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
            },
            activeRadioConfigIndex: [],
            // 图片
            importFileUrl: location.origin + "/api/oss",
            dialogImageVisible: false,
            // 转人工
            custonerOptions: [
                // { id: 1, name: "火车客服组" },
                // { id: 2, name: "淘宝客服组" }
            ],
            artificialSendTextValue: 1,
            selectCustRadioVisible: false, // 转人工客服组选择按钮弹窗
            selectCustRadioObj: {
                name: "",
                value: "",
                customerId: "",
            },
            selectCustRadioConfigIndex: [],
            // groupList: [], // 客服组
            //
            actionListCopy: [],
            isStartOrEnd: false, // 当前操作是否为开始或者结束节点
            isUnrecognized: false,
            // 满意度组件操作
            satiAnswerRadioVisible: false,
            activeSatiAnswerRadioObj: {
                name: "",
                answer: "",
            },
            activeSaIndex: 0, // 当前编辑满意度组件index
            activeSaCellIndex: 0, // 点选按钮位置
            // 视频组件
            activeVideoIndex: 0, // 当前操作视频组件index
            videoFlag: false,
            // webhook
            webhookEntityOptions: [],
            cascaderProps: {
                value: "label",
            },
            noGetParamOptions: [
                { name: "执行反问", id: "QUESTION" },
                { name: "执行点选", id: "RADIO" },
                { name: "跳转", id: "GOTO" },
                { name: "不获取", id: "NONE" },
            ],
            noGetParamOptionsRequire: [
                { name: "执行反问", id: "QUESTION" },
                { name: "执行点选", id: "RADIO" },
                { name: "跳转", id: "GOTO" },
            ],
            noGetParamQNumber: [
                { name: 1, id: 1 },
                { name: 2, id: 2 },
                { name: 3, id: 3 },
                { name: 4, id: 4 },
                { name: 5, id: 5 },
                { name: "直到检测到实体", id: -1 },
            ],
            webhookDirList: [],
            // webhook点选配置
            webhookAnswerRadioConfigVisible: false,
            webhookActiveRadionConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
            },
            webhookActiveRadioConfigIndex: [],
            webhookSendToRobotContent: "",
            webhookbaseList: [],
            webhookBaseCascList: [],
            webhookMultivaluedCascList: [],
            expBaseCascList: [],
            // 消息卡片
            infoCardDesVisible: false,
            //是否显示进度条
            videoUploadPercent: "",
            //进度条的进度，
            isShowUploadVideo: false,
            //显示上传按钮
            videoForm: {
                showVideoPath: "",
            },
            // 意图树
            intentTree4Radio: [],
            changeNoderelationVisible: false, // 变更节点关系弹窗
            activeMoveNodeIndexArr: [0, 0, 0, 0],
            activeMoveNodeId: "",
            activeMoveToNode: "",
            isPreMoveNode: false,
            activeInitNode: {},
            isInitNode: false,
            msg: "<h2>Vue + UEditor + v-model双向绑定</h2>",
            // 简单配置
            editorConfig: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 200,
                // 字数统计
                wordCount: false,
                // 元素路径
                elementPathEnabled: false,
                // 初始容器宽度
                initialFrameWidth: "98%",
                // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
                // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
                // serverUrl: "https://open.guoranbot.com/ueditor",
                // serverUrl: "http://test.open.abitai.com",
                serverUrl: "/ueditor-api/",
                errorHandler: (e) => {
                    console.log(e);
                },

                UEDITOR_HOME_URL: "/UEditor/",
                toolbars: [
                    [
                        "undo", //撤销
                        "redo", //重做
                        "bold", //加粗
                        "indent", //首行缩进
                        "snapscreen", //截图
                        "italic", //斜体
                        "underline", //下划线
                        "strikethrough", //删除线
                        "subscript", //下标
                        "superscript", //上标
                        "source", //源代码
                        "removeformat", //清除格式
                        "fontfamily", //字体
                        "fontsize", //字号
                        "paragraph", //段落格式
                        "simpleupload", //单图上传
                        "link", //超链接
                        "emotion", //表情
                        // "insertvideo", //视频
                        "justifyleft", //居左对齐
                        "justifyright", //居右对齐
                        "justifycenter", //居中对齐
                        "justifyjustify", //两端对齐
                        "forecolor", //字体颜色
                        "backcolor", //背景色
                        // "insertorderedlist", //有序列表
                        // "insertunorderedlist", //无序列表
                        "fullscreen", //全屏
                        "attachment", //附件
                        "imagecenter", //居中
                        // "edittip ", //编辑提示
                        // "inserttable", //插入表格
                    ],
                ],
                fontfamily: [
                    { label: "", name: "songti", val: "宋体,SimSun" },
                    { label: "仿宋", name: "fangsong", val: "仿宋,FangSong" },
                    {
                        label: "仿宋_GB2312",
                        name: "fangsong",
                        val: "仿宋_GB2312,FangSong",
                    },
                    {
                        label: "",
                        name: "kaiti",
                        val: "楷体,楷体_GB2312, SimKai",
                    },
                    {
                        label: "",
                        name: "yahei",
                        val: "微软雅黑,Microsoft YaHei",
                    },
                    { label: "", name: "heiti", val: "黑体, SimHei" },
                    { label: "", name: "lishu", val: "隶书, SimLi" },
                    { label: "", name: "andaleMono", val: "andale mono" },
                    {
                        label: "",
                        name: "arial",
                        val: "arial, helvetica,sans-serif",
                    },
                    {
                        label: "",
                        name: "arialBlack",
                        val: "arial black,avant garde",
                    },
                    { label: "", name: "comicSansMs", val: "comic sans ms" },
                    { label: "", name: "impact", val: "impact,chicago" },
                    {
                        label: "",
                        name: "timesNewRoman",
                        val: "times new roman",
                    },
                ],
            },
            // webhook组件
            webhookOptions: [
                // {name: 'webhook1', id: 1},{name: 'webhook2', id: 2}
            ],
            webhookApiList: [],
            webhookCell: {
                id: 23,
                mainId: "6cdfcac6a7d611eabdcd506b4b2f3acc",
                name: "测试添加webhook",
                description: "测试添加webhook描述",
                dirId: 47,
                method: "GET",
                url: "https://test.open.askbot.cn/webhook/23",
                setting: {
                    authorization: {
                        type: null,
                        token: null,
                        userName: null,
                        password: null,
                    },
                    headers: [
                        {
                            key: "",
                            value: "",
                            description: "",
                        },
                    ],
                    params: [
                        {
                            key: "id",
                            value: "1",
                            description: "",
                            required: false,
                            entityId: null,
                            entityName: ["画室咨询机器人-默认知识库", "性别"],
                        },
                    ],
                    body: {
                        type: "none",
                        content: null,
                        params: [
                            {
                                key: "",
                                value: "",
                                description: "",
                                required: null,
                                entityId: null,
                                entityName: null,
                            },
                        ],
                        fileUrl: null,
                        fileItem: null,
                    },
                    response: {
                        code: null,
                        msg: null,
                        data: null,
                        sendResponse: {
                            code: "200",
                            msg: "success",
                            data: "",
                        },
                        config: [
                            {
                                name: "",
                                multivalued: false,
                                value: "",
                                type: null,
                            },
                        ],
                    },
                },
                status: 0,
                deleted: false,
                creator: 13,
                createTime: "2020-09-09T08:21:17.000+0000",
                updateTime: "2020-09-09T08:21:17.000+0000",
                activeTabName: "first",
            },
            // 生效时间
            timeTimeRange: [new Date(), new Date()],
            addEffectiveTimeVisible: false,
            effectiveTimeObjP: {
                range: "PERMANENT", // PERMANENT/TIME/DATETIME/EFFECTIVE_TIME
                startTime: "",
                endTime: "",
                repeat: ["1", "2", "3", "4", "5", "6", "7"], // 1 2 3 4 5 6 7
                timeRange: [new Date(), new Date()],
                filterHolidays: true,
                balanceHolidays: true,
            }, // 当前编辑生效时间信息
            effectiveTimeIndex: 0, // 当前编辑生效时间index
            customerServiceOfflineIntentId: "", // 没有客服在线默认跳转到意图
            maxLengthLimit:40
        };
    },
    components: {
        UEditorRichText,
        aMail,
        VueUeditorWrap,
        recursive,
        aRichtextpro,
        aFormsubmit,
        aCconfirmvalue,
        aAlertIframe,
        aFeedback,
        effectiveTime,
        aEnforce,
        cRecommend,
        aFastselect,
        aIntellectGuide,
        aTransQw,
        aQuestion,
        intentComponent,
        intentNodeComponent,
        VersionTip,
        Popup,
    },
    filters: {},
    methods: {
        saveAigConfig(value, index){
            this.$set(this.actionList, index, value);
            let actionListCopy = JSON.parse(
                JSON.stringify(this.actionList)
            );
            this.actionList = [];
            this.$nextTick(() => {
                this.actionList = [...actionListCopy]
            })
        },
        closePartDrewer(){
            console.log("close___________")
            this.drawer = false;
            if (this.isPart == 1) {
                document.getElementsByClassName('v-modal')[0].style.height = '100%';
            }
        },
        getOldAndNewIntentFlow() {
            console.log(this.intentIds);
            this.FetchGet(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId: this.intentIds[0],
            })
                .then((res) => {
                    if (res.code === "0") {
                        res.data.initNode.children =
                            res.data.initNode.childrenNode == null
                                ? []
                                : res.data.initNode.childrenNode;
                        let setName = (firstNodeArr) => {
                            //遍历树  获取id数组
                            firstNodeArr.forEach((item) => {
                                if (item.childrenNode === null) {
                                    item.children = [];
                                } else {
                                    item.children = item.childrenNode;
                                    if (item.children.length > 0) {
                                        setName(item.children);
                                    }
                                }
                            });
                        };
                        setName(res.data.initNode.children);
                        this.intentItemTamp = {
                            id: this.intentIds[0],
                            name: "",
                            isLeafNode: true,
                            hasProcess: true,
                            children: [res.data.initNode],
                            show: true
                        };
                        this.intentItemTamp.children = [...this.intentItemTamp.children];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
            this.FetchGet(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId: this.intentIds[1],
            })
                .then((res) => {
                    if (res.code === "0") {
                        res.data.initNode.children =
                            res.data.initNode.childrenNode == null
                                ? []
                                : res.data.initNode.childrenNode;
                        let setName = (firstNodeArr) => {
                            //遍历树  获取id数组
                            firstNodeArr.forEach((item) => {
                                if (item.childrenNode === null) {
                                    item.children = [];
                                } else {
                                    item.children = item.childrenNode;
                                    if (item.children.length > 0) {
                                        setName(item.children);
                                    }
                                }
                            });
                        };
                        setName(res.data.initNode.children);
                        this.parentIntentItemTamp = {
                            id: this.intentIds[1],
                            name: "",
                            isLeafNode: true,
                            hasProcess: true,
                            children: [res.data.initNode],
                            show: true
                        };
                        this.parentIntentItemTamp.children = [...this.parentIntentItemTamp.children];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        entityFetchSuggestions(queryString, cb) {
            cb([{ value: "空", label: "空" }]);
        },
        filterJumpTypeOptions(value) {
            if (this.isStartOrEnd) {
                return [
                    {
                        value: "INTENT",
                        label: "跳转到意图",
                    },
                ];
            } else {
                return value;
            }
        },
        submitErrorChangeOptions(e, index) {
            this.actionList[index].content.ifOffline.value = "";
        },
        formatWekList(data) {
            let list = [],
                wekList = [
                    { lable: "周一", id: "1" },
                    { lable: "周二", id: "2" },
                    { lable: "周三", id: "3" },
                    { lable: "周四", id: "4" },
                    { lable: "周五", id: "5" },
                    { lable: "周六", id: "6" },
                    { lable: "周日", id: "7" },
                ];
            wekList.forEach((item) => {
                if (data.indexOf(item.id) != -1) {
                    list.push(item.lable);
                }
            });
            if (list.length == 7) {
                list = ["每天"];
            }
            if (list.length == 0) {
                list = ["无"];
            }
            return list;
        },
        effectiveTimeFormatDateTime(value) {
            let date = new Date(value);
            let str = "";
            if (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                var h = date.getHours();
                h = h < 10 ? "0" + h : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                var second = date.getSeconds();
                second = second < 10 ? "0" + second : second;
                str =
                    y +
                    "-" +
                    m +
                    "-" +
                    d +
                    " " +
                    h +
                    ":" +
                    minute +
                    ":" +
                    second;
            }

            return str;
        },
        addEffectiveTimeVisibleHandle(data) {
            this.addEffectiveTimeVisible = false;
            console.log(data.data);
            if (data.type == "save") {
                this.actionList[this.effectiveTimeIndex].effectiveTime =
                    data.data;
            }
        },
        /**
         * 添加生效时间
         * @method addEffectiveTime
         * @param {Number} 组件index
         * @param {unit} 组件信息
         * @return {}
         */
        addEffectiveTime(index, unit) {
            this.effectiveTimeIndex = index;
            if ("effectiveTime" in unit) {
                this.effectiveTimeObjP = JSON.parse(
                    JSON.stringify(unit.effectiveTime)
                );
            } else {
                this.effectiveTimeObjP = {
                    range: "PERMANENT", // PERMANENT/TIME/DATETIME/EFFECTIVE_TIME
                    startTime: "",
                    endTime: "",
                    repeat: ["1", "2", "3", "4", "5", "6", "7"], // 1 2 3 4 5 6 7
                    timeRange: [
                        new Date(2016, 9, 10, 8, 40, 0),
                        new Date(2016, 9, 10, 8, 40, 59),
                    ],
                    filterHolidays: true,
                    balanceHolidays: true,
                };
            }
            this.addEffectiveTimeVisible = true;
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        rowDrop() {
            const box = document.querySelector(".interaction-content");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.actionList.splice(oldIndex, 1);
                    _this.actionList.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.actionList = _this.actionList;
        },

        getId(name, id) {
            return name + id
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        sortClickBtn(e, items, index) {
            e.stopPropagation()
            let _this = this;
            let idname = 'custom-column-field' + index;
            let box = document.getElementById(idname)
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: 'shared',
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item =  _this.actionList[index].content.options.splice(oldIndex, 1);
                    _this.actionList[index].content.options.splice(newIndex, 0, item[0]);
                },
            });
            this.actionList = _this.actionList;
        },
        updateFormUnit(data) {
            this.actionList[data.unitIndex] = data.unit;
            this.actionList = [...this.actionList];
        },
        getAllEntityByBot() {
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill, {
                botId: this.activeBot,
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptionsBot = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 消息卡片V2
        onEditorFocus(zh, editor) {
            changeEditor(editor);
            // this.currentEditor = editor;
        },
        handleIconUploadSuccess(response, file, fileList) {
            if (response.code == 0) {
                this.templateConfigIconUrl = response.data;
            }
        },
        rtpAddBottondiaVisible(content) {
            console.debug(
                "rtpAddBottondiaVisible",
                content.index,
                content.webHookSCope
            );
            this.addBottondiaVisible = true;
            this.addBottondiaVisibleType = "rtpBtnEdit";
            this.infoCardActiveConfigObj = content.info;
            this.infoCardActiveConfigObj.currentWebHookSCope =
                content.webHookSCope;
            this.webhookBaseCascList = filterSingleWithScope(
                this.webhookBaseCascList,
                content.webHookSCope
            );
        },
        showSelectPlaceholder() {
            this.templateConfigPlaceholderVisible = true;
        },
        templateConfigVisibleHandle(content) {
            // 初始化
            this.templateActivePlaceholderType = "entity";
            this.activeTemplateInfoCardSelectEntity = "";
            this.activeTemplateInfoCardSelectEntityCell = "";
            this.activeTemplateInfoCardSelectWebhook = "";
            this.activeTemplateInfoCardSelectWebhookResp = "";
            this.activeInfoCardSelectWebhookRespListArr = [];
            this.tampActiveInfoCardSelectWebhookRespListArr = [];

            this.templateConfigLinkName = "";
            this.templateConfigLink = "";

            this.templateConfigIconUrl = "";

            this.templateConfigParams = content;
            console.log(content);
            if (
                this.templateConfigParams.type == "placeholder" &&
                this.templateConfigParams.multiple
            ) {
                this.selectTemplateInfoCardWebhook(
                    {
                        id: this.templateConfigParams.webhookId_key[0],
                        value: this.templateConfigParams.webhookId_key[1],
                    },
                    "scope"
                );
            }
            if (
                this.templateConfigParams.type == "placeholder" ||
                this.templateConfigParams.type == "confirm-placeholder" ||
                this.templateConfigParams.type == "alert-placeholder"
            ) {
                this.templateConfigPlaceholderVisible = true;
            }
            if (
                this.templateConfigParams.type == "link" ||
                this.templateConfigParams.type == "icon" ||
                this.templateConfigParams.type == "url"
            ) {
                this.templateConfigLiIcVisible = true;
            }
        },
        confirmSaveLinkPlacConfig() {
            if (this.templateActivePlaceholderType == "entity") {
                this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                    (item) => {
                        if (item.isSelected) {
                            this.addEntityPlace(item);
                        }
                    }
                );
            } else {
                this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                    (item) => {
                        this.addWebHookSinglePlace(
                            item,
                            this.activeTemplateInfoCardSelectWebhook
                        );
                    }
                );
            }
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
            this.activeInfoCardSelectWebhookRespListArr.forEach((item) => {
                item.isSelected = false;
            });
            this.templateConfigPlaceholderVisible = false;
        },
        addEntityPlace(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.label,
                    data: entityDataHolder(data.value),
                },
            });
        },
        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },
        addWebHookPlaceWithScope(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolderWithScope(data.value),
                },
            });
        },
        trLink(value) {
            let link = value;
            let htmlDivElement = document.createElement("div");
            htmlDivElement.innerHTML = link;
            let placeholders = htmlDivElement.getElementsByClassName(
                "ask-component-placeholder"
            );
            if (placeholders != null && placeholders.length > 0) {
                for (let i = 0, len = placeholders.length; i < len; i++) {
                    let attribute =
                        "'+" + placeholders[i].getAttribute("th:text") + "+'";
                    placeholders[i].innerText = attribute;
                }
            }
            link = "'" + htmlDivElement.innerText + "'";
            return link;
        },
        confirmValueSaveTemplateConfig(type) {
            let arichtextproName = "";
            if (type == "confirm-placeholder") {
                arichtextproName =
                    "action_confirm_value" +
                    this.templateConfigParams.unitIndex;
            }
            if (type == "alert-placeholder") {
                arichtextproName =
                    "action_alert_iframe" + this.templateConfigParams.unitIndex;
            }
            if (this.templateActivePlaceholderType == "response") {
                if (type == "alert-placeholder") {
                    this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                        (item) => {
                            this.$refs[
                                arichtextproName
                            ][0].addWebHookSinglePlace(
                                item,
                                this.activeTemplateInfoCardSelectWebhook
                            );
                        }
                    );
                } else {
                    this.$refs[arichtextproName][0].addConfirmDataResponse();
                }
            }
            if (this.templateActivePlaceholderType == "entity") {
                this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                    (item) => {
                        if (item.isSelected) {
                            this.$refs[
                                arichtextproName
                            ][0].addConfirmDataEntity(
                                item,
                                this.confirmPlaceholderEditabel
                            );
                        }
                    }
                );
            }
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
            this.templateConfigPlaceholderVisible = false;
        },
        confirmSaveTemplateConfig() {
            let arichtextproName =
                "arichtextpro" +
                this.templateConfigParams.activeARichtextproIndex;
            switch (this.templateConfigParams.type) {
                case "placeholder-pre":
                case "placeholder":
                    if (this.templateActivePlaceholderType == "response") {
                        if (
                            this.templateConfigParams.multiple &&
                            this.templateConfigParams.webhookId_key[1] ==
                                this.activeTemplateInfoCardSelectWebhookRespObj
                                    .parentValue
                        ) {
                            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addWebHookPlaceWithScope(item);
                                }
                            );
                        } else {
                            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addWebHookSinglePlace(
                                        item,
                                        this.activeTemplateInfoCardSelectWebhook
                                    );
                                }
                            );
                        }
                    }
                    if (this.templateActivePlaceholderType == "entity") {
                        this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                            (item) => {
                                if (item.isSelected) {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addEntityPlace(item);
                                }
                            }
                        );
                    }
                    this.activeTemplateInfoCardSelectEntityOptions.forEach(
                        (item) => {
                            item.isSelected = false;
                        }
                    );
                    this.activeInfoCardSelectWebhookRespListArr.forEach(
                        (item) => {
                            item.isSelected = false;
                        }
                    );
                    this.tampActTemplateInfoCardSelectEntityOptions = [];
                    this.tampActiveInfoCardSelectWebhookRespListArr = [];
                    this.templateConfigPlaceholderVisible = false;
                    break;
                case "url":
                    this.actionList[
                        this.templateConfigParams.activeARichtextproIndex
                    ].content.content.htmlList[
                        this.templateConfigParams.cellIndex
                    ].src = this.trLink(this.templateConfigLink);
                    this.actionList[
                        this.templateConfigParams.activeARichtextproIndex
                    ].content.content.htmlList[
                        this.templateConfigParams.cellIndex
                    ].dynamic = "true";
                    let actionListStr = JSON.stringify(this.actionList);
                    this.actionList = [...JSON.parse(actionListStr)];
                    this.templateConfigLiIcVisible = false;
                    break;
                case "link":
                    let command = lastEditor.commands.get("insertAskComponent");
                    command.execute({
                        tag: "a",
                        options: {
                            href: this.trLink(this.templateConfigLink),
                            name: this.templateConfigLinkName,
                        },
                    });
                    this.templateConfigLiIcVisible = false;
                    break;
                case "icon":
                    if (this.templateActivePlaceholderType == "entity") {
                        this.$refs[arichtextproName][0].addIcon(
                            this.templateConfigIconUrl
                        );
                    }
                    this.templateConfigLiIcVisible = false;
                    break;
                default:
                    break;
            }
        },
        selectTemplatePlaceholderType(info) {
            this.templateActivePlaceholderType = info.id;
        },
        templateSelectInfoCardEntity(item) {
            this.activeTemplateInfoCardSelectEntity = item.value;
            this.activeTemplateInfoCardSelectEntityOptions = item.children;
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
        },
        selectTemplateInfoCardEntityCell(data, index) {
            this.activeTemplateInfoCardSelectEntityCell = data.id;
            this.activeTemplateInfoCardSelectEntityObj = data;
            this.activeTemplateInfoCardSelectEntityOptions[index].isSelected =
                !this.activeTemplateInfoCardSelectEntityOptions[index]
                    .isSelected;
            this.activeTemplateInfoCardSelectEntityOptions = [
                ...this.activeTemplateInfoCardSelectEntityOptions,
            ];

            let isExit = false,
                exitIndex = -1;
            this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                (item, itemIndex) => {
                    if (data.id == item.id) {
                        isExit = true;
                        exitIndex = itemIndex;
                    }
                }
            );
            if (isExit) {
                this.tampActTemplateInfoCardSelectEntityOptions.splice(
                    exitIndex,
                    1
                );
            } else {
                this.tampActTemplateInfoCardSelectEntityOptions.push(data);
            }
            this.tampActTemplateInfoCardSelectEntityOptions = [
                ...this.tampActTemplateInfoCardSelectEntityOptions,
            ];
        },
        selectTemplateInfoCardWebhook(cell1, type) {
            let cell = {};
            if (type == "click") {
                cell = {
                    id: cell1.id,
                    value: this.templateConfigParams.webhookId_key[1],
                };
            } else {
                cell = {
                    id: this.templateConfigParams.webhookId_key[0],
                    value: this.templateConfigParams.webhookId_key[1],
                };
            }

            this.activeTemplateInfoCardSelectWebhook = cell.id;
            this.activeTemplateInfoCardSelectWebhookObj = cell;

            this.FetchGet(this.requestUrl.webhook.webhookDetail + cell.id).then(
                (res) => {
                    if (res.code === "0") {
                        this.activeTemplateInfoCardSelectWebhookResp = "";
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;
                            let activeInfoCardSelectWebhookRespListArr = [];
                            if (type !== "scope" && type !== "click") {
                                this.activeInfoCardSelectWebhookRespList.forEach(
                                    (item, index) => {
                                        if (!item.multivalued) {
                                            if (
                                                item.value.indexOf("[*]") == -1
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                    }
                                );
                            } else {
                                this.activeInfoCardSelectWebhookRespList.forEach(
                                    (item, index) => {
                                        if (!item.multivalued) {
                                            if (
                                                item.value.indexOf("[*]") == -1
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                        if (
                                            item.value.split("[*].")[0] ==
                                                cell.value &&
                                            item.value.split("[*].").length == 2
                                        ) {
                                            activeInfoCardSelectWebhookRespListArr.push(
                                                item
                                            );
                                        }
                                    }
                                );
                            }
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => (item.isSelected = false)
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectTemplateInfoCardWebhookResp(data, index) {
            this.activeTemplateInfoCardSelectWebhookResp = data.value;
            this.activeTemplateInfoCardSelectWebhookRespObj = data;
            this.activeInfoCardSelectWebhookRespListArr[index].isSelected =
                !this.activeInfoCardSelectWebhookRespListArr[index].isSelected;
            this.activeInfoCardSelectWebhookRespListArr = [
                ...this.activeInfoCardSelectWebhookRespListArr,
            ];

            let isExit = false,
                exitIndex = -1;
            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                (item, itemIndex) => {
                    if (data.value == item.value) {
                        isExit = true;
                        exitIndex = itemIndex;
                    }
                }
            );
            if (isExit) {
                this.tampActiveInfoCardSelectWebhookRespListArr.splice(
                    exitIndex,
                    1
                );
            } else {
                this.tampActiveInfoCardSelectWebhookRespListArr.push(data);
            }
            this.tampActiveInfoCardSelectWebhookRespListArr = [
                ...this.tampActiveInfoCardSelectWebhookRespListArr,
            ];
        },
        // webhook 展示数据展开和收起
        showFailedActionCollapse(index) {
            this.actionList[index].content.failedActionCollapse =
                !this.actionList[index].content.failedActionCollapse;
            this.actionList = [...this.actionList];
        },
        showresponseCollapse(index) {
            this.actionList[index].content.responseCollapse =
                !this.actionList[index].content.responseCollapse;
            this.actionList = [...this.actionList];
        },
        webhookactionNoParamDescriptionChange(e, index, indexCell) {
            console.log(e, index, indexCell);
        },
        addToEntityCell(index) {
            this.infoCardActiveConfigObj.assignments.push({
                webhookId_key: "",
                _full_entity_entity: "",
            });
        },
        deleteToEntityCell(index) {
            this.infoCardActiveConfigObj.assignments.splice(index, 1);
        },
        webhookApiListHandleChange(e) {
            console.log(e);
        },
        beforeButtonInsert(event, callback) {
            // let type = event.type; // 事件类型
            // let param = event.param; // 各个事件param类型有所不同,参考下方实现

            // 弹窗
            this.showAddBottondia(callback);
        },
        beforePlaceholderInsert(event, callback) {
            this.placeHolderActiveEvent = event;
            this.selectInfoCardData(event, callback);
        },

        beforeLoopBlockInsert(event, callback) {
            this.selectInfoCardData(event, callback);
        },

        // 消息卡片
        showAddBottondia(callback) {
            this.addBottondiaVisible = true;
            this.addBottondiaVisibleType = "simple";
            // 重置当前回调
            this.ckeditor.currentCallBack = callback;
        },
        // 取消保存添加按钮
        cancelSaveAddBottonConfig() {
            this.addBottondiaVisible = false;
            this.infoCardActiveConfigObj = {
                name: "点击编辑点选",
                scope: "NODE",
                value: "",
                assignments: [{ webhookId_key: "", _full_entity_entity: "" }],
            };
        },
        // 保存编辑添加按钮
        confirmSaveAddBottonConfig() {
            if (this.infoCardActiveConfigObj.name === "") {
                this.$message.error("按钮名称不能为空！");
            } else {
                if (this.infoCardActiveConfigObj.value === "") {
                    this.$message.error("请选择跳转到的意图或节点！");
                } else {
                    this.addBottondiaVisible = false;
                    let assignments = [];
                    this.infoCardActiveConfigObj.assignments.forEach((item) => {
                        assignments.push({
                            webhookId: item.webhookId_key[0],
                            key: item.webhookId_key[1],
                            entity: item._full_entity_entity[1],
                            _full_entity: item._full_entity_entity[0],
                        });
                    });
                    let sendToRT = {
                        name: this.infoCardActiveConfigObj.name,
                        event:
                            this.infoCardActiveConfigObj.scope == "INTENT"
                                ? "TO_INTENT"
                                : "TO_NODE",
                        value: this.infoCardActiveConfigObj.value,
                        assignments: assignments,
                    };
                    this.infoCardActiveConfigObj = {
                        name: "",
                        scope: "NODE",
                        value: "",
                        assignments: [
                            { webhookId_key: "", _full_entity_entity: "" },
                        ],
                    };
                    if (this.addBottondiaVisibleType == "simple") {
                        this.ckeditor.currentCallBack(sendToRT); // 回调用户输入
                    } else {
                        console.log(1000000);
                    }
                }
            }
        },
        // 消息卡片添加按钮切换点击跳转类型
        infoCardActiveConfigObjScopeChange(e) {
            this.infoCardActiveConfigObj.value = "";
        },
        selectInfoCardData(data, callback) {
            switch (data) {
                case "BUTTON":
                    data = {
                        type: "BUTTON",
                    };
                    break;
                case "PLACEHOLDER-entity":
                    data = {
                        type: "PLACEHOLDER",
                        param: {
                            source: "entity",
                            model: {
                                webhook_id: "1",
                                list_key: "location",
                            },
                        },
                    };
                    break;
                case "PLACEHOLDER-webhook":
                    data = {
                        type: "PLACEHOLDER",
                        param: {
                            source: "webhook",
                            model: {
                                webhook_id: "38",
                                list_key: "location",
                            },
                        },
                    };
                    break;
                case "LOOP-NOT-ROOT":
                    data = {
                        type: "LOOP",
                        param: {
                            model: {
                                webhook_id: 38,
                                list_key: "location",
                            },
                        },
                    };

                    break;
                case "LOOP-IS-ROOT":
                    data = {
                        type: "LOOP",
                        param: {
                            model: null,
                        },
                    };
                    break;

                default:
                    break;
            }

            if (data.type == "BUTTON") {
                this.infoCardSelectDataType = data.type;
                this.showAddBottondia();
            } else {
                if (
                    data.type == "PLACEHOLDER" &&
                    data.param.source == "entity"
                ) {
                    this.infoCardSelectDataType = "PLACEHOLDER-entity";
                }
                if (
                    data.type == "PLACEHOLDER" &&
                    data.param.source == "webhook"
                ) {
                    this.infoCardSelectDataType = "PLACEHOLDER-webhook";
                }
                if (data.type == "LOOP" && !JSON.stringify(data.param.model)) {
                    this.infoCardSelectDataType = "LOOP-NOT-ROOT";
                }
                if (
                    data.type == "LOOP" &&
                    JSON.stringify(data.param.model) == "{}"
                ) {
                    this.infoCardSelectDataType = "LOOP-IS-ROOT";
                }
                this.infoCardSelectDatadiaVisible = true;
                this.ckeditor.currentCallBack = callback;

                this.activeInfoCardSelectEntity = "";
                this.activeInfoCardSelectEntityCell = "";
                this.activeInfoCardSelectWebhook = "";
                this.activeInfoCardSelectWebhookResp = "";
                this.activeInfoCardSelectWebhookRespListArr = [];
                this.tampActiveInfoCardSelectWebhookRespListArr = [];
            }
        },
        selectInfoCardEntity(item) {
            this.activeInfoCardSelectEntity = item.id;
            this.activeInfoCardSelectEntityOptions = item.children;
            this.activeInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
        },
        selectInfoCardEntityCell(data) {
            this.activeInfoCardSelectEntityCell = data.id;
            this.activeInfoCardSelectEntityObj = data;
        },
        resultsResponse(arr) {
            let tampArr = [];
            JSON.parse(arr).forEach((item) => {
                if (
                    !item.multivalued &&
                    item.value.indexOf(
                        this.placeHolderActiveEvent.param.model.list_key + "[*]"
                    ) != -1
                ) {
                    tampArr.push(item);
                }
            });
            return tampArr;
        },
        selectInfoCardWebhookPlaceH(cell) {
            this.activeInfoCardSelectWebhook = cell.id;
            this.activeInfoCardSelectWebhookObj = cell;
            this.FetchGet(this.requestUrl.webhook.webhookDetail + cell.id).then(
                (res) => {
                    if (res.code === "0") {
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;

                            let activeInfoCardSelectWebhookRespListArr = [];
                            if (
                                JSON.stringify(
                                    this.placeHolderActiveEvent.param.model
                                ) == "{}"
                            ) {
                                this.activeInfoCardSelectWebhookRespList.forEach(
                                    (item, index) => {
                                        if (!item.multivalued) {
                                            if (
                                                item.value.indexOf("[*]") == -1
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                    }
                                );
                            } else {
                                activeInfoCardSelectWebhookRespListArr =
                                    this.resultsResponse(
                                        JSON.stringify(
                                            this
                                                .activeInfoCardSelectWebhookRespList
                                        )
                                    );
                            }
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    item.isSelected = false;
                                }
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectInfoCardWebhook(item) {
            this.activeInfoCardSelectWebhook = item.id;
            this.activeInfoCardSelectWebhookObj = item;
            this.FetchGet(this.requestUrl.webhook.webhookDetail + item.id).then(
                (res) => {
                    if (res.code === "0") {
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;
                            let activeInfoCardSelectWebhookRespListArr = [];
                            this.activeInfoCardSelectWebhookRespList.forEach(
                                (item) => {
                                    if (item.multivalued) {
                                        activeInfoCardSelectWebhookRespListArr.push(
                                            item
                                        );
                                    }
                                }
                            );
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    item.isSelected = false;
                                }
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectInfoCardWebhookResp(item) {
            this.activeInfoCardSelectWebhookResp = item.value;
            this.activeInfoCardSelectWebhookRespObj = item;
            let sendToRT = {
                type: "LOOP",
                param: {
                    model: {
                        webhook_id: this.activeInfoCardSelectWebhook,
                        list_key: item.value,
                    },
                },
            };
        },
        selectInfoCardHandleNodeClick(data) {
            console.log(data);
        },
        confirmSaveAddinfoCardSelectData() {
            this.infoCardSelectDatadiaVisible = false;
            let sendToRT = {};

            if (this.infoCardSelectDataType == "PLACEHOLDER-entity") {
                sendToRT = {
                    name: this.activeInfoCardSelectEntityObj.label,
                    key: this.activeInfoCardSelectEntityObj.value,
                };
            }
            if (this.infoCardSelectDataType == "PLACEHOLDER-webhook") {
                sendToRT = {
                    name: this.activeInfoCardSelectWebhookRespObj.name,
                    key:
                        this.activeInfoCardSelectWebhook +
                        "." +
                        this.activeInfoCardSelectWebhookRespObj.value,
                };
            }
            if (
                this.infoCardSelectDataType == "LOOP-NOT-ROOT" ||
                this.infoCardSelectDataType == "LOOP-IS-ROOT"
            ) {
                sendToRT = {
                    name: this.activeInfoCardSelectWebhookRespObj.name,
                    key:
                        this.activeInfoCardSelectWebhook +
                        "." +
                        this.activeInfoCardSelectWebhookRespObj.value,
                };
            }

            this.ckeditor.currentCallBack(sendToRT);
        },
        transfResponseConfig(list) {
            let tampArr = [],
                maxlength = 0;
            list.forEach((cell, index) => {
                cell.label = cell.name;
                if (cell.value != "") {
                    cell.children = [];
                    cell.valueToArr = cell.value.split("[*].");
                    if (cell.valueToArr.length == 2) {
                        cell.parentValue = cell.valueToArr[0];
                    } else if (cell.valueToArr.length == 1) {
                        cell.parentValue = 0;
                    } else if (cell.valueToArr.length > 2) {
                        let tampArr = cell.value.split("[*].");
                        tampArr.splice(tampArr.length - 1, 1);
                        cell.parentValue = tampArr.join("[*].");
                    }

                    if (cell.valueToArr.length > maxlength) {
                        maxlength = cell.valueToArr.length;
                    }
                }
            });

            for (let index = maxlength; index > 0; index--) {
                list.forEach((cell, cellIndex) => {
                    if (cell.valueToArr.length == index) {
                        list.forEach((innerCell) => {
                            if (innerCell.value == cell.parentValue) {
                                innerCell.children.push(cell);
                            }
                        });
                    }
                });
            }

            list.forEach((cell) => {
                if (cell.parentValue == 0) {
                    tampArr.push(cell);
                }
            });
            return tampArr;
        },
        // webhook
        // 失败跳转类型切换
        webhookfailedActionTypeValueChange(value, index) {
            this.actionList[index].content.failedAction.scope = value;
            this.actionList[index].content.failedAction.value = "";
            this.actionList = [...this.actionList];
        },
        webhookfailedActionNodeSelectChange(value, index) {
            this.actionList[index].content.failedAction.value = value;
            this.actionList = [...this.actionList];
        },
        webhookfailedActionIntentSelectChange(value, index) {
            this.actionList[index].content.failedAction.value = value;
            this.actionList = [...this.actionList];
        },
        // 跳转类型切换
        webhookJumpTypeValueChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.type = value;
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = "";
            this.actionList = [...this.actionList];
        },
        webhookJumpNodeSelectChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = value;
            this.actionList = [...this.actionList];
        },
        webhookJumpIntentSelectChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = value;
            this.actionList = [...this.actionList];
        },
        getWebhookList() {
            this.FetchGet(
                this.requestUrl.webhook.getWebhookDir,
                "keyword=''"
            ).then((res) => {
                if (res.code === "0") {
                    let tampArr = [];
                    this.webhookDirList = res.data;
                    this.loading = false;

                    let moToExtractNodeAsList = (target, item) => {
                        if (item.type === "api" && item.status != 0) {
                            tampArr.push(item);
                        } else {
                            item.children.forEach((cell) => {
                                moToExtractNodeAsList(tampArr, cell);
                            });
                        }
                    };

                    res.data.forEach((item) => {
                        moToExtractNodeAsList(tampArr, item);
                    });

                    this.webhookApiList = tampArr;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        showWebhookDetail(index, id) {
            this.FetchGet(this.requestUrl.webhook.webhookDetail + id).then(
                (res) => {
                    if (res.code === "0") {
                        this.actionList[index].content.params =
                            res.data.setting.params;
                        this.actionList[index].content.params.forEach(
                            (item) => {
                                item.description =
                                    item.description == null
                                        ? ""
                                        : item.description;
                                item.entityId =
                                    item.entityId == null ? "" : item.entityId;
                                item.defaultValue = item.value;
                                item.entities = item.entityName;
                                item.webHookResponseRequire = item.required;
                                item.type = "ENTITY";
                                this.$set(item, "actionNoParam", {
                                    type: "",
                                    radioContent: {
                                        description: "",
                                        options: [
                                            {
                                                name: "点击编辑点选",
                                                onclick: "SEND_MSG_TO_BOT",
                                                content: "",
                                            },
                                        ],
                                    },
                                    questionContent: {
                                        questions: [""],
                                        questionNum: -1,
                                    },
                                    gotoContent: {
                                        type: "NODE",
                                        value: "",
                                    },
                                    content: {},
                                });
                            }
                        );
                        this.actionList[index].content.response =
                            res.data.setting.response.config;
                        this.actionList[index].content.responseCollapse = false;
                        this.actionList[
                            index
                        ].content.failedActionCollapse = false;
                        this.actionList = [...this.actionList];
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        getWebhookEntityTree() {
            this.FetchGet(this.requestUrl.entity.getEntityTree).then((res) => {
                if (res.code === "0") {
                    this.webhookEntityOptions = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        webhookHandleEntityChange(value) {
            console.log(value);
        },
        paramSetWChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[paramCellIndex].type = value;
            this.actionList = [...this.actionList];
        },
        actionNoParamChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.type = value;
            this.actionList = [...this.actionList];
        },
        questionNumChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questionNum = value;
            this.actionList = [...this.actionList];
        },
        addWebhookQText(index, paramCellIndex, textCellInd) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions.push("");
            this.actionList = [...this.actionList];
        },
        deleteWebhookQText(index, paramCellIndex, textCellInd) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions.splice(textCellInd, 1);
            this.actionList = [...this.actionList];
        },
        webhookQQtextCellChange(value, index, paramCellIndex, textCellInd) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions[textCellInd] = value;
            this.actionList = [...this.actionList];
        },
        // 点选类型配置
        // 添加点选
        webhookAddAnswerRadio(index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.radioContent.options.push({
                name: "点击编辑点选",
                onclick: "SEND_MSG_TO_BOT",
                content: "",
            });

            this.actionList = [...this.actionList];
        },
        // 删除点选
        webhookDeleteAnswerRadio(index, paramCellIndex, radioTextIndex) {
            let tampArr = this.actionList;
            tampArr[index].content.params[
                paramCellIndex
            ].actionNoParam.radioContent.options.splice(radioTextIndex, 1);
            this.actionList = [...tampArr];
        },
        // 编辑点选
        webhookShowAnswerRadioEdite(index, paramCellIndex, radioTextIndex) {
            let tampArr = this.actionList;
            this.webhookAnswerRadioConfigVisible = true;
            this.webhookActiveRadioConfigIndex = [
                index,
                paramCellIndex,
                radioTextIndex,
            ];
            this.webhookActiveRadionConfigObj = JSON.parse(
                JSON.stringify(
                    this.actionList[index].content.params[paramCellIndex]
                        .actionNoParam.radioContent.options[radioTextIndex]
                )
            );
            if (this.webhookActiveRadionConfigObj.name === "点击编辑点选") {
                this.webhookActiveRadionConfigObj.name = "";
            }
        },
        // 保存编辑点选
        webhookConfirmSaveAnswerRadioConfig() {
            if (this.webhookActiveRadionConfigObj.name === "") {
                this.$message.error("点选按钮名称不能为空！");
            } else {
                if (this.webhookActiveRadionConfigObj.content === "") {
                    this.$message.error("发送内容不能为空！");
                } else {
                    this.webhookAnswerRadioConfigVisible = false;
                    let tampArr = this.actionList;
                    tampArr[
                        this.webhookActiveRadioConfigIndex[0]
                    ].content.params[
                        this.webhookActiveRadioConfigIndex[1]
                    ].actionNoParam.radioContent.options[
                        this.webhookActiveRadioConfigIndex[2]
                    ] = this.webhookActiveRadionConfigObj;
                    this.actionList = [...tampArr];
                }
            }
        },
        // 取消保存点选
        webhookCancelSaveAnswerRadioConfig() {
            this.webhookAnswerRadioConfigVisible = false;
            this.webhookActiveRadionConfigObj = {
                name: "点击编辑点选",
                onclick: "SEND_MSG_TO_BOT",
                content: "",
            };
        },
        // 获取webhookbaselist
        getWebhookBaseList() {
            this.FetchGet(this.requestUrl.webhook.webhookbaseList, {
                status: 1,
            }).then((res) => {
                if (res.code === "0") {
                    this.webhookbaseList = JSON.parse(JSON.stringify(res.data));
                    let tampWebhookBaseCascList = JSON.parse(
                        JSON.stringify(res.data)
                    );
                    let tampExpBaseCascList = JSON.parse(
                        JSON.stringify(res.data)
                    );
                    let tampWebhookMultivaluedCascList = JSON.parse(
                        JSON.stringify(res.data)
                    );

                    tampWebhookBaseCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (!cell.multivalued) {
                                item.children.push(cell);
                            }
                        });
                    });

                    tampExpBaseCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (
                                !cell.multivalued &&
                                cell.value.indexOf("*") == -1
                            ) {
                                item.children.push(cell);
                            }
                        });
                    });

                    tampWebhookMultivaluedCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (cell.multivalued) {
                                item.children.push(cell);
                            }
                        });
                    });

                    this.webhookMultivaluedCascList = [
                        ...tampWebhookMultivaluedCascList,
                    ];
                    this.webhookBaseCascList = [...tampWebhookBaseCascList];
                    this.expBaseCascList = [...tampExpBaseCascList];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // onEditorChange({ editor, html, text }) {
        //   this.content = html;
        // },
        // 变更父节点
        showChangeNoderelation() {
            this.cancelMoveNodeSaveVisible = false;

            let activeFirstNode =
                    this.nodeTree[this.activeMoveNodeIndexArr[0]].children[
                        this.activeMoveNodeIndexArr[1]
                    ].children[this.activeMoveNodeIndexArr[2]].children[
                        this.activeMoveNodeIndexArr[3]
                    ],
                moveToOptions = [];

            let moToExtractNodeAsList = (target, node) => {
                if (
                    node.id !== this.activeMoveNodeId &&
                    node.childrenNode !== null &&
                    node.childrenNode.length > 0
                ) {
                    node.childrenNode.forEach((item, index) => {
                        if (item.id == this.activeMoveNodeId) {
                            this.activeMoveNode = item;
                            this.activeMoveToNode = node.id;
                        } else {
                            if (
                                item.childrenNode !== null &&
                                item.childrenNode.length > 0
                            ) {
                                moToExtractNodeAsList([], item);
                            }
                        }
                    });
                } else {
                    this.activeMoveNode = node;
                }
            };
            moToExtractNodeAsList([], activeFirstNode);

            let extractNodeAsList = (target, node, parentNum) => {
                if (
                    node.childrenNode !== null &&
                    node.childrenNode.length > 0
                ) {
                    parentNum++;
                    node.childrenNode.forEach((item, index) => {
                        if (
                            item.childrenNode !== null &&
                            item.childrenNode.length > 0
                        ) {
                            if (item.id !== this.activeMoveNode.id) {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "__",
                                });
                                extractNodeAsList(target, item, parentNum);
                            } else {
                                node.childrenNode.splice(index, 1);
                            }
                        } else {
                            if (item.id !== this.activeMoveNode.id) {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "oo",
                                });
                                extractNodeAsList(target, item, parentNum);
                            } else {
                                node.childrenNode.splice(index, 1);
                            }
                        }
                    });
                } else {
                    node.hasLeaf = false;
                }
                return target;
            };
            this.moveToOptions = extractNodeAsList([], this.motoInitNode, 1);

            setTimeout(() => {
                this.moveToOptions = extractNodeAsList(
                    [],
                    this.motoInitNode,
                    1
                );
                this.moveToOptions = [...this.moveToOptions];
                this.changeNoderelationVisible = true;
                this.moveToOptions.unshift(this.activeInitNode);
            }, 200);
        },
        // 变更父节点
        cancelchangeNoderelation() {
            this.changeNoderelationVisible = false;
        },
        // 变更父节点
        confirmchangeNoderelation() {
            this.FetchPost(
                this.requestUrl.chatFlowNode.moveNode,
                {
                    fromIntentId: this.intentId,
                    from: this.activeMoveNodeId,
                    to: this.activeMoveToNode,
                },
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("节点移动成功！");
                    this.changeNoderelationVisible = false;
                    this.isPreMoveNode = false;
                    this.activeMoveToNode = "";
                    this.getIntentListByBotId(this.activeBot);
                    this.drawer = false;
                    this.$router.push({
                        name: "chatFlow",
                        query: {
                            bid: this.activeBot,
                            bName: this.bName,
                            intentId: this.intentId,
                            intentName: this.intentName,
                            refApiKey:
                                this.$router.currentRoute.query.refApiKey,
                        },
                    });
                    setTimeout(() => {
                        this.$nextTick(() => {
                            this.rollToIntent();
                            this.getEleHeight();
                        });
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        nodeSelectChange(e) {
            console.log(e);
        },
        cancelSelectCustrRadioVisible() {
            this.selectCustRadioVisible = false;
        },
        confirmselectCustRadioVisible() {
            if (this.selectCustRadioObj.name === "") {
                this.$message.error("点选按钮名称不能为空！");
            } else {
                this.selectCustRadioVisible = false;
                let tampArr = this.actionList;
                tampArr[
                    this.selectCustRadioConfigIndex[0]
                ].content.select.options[this.selectCustRadioConfigIndex[1]] =
                    this.selectCustRadioObj;
                this.actionList = [...tampArr];
            }
        },
        // 客服组点选类型配置
        // 添加点选
        addSelectCustRadio(index) {
            let tampArr = this.actionList;
            tampArr[index].content.select.options.push({
                name: "点击编辑点选客服组",
                customerId: this.custonerOptions[0].id || "",
                value: "",
            });
            this.actionList = [...tampArr];
        },
        // 删除点选
        deleteSelectCustRadio(index, radioTextIndex) {
            let tampArr = this.actionList;
            tampArr[index].content.select.options.splice(radioTextIndex, 1);
            this.actionList = [...tampArr];
        },
        // 编辑点选
        showSelectCustRadioEdite(index, radioTextIndex) {
            let tampArr = this.actionList;
            this.selectCustRadioVisible = true;
            this.selectCustRadioConfigIndex = [index, radioTextIndex];
            this.selectCustRadioObj = JSON.parse(
                JSON.stringify(
                    tampArr[index].content.select.options[radioTextIndex]
                )
            );
            if (this.selectCustRadioObj.name === "点击编辑点选客服组") {
                this.selectCustRadioObj.name = "";
            }
        },
        showComponent(cellId, disable, nodeId) {
            if (disable == true) {
                return false;
            }

            if (nodeId == "start") {
                return (
                    cellId == "answer_welcometext" ||
                    cellId == "answer_image" ||
                    cellId == "answer_video" ||
                    cellId == "answer_radio" ||
                    cellId == "action_transfer" ||
                    cellId == "action_set_entity" ||
                    cellId == "action_webhook" ||
                    cellId == "action_random_jump" ||
                    cellId == "action_jump" ||
                    cellId == "action_fast_select" ||
                    cellId == "answer_intellect_guide"

                );
            } else if (nodeId == "end") {
                return (
                    cellId == "answer_image" ||
                    cellId == "answer_video" ||
                    cellId == "answer_text" ||
                    cellId == "answer_radio" ||
                    cellId == "action_satisfaction" ||
                    cellId == "action_set_entity" ||
                    cellId == "action_webhook" ||
                    cellId == "action_random_jump" ||
                    cellId == "action_jump" ||
                    cellId == "action_fast_select" ||
                    cellId == "answer_intellect_guide"
                );
            } else {
                if (cellId == "answer_welcometext") {
                    return false;
                } else {
                    return true;
                }
            }
        },
        checkActionListChangedMoveNode() {
            let newVal = JSON.stringify(this.actionList);
            let oldVal = JSON.stringify(this.actionListCopy);
            newVal = this.checkFormatRich(newVal)
                ? newVal
                : this.formatRichTextNew(newVal);
            oldVal = this.checkFormatRich(oldVal)
                ? oldVal
                : this.formatRichTextNew(oldVal);
            if (
                newVal.split(" ").join("").length ==
                oldVal.split(" ").join("").length
            ) {
                this.showChangeNoderelation();
            } else {
                this.cancelSaveVisible = false;
                this.cancelMoveNodeSaveVisible = true;
            }
        },
        checkFormatRich(text) {
            console.log("++++++++++++++");
            let flag = false;
            if (text.indexOf("onclick='previewImage(this)'") != -1) {
                flag = true;
            }
            if (
                text.indexOf(
                    '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_'
                ) != -1
            ) {
                flag = true;
            }
            if (text.indexOf("<li class='rich-text-li'") != -1) {
                flag = true;
            }
            if (text.indexOf('<img width="100%"  src="https://static') != -1) {
                flag = true;
            }
            if (
                text.indexOf(
                    "a style='color: -webkit-link;text-decoration: underline;' href="
                ) != -1
            ) {
                flag = true;
            }
            console.log(7006, flag);
            return flag;
        },
        formatRichTextNew(html) {
            console.log("__________________");
            let str = html.replace(
                /\<img src="https:\/\/static/g,
                '<img width="100%"  src="https://static'
            );
            str = str.replace(
                /a href=/g,
                "a style='color: -webkit-link;text-decoration: underline;' href="
            );
            str = str.replace(/\<li/g, "<li class='rich-text-li'");
            str = str.replace(/\<img/g, "<img onclick='previewImage(this)' ");
            str = str.replace(/\<table/g, "<table class='rich-text-table' ");
            // 附件前标
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_txt.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_txt.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_jpg.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_jpg.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_rar.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_zip.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_pdf.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_pdf.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_doc.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_doc.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_ppt.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_ppt.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_xls.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_xls.gif'
            );
            return str;
        },
        checkActionListChanged() {
            let newVal = JSON.stringify(this.actionList);
            let oldVal = JSON.stringify(this.actionListCopy);
            newVal = this.checkFormatRich(newVal)
                ? newVal
                : this.formatRichTextNew(newVal);
            oldVal = this.checkFormatRich(oldVal)
                ? oldVal
                : this.formatRichTextNew(oldVal);
            if (
                newVal.split(" ").join("").length ==
                oldVal.split(" ").join("").length
            ) {
                this.cancelSaveConfirm();
            } else {
                if (
                    !(
                        (this.nodeId == "start" &&
                            !this.RP_Visible("OPEN_PROCESS_START")) ||
                        (this.nodeId == "end" &&
                            !this.RP_Visible("OPEN_PROCESS_END")) ||
                        (!this.isStartOrEnd &&
                            !this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_CUSTOMIZE")) ||
                        (this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_OTHER"))
                    )
                ) {
                    this.cancelMoveNodeSaveVisible = false;
                    this.cancelSaveVisible = true;
                } else {
                    this.drawer = false;
                }
            }
        },
        // 点击节点配置流程
        configAction(intentId, nodeId, skillId, name, indexArr, isInitNode) {
            if (isInitNode === 0) {
                this.isInitNode = true;
            } else {
                this.isInitNode = false;
            }
            if (nodeId !== "start" && nodeId !== "end") {
                if (Array.isArray(indexArr)) {
                    this.activeMoveNodeIndexArr = indexArr;
                } else {
                    if (Array.isArray(JSON.parse(indexArr))) {
                        this.activeMoveNodeIndexArr = JSON.parse(indexArr);
                    }
                }
            }

            // 判定当前是否为“其他”技能
            this.nodeTree.forEach((item) => {
                if (
                    item.id == skillId &&
                    item.name == "特殊规则" &&
                    !item.display
                ) {
                    this.isOtherSkill = true;
                } else {
                    this.isOtherSkill = false;
                }
            });
            this.activeMoveNodeId = nodeId;
            this.nodeId = nodeId;
            this.intentId = intentId;
            this.activeNodeName = name;
            console.log(name, nodeId);
            this.skillId = skillId;
            this.drawer = true;
            if (this.isPart == 1) {
                this.$nextTick(() => {
                    document.getElementsByClassName('v-modal')[0].style.height = '0';
                })
            }

            if (nodeId !== "start" && nodeId !== "end") {
                this.isStartOrEnd = false;
                this.activeRadionConfigObj.scope = "INTENT";
                this.getIntentListBySkill();
                this.getAllEntityBySkill();
            } else {
                this.isStartOrEnd = true;
                this.getAllEntityByBot();
            }

            for (let i = 0, len = this.botList.length; i < len; i++) {
                let bot = this.botList[i];
                if (bot.id == this.activeBot) {
                    if (
                        bot.setting != null &&
                        bot.setting.unrecognizedIntentId != null &&
                        bot.setting.unrecognizedIntentId == intentId
                    ) {
                        this.isUnrecognized = true;
                        break;
                    }
                } else {
                    this.isUnrecognized = false;
                    continue;
                }
            }

            this.getNodeListByIntent(skillId, intentId, nodeId);

            this.$nextTick(() => {
                this.rowDrop();
            });
        },
        // 点击展示或者关闭技能节点
        closeSkillNodeList(skillIndex) {
            this.nodeTree[skillIndex].show = !this.nodeTree[skillIndex].show;
            this.nodeTree = [...this.nodeTree];
        },
        // 点击展示或者关闭分类节点展示
        closeClassNodeList(skillIndex, classIndex) {
            this.nodeTree[skillIndex].children[classIndex].show =
                !this.nodeTree[skillIndex].children[classIndex].show;
            this.nodeTree = [...this.nodeTree];
            this.getEleHeight();
        },
        // 点击展示或者关闭分类节点展示
        closeIntentNodeList(skillIndex, classIndex, intentIndex, value, type) {
            console.log(skillIndex, classIndex, intentIndex);
            if (value) {
                this.nodeTree[skillIndex].children[classIndex].children[
                    intentIndex
                ].show = true;
            } else {
                this.nodeTree[skillIndex].children[classIndex].children[
                    intentIndex
                ].show =
                    !this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].show;
            }

            let len =
                this.nodeTree[skillIndex].children[classIndex].children.length;
            if (len - 1 === intentIndex) {
                this.nodeTree[skillIndex].children[classIndex].isOpenLast =
                    this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].show;
            }
            if (
                !this.nodeTree[skillIndex].children[classIndex].children[
                    len - 1
                ].show
            ) {
                this.nodeTree[skillIndex].children[
                    classIndex
                ].isOpenLast = false;
                this.nodeTree = [...this.nodeTree];
            } else {
                this.nodeTree = [...this.nodeTree];
                this.getEleHeight();
            }

            this.FetchGet(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId:
                    this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].id,
            })
                .then((res) => {
                    if (res.code === "0") {
                        if (res.data.initNode == null && type === "delete") {
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].hasProcess = false;
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].children = [];
                        } else {
                            res.data.initNode.children =
                                res.data.initNode.childrenNode == null
                                    ? []
                                    : res.data.initNode.childrenNode;
                            let setName = (firstNodeArr) => {
                                //遍历树  获取id数组
                                firstNodeArr.forEach((item) => {
                                    if (item.childrenNode === null) {
                                        item.children = [];
                                        this.nodeTree = [...this.nodeTree];
                                    } else {
                                        item.children = item.childrenNode;
                                        this.nodeTree = [...this.nodeTree];
                                        if (item.children.length > 0) {
                                            setName(item.children);
                                        }
                                    }
                                });
                            };
                            setName(res.data.initNode.children);

                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].children[0] =
                                res.data.initNode;
                            this.nodeTree = [...this.nodeTree];
                        }

                        if (
                            this.nodeTree[skillIndex].children[classIndex]
                                .children.length -
                                1 ===
                            intentIndex
                        ) {
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].isOpenLast =
                                this.nodeTree[skillIndex].children[
                                    classIndex
                                ].children[intentIndex].show;
                        }

                        this.nodeTree = [...this.nodeTree];
                        console.log(this.nodeTree);
                        this.getEleHeight();
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 点击添加条件
        addCondittion() {
            this.conditionConfigVisible = !this.conditionConfigVisible;
        },
        // 删除条件
        deleteCondition(index, ind, miniuIndex) {
            console.log(index, ind, miniuIndex);
            this.actionList[index].conditionList[ind].splice(miniuIndex, 1);
            if (this.actionList[index].conditionList[ind].length === 0) {
                this.actionList[index].conditionList.splice(ind, 1);
            }
        },
        // 条件配置 - 取消条件设置
        cancelSaveCondiConfig(item) {
            this.conditionConfigVisible = false;
        },
        // 根据id返回意图分类名称
        getIntentNameById(idArr) {
            let tamparr = [];
            this.intentSAkillTypeOptionsNoChildren.forEach((item) => {
                if (item.id === idArr[0]) {
                    item.children.forEach((cell) => {
                        if (cell.id === idArr[1]) {
                            tamparr.push(item.name);
                            tamparr.push(cell.name);
                        }
                    });
                }
            });
            return tamparr;
        },
        // 根据id返回意图分类名称
        getRelationName(value) {
            let tampValue = "";
            this.conditionTypes.forEach((item) => {
                item.relations.forEach((cell) => {
                    if (cell.value === value) {
                        tampValue = cell.name;
                    }
                });
            });
            return tampValue;
        },
        // 根据id获取webhook名称和返回值名称
        getWebhookNameAndResName(ids) {
            let obj = {
                webhook: "",
                response: "",
            };
            this.expBaseCascList.forEach((item) => {
                if (item.id == ids[0]) {
                    obj.webhook = item.name;
                    item.configs.forEach((cell) => {
                        if (cell.value == ids[1]) {
                            obj.response = cell.name;
                        }
                    });
                }
            });
            return obj;
        },
        // 条件配置 - 保存条件设置
        confirmSaveCondiConfig() {
            this.conditionConfigVisible = false;
            let paramObj = { ext: {} };
            paramObj.scope = this.conditionType;
            paramObj.relation = this.conditionValue;
            if (this.conditionType === "ENTITY") {
                paramObj.variable = this.entityValue[1];
                paramObj.value = this.activeEntityValue;
                paramObj.ext = { entityValue: this.entityValue };
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionValue
                );
            }
            if (this.conditionType === "INTENT") {
                paramObj.variable = this.activeIntentStatus;
                paramObj.value = this.intentValue[1];
                paramObj.ext = { intentValue: this.intentValue };
                paramObj.ext.intentTypeNameArr = this.getIntentNameById(
                    this.intentValue
                );
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionValue
                );
            }
            if (this.conditionType === "USER_WAITING_TIME") {
                paramObj.variable = this.activeIntentStatus;
                paramObj.value = this.silentTimeValue + this.silentTimeUnit;
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionValue
                );
                paramObj.ext.silentTimeValue = this.silentTimeValue;
                paramObj.ext.silentTimeUnit = this.silentTimeUnit;
                switch (paramObj.ext.silentTimeUnit) {
                    case "1s":
                        paramObj.ext.silentTimeUnitName = "秒";
                        break;
                    case "1m":
                        paramObj.ext.silentTimeUnitName = "分";
                        break;
                    case "1h":
                        paramObj.ext.silentTimeUnitName = "时";
                        break;
                }
            }

            if (this.conditionType === "WEB_HOOK_RESPONSE") {
                paramObj.variable =
                    this.conditionResponseValue[0] +
                    "." +
                    this.conditionResponseValue[1];
                paramObj.value = this.conditionResponseNum;
                paramObj.relation = this.conditionResponseCondit;
                paramObj.scopeType = this.conditionResponseType;
                paramObj.ext = this.getWebhookNameAndResName(
                    this.conditionResponseValue
                );
                paramObj.ext.relationName = this.getRelationName(
                    this.conditionResponseCondit
                );
                paramObj.ext.variable = this.conditionResponseValue;
            }

            let indexArr = this.conditionEditing.index;
            switch (this.conditionEditing.type) {
                case "edit":
                    this.actionList[indexArr[0]].conditionList[indexArr[1]][
                        indexArr[2]
                    ] = paramObj;
                    break;
                case "addOr":
                    this.actionList[indexArr[0]].conditionList.push([paramObj]);
                    break;
                case "addAnd":
                    if (indexArr[1] === 0 && indexArr[2] === 0) {
                        this.actionList[indexArr[0]].conditionList[
                            indexArr[1]
                        ] = [];
                    }
                    this.actionList[indexArr[0]].conditionList[
                        indexArr[1]
                    ].push(paramObj);
                    break;
            }
        },
        // 条件配置 - 回显和初始化
        conditionEcho(type, data) {
            this.conditionConfigVisible = true;
            switch (type) {
                case "edit":
                    this.conditionConfigTitle = "编辑条件";
                    let tampObj =
                        this.actionList[data[0]].conditionList[data[1]][
                            data[2]
                        ];
                    this.conditionEditing = {
                        type: type,
                        activeObj: tampObj,
                        index: data,
                    };
                    this.conditionType = tampObj.scope;
                    this.conditionValue = tampObj.relation;
                    if (tampObj.scope === "ENTITY") {
                        this.entityValue = tampObj.ext.entityValue;
                        this.activeEntityValue = tampObj.value;
                    }
                    if (tampObj.scope === "INTENT") {
                        this.intentValue = tampObj.ext.intentValue;
                        this.activeIntentStatus = tampObj.variable;
                    }
                    if (tampObj.scope === "USER_WAITING_TIME") {
                        this.silentTimeValue = tampObj.ext.silentTimeValue;
                        this.silentTimeUnit = tampObj.ext.silentTimeUnit;
                    }
                    if (tampObj.scope === "WEB_HOOK_RESPONSE") {
                        this.conditionResponseNum = tampObj.value;
                        this.conditionResponseValue = tampObj.ext.variable;
                        this.conditionResponseCondit = tampObj.relation;

                        // paramObj.variable = this.conditionResponseValue;
                        // paramObj.value = this.conditionResponseNum;
                        // paramObj.relation = this.conditionResponseCondit;
                        // paramObj.scopeType = this.conditionResponseType;
                    }
                    break;
                case "addOr":
                    this.conditionConfigTitle = "添加或者条件";
                    this.conditionType = "ENTITY";
                    // this.entityValue =  [this.entityOptions[0].value, this.entityOptions[0].children[0].value];
                    this.entityValue = [];
                    this.conditionValue = "EQUAL";
                    this.activeEntityValue = "";
                    this.conditionResponseValue = "";
                    this.conditionResponseNum = "";
                    this.conditionResponseCondit = "";
                    this.conditionEditing = {
                        type: type,
                        activeObj: this.initConfigData,
                        index: data,
                    };
                    break;
                case "addAnd":
                    this.conditionConfigTitle = "添加并且条件";
                    this.conditionType = "ENTITY";
                    // this.entityValue =  [this.entityOptions[0].value, this.entityOptions[0].children[0].value];
                    this.entityValue = [];
                    this.conditionValue = "EQUAL";
                    this.activeEntityValue = "";
                    this.conditionResponseValue = "";
                    this.conditionResponseNum = "";
                    this.conditionResponseCondit = "";
                    this.conditionEditing = {
                        type: type,
                        activeObj: this.initConfigData,
                        index: data,
                    };
                    break;
            }
        },

        // 条件配置 - 选择类型改变
        conditionTypeChange(value) {
            if (value === "USER_WAITING_TIME") {
                this.conditionValue = "GREATER_THAN";
            } else {
                this.conditionValue = "EQUAL";
            }
        },
        // 选择实体
        handleChangeEntity(e) {
            this.entityValue = e;
            let tampArr = [];
            this.entityOptions.forEach((item) => {
                if (item.value === e[0]) {
                    item.children.forEach((cell) => {
                        if (cell.value === e[1]) {
                            tampArr = cell.content;
                        }
                    });
                }
            });
            tampArr.forEach((item) => {
                item.value = item.standardWord;
                item.label = item.standardWord;
            });
            this.allEntityValue = [...tampArr];
            this.activeEntityValue = "";
        },
        // 实体值选择
        querySearchEntityValue(queryString, cb) {
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e = this.entityValue;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        loadAllEntity() {
            return [];
        },
        handleSelectEntity(item) {
            console.log(item);
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        // 点击组件添加到右侧
        addComponents(type) {
            console.log(type, "++++++++++++++")
            if (type == 'action_webhook' && localStorage.getItem('_bot_version') == 2) {
                this.tipIndex = 2;
                this.versionTipDialog = true;
                return false;
            }
            if (type == "action_feedback") {
                this.FetchGet(
                    this.requestUrl.chatFlowComponent.getLastFeedback,
                    { botId: this.activeBot }
                )
                    .then((res) => {
                        let obj = null;
                        if (res.code === "0") {
                            if (res.data == null) {
                                obj = {
                                    // 答案反馈
                                    conditionList: [],
                                    content: {
                                        positiveFeedback: {
                                            scope: "NODE", // INTENT NODE CONTENT NONE
                                            value: "",
                                            text: "有用",
                                        },
                                        negativeFeedback: {
                                            scope: "NODE", // INTENT NODE CONTENT NONE 😄
                                            value: "",
                                            text: "无用",
                                        },
                                    },
                                    effectiveTime: {
                                        range: "PERMANENT",
                                        startTime: "",
                                        endTime: "",
                                        repeat: [
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                        ],
                                        filterHolidays: true,
                                        balanceHolidays: true,
                                        timeRange: [
                                            new Date(
                                                "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                                            ),
                                            new Date(
                                                "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                                            ),
                                        ],
                                    },
                                    type: "action_feedback",
                                };
                                if (
                                    this.actionList == null ||
                                    this.actionList == undefined
                                ) {
                                    this.actionList = [obj];
                                } else {
                                    this.actionList.push(obj);
                                }
                            } else {
                                obj = {
                                    // 答案反馈
                                    conditionList: [],
                                    content: {
                                        positiveFeedback:
                                            res.data.positiveFeedback,
                                        negativeFeedback:
                                            res.data.negativeFeedback,
                                    },
                                    effectiveTime: {
                                        range: "PERMANENT",
                                        startTime: "",
                                        endTime: "",
                                        repeat: [
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                        ],
                                        filterHolidays: true,
                                        balanceHolidays: true,
                                        timeRange: [
                                            new Date(
                                                "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                                            ),
                                            new Date(
                                                "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                                            ),
                                        ],
                                    },
                                    type: "action_feedback",
                                };
                                if (res.data.positiveFeedback.scope == "NODE") {
                                    obj.content.positiveFeedback.value = "";
                                }
                                if (res.data.negativeFeedback.scope == "NODE") {
                                    obj.content.negativeFeedback.value = "";
                                }
                                if (
                                    this.actionList == null ||
                                    this.actionList == undefined
                                ) {
                                    this.actionList = [obj];
                                } else {
                                    this.actionList.push(obj);
                                }
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            } else {
                if (this.actionList == null || this.actionList == undefined) {
                    this.actionList = [
                        answerInit(
                            type,
                            this.isStartOrEnd,
                            this.custonerOptions,
                            this.customerServiceOfflineIntentId
                        ),
                    ];
                } else {
                    this.actionList.push(
                        answerInit(
                            type,
                            this.isStartOrEnd,
                            this.custonerOptions,
                            this.customerServiceOfflineIntentId
                        )
                    );
                }
            }

            // 滚动到底部
            this.$nextTick(() => {
                this.$refs.grContent.scrollTo(0, 100000000);
            });
        },
        // 获取
        getComponents() {
            // 答案类型列表
            this.FetchGet(
                this.requestUrl.chatFlowComponent.getComponentsTypes,
                {}
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.componentsTypes = res.data;

                        // 模拟新增组件类型
                        this.componentsTypes[0].components.push({
                          disable: false,
                          icon: ["icon-big", "iconfont", "guoran-tongyichicun-16-07-liebiaokapianmoshi"],
                          id: "answer_intellect_guide",
                          name: "智能引导",
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });

            // 条件列表
            this.FetchGet(
                this.requestUrl.chatFlowComponent.getConditionList,
                {}
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.conditionTypes = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
            // 条件列表
            this.FetchGet(this.requestUrl.chatFlowComponent.getConditionList, {
                scope: "WEB_HOOK_RESPONSE",
                type: "LENGTH",
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.webhookConditionTypes = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取意图节点
        getNodeListByIntent(skillId, intentId, nodeId) {
            if (nodeId === "start" || nodeId === "end") {
                let url =
                    nodeId === "start"
                        ? this.requestUrl.chatFlowNode.getStertChatFlow
                        : this.requestUrl.chatFlowNode.getEndChatFlow;
                this.FetchGet(url, {
                    botId: this.activeBot,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            let tampActions = res.data;
                            tampActions &&
                                tampActions.forEach((action, index) => {
                                    if (
                                        !("effectiveTime" in action) ||
                                        action.effectiveTime == null
                                    ) {
                                        action.effectiveTime = {
                                            range: "PERMANENT",
                                            startTime: "",
                                            endTime: "",
                                            repeat: [
                                                "1",
                                                "2",
                                                "3",
                                                "4",
                                                "5",
                                                "6",
                                                "7",
                                            ],
                                            filterHolidays: true,
                                            balanceHolidays: true,
                                            timeRange: [
                                                new Date(),
                                                new Date(
                                                    "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                                ),
                                            ],
                                        };
                                    }
                                });

                            this.actionList = tampActions;
                            this.actionListCopy = JSON.parse(
                                JSON.stringify(this.actionList)
                            );
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.debug(err);
                        this.$message.error("获取意图节点失败！");
                    });
            } else {
                this.FetchGet(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                    intentId: this.intentId,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            if (res.data.initNode.id === nodeId) {
                                this.actionList =
                                    res.data.initNode.actionList == null
                                        ? []
                                        : res.data.initNode.actionList;
                            } else {
                                let setName = (firstNodeArr) => {
                                    //遍历树
                                    firstNodeArr &&
                                        firstNodeArr.forEach((item) => {
                                            if (item.id === nodeId) {
                                                if (item.actionList == null) {
                                                    this.actionList = [];
                                                } else {
                                                    this.actionList =
                                                        item.actionList;
                                                }
                                            } else {
                                                setName(item.childrenNode);
                                            }
                                        });
                                };
                                setName(res.data.initNode.childrenNode);
                            }

                            let arr = res.data.initNode;
                            this.motoInitNode = res.data.initNode;
                            let extractNodeAsList = (
                                target,
                                node,
                                parentNum
                            ) => {
                                if (
                                    node.childrenNode !== null &&
                                    node.childrenNode.length > 0
                                ) {
                                    parentNum++;
                                    node.childrenNode.forEach((item) => {
                                        if (
                                            item.childrenNode !== null &&
                                            item.childrenNode.length > 0
                                        ) {
                                            target.push({
                                                name: item.name,
                                                id: item.id,
                                                parentNum: parentNum,
                                                classPop: "__",
                                            });
                                        } else {
                                            target.push({
                                                name: item.name,
                                                id: item.id,
                                                parentNum: parentNum,
                                                classPop: "oo",
                                            });
                                        }

                                        extractNodeAsList(
                                            target,
                                            item,
                                            parentNum
                                        );
                                    });
                                } else {
                                    node.hasLeaf = false;
                                }
                                return target;
                            };
                            this.nodeOptions = extractNodeAsList([], arr, 1);
                            this.activeInitNode = {
                                name: res.data.initNode.name,
                                id: res.data.initNode.id,
                                parentNum: 1,
                                classPop: "__",
                            };
                            this.nodeOptions.unshift({
                                name: res.data.initNode.name,
                                id: res.data.initNode.id,
                                parentNum: 1,
                                classPop: "__",
                            });
                            let list = this.actionList;
                            let dataFixed = false;
                            if (list && list.length != 0) {
                                for (
                                    let i = 0, len = list.length;
                                    i < len;
                                    i++
                                ) {
                                    let action = list[i];
                                    console.log(action, 100000000);
                                    if (
                                        !("effectiveTime" in action) ||
                                        action.effectiveTime == null
                                    ) {
                                        action.effectiveTime = {
                                            range: "PERMANENT",
                                            startTime: "",
                                            endTime: "",
                                            repeat: [
                                                "1",
                                                "2",
                                                "3",
                                                "4",
                                                "5",
                                                "6",
                                                "7",
                                            ],
                                            filterHolidays: true,
                                            balanceHolidays: true,
                                            timeRange: [
                                                new Date(),
                                                new Date(
                                                    "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                                ),
                                            ],
                                        };
                                    }
                                    if (
                                        !("recommend" in action.content) ||
                                        action.content.recommend == null
                                    ) {
                                        this.$set(action.content, "recommend", {
                                            list: [
                                                {
                                                    id: "",
                                                    type: 0,
                                                    text: "", // type 0 自定义 1 跟随意图
                                                    ext: {
                                                        intentValue: [], // 用于回显 对后端没用
                                                    },
                                                },
                                            ],
                                            status: false, // 推荐开关
                                            description: "", // 推荐描述
                                        });
                                    }
                                    if (action.type == "answer_text") {
                                        let textList = action.content.list;
                                        let extInfos = action.content.extInfos;
                                        if (
                                            extInfos == null ||
                                            textList.length != extInfos.length
                                        ) {
                                            dataFixed = true;
                                            extInfos = new Array();
                                            for (
                                                let j = 0,
                                                    len2 = textList.length;
                                                j < len2;
                                                j++
                                            ) {
                                                extInfos[j] = {
                                                    expectedIntention: "",
                                                    notAsExpectedReply: "",
                                                };
                                            }
                                            action.content.extInfos = extInfos;
                                        }
                                    }
                                    if (action.type === "action_question") {
                                        if (action.content.goToAction == null) {
                                            this.$set(
                                                action.content,
                                                "goToAction",
                                                { scope: "NODE", value: "" }
                                            );
                                        }
                                        if (
                                            action.content
                                                .entityNotFountAction == null
                                        ) {
                                            this.$set(
                                                action.content,
                                                "entityNotFountAction",
                                                {
                                                    type: "RECOGNIZE_INTENT",
                                                    recognizeIntent: {
                                                        roundToContinue: 3,
                                                    },
                                                    gotoNode: {
                                                        id: "",
                                                    },
                                                    gotoIntent: {
                                                        id: "",
                                                    },
                                                    sendMsgToUser: {
                                                        content: "",
                                                    },
                                                }
                                            );
                                        }
                                    }
                                    if (action.type === "action_transfer") {
                                        if (action.content.ifOffline == null) {
                                            this.$set(
                                                action.content,
                                                "ifOffline",
                                                { scope: "NODE", value: "" }
                                            );
                                        }
                                    }
                                    if (action.type == "action_webhook") {
                                        if (
                                            action.content.failedAction.scope ==
                                            "INTENT"
                                        ) {
                                            // if (action.content.failedAction.value != "") {
                                            //   action.content.failedAction.value = Number(
                                            //     action.content.failedAction.value
                                            //   );
                                            // }
                                        }
                                        action.content.params.forEach(
                                            (paramsItem) => {
                                                if (
                                                    paramsItem.type ==
                                                    "WEB_HOOK_RESPONSE"
                                                ) {
                                                    paramsItem.webHookShowInfo[0] =
                                                        Number(
                                                            paramsItem
                                                                .webHookShowInfo[0]
                                                        );
                                                }
                                                if (
                                                    paramsItem.actionNoParam
                                                        .gotoContent.type ==
                                                    "INTENT"
                                                ) {
                                                    // if (paramsItem.actionNoParam.gotoContent.value != "") {
                                                    //   paramsItem.actionNoParam.gotoContent.value = Number(
                                                    //     paramsItem.actionNoParam.gotoContent.value
                                                    //   );
                                                    // }
                                                }
                                            }
                                        );
                                    }
                                }
                            }
                            for (let i = 0, len = list.length; i < len; i++) {
                                this.actionList[i] = list[i];
                            }
                            if (dataFixed) {
                                this.saveChatFlow(true);
                            }
                            this.actionListCopy = JSON.parse(
                                JSON.stringify(this.actionList)
                            );
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.debug(err);
                        this.$message.error("获取意图节点失败！");
                    });
            }
        },
        // 获取当前知识库下意图列表
        getIntentListBySkill() {
            this.FetchGet(this.requestUrl.trainList.skillIntent, {
                skillId: this.skillId.slice(1),
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.intentOptions = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 条件配置选中意图
        intentSelectedValue(data) {
            console.log(data);
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId) {
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId })
                .then((res) => {
                    if (res.code === "0") {
                        console.log("+++++++++++");
                        this.nodeTree = [...res.data];
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        for (let i = nodeTree.length - 1; i >= 0; i--) {
                            for (
                                let j = nodeTree[i].children.length - 1;
                                j >= 0;
                                j--
                            ) {
                                for (
                                    let k =
                                        nodeTree[i].children[j].children
                                            .length - 1;
                                    k >= 0;
                                    k--
                                ) {
                                    for (
                                        let l =
                                            nodeTree[i].children[j].children
                                                .length - 1;
                                        l > -0;
                                        l--
                                    ) {
                                        let intent =
                                            nodeTree[i].children[j].children[l];
                                        nodeTree[i].children[j].children[l] = {
                                            id: intent.id,
                                            name: intent.name,
                                        };
                                    }
                                }
                            }
                            this.intentTree4Radio = nodeTree;
                        }

                        let tampArr = [];
                        res.data.forEach((item) => {
                            item.show = true;
                            item.children.forEach((cell) => {
                                cell.show = true;
                                cell.label = item.name + " — " + cell.name;
                                cell.value = cell.id;
                                cell.children.forEach((miniItem) => {
                                    miniItem.label = miniItem.name;
                                    miniItem.value = miniItem.id;
                                    if (miniItem.children == null) {
                                        miniItem.children = [];
                                    }
                                });
                                tampArr.push(cell);
                            });
                        });
                        this.intentSAkillTypeOptions = [...tampArr];
                        let tampNochidrenStr = JSON.stringify(
                            this.intentSAkillTypeOptions
                        );
                        let tampNochidrenArr = [];
                        JSON.parse(tampNochidrenStr).forEach((item) => {
                            item.children.forEach((cell, index) => {
                                cell = {
                                    label: cell.label,
                                    value: cell.value,
                                    name: cell.name,
                                    id: cell.id,
                                    exampleQuestion: cell.exampleQuestion,
                                };
                                item.children[index] = cell;
                            });
                            item.children = [...item.children];
                            tampNochidrenArr.push(item);
                        });
                        this.intentSAkillTypeOptionsNoChildren = [
                            ...tampNochidrenArr,
                        ];
                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.rollToIntent();
                                this.getEleHeight();
                            });
                        }, 1);
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取指定知识库下所有实体
        getAllEntityBySkill() {
            this.FetchGet(this.requestUrl.entity.getAllEntityBySkill, {
                skillId: this.skillId.slice(1),
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 添加文本答案输入框
        addText(index, textCellInd) {
            this.actionList[index].content.list.push("");
            this.actionList[index].content.extInfos.push({
                expectedIntention: "",
                notAsExpectedReply: "",
            });
        },
        // 删除文本答案输入框
        deleteText(index, textCellInd) {
            this.actionList[index].content.list.splice(textCellInd, 1);
            this.actionList[index].content.extInfos.splice(textCellInd, 1);
        },
        // 改版反问跳转类型切换
        questionJumpTypeValueChange(index, data) {
            this.actionList[index].content.goToAction.value = "";
        },
        // 跳转类型切换
        jumpTypeValueChange(index, data) {
            this.actionList[index].content.value = "";
        },
        // 跳转类型切换
        answerRadioValueChange() {
            this.activeRadionConfigObj.value = "";
        },
        // 添加反问类型答案cell
        addActionQuestionCell(index) {
            this.actionList[index].content.list.push({
                questions: [""],
                entity: [],
                askIfExist: 0,
            });
        },
        // 删除反问类型答案cell
        deleteActionQuestionCell(index) {
            this.actionList[index].content.list.splice(q_index, 1);
        },
        // 操作反问类型答案cell中单条文案
        handleActionQuestionCellTextItem(type, index, q_item_text_index) {
            if (type === "delete") {
                this.actionList[index].content.questions.splice(
                    q_item_text_index,
                    1
                );
            }
            if (type === "add") {
                this.actionList[index].content.questions.push("");
            }
        },
        // 操作答案组件顺序或者删除
        handleUnit(type, index) {
            let tampArr = this.actionList;
            let tampObj = tampArr[index];
            switch (type) {
                case "bottom":
                    tampArr.splice(index, 1);
                    tampArr.push(tampObj);
                    break;
                case "top":
                    tampArr.splice(index, 1);
                    tampArr.unshift(tampObj);
                    break;
                case "down":
                    tampArr[index] = tampArr[index + 1];
                    tampArr[index + 1] = tampObj;
                    break;
                case "up":
                    tampArr[index] = tampArr[index - 1];
                    tampArr[index - 1] = tampObj;
                    break;
                case "delete":
                    tampArr.splice(index, 1);
                    break;
            }
            this.actionList = [...tampArr];
        },

        // 点选类型配置
        // 添加点选
        addAnswerRadio(index) {
            let tampArr = this.actionList;
            if (this.isStartOrEnd) {
                tampArr[index].content.options.push({
                    name: "点击编辑点选",
                    scope: "INTENT",
                    value: "",
                });
            } else {
                tampArr[index].content.options.push({
                    name: "点击编辑点选",
                    scope: "NODE",
                    value: "",
                });
            }

            this.actionList = [...tampArr];
        },
        // 删除点选
        deleteAnswerRadio(index, radioTextIndex) {
            let tampArr = this.actionList;
            tampArr[index].content.options.splice(radioTextIndex, 1);
            this.actionList = [...tampArr];
        },
        // 编辑点选
        showAnswerRadioEdite(index, radioTextIndex) {
            let tampArr = this.actionList;
            this.answerRadioConfigVisible = true;
            this.activeRadioConfigIndex = [index, radioTextIndex];
            this.activeRadionConfigObj = JSON.parse(
                JSON.stringify(tampArr[index].content.options[radioTextIndex])
            );
            if (this.activeRadionConfigObj.name === "点击编辑点选") {
                this.activeRadionConfigObj.name = "";
            }
        },
        // 保存编辑点选
        confirmSaveAnswerRadioConfig() {
            if (this.activeRadionConfigObj.name === "") {
                this.$message.error("点选按钮名称不能为空！");
            } else {
                if (this.activeRadionConfigObj.value === "") {
                    this.$message.error("请选择跳转到的意图或节点！");
                } else {
                    this.answerRadioConfigVisible = false;
                    let tampArr = this.actionList;
                    tampArr[this.activeRadioConfigIndex[0]].content.options[
                        this.activeRadioConfigIndex[1]
                    ] = this.activeRadionConfigObj;
                    this.actionList = [...tampArr];
                }
            }
        },
        // 取消保存点选
        cancelSaveAnswerRadioConfig() {
            this.answerRadioConfigVisible = false;
            this.activeRadionConfigObj = {
                name: "点击编辑点选",
                scope: "NODE",
                value: "",
            };
        },
        // 满意度类型
        // 编辑满意度类型按钮
        editSatisfactionRadio(index, cellIndex, item) {
            this.activeSatiAnswerRadioObj = item;
            this.activeSaIndexType = "edit";
            this.activeSaIndex = index;
            this.activeSaCellIndex = cellIndex;
            this.satiAnswerRadioVisible = true;
        },
        // 添加
        addSatisfactionRadio(index) {
            this.activeSaIndexType = "add";
            this.activeSatiAnswerRadioObj = { name: "", answer: "" };
            this.satiAnswerRadioVisible = true;
            this.activeSaIndex = index;
        },
        // 删除
        handleCloseSaRadio(index, cellIndex) {
            this.actionList[index].content.answerRadio.splice(cellIndex, 1);
        },
        // 编辑满意度点选
        cancelSatiAnswerRadioVisible() {
            this.satiAnswerRadioVisible = false;
        },
        confirmSaveSatiAnswerRadioVisible() {
            if (
                this.activeSatiAnswerRadioObj.name.trim() != "" &&
                this.activeSatiAnswerRadioObj.answer.trim() != ""
            ) {
                let obj = JSON.stringify(this.activeSatiAnswerRadioObj);
                if (this.activeSaIndexType == "add") {
                    this.actionList[
                        this.activeSaIndex
                    ].content.answerRadio.push(JSON.parse(obj));
                } else {
                    this.actionList[this.activeSaIndex].content.answerRadio[
                        this.activeSaCellIndex
                    ] = JSON.parse(obj);
                }
                this.satiAnswerRadioVisible = false;
            } else {
                this.$message.error("点选名称、点击回复不能为空！");
            }
        },

        // 图片答案类型
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        // 图片上传成功
        handlePictureUploadSuccess(response, file, fileList, content) {
            if (response.code == 0) {
                content.url = response.data;
            }
        },
        beforeImageMUpload(file) {
            const isJPG =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            if (!isLt2M) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            return isJPG && isLt2M;
        },
        handlePictureCardPreview(file) {
            // this.dialogImageUrl = file.url;
            // this.dialogImageVisible = true;
        },

        // 视频组件
        //上传前回调
        beforeUploadVideo(file, index) {
            this.activeVideoIndex = index;
            var fileSize = file.size / 1024 / 1024 < 100;
            if (
                [
                    "video/mp4",
                    "video/ogg",
                    "video/flv",
                    "video/avi",
                    "video/wmv",
                    "video/rmvb",
                    "video/mov",
                    "video/quicktime",
                ].indexOf(file.type) == -1
            ) {
                this.$message.error("请上传正确的视频格式");
                return false;
            }
            if (!fileSize) {
                this.$message.error("视频大小不能超过10MB");
                return false;
            }
            this.isShowUploadVideo = false;
        },
        //进度条
        uploadVideoProcess(event, file, fileList, activeVideoIndex) {
            this.actionList[activeVideoIndex].content.videoFlag = true;
            this.actionList[activeVideoIndex].content.videoUploadPercent =
                file.percentage.toFixed(0) - 0;
            // this.videoFlag = true;
            // this.videoUploadPercent = file.percentage.toFixed(0) * 1;
        },
        //上传成功回调
        handleVideoSuccess(res, file, activeVideoIndex) {
            // this.isShowUploadVideo = true;
            this.actionList[activeVideoIndex].content.isShowUploadVideo = true;
            this.actionList[activeVideoIndex].content.videoFlag = false;
            this.actionList[activeVideoIndex].content.videoUploadPercent = 0;
            // this.videoFlag = false;
            // this.videoUploadPercent = 0;

            //前台上传地址
            if (file.status == "success") {
                // this.videoForm.showVideoPath = file.url;
                this.actionList[this.activeVideoIndex].content.url = file.url;
            } else {
                this.$message.error("上传失败，请重新上传");
            }

            //后台上传地址
            if (res.code == 0) {
                // this.videoForm.showVideoPath = res.data;
                this.actionList[this.activeVideoIndex].content.url = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
        // 当前操作视频组件index
        activeVideo(index) {
            console.log(index);
        },
        // 取消保存
        cancelSaveConfirm() {
            this.cancelSaveVisible = false;
            this.drawer = false;
        },

        cancelMoveNodeSaveConfirm() {
            this.cancelMoveNodeSaveVisible = false;
            this.drawer = false;
        },

        saveAndChangeNoderelation() {
            this.isPreMoveNode = true;
            this.saveChatFlow();
        },
        // 保存流程设置
        saveChatFlow(hiddenMsg) {
            this.cancelSaveVisible = false;
            this.cancelMoveNodeSaveVisible = false;
            let actionListTamp = this.actionList;
            actionListTamp.forEach((item) => {
                if (item.type == "answer_rich_text") {
                    let str = item.content.html.replace(
                        /\<img src="https:\/\/static/g,
                        '<img width="100%"  src="https://static'
                    );
                    str = str.replace(
                        /a href=/g,
                        "a style='color: -webkit-link;text-decoration: underline;' href="
                    );
                    str = str.replace(/\<li/g, "<li class='rich-text-li'");
                    str = str.replace(
                        /\<img/g,
                        "<img onclick='previewImage(this)' "
                    );
                    str = str.replace(
                        /\<table/g,
                        "<table class='rich-text-table' "
                    );
                    // 附件前标
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_txt.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_txt.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_jpg.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_jpg.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_rar.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_zip.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_pdf.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_pdf.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_doc.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_doc.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_ppt.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_ppt.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_xls.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_xls.gif'
                    );
                    item.content.html = str;
                }
                if (item.type == "action_webhook") {
                    item.content.params.forEach((paramCell, paramCellIndex) => {
                        if (paramCell.type == "WEB_HOOK_RESPONSE") {
                            if (paramCell.webHookShowInfo == undefined) {
                                paramCell.webHookShowInfo = ["", ""];
                            }
                            paramCell.webhookId =
                                paramCell.webHookShowInfo[0] || "";
                            paramCell.webHookResponseName =
                                paramCell.webHookShowInfo[1] || "";
                        }
                    });
                }
            });
            let url = "";
            if (this.nodeId == "start" || this.nodeId == "end") {
                url =
                    this.nodeId == "start"
                        ? this.requestUrl.chatFlowNode.ipdateStartNodeNew
                        : this.requestUrl.chatFlowNode.ipdateEndNodeNew;
                this.FetchPut(url, this.activeBot, this.actionList)
                    .then((res) => {
                        if (res.code === "0") {
                            this.$message.success("保存成功");
                        } else {
                            this.$message.error(res.message);
                        }
                        this.actionListCopy = JSON.parse(
                            JSON.stringify(this.actionList)
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                url = this.requestUrl.chatFlowNode.updateNode;
                this.FetchPut(url, this.nodeId, {
                    actionList: this.actionList,
                    intentId: this.intentId,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            if (hiddenMsg == true) {
                            } else {
                                this.$message.success("保存成功");
                                let isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
                                this.$eventBus.$emit("bot-heathly-visible", {
                                    flag:isShowHeathly,
                                    type:"updateIntentDetail",
                                    intentId:this.$route.query.intentId,
                                    botId:this.$route.query.bid
                                });
                                if (this.isPreMoveNode) {
                                    this.showChangeNoderelation();
                                }
                            }
                            // 编辑节点名称
                            if (this.activeNodeName.trim().length === 0) {
                                this.$message.error("节点名称不能为空！");
                            } else {
                                this.FetchPut(
                                    this.requestUrl.chatFlowNode.updateNodeName,
                                    this.nodeId,
                                    {
                                        intentId: this.intentId,
                                        name: this.activeNodeName.trim(),
                                    }
                                ).then((res) => {
                                    if (res.code === "0") {
                                        this.nodeTree.forEach(
                                            (skillItem, skillIndex) => {
                                                skillItem.children.forEach(
                                                    (classItem, classIndex) => {
                                                        classItem.children.forEach(
                                                            (
                                                                intentItem,
                                                                intentIndex
                                                            ) => {
                                                                if (
                                                                    intentItem.id ===
                                                                    this
                                                                        .intentId
                                                                ) {
                                                                    this.closeIntentNodeList(
                                                                        skillIndex,
                                                                        classIndex,
                                                                        intentIndex,
                                                                        true
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        );
                                    } else {
                                        this.$message.error(res.message);
                                    }
                                });
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                        this.actionListCopy = JSON.parse(
                            JSON.stringify(this.actionList)
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // 点击添加节点
        addNodeHandleFirst(intentId, parentId, indexArr, type) {
            if (type === "first") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].children[0].isAddEditing = true;
                this.nodeTree = [...this.nodeTree];
            } else if (type === "pre") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].isAddEditing = true;
                this.nodeTree = [...this.nodeTree];
            } else {
                let firstNodeArr =
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].children;
                let setName = (firstNodeArr) => {
                    //遍历树  获取id数组
                    firstNodeArr.forEach((item) => {
                        if (item.id === parentId) {
                            item.isAddEditing = true;
                            item.name = item.name.trim() + " ";
                            let tampNodeTree = this.nodeTree;
                            this.nodeTree = [];
                            this.nodeTree = tampNodeTree;
                        } else {
                            item.isAddEditing = false;
                            item.name = item.name.trim() + " ";
                            this.nodeTree = [...this.nodeTree];
                            if (item.children.length > 0) {
                                setName(item.children);
                            }
                        }
                    });
                };
                setName(firstNodeArr);
            }
            this.nodeNameValue = "";
        },
        // 确认添加节点
        confirmAddNodeHandleFirst(intentId, parentId, indexArr, value, type) {
            if (value.length === 0) {
                this.$message.error("请输入节点名称！");
            } else {
                if (type === "first") {
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].isAddEditing = false;
                    this.nodeTree = [...this.nodeTree];
                } else if (type === "pre") {
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].isAddEditing = false;
                    this.nodeTree = [...this.nodeTree];
                } else {
                    let firstNodeArr =
                        this.nodeTree[indexArr[0]].children[indexArr[1]]
                            .children[indexArr[2]].children[0].children;
                    let setName = (firstNodeArr) => {
                        //遍历树  获取id数组
                        firstNodeArr.forEach((item) => {
                            if (item.id === parentId) {
                                item.isAddEditing = false;
                                item.name = item.name + " ";
                                this.nodeTree = [...this.nodeTree];
                            } else {
                                if (item.children.length > 0) {
                                    setName(item.children);
                                }
                            }
                        });
                    };
                    setName(firstNodeArr);
                }
                let paramData = {
                    intentId: intentId,
                    name: value,
                    parentId: parentId,
                };
                this.FetchPost(
                    this.requestUrl.chatFlowNode.addNode,
                    {
                        intentId: intentId,
                        name: value,
                        parentId: parentId,
                    },
                    { emulateJSON: true }
                ).then((res) => {
                    if (res.code === "0") {
                        this.$message.success("节点添加成功！");
                        this.nodeTree[indexArr[0]].children[
                            indexArr[1]
                        ].children[indexArr[2]].hasProcess = true;
                        this.closeIntentNodeList(
                            indexArr[0],
                            indexArr[1],
                            indexArr[2],
                            true
                        );
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },

        // 放弃添加节点
        concelAddNodeHandleFirst(intentId, parentId, indexArr, type) {
            if (type === "first") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].children[0].isAddEditing = false;
                this.nodeTree = [...this.nodeTree];
            } else if (type === "pre") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].isAddEditing = false;
                this.nodeTree = [...this.nodeTree];
            } else {
                let firstNodeArr =
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].children;
                let setName = (firstNodeArr) => {
                    //遍历树  获取id数组
                    firstNodeArr.forEach((item) => {
                        if (item.id === parentId) {
                            item.isAddEditing = false;
                            item.name = item.name + " ";
                            this.nodeTree = [...this.nodeTree];
                        } else {
                            setName(item.children);
                        }
                    });
                };
                setName(firstNodeArr);
            }
        },

        // 点击删除节点
        deleteNodeHandleFirst(intentId, id) {
            this.$confirm(
                "删除节点后，该任务下的所有子节点都将被删除！",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.FetchDelete2(
                        this.requestUrl.chatFlowNode.deleteNode +
                            "?intentId=" +
                            intentId +
                            "&nodeId=" +
                            id
                    ).then((res) => {
                        if (res.code === "0") {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.nodeTree.forEach((item, skillIndex) => {
                                item.children.forEach((cell, classIndex) => {
                                    cell.children.forEach(
                                        (miniItem, intentIndex) => {
                                            if (miniItem.id === intentId) {
                                                this.closeIntentNodeList(
                                                    skillIndex,
                                                    classIndex,
                                                    intentIndex,
                                                    true,
                                                    "delete"
                                                );
                                            }
                                        }
                                    );
                                });
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },

        // 获取机器人列表
        getBotList() {
            let bid = this.$route.query.bid;
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    if (this.botList.length > 0) {
                        if (!bid) {
                            this.activeBot = Number(this.$route.query.id);
                        } else {
                            this.activeBot = Number(this.$route.query.bid);
                        }
                        this.getIntentListByBotId(this.activeBot);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // select bot
        selectBot() {
            this.getIntentListByBotId(this.activeBot);
            //this.getIntentListByBotId(this.$route.query.id)
        },
        //根据Url跳转至意图列表页面
        tobotIntent(botId, botName, intentId, intentName) {
            var intentId = this.$route.query.intentId;
            if (this.$route.query.isOtherSkill) {
                sessionStorage.setItem('IntentIsRet',1);
                this.$router.push({
                    name: "botIntent",
                    query: {
                        id: this.$route.query.id,
                        name: this.$route.query.bName,
                        fromIntentID: this.$route.query.intentId,
                    },
                });
            } else {
                if (!this.RP_Visible("OPEN_INTENT")) {
                    this.$router.push({
                        name: "botIntent",
                        query: {
                            id: this.$route.query.id,
                            name: this.$route.query.name,
                            refApiKey: this.$router.currentRoute.query.refApiKey,
                            tabIndex: "skill",
                            isDefault: this.$route.query.isDefault,
                        },
                    });
                } else {
                    if (!intentId) {
                        this.$router.push({
                            name: "botIntent",
                            query: {
                                id: this.$route.query.id,
                                name: this.$route.query.name,
                                isDefault: this.$route.query.isDefault,
                            },
                        });
                    } else {
                        var bid = this.$route.query.bid;
                        var bName = this.$route.query.bName;
                        this.$router.push({
                            name: "intentQa",
                            query: {
                                bid: this.$route.query.bid,
                                bName: this.$route.query.bName,
                                intentId: this.$route.query.intentId,
                                intentName: this.$route.query.intentName,
                                refApiKey:
                                    this.$router.currentRoute.query.refApiKey,
                                isDefault: this.$route.query.isDefault,
                            },
                        });
                    }
                }
            }
            
        },
        //流程中点击意图名称跳转至意图页面
        tobotIntentQa(intentId, intentName) {
            var id = this.$route.query.id;
            var name = this.$route.query.name;
            var bName = this.$route.query.bName;
            var bid = this.$route.query.bid;
            console.log(8912, intentName);
            if (this.$route.query.isOtherSkill) {
                sessionStorage.setItem('IntentIsRet',1);
                this.$router.push({
                    name: "botIntent",
                    query: {
                        id: this.$route.query.id,
                        name: this.$route.query.bName,
                        fromIntentID: this.$route.query.intentId,
                    },
                });
            } else {
                if (bid && intentName != "咨询者问题机器人不识别") {
                    this.$router.push({
                        name: "intentQa",
                        query: {
                            bid: this.$route.query.bid,
                            bName: this.$route.query.bName,
                            intentId: intentId,
                            intentName: intentName,
                        },
                    });
                } else if (id && intentName != "咨询者问题机器人不识别") {
                    this.$router.push({
                        name: "intentQa",
                        query: {
                            bid: this.$route.query.id,
                            bName: this.$route.query.name,
                            intentId: intentId,
                            intentName: intentName,
                        },
                    });
                }
            }
        },
        //页面加载时滚动到指定意图
        rollToIntent() {
            let refApiKey = this.$router.currentRoute.query.refApiKey;
            var rollToId = "intentSpan" + this.$route.query.intentId;
            if (
                this.$route.query.intentId &&
                refApiKey !==
                    apiKeys[process.env.VUE_APP_CONFIG_ENV].SAMPLE_BOT_API_KEY
            ) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        document.querySelector("#" + rollToId).scrollIntoView({
                            behavior: "smooth", // 平滑过渡
                            block: "start", // 上边框与视窗顶部平齐。默认值
                        });
                    }, 500);
                });
                this.nodeTree.forEach((skillItem, skillIndex) => {
                    skillItem.children.forEach((classItem, classIndex) => {
                        classItem.children.forEach(
                            (intentItem, intentIndex) => {
                                if (
                                    intentItem.id == this.$route.query.intentId
                                ) {
                                    this.closeIntentNodeList(
                                        skillIndex,
                                        classIndex,
                                        intentIndex,
                                        true
                                    );
                                }
                            }
                        );
                    });
                });
                this.nodeTree = [...this.nodeTree];
            } else {
                if (this.$route.query.toBottom) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            document
                                .querySelector("#" + "endNode")
                                .scrollIntoView({
                                    behavior: "smooth", // 平滑过渡
                                    block: "end", // 上边框与视窗顶部平齐。默认值
                                });
                        }, 500);
                    });
                }
            }
        },
        //初次登陆引导层
        guide() {
            //this.driver.defineSteps(steps)
            this.driver.defineSteps([
                {
                    element: "#start-info",
                    popover: {
                        title: "开始节点",
                        description:
                            "您已经进入机器人对话流程设计页面，机器人从开始节点进行启动，您可以点击开始按钮进行欢迎语等设置",
                        position: "right",
                    },
                    /* onNext: () => {
              this.configAction(0, 'start', 0, '开始')
            } */
                },
                {
                    element: "#skillName",
                    popover: {
                        title: "知识库",
                        description:
                            "机器人的知识库是通过层级结构进行存放和展示<br>比如：企业内部服务机器人，可以把财务、人力资源不同部门的知识存放在不同的知识库，方便进行分权限进行管理",
                        position: "right",
                    },
                },
                {
                    element: "#typeName",
                    popover: {
                        title: "意图分类",
                        description:
                            "知识库下的知识是以意图的形式进行管理，为了方便管理意图，对意图进行了分类",
                        position: "right",
                    },
                },
                {
                    element: "#intentNames",
                    popover: {
                        title: "意图",
                        description:
                            "一个机器人就是由多个意图组成，机器人的意图越多，也代表着机器人能够识别的问题越多，越聪明。<br><br><b>单轮意图</b><br>当我们给意图进行回答设置时，有的意图如果机器人识别到了，可以直接回复一个答案，我们称之为单轮意图<br><br><b>多轮意图</b><br>如果没法直接回复答案，需要和用户进行一些信息确认，我们称这个意图为多轮意图",
                        position: "right",
                    },
                    onNext: () => {
                        this.closeIntentNodeList(0, 0, 0);
                        setTimeout(() => {
                            this.guideNode();
                        }, 1000);
                    },
                },
                {
                    element: ".intent-node",
                    popover: {
                        title: "流程节点",
                        description:
                            "现在您看到的意图是：询问手机下载客户端的方法，因为手机一般分为安卓和苹果操作系统的手机，所以下载客户端的方法也不太一样，那我们就需要和用户确认，使用的手机类型是哪种<br><br>所以配置的方法为：<br>（1）增加一个节点用来判断手机类型<br>（2）继续增加两个节点，分别配置安卓和苹果手机的下载方法",
                        position: "right",
                    },
                },
            ]);
            this.driver.start();
        },
        guideNode() {
            //this.driver.defineSteps(steps)
            this.driver.defineSteps([
                {
                    element: ".intent-node",
                    popover: {
                        title: "流程节点",
                        description:
                            "现在您看到的意图是：询问手机下载客户端的方法，因为手机一般分为安卓和苹果操作系统的手机，所以下载客户端的方法也不太一样，那我们就需要和用户确认，使用的手机类型是哪种<br><br>所以配置的方法为：<br>（1）增加一个节点用来判断手机类型<br>（2）继续增加两个节点，分别配置安卓和苹果手机的下载方法<br><br>点击节点名称进入节点配置页面",
                        position: "right",
                    },
                },
            ]);
            this.driver.start();
        },
        // 获取元素高度
        getEleHeight(type) {
            // type 1 副元素高度 2 子元素高度
            this.$nextTick(() => {
                setTimeout(() => {
                    let that = this;
                    that._$(".left-border-replace").height(0);
                    that._$(".left-border-replace").each(function () {
                        that._$(this).height(
                            that._$(this).parent().height() -
                                that
                                    ._$(this)
                                    .parent()
                                    .next()
                                    .children()
                                    .last()
                                    .height() +
                                30
                        );
                    });
                }, 100);
            });
        },
    },
    created() {
        // 点击添加节点
        Bus.$on("addNodeHandle", (intentId, parentId, indexArr) => {
            this.addNodeHandleFirst(intentId, parentId, indexArr, "child");
        });

        // 确认添加节点
        Bus.$on(
            "confirmAddNodeHandle",
            (intentId, parentId, indexArr, value) => {
                this.confirmAddNodeHandleFirst(
                    intentId,
                    parentId,
                    indexArr,
                    value,
                    "child"
                );
            }
        );

        // 放弃添加节点
        Bus.$on("concelAddNodeHandle", (intentId, parentId, indexArr) => {
            this.concelAddNodeHandleFirst(
                intentId,
                parentId,
                indexArr,
                "child"
            );
        });

        // 点击删除节点
        Bus.$on("deleteNodeHandle", (intentId, id) => {
            this.deleteNodeHandleFirst(intentId, id);
        });

        // 点击删除节点
        Bus.$on("configAction", (intentId, id, skillId, name, indexArr) => {
            this.configAction(intentId, id, skillId, name, indexArr);
        });
        this.maxLengthLimit = localStorage.getItem('_mainId') === '50a9fb43e7084fdfa450e528c8770d4e' && this.$route.query.bid === '1951' ? 999 : 40;
    },
    mounted() {
        this.botVersion = localStorage.getItem('_bot_version');
        this.$nextTick(() => {
            this.showTip = true;
        });
        if (this.isPart == 1) {
            this.getOldAndNewIntentFlow();
        }
        this.getWebhookEntityTree();
        this.getWebhookBaseList();
        this.getBotList();
        this.getComponents();
        this.getWebhookList();
        this.allEntitys = this.loadAllEntity();
        // setTimeout(() => {
        //     this.$nextTick(() => {
        //         this.rollToIntent();
        //         this.getEleHeight();
        //     });
        // }, 500);
        this.driver = new Driver({
            className: "scoped-class", // className to wrap driver.js popover
            animate: true, // Animate while changing highlighted element
            opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
            padding: 10, // Distance of element from around the edges
            allowClose: true, // Whether clicking on overlay should close or not
            overlayClickNext: true, // Should it move to next step on overlay click
            doneBtnText: "完成", // Text on the final button
            closeBtnText: "关闭", // Text on the close button for this step
            nextBtnText: "下一步", // Next button text for this step
            prevBtnText: "上一步", // Previous button text for this step
        });
        //this.driver = new Driver()
        //页面加载完成后出现引导层
        let refApiKey = this.$router.currentRoute.query.refApiKey;
        let that = this;
        this.$nextTick(() => {
            // 确保dom异步加载完毕
            if (
                refApiKey ===
                apiKeys[process.env.VUE_APP_CONFIG_ENV].SAMPLE_BOT_API_KEY
            ) {
                that.guidTaskId = setTimeout(() => {
                    this.guide();
                }, 4000);
            }
        });

        // 获取用户分组list
        this.FetchGet(this.requestUrl.accountGroup.getAccountGroupList).then(
            (res) => {
                if (res.code === "0") {
                    this.custonerOptions = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }
        );
        //
        this.FetchGet(
            this.requestUrl.bot.getBotInfo + "/" + this.$route.query.id
        ).then((res) => {
            if (res.code === "0") {
                this.customerServiceOfflineIntentId = String(
                    res.data.setting.customerServiceOfflineIntentId
                );
            } else {
                this.$message.error(res.message);
            }
        });
        if(this.$route.query.isHeathly == 1){
            this.activeBot = Number(this.$route.query.id);
            this.configAction(0, 'start', 0, '开始')
        }
    },
};
</script>
<style lang="less">
@import "./../../assets/less/ChatFlow/ChatFlowOld.less";
@import "./../../assets/less/ChatFlow/qEditor.less";
.btn-list-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .btn-list-cell:hover {
        .my-handle-btn {
            display: inline-block;
        }
        .my-handle-close {
            display: inline-block;
        }
    }
    .btn-list-cell {
       height: 32px;
       line-height: 32px;
       border-radius: 16px;
       padding: 0;
       margin-right: 6px;
       margin-top: 10px;
       border: solid 1px #366AFF;
       color: #366AFF;
       cursor: pointer;
       position: relative;
       background-color: white;
       .btn-list-cell-text {
           display: inline-block;
           height: 32px;
           line-height: 32px;
           padding: 0 16px;
       }
       .my-handle-btn {
            display: none;
            position: absolute;
            top: -8px;
            right: 26px;
            height: 16px;
            line-height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: white;
           
       }
       .my-handle-close {
            display: none;
            position: absolute;
            top: -8px;
            right: 3px;
            height: 16px;
            line-height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: white;
       }
   } 
}
.ck-content {
    word-break: break-all;
    // ul,
    // ol,
    // li {
    //     list-style-type: none;
    // }
    ul {
        display: block !important;
        list-style-type: disc !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
    }
    ul,
    ul li {
        list-style-type: disc !important;
    }

    ol {
        display: block !important;
        list-style-type: decimal !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
    }

    ol,
    ol li {
        list-style-type: decimal !important;
    }
}
</style>
