<template>
    <div  :class="['question-list',isShowSearch ? 'show-search' : '']">
        <div class="batch-title" v-if="checkedIds.length">
            <el-checkbox v-model="checkAll" @change="selectAll">{{
                checkAll === true ? "取消选择" : "全选本页"
            }}</el-checkbox>
            <span class="current-count"
                >已选择<i>{{ checkedIds.length }}</i
                >条</span
            >
            <span v-if="currentQ[0].isIdentify === true">
                <i
                    class="el-icon-check"
                    @click="tagIntent(currentQ, true, 1)"
                ></i>
                <i
                    class="el-icon-close"
                    @click="tagIntent(currentQ, false, 1)"
                ></i>
            </span>
            <span v-else class="tagging" @click="tagIntent(currentQ, false, 1)"
                >标注</span
            >
            <span
                v-if="qwType == 'mark'"
                class="tagging"
                style="margin-left: 12px"
                @click="ignoreBatch(currentQ)"
                >忽略</span
            >
        </div>
        <el-table
            id="q-table"
            v-loading="loading"
            :height="'calc(100vh - ' + (240) + 'px)'"
            :data="tableData"
            @cell-mouse-enter="cellEnter"
            @cell-mouse-leave="cellLeave"
        >
            <el-table-column
                min-width="390"
                label=""
                :render-header="renderquestion"
                prop="question"
            >
                <template slot-scope="scope">
                    <div style="display: flex">
                        <span
                            style="width: 14px; height: 14px; margin-right: 5px"
                        >
                            <el-checkbox
                                v-show="scope.row.isActive || checkedIds.length"
                                v-model="scope.row.checked"
                                :disabled="
                                    Boolean(
                                        scope.row.disabled && checkedIds.length
                                    )
                                "
                                @change="selectQuestion($event, scope.row)"
                            />
                        </span>
                        <el-tooltip
                            v-if="scope.row.question.length > 31"
                            effect="dark"
                            :content="scope.row.question"
                            placement="top-start"
                        >
                            <span
                                style="
                                    flex: 1;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                "
                            >
                                {{ scope.row.question }}
                            </span>
                        </el-tooltip>
                        <span v-else style="flex: 1">{{
                            scope.row.question
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                label="意图名称"
                min-width="180"
                align="center"
                prop="intentName"
            >
                <template slot-scope="scope">
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            (scope.row.unrecognizedAction == null ||
                                scope.row.unrecognizedAction == 0)
                        "
                        style="color: #f56c6c"
                        >不识别</span
                    >
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            scope.row.unrecognizedAction == 1
                        "
                        style="color: #f56c6c"
                        >关键词推荐</span
                    >
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            scope.row.unrecognizedAction == 2
                        "
                        style="color: #f56c6c"
                        >不识别推荐</span
                    >
                    <span v-else>{{ scope.row.intentName }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="time"
                label="日期"
                :render-header="renderDate"
                align="center"
                min-width="150"
            >
            </el-table-column>
            <el-table-column
                prop="hits"
                label="频次"
                :render-header="renderCount"
                align="center"
                width="150"
            >
                <template slot-scope="scope">
                    <el-button type="text" @click="viewAnswer(scope.row)" v-if="scope.row.isActive === true" class="view-info"
                        >查看聊天记录</el-button
                    >
                    <span v-else>{{ scope.row.hits }} </span>
                </template>
                <!-- <template slot-scope="scope">
                    <span>{{ scope.row.hits }} </span>
                    <el-button
                        type="text"
                        class="reset-tag"
                        icon="el-icon-view"
                        @click="viewAnswer(scope.row)"
                    ></el-button>
                </template> -->
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" width="240">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: center">
                        <el-button
                            v-if="
                                [5].indexOf(scope.row.button) > -1 &&
                                RP_Visible('OPEN_ML_MARK')
                            "
                            type="text"
                            :disabled="Boolean(checkedIds.length)"
                            class="reset-tag"
                            @click="tagIntent(scope.row, false, 0)"
                            >标注</el-button
                        >
                        <div v-if="[1].indexOf(scope.row.button) > -1">
                            <el-tooltip
                                content="识别正确"
                                placement="top"
                                effect="light"
                            >
                                <i
                                    class="el-icon-check"
                                    @click="tagIntent(scope.row, true, 0)"
                                ></i>
                            </el-tooltip>
                            <el-tooltip
                                content="识别错误"
                                placement="bottom"
                                effect="light"
                            >
                                <i
                                    class="el-icon-close"
                                    @click="tagIntent(scope.row, false, 0)"
                                ></i>
                            </el-tooltip>
                        </div>
                        <div
                            style="padding-top: 2px"
                            v-if="
                                [6].indexOf(scope.row.button) > -1 &&
                                RP_Visible('OPEN_ML_MARK')
                            "
                        >
                            <i
                                class="el-icon-check"
                                @click="tagIntent(scope.row, true, 0)"
                            ></i>
                            <i
                                class="el-icon-close"
                                @click="tagIntent(scope.row, false, 0)"
                            ></i>
                        </div>
                        <div v-if="[2].indexOf(scope.row.button) > -1">
                            <el-button
                                v-if="
                                    scope.row.isActive === true &&
                                    RP_Visible('OPEN_ML_MARK')
                                "
                                type="text"
                                class="reset-tag"
                                @click="tagIntent(scope.row, false, 0)"
                                >重新标注</el-button
                            >
                            <span v-else>识别正确</span>
                        </div>
                        <div v-if="[3, 4].indexOf(scope.row.button) > -1">
                            <template v-if="scope.row.isActive === true">
                                <el-tooltip
                                    v-if="
                                        scope.row.tagIntentName.length > 10 &&
                                        RP_Visible('OPEN_ML_MARK')
                                    "
                                    class="item"
                                    effect="dark"
                                    :content="scope.row.tagIntentName"
                                    placement="top-start"
                                >
                                    <el-button
                                        type="text"
                                        class="reset-tag"
                                        @click="tagIntent(scope.row, false, 0)"
                                        >重新标注</el-button
                                    >
                                </el-tooltip>
                                <el-button
                                    v-else
                                    type="text"
                                    class="reset-tag"
                                    @click="tagIntent(scope.row, false, 0)"
                                    >重新标注</el-button
                                >
                            </template>
                            <template v-else>
                                <span>
                                    {{
                                        scope.row.tagIntentName.length > 10
                                            ? scope.row.tagIntentName.substring(
                                                  0,
                                                  10
                                              ) + "..."
                                            : scope.row.tagIntentName
                                    }}</span
                                >
                            </template>
                        </div>
                        <div v-if="qwType == 'mark'" style="margin-left: 6px">
                            <el-button
                                v-if="RP_Visible('OPEN_ML_MARK')"
                                type="text"
                                class="reset-tag"
                                @click="ignore(scope.row)"
                                >忽略</el-button
                            >
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="annotatorName"
                label="操作人"
                align="center"
                width="120"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.annotatorName }} </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTimeStr"
                label="操作时间"
                align="center"
                width="150"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.updateTimeStr }} </span>
                </template>
            </el-table-column>
            
        </el-table>
        <el-pagination
            :page-size="pageSize"
            :current-page="currentPage"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="pageChange"
        >
        </el-pagination>
        <el-drawer
            size="1010px"
            direction="rtl"
            :visible.sync="drawer"
            :with-header="false"
        >
            <div class="drawer-content-o" style="height: 100%">
                <feedback-detail
                    v-if="drawer"
                    viewTypeParent="question"
                    @closeFeedbackDetailBox="closeFeedbackDetailBox"
                    :activeItem="activeItem"
                />
            </div>
        </el-drawer>
        <tagging ref="myTaggging" :botId="botId" @close="close" />
    </div>
</template>

<script>
import Tagging from "./tagging";
import TaggingPopover from "./taggingPopover";
import FeedbackDetail from "./component/FeedbackDetail";
export default {
    props: ["botId", "keyword", "qwType", "topAdd","isShowSearch"],
    components: {
        Tagging,
        TaggingPopover,
        FeedbackDetail,
    },
    data() {
        return {
            drawer: false,
            activeItem: {},
            pageSize: 20,
            currentPage: 1,
            total: 0,
            queryType: 100, // 全部问题100 不识别问题 0 已识别问题 1
            sort: false, // 相似问题排序
            orderType: 0, //  0：时间倒序 1：时间正序 2：频次倒序 3：频次正序 4：相似问法
            currentIntent: "", // 当前意图
            checkAll: false,
            intentionList: [],
            tableData: [],
            tagType: false, // 标注正确还是错误，重新标注和叉是false,勾是true
            currentQ: [],
            checkedIds: [], // 选中的
            taggingStatus: "", // 选中问题的标注状态
            loading: false,
            // tabelHeight: "calc(100vh - 200px" - this.topAdd + "px)",
            advSearchObjParent: null
        };
    },
    watch: {
        botId(n, o) {
            this.getIntentList();
        },
    },
    mounted() {
        // this.getIntentList();
        // this.getQuestion();
    },
    methods: {
        closeFeedbackDetailBox() {
            this.drawer = false;
        },
        viewAnswer(item) {
            this.activeItem = "";
            this.activeItem = JSON.stringify(item);
            this.drawer = true;
        },
        // 忽略待标注 批量
        ignoreBatch(currentQ) {
            this.FetchPost(this.requestUrl.trainList.batchIgnoreTag, {
                list: this.checkedIds,
            }).then((res) => {
                if (res.code === "0") {
                    this.getQuestion();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 忽略待标注 单条
        ignore(row) {
            this.FetchPut(this.requestUrl.trainList.ignoreTag, row.id, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.getQuestion();
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 单选
        selectQuestion(val, row) {
            this.taggingStatus = row.isIdentify;
            this.tableData.forEach((v) => {
                v.disabled = false;
                if (v.isIdentify !== row.isIdentify) v.disabled = true;
            });
            if (val === true) {
                this.checkedIds.push(row.id);
                this.currentQ.push(row);
            } else {
                const ind = this.checkedIds.indexOf(row.id);
                this.checkedIds.splice(ind, 1);
                this.currentQ.splice(ind, 1);
            }
        },
        cellEnter(row) {
            this.tableData.forEach((v) => {
                v.isActive = false;
            });
            if (this.checkedIds.length === 0) row.isActive = true;
        },
        cellLeave(row) {
            this.tableData.forEach((v) => {
                v.isActive = false;
            });
        },
        close() {
            this.checkedIds = [];
            this.currentQ = [];
            this.getQuestion();
            this.$emit("refresh");
        },
        // 获取机器人下的意图
        getIntentList() {
            this.FetchGet(
                this.requestUrl.trainList.intentTree +
                    "?bId=" +
                    this.botId +
                    "&filterType=1"
            ).then((res) => {
                if (res.code === "0") {
                    res.data.forEach((val) => {
                        let arr = [];
                        val.children.forEach((v) => {
                            arr = arr.concat(v.children);
                        });
                        val.children = arr;
                    });
                    this.intentionList = res.data;
                    this.getQuestion();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        formatAdvSearchParam(param, advObj) {
            // 问题类型
            if (advObj.queryTypeObj != undefined) {
                param.identifyStatus = advObj.queryTypeObj.value;
                this.queryType = advObj.queryTypeObj.value;
                if (this.queryType == 0) {
                    param.unrecognizedAction = 0;
                    param.identifyStatus = 0;
                }
                if (this.queryType == 2) {
                    param.unrecognizedAction = 2;
                    param.identifyStatus = 0;
                }
                if (this.queryType == 3) {
                    param.unrecognizedAction = 1;
                    param.identifyStatus = 0;
                }
            } else {
                param.identifyStatus = null;
                this.queryType = 100;
            }
            // 被识别意图
            if (advObj.intents != undefined) {
                param.intentIds = advObj.intents
                    .map((intent) => {
                        return intent.id;
                    })
                    .join(",");
            }
            // 最后消息时间
            if (advObj.lastMsgTime != undefined) {
                param.beginMsgTime = advObj.lastMsgTime[0];
                param.endMsgTime = advObj.lastMsgTime[1];
                if (param.beginMsgTime == param.endMsgTime) {
                    let end = new Date(param.endMsgTime).getTime() + 24*60*60*1000;
                    param.endMsgTime = new Date(end);
                }
            }
            // 最后消息部门
            if (advObj.orgnation != undefined) {
                param.departmentId = advObj.orgnation[0].id;
            }
            // 最后标注时间
            if (advObj.lastNoteTime != undefined) {
                param.beginTagTime = advObj.lastNoteTime[0];
                param.endTagTime = advObj.lastNoteTime[1];
                if (param.beginTagTime == param.endTagTime) {
                    let end = new Date(param.endTagTime).getTime() + 24*60*60*1000;
                    param.endTagTime = new Date(end);
                }
            }
            // 最后消息部门
            if (advObj.noteStetus != undefined) {
                param.status = advObj.noteStetus.status;
            }
            // 最后消息部门
            if (advObj.noteUser != undefined) {
                param.annotators = (advObj.noteUser.map(item => {return item.id})).join(",");
            }

            return param;
        },
        // 获取待标注问题
        getQuestion(setPage1, advSearchObj) {
            if (setPage1 == 1) {
                this.currentPage = 1;
            }
            this.loading = true;
            let param = {
                botId: this.botId,
                intentId: this.currentIntent,
                keyword: this.keyword.trim(),
                orderType: this.orderType,
                page: this.currentPage,
                size: this.pageSize,
                ignored: this.qwType == "mark" ? 0 : 1,
            };
            if (this.queryType != 100) {
                param.identifyStatus = this.queryType;
                if (this.queryType == 0) {
                    param.unrecognizedAction = 0;
                    param.identifyStatus = 0;
                }
                if (this.queryType == 2) {
                    param.unrecognizedAction = 2;
                    param.identifyStatus = 0;
                }
                if (this.queryType == 3) {
                    param.unrecognizedAction = 1;
                    param.identifyStatus = 0;
                }
            }
            if (advSearchObj != undefined) {
                this.advSearchObjParent =  advSearchObj;
                param = this.formatAdvSearchParam(param, advSearchObj);
            }
            this.FetchPost(this.requestUrl.trainList.search, param)
                .then((res) => {
                    if (res.code === "0") {
                        this.tableData = [];
                        res.data.list.forEach((v) => {
                            if (v.isIdentify === true && v.status === "NOT_TAG")
                                v.button = 1;
                            if (
                                v.isIdentify === true &&
                                v.status === "IDENTIFY_CORRECT_TAGED"
                            )
                                v.button = 2;
                            if (
                                v.isIdentify === true &&
                                v.status === "IDENTIFY_ERROR_TAGED"
                            )
                                v.button = 3;
                            if (
                                v.isIdentify === false &&
                                v.status === "NOT_IDENTIFY_TAGED"
                            )
                                v.button = 4;
                            if (
                                v.isIdentify === false &&
                                v.status === "NOT_TAG"
                            )
                                v.button = 5;
                            if (v.isIdentify === true && v.status === "NOT_TAG")
                                v.button = 6;
                            v.isActive = false;
                            v.checked = false;
                            v.disabled = false;
                            v.time = new Date(v.createTime).Format(
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            if (v.tagTime != null || v.tagTime != undefined) {
                                v.updateTimeStr = new Date(v.tagTime).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                            } else {
                                v.updateTimeStr = "";
                            }
                            if (v.annotatorInfo != null) {
                                v.annotatorName = v.annotatorInfo.username;
                            } else {
                                v.annotatorName = "";
                            }
                            this.tableData.push(v);
                        });
                        this.total = res.data.total;
                        this.$emit('getOverview',param)
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        pageChange(curr) {
            this.currentPage = curr;
            if (this.advSearchObjParent != null && JSON.stringify(this.advSearchObjParent) != "{}") {
                this.getQuestion( this.currentPage, this.advSearchObjParent);
            } else {
                this.getQuestion();
            }
        },
        // 全选
        selectAll(val) {
            this.checkedIds = [];
            this.currentQ = [];
            if (val === true) {
                this.tableData.forEach((v) => {
                    if (this.taggingStatus === v.isIdentify) {
                        v.checked = true;
                        this.checkedIds.push(v.id);
                        this.currentQ.push(v);
                    }
                });
            } else {
                this.taggingStatus = "";
                this.tableData.forEach((v) => {
                    v.checked = false;
                    v.disabled = false;
                });
            }
        },
        tagIntent(row, isRight, type) {
            this.$refs.myTaggging.botId = this.botId;
            if (isRight === true) {
                // 标注正确
                this.$refs.myTaggging.isRight = isRight;
                // this.$refs.myTaggging.intentId = row.intentId;
                // this.$refs.myTaggging.intentName = row.intentName;
                this.$refs.myTaggging.type = type;
                if (type) {
                    this.$refs.myTaggging.tId = this.checkedIds.join(",");
                    this.$refs.myTaggging.isIdentify = row[0].isIdentify;
                    this.checkedIds = [];
                } else {
                    this.$refs.myTaggging.tId = row.id;
                    this.$refs.myTaggging.isIdentify = row.isIdentify;
                }
                this.$refs.myTaggging.tagIntent("tab");
            } else {
                this.$refs.myTaggging.getIntentListByBotId(this.botId);
                this.$refs.myTaggging.open(row, isRight, type, this.checkedIds);
            }
        },
        renderIntention(h, { column }) {
            return h(
                "el-select",
                {
                    props: {
                        value: this.currentIntent,
                        size: "small",
                        filterable: true,
                    },
                    class: "select-intention",
                    on: {
                        change: (val) => {
                            this.currentIntent = val;
                            this.getQuestion();
                        },
                    },
                },
                [
                    h("el-option", {
                        props: {
                            value: "",
                            label: "全部意图",
                            key: "",
                        },
                    }),
                    this.intentionList.map((item) => {
                        return h(
                            "el-option-group",
                            {
                                props: {
                                    label: item.name,
                                    key: item.id,
                                },
                                style: {
                                    width: "300px",
                                },
                            },
                            [
                                item.children.map((v) => {
                                    return h("el-option", {
                                        props: {
                                            value: v.id,
                                            label: v.name,
                                            key: v.id,
                                        },
                                    });
                                }),
                            ]
                        );
                    }),
                ]
            );
        },
        renderquestion(h, { column }) {
            const filters = [
                {
                    value: 100,
                    text: "全部问题",
                },
                {
                    value: 0,
                    text: "不识别问题",
                },
                {
                    value: 1,
                    text: "已识别问题",
                },
                {
                    value: 2,
                    text: "不识别推荐",
                },
                 {
                    value: 3,
                    text: "关键词推荐",
                },
            ];
            return h("div", [
                h(
                    "el-select",
                    {
                        props: {
                            value: this.queryType,
                            size: "small",
                        },
                        class: "select-question",
                        on: {
                            change: (val) => {
                                this.queryType = val;
                                this.getQuestion(1);
                            },
                        },
                    },
                    [
                        filters.map((item) => {
                            return h("el-option", {
                                props: {
                                    value: item.value,
                                    label: item.text,
                                },
                            });
                        }),
                    ]
                ),
                h(
                    "div",
                    {
                        class: "open-sort",
                    },
                    [
                        h("span", "相似问题排序："),
                        h("el-switch", {
                            props: {
                                "active-text": "开",
                                "inactive-text": "关",
                                value: this.sort,
                            },
                            on: {
                                change: (val) => {
                                    this.sort = val;
                                    if (val === true) {
                                        this.orderType = 4;
                                    } else {
                                        this.orderType = 0;
                                    }
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        renderDate(h) {
            return h("div", [
                h("span", "最后消息时间"),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    !this.sort && this.orderType === 1
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 1;
                                    this.getQuestion();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    !this.sort && this.orderType === 0
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 0;
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        renderCount(h) {
            return h("div", [
                h("span", "频次"),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    !this.sort && this.orderType === 3
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 3;
                                    this.getQuestion();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    !this.sort && this.orderType === 2
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 2;
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/main/common.less";
#q-table {
    margin-top: 60px;
    display: inline-grid;
}
.el-pagination {
    padding: 20px 60px;
    text-align: center;
    background-color: white;
}
.question-list {
    // margin-top: 50px;
    margin-right: 5px;
    // margin-left: 5px;
    background: #ffffff;
    overflow: hidden;
    .el-table .el-table__fixed {
        height: auto !important;
        bottom: 17px;
    }
    .el-table__header-wrapper {
        height: 60px !important;
    }
    // .el-table__fixed-body-wrapper {
    //     height: calc(100vh - 267px) !important;
    // }
    .guoran-tab {
    }
    .el-icon-check {
        color: #67c23a;
        font-size: 18px;
        font-weight: bolder;
        cursor: pointer;
    }
    .el-icon-close:not(.l-dialog__close) {
        margin-left: 10px;
        color: #f56c6c;
        font-size: 18px;
        font-weight: bolder;
        cursor: pointer;
    }
    .el-checkbox {
        padding: 0px !important;
    }
    &.show-search{
        #q-table {
            margin-top: 110px;
        }
    }
}
.batch-title {
    display: flex;
    width: calc(100% - 30px);
    height: 37px;
    position: absolute;
    background: #fff;
    z-index: 999;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    line-height: 37px;
    .current-count {
        flex: 1;
        padding-right: 10px;
        text-align: right;
        i {
            padding: 0 5px;
            color: #366AFF;
            font-style: normal;
        }
    }
    .tagging {
        color: #366AFF;
        cursor: pointer;
    }
}
.el-table {
    th div {
        line-height: 34px;
    }
    th .cell {
        height: 34px;
        line-height: 34px;
    }
    .el-switch {
        display: inline;
        line-height: unset;
        height: 24px;
        padding: 0;
    }
    .el-select,
    .el-input {
        padding-left: 0;
    }
    .reset-tag,
    .view-info {
        padding: 0;
    }
    .open-sort {
        float: left;
        padding-left: 0;
        .el-switch {
            margin-top: -10px;
            display: inline-block;
            line-height: unset;
            height: 30px;
        }
        p {
            height: 20px;
            line-height: 20px;
        }
        .el-switch__label {
            line-height: 20px;
        }
    }
}
.select-question {
    float: left;
    width: 150px;
    .el-input {
        padding-right: 0;
    }
}
.select-intention {
    width: 180px;
    .el-input {
        padding-right: 0;
    }
}
</style>