<template>
    <div class="adv-search-cpnt">
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">问题类型</div>
            <div class="adv-s-cell-b">
                <el-select size="mini" v-model="queryType" placeholder="请选择">
                    <el-option
                        v-for="item in queryTypeOptions"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">被识别意图</div>
            <div class="adv-s-cell-b">
                <el-cascader
                    size="mini"
                    style="width: 100%"
                    :options="intentTree"
                    :collapse-tags="true"
                    key="advIntentSearch"
                    :props="props"
                    :show-all-levels="false"
                    placeholder="试试搜索：意图名"
                    v-model="intentIds"
                    filterable
                ></el-cascader>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">最后消息时间</div>
            <div class="adv-s-cell-b">
                <el-date-picker
                    size="mini"
                    v-model="lastMsgTime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">最后消息部门</div>
            <div class="adv-s-cell-b">
                <el-cascader
                    size="mini"
                    v-model="orgnation"
                    :key="cascaderKey"
                    :options="orgTree"
                    :collapse-tags="true"
                    :props="{
                        multiple: false,
                        checkStrictly: true,
                        emitPath: false,
                    }"
                    @change="orgCascaderChange"
                    clearable
                ></el-cascader>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">操作人</div>
            <div class="adv-s-cell-b">
                <el-select
                    size="mini"
                    v-model="noteUserIds"
                    multiple
                    filterable
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in noteUsers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">最后标注时间</div>
            <div class="adv-s-cell-b">
                <el-date-picker
                    size="mini"
                    v-model="lastNoteTime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
            </div>
        </div>
        <div class="adv-search-cell">
            <div class="adv-s-cell-t">是否标注过</div>
            <div class="adv-s-cell-b">
                <el-radio-group size="mini" v-model="noteStetus">
                    <el-radio :label="2">全部</el-radio>
                    <el-radio :label="1">标注过</el-radio>
                    <el-radio :label="0">未标注过</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="adv-s-footer">
            <el-button size="small" @click="closeAdvSerch">取消</el-button>
            <el-button size="small" @click="submitAdvSerch" type="primary"
                >筛选</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    props: ["botId"],
    data() {
        return {
            noteStetus: 2,
            lastNoteTime: "",
            noteUsers: [],
            noteUserIds: [],
            defaultExpandAll: true,
            orgTree: [],
            orgnation: [],
            flatDepartments: [],
            cascaderKey: 1,
            lastMsgTime: "",
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨日",
                        onClick(picker) {
                            var day1 = new Date();
                            day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
                            var s1 =
                                day1.getFullYear() +
                                "-" +
                                (day1.getMonth() + 1) +
                                "-" +
                                day1.getDate();

                            const end = new Date(s1) + 24 * 60 * 60 * 1000;
                            const start = new Date(s1);
                            // start.setTime(
                            //     start.getTime() - 3600 * 1000 * 24 * 90
                            // );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "今日",
                        onClick(picker) {
                            var day1 = new Date();
                            day1.setTime(day1.getTime());
                            var s1 =
                                day1.getFullYear() +
                                "-" +
                                (day1.getMonth() + 1) +
                                "-" +
                                day1.getDate();
                            const end = new Date();
                            const start = new Date(s1);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "近7日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "近30日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            intentIds: [],
            intentTree: [],
            props: {
                multiple: true,
                value: "id",
                label: "name",
                emitPath: false,
            },
            queryType: "100",
            queryTypeOptions: [
                {
                    value: "100",
                    text: "全部问题",
                },
                {
                    value: 0,
                    text: "不识别问题",
                },
                {
                    value: 1,
                    text: "已识别问题",
                },
                {
                    value: 2,
                    text: "不识别推荐",
                },
                 {
                    value: 3,
                    text: "关键词推荐",
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getIntentList();
        this.getDepartmentTree();
        this.getAnnotator();
    },
    methods: {
        closeAdvSerch() {
            this.$emit("closeAdvSerch");
        },
        clearAdvConditionAll() {
            this.queryType = "100";
            this.intentIds = [...[]];
            this.lastMsgTime = "";
            this.orgnation = [...[]];
            this.noteUserIds = [...[]];
            this.lastNoteTime = "";
            this.noteStetus = 2;
        },
        clearAdvConditionCell(type) {
            switch (type) {
                case "queryTypeObj":
                    this.queryType = "100";
                    break;
                case "intents":
                    this.intentIds = [...[]];
                    break;
                case "lastMsgTime":
                    this.lastMsgTime = "";
                    break;
                case "orgnation":
                    this.orgnation = [...[]];
                    break;
                case "noteUser":
                    this.noteUserIds = [...[]];
                    break;
                case "lastNoteTime":
                    this.lastNoteTime = "";
                    break;
                case "noteStetus":
                    this.noteStetus = 2;
                    break;
                default:
                    break;
            }
        },
        submitAdvSerch() {
            let param = {};
            if (this.queryType != 100) {
                console.log(this.queryType);
                param.queryTypeObj = this.queryTypeOptions[this.queryType + 1];
            }
            if (this.intentIds.length != 0) {
                param.intents = this.formatIntentIdsInfo(this.intentIds);
            }
            if (this.lastMsgTime != "") {
                param.lastMsgTime = this.lastMsgTime;
                console.log(new Date(this.lastMsgTime[0]));
            }
            if (this.orgnation == null) {
                this.orgnation = [];
            }
            if (this.orgnation.length != 0) {
                param.orgnation = this.formatorgInfo(this.orgnation);
            }
            if (this.noteUserIds.length != 0) {
                param.noteUser = this.formatNoteUserInfo(this.noteUserIds);
            }
            if (this.lastNoteTime != "") {
                param.lastNoteTime = this.lastNoteTime;
            }
            if (this.noteStetus != 2) {
                param.noteStetus = {
                    name: ["未标注过", "标注过", ""][this.noteStetus],
                    status: this.noteStetus,
                };
            }
            console.log(param);
            this.$emit("submitAdvSerch", JSON.stringify(param));
        },
        formatNoteUserInfo(ids) {
            let tampArr = [];
            this.noteUsers.forEach((item) => {
                if (ids.indexOf(item.id) != -1) {
                    tampArr.push(item);
                }
            });
            console.log(tampArr);
            return tampArr;
        },
        formatorgInfo(ids) {
            let tampArr = [];
            this.flatDepartments.forEach((item) => {
                if ([ids].indexOf(item.id) != -1) {
                    tampArr.push(item);
                }
            });
            return tampArr;
        },
        formatIntentIdsInfo(intentIds) {
            let intentsInfo = [];
            this.intentTree.forEach((skill) => {
                if (skill.children != null) {
                    skill.children.forEach((_class) => {
                        if (_class.children != null) {
                            _class.children.forEach((item) => {
                                if (intentIds.indexOf(item.id) != -1) {
                                    intentsInfo.push({
                                        id: item.id,
                                        name: item.name,
                                    });
                                }
                            });
                        }
                    });
                }
            });
            return intentsInfo;
        },
        orgCascaderChange() {},
        // 获取部门树
        getDepartmentTree() {
            let flatDepartments = [];
            this.FetchGet(this.requestUrl.bindV2.departmentTree).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    if (arr[0].total > 300) {
                        this.defaultExpandAll = false;
                    } else {
                        this.defaultExpandAll = true;
                    }
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            flatDepartments.push(item);
                            item.name = item.label;
                            item.value = item.id;
                            item.checked = false;
                            if (
                                item.children &&
                                item.children != null &&
                                item.children.length != 0
                            ) {
                                item.children = formatDepartmentTree(
                                    item.children.filter(
                                        (cell) => cell.type != 0
                                    )
                                );
                            }
                            if (JSON.stringify(item.children) == "[]") {
                                item.children = null;
                            }
                        });
                        return arr;
                    };
                    this.orgTree = formatDepartmentTree(
                        arr.filter((item) => item.type != 0)
                    );
                    this.flatDepartments = flatDepartments;
                    this.cascaderKey++;
                } else {
                    this.$message.error(res.message);
                    this.cascaderKey++;
                }
            });
        },
        getAnnotator() {
            this.FetchGet(this.requestUrl.trainList.getAnnotator, {
                botId: this.botId
            }).then(
                (res) => {
                    if (res.code === "0") {
                        let data = res.data;
                        this.noteUsers = data.map(item => {
                            return {
                                id: item.annotatorId,
                                name: item.annotatorName
                            }
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        getIntentList() {
            this.FetchGet(
                this.requestUrl.intent.getIntentTree +
                    "?bId=" +
                    this.botId +
                    "&filterType=1"
            ).then((res) => {
                if (res.code === "0") {
                    let data = res.data;
                    this.intentTree = data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
};
</script>

<style lang="less">
.adv-search-cpnt {
    padding: 20px 36px 20px 20px;
    .adv-search-cell {
        line-height: 42px;
        display: flex;
        justify-content: flex-start;
        .adv-s-cell-t {
            width: 120px;
            flex: none;
            text-align: right;
            padding-right: 20px;
        }
        .adv-s-cell-b {
            text-align: left;
            flex: auto;
            .el-date-picker {
                width: 100% !important;
            }
            .el-select {
                width: 100%;
            }
            .el-cascader {
                width: 100%;
            }
        }
    }
    .adv-s-footer {
        margin-top: 20px;
        text-align: right;
    }
}
</style>