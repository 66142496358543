<template>
  <div id="form-submit">
    <!------提交表单-------->
    <div
      class="respose-text-content info_card-content"
    >
      <div class="simple-cell-satisfaction">
        <div class="title">
          <span>选择表单 </span>
          <el-button v-if="false" plain type="primary" size="mini"
            >查看表单</el-button
          >
        </div>
        <div class="action_form_cascader">
          <el-cascader
            size="small"
            v-model="unit.content.form"
            :options="formOptions"
            :props="cascaderProps"
            @change="formChange"
            @focus="formFocus"
          >
            <template slot-scope="{ node, data }">
              <span style="color: #C0C4CC" v-if="botVersion == 2 && node.value == 'CUSTOM'">{{ data.name }}</span>
              <span v-else>{{ data.name }}</span>
            </template>
          </el-cascader>
        </div>
      </div>
      <div v-if="unit.content.relation.length > 0" class="key-relation">
        <div class="key-relation-title">
          <span class="key-relation-title-name">表单字段</span>
          <span class="key-relation-title-entity"
            >关联实体
            <em>(可根据实体自动填写字段值)</em>
          </span>
          <span class="key-relation-title-visible">是否可见</span>
        </div>
        <div class="key-relation-content">
          <div
            v-for="(item, itemIndex) in filterForm(unit.content.relation)"
            :key="itemIndex"
            class="key-relation-cell"
            v-if="item.fieldId!=='workorder_name'&&item.formField.type!=='CHANNEL'&&item.formField.type!=='CHAT_RECORD'"

          >
            <span class="key-relation-title-name" v-if="item.fieldId!=='workorder_clientId'&&item.fieldId!=='workorder_description'">{{
              item.formField.name
            }}</span>
            <span class="key-relation-title-name" v-if="item.fieldId==='workorder_clientId'">
              报单人</span>
            <span class="key-relation-title-name" v-if="item.fieldId==='workorder_description'">
              问题描述
            </span>
            <div class="key-relation-title-entity">
              <el-cascader
                placeholder="请选择实体"
                size="small"
                :key="'f__' + new Date()"
                v-if="isStartOrEnd"
                clearable
                v-model="item.entity"
                :options="entityOptionsBot"
              ></el-cascader>
              <el-cascader
                placeholder="请选择实体"
                v-if="!isStartOrEnd&&item.paramName!=='clientId'"
                :key="'f__' + new Date()"
                clearable
                size="small"
                v-model="item.entity"
                :options="entityOptions"
              ></el-cascader>
              <div v-if="!isStartOrEnd&&item.paramName==='clientId'" class="client-tip">如果是企业内部员工，会自动填写当前报单的员工</div>
            </div>
            <span class="key-relation-title-visible">
              <!-- <el-switch
                v-model="item.display"
                active-color="#13ce66"
                inactive-color="#CCCCCC"
              >
              </el-switch> -->
              <el-checkbox style="padding: 0" v-model="item.display" :disabled="(item.required&&(!item.value || item.value.length == 0)&&(unit.content.form[0]!=='CUSTOM'))||item.paramName==='clientId'?true:false"></el-checkbox>
            </span>
          </div>
        </div>
      </div>
      <div class="simple-cell-satisfaction">
        <span class="title">如果用户提交了表单</span>
        <div class="action_form_cascader">
          <el-select
            v-model="unit.content.submitSuccess.scope"
            size="small"
            placeholder="请选择"
            @change="submitSuccessChangeOptions"
          >
            <el-option
              v-for="item in jumpTypeAddContentOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              
            ></el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="unit.content.submitSuccess.scope === 'INTENT'"
        class="simple-cell-satisfaction"
      >
        <span class="title">选择意图</span>
        <div class="action_form_cascader">
          <!-- <el-cascader
            size="small"
            v-model="unit.content.submitSuccess.value"
            placeholder="请搜索或选择意图"
            :options="intentSAkillTypeOptionsNoChildren"
            filterable
          ></el-cascader> -->
          <el-cascader
            v-model="unit.content.submitSuccess.value"
            size="small"
            :options="intentTree4Radio"
            :props="{
              expandTrigger: 'hover',
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
          ></el-cascader>
        </div>
      </div>
      <div
        v-if="unit.content.submitSuccess.scope === 'NODE'"
        class="simple-cell-satisfaction"
      >
        <span class="title">选择节点</span>
        <div class="action_form_cascader">
          <el-select
            v-model="unit.content.submitSuccess.value"
            :popper-append-to-body="false"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in nodeOptions"
              :key="item.id"
              :popper-class="'pop_my' + item.classPop + item.parentNum"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="unit.content.submitSuccess.scope === 'CONTENT'"
        class="simple-cell-satisfaction"
      >
        <span class="title">发送内容</span>
        <div class="action_form_cascader">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            v-model="unit.content.submitSuccess.value"
            maxlength="2000"
            placeholder="请输入文字答案"
          ></el-input>
        </div>
      </div>
      <div class="simple-cell-satisfaction">
        <span class="title">如果提交表单失败</span>
        <div class="action_form_cascader">
          <el-select
            v-model="unit.content.submitError.scope"
            size="small"
            placeholder="请选择"
            @change="submitErrorChangeOptions"
          >
            <el-option
              v-for="item in jumpTypeAddContentOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="unit.content.submitError.scope === 'INTENT'"
        class="simple-cell-satisfaction"
      >
        <span class="title">选择意图</span>
        <div class="action_form_cascader">
          <!-- <el-cascader
            size="small"
            v-model="unit.content.submitError.value"
            placeholder="请搜索或选择意图"
            :options="intentSAkillTypeOptionsNoChildren"
            filterable
          ></el-cascader> -->
          <el-cascader
            v-model="unit.content.submitError.value"
            size="small"
            :options="intentTree4Radio"
            :props="{
              expandTrigger: 'hover',
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
          ></el-cascader>
        </div>
      </div>
      <div
        v-if="unit.content.submitError.scope === 'NODE'"
        class="simple-cell-satisfaction"
      >
        <span class="title">选择节点</span>
        <div class="action_form_cascader">
          <el-select
            v-model="unit.content.submitError.value"
            :popper-append-to-body="false"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in nodeOptions"
              :key="item.id"
              :popper-class="'pop_my' + item.classPop + item.parentNum"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="unit.content.submitError.scope === 'CONTENT'"
        class="simple-cell-satisfaction"
      >
        <span class="title">发送内容</span>
        <div class="action_form_cascader">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            v-model="unit.content.submitError.value"
            maxlength="2000"
            placeholder="请输入文字答案"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 版本功能不可用提示 -->
    <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
        <div slot="popup-name" class="popup-name">无法访问</div>
        <div slot="popup-tip">您当前的版本无法使用该功能</div>
        <div slot="popup-con">
            <VersionTip :tipIndex="tipIndex"/>
        </div>
        <div slot="dialog-footer">
            <el-button size="small" plain @click="versionTipDialog = false"
                >我知道了</el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="versionTipDialog = false"
                >拨打 4001-789-800 升级</el-button
            >
        </div>
    </popup>
  </div>
</template>
<script>
import Popup from "@/components/popup";
import VersionTip from "./../../../version/VersionTip.vue";
export default {
  name: "Form_submit",
  props: [
    "unit",
    "unitIndex",
    "intentSAkillTypeOptionsNoChildren",
    "nodeOptions",
    "isStartOrEnd",
    "entityOptionsBot",
    "entityOptions",
    "intentTree4Radio",
    "activeBot",
    "botVersion"
  ],
  data() {
    return {
      versionTipDialog: false,
      tipIndex: 5,
      botVersion: '',
      preFormValue: '',

      formOptions: [],
      cascaderProps: {
        value: "processConfigId",
        label: "name",
      },
      jumpTypeAddContentOptions: [
        {
          value: "NODE",
          label: "跳转到节点",
        },
        {
          value: "INTENT",
          label: "跳转到意图",
        },
        {
          value: "CONTENT",
          label: "向用户发送内容",
        },
      ],
    };
  },
  components: {
    VersionTip,
    Popup,
  },
  methods: {
    filterForm(arr){
      let tamp = [];
      arr.forEach(item => {
        if (item.paramName==='clientId'){
          this.$set(item,'display',false)
        }
        if (item.formField.type != "EXPLANATION") {
          tamp.push(item);
        }

      })
      return tamp;
    },
    submitSuccessChangeOptions() {
      this.unit.content.submitSuccess.value = "";
    },
    submitErrorChangeOptions() {
      this.unit.content.submitError.value = "";
    },

    formFocus(){
      this.preFormValue = JSON.stringify(this.unit.content.form);
    },

    /**
     * 选择具体表单
     * @param {}
     * @return {}
     */
    formChange(value) {
      console.log(value, value == 'CUSTOM');
      if (this.botVersion == 2 && value == 'CUSTOM') {
          // this.unit.content.form = [];
          this.unit.content.form = JSON.parse( this.preFormValue);
          this.tipIndex = 5;
          this.versionTipDialog = true;
          return false;
      }
      console.log(this.unit.content.form[0])
      this.formOptions.forEach((item) => {
        if (item.processConfigId == value[0]) {
          item.children.forEach((cell) => {
            if (cell.processConfigId == value[1]) {
              this.unit.content.originForm = cell;
              this.unit.content.relation = cell.formFieldRelation;
              this.unit.content.relation.defaultValue = cell.formFieldRelation.value;
              console.log(cell.formFieldRelation);
            }
          });
        }
      });
    },
    /**
     * 获取表单树并赋值
     * @param {}
     * @return {}
     */
    getFormTree() {
      this.FetchGet(this.requestUrl.form.formTree4bot + this.activeBot, {})
        .then((res) => {
          if (res.code === "0") {
            console.log(res.data);
            this.formOptions = [
              {
                value: "WORK_ORDER",
                label:"系统工单",
                name:"系统工单",
                processConfigId: "WORK_ORDER",
                children: res.data.系统工单,
              },
              {
                value: "CUSTOM",
                label:"自定义表单",
                name:"自定义表单",
                processConfigId: "CUSTOM",
                children: this.botVersion == 2?null:res.data.自定义表单,
              },
            ];
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message(err);
        });
    },
  },
  mounted() {
    this.getFormTree();
    console.log('unit',this.unit)
  },
};
</script>
<style lang="less" scoped>
#form-submit {
  .key-relation {
    background-color: white;
    margin-top: 8px;
    border-left: solid 1px #e4e7ed;
    border-right: solid 1px #e4e7ed;
    .key-relation-title {
      border-top: solid 1px #e4e7ed;
      display: flex;
      justify-content: space-around;
      line-height: 32px;
      height: 32px;
      .key-relation-title-name {
        flex: none;
        width: 160px;
        border-right: solid 1px #e4e7ed;
      }
      .key-relation-title-entity {
        flex: auto;
        border-right: solid 1px #e4e7ed;
        em {
          font-size: 10px;
          color: tomato;
        }
      }
      .key-relation-title-visible {
        flex: none;
        width: 100px;
      }
    }
    .key-relation-content {
      .key-relation-cell {
        border-top: solid 1px #e4e7ed;
        display: flex;
        justify-content: space-around;
        /*line-height: 38px;
        height: 38px;*/
        padding: 4px 0;
        display: flex;
        align-items: center;
        .key-relation-title-name {
          flex: none;
          width: 150px;
          border-right: solid 1px #e4e7ed;
          padding: 0 4px;
        }
        .key-relation-title-entity {
          flex: auto;
          border-right: solid 1px #e4e7ed;
          text-align: center;
          .el-cascader {
            width: 90%;
          }
          .el-input {
            border: none !important;
          }
          .el-input__inner {
            border: none !important;
          }
          .client-tip{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            color: #CCCCCC;
          }
        }
        .key-relation-title-visible {
          flex: none;
          width: 100px;
        }
      }
    }
  }
  .simple-cell-satisfaction {
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 8px;
      text-align: left;
      margin-bottom: 8px;
    }
    .action_form_cascader {
      .el-cascader {
        width: 100% !important;
      }
      .el-select {
        width: 100% !important;
      }
    }
    .title-other {
      margin-bottom: 8px;
      color: grey;
      text-align: left;
    }
    .el-textarea {
      width: 100%;
      margin-bottom: 12px;
    }
    .option-sa {
      display: flex;
      justify-content: flex-start;
      .el-tag {
        margin-right: 8px;
      }
      margin-bottom: 12px;
    }
    .satisfaction-radio-box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .el-tag {
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>