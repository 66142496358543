var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"note-list"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"botName","label":"机器人","width":"180"}}),_c('el-table-column',{attrs:{"prop":"botVersion","label":"版本","width":"180"}}),_c('el-table-column',{attrs:{"prop":"time","label":"训练时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"tagCount","label":"标注问题条数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status === 'TRAIN_SUCCESS'
                            ? 'success'
                            : scope.row.status === 'TRAIN_ERROR'
                            ? 'danger'
                            : '',"size":"mini"}},[_vm._v(" "+_vm._s(scope.row.status === "TRAIN_SUCCESS" ? "训练完成" : scope.row.status === "TRAIN_ERROR" ? "训练失败" : "训练中")+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[([''].indexOf(scope.row.status) > -1)?_c('el-button',{attrs:{"type":"text","disabled":""}},[_vm._v("详情")]):_vm._e(),(
                            ['TRAIN_SUCCESS'].indexOf(scope.row.status) > -1
                        )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.viewDetails(scope.row)}}},[_vm._v("详情")]):_vm._e(),(
                            ['TRAIN_ERROR'].indexOf(scope.row.status) > -1
                        )?_c('el-button',{attrs:{"type":"text"}},[_vm._v("重新训练")]):_vm._e()],1)]}}])})],1),_c('el-pagination',{attrs:{"page-size":_vm.pageSize,"current-page":_vm.currentPage,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.pageChange}}),_c('note-details',{attrs:{"visible-details":_vm.showDetails,"r-id":_vm.rId},on:{"close":_vm.close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }