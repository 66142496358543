<template>
    <div class="action_enforce">
        <div v-show="type == 'action_enforce'" class="top-tip">
            执行该组件时，需要等待用户再发一句话给机器人后再执行跳转动作，并且强制进行跳转，多个跳转之间随机跳转一个。
        </div>
        <div v-show="type == 'action_random_jump'" class="top-tip">
            执行该组件时，多个跳转之间随机跳转一个。
        </div>
        <div class="goto-list">
            <div
                v-for="(cell, index) in unit.content.gotoList"
                :key="index"
                class="jump-cell"
            >
                <div class="jump-cell-l">
                    <div class="left-select">
                        <el-select
                            style="width: 120px"
                            v-model="cell.scope"
                            size="small"
                            @change="jumpTypeValueChange($event, index)"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in filterJumpTypeOptions(
                                    jumpTypeOptions
                                )"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div class="right-select">
                        <!-- <el-select
                            v-show="cell.scope === 'NODE'"
                            v-model="cell.value"
                            @change="nodeSelectChange"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入或选择节点名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            v-if="cell.scope === 'NODE'"
                            :nodeOptions="nodeOptions"
                            :nodeId="cell.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionEnforceAddnode_${com_index}_${index}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeId"
                            :index="index"
                        ></SelectAddnode>
                        <el-cascader
                            v-show="cell.scope === 'INTENT'"
                            v-model="cell.value"
                            placeholder="请选择意图"
                            size="small"
                            :options="intentTree4Radio"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                </div>

                <span
                    v-if="index == 0"
                    class="el-icon-plus-box"
                    @click="addGoto"
                >
                    <i class="el-icon-plus"></i>
                </span>
                <span
                    v-if="index !== 0"
                    class="el-icon-delete-box"
                    @click="deleteGoto(index)"
                    ><i class="el-icon-close"></i
                ></span>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddnode from "./../cell/SelectAddnode.vue";
export default {
    components: { SelectAddnode },
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "type",
        "isStartOrEnd",
        "intentId",
        "nodeId",
        "com_index",
    ],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            // unit: {
            //     conditionList: [],
            //     content: {
            //         gotoList: [
            //             {
            //                 scope: "NODE", // INTENT NODE
            //                 value: "",
            //             },
            //         ],
            //     },
            //     type: "action_enforce",
            // },
        };
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            console.log(nodeId, index);
            this.$set(this.unit.content.gotoList[index], "value", nodeId);
        },
        filterJumpTypeOptions(value) {
            if (this.isStartOrEnd) {
                return [
                    {
                        value: "INTENT",
                        label: "跳转到意图",
                    },
                ];
            } else {
                return value;
            }
        },
        jumpTypeValueChange(e, index) {
            console.log(e, index);
            this.unit.content.gotoList[index].value = "";
        },
        addGoto() {
            if (this.isStartOrEnd) {
                this.unit.content.gotoList.push({
                    scope: "INTENT", // INTENT NODE
                    value: "",
                });
            } else {
                this.unit.content.gotoList.push({
                    scope: "NODE", // INTENT NODE
                    value: "",
                });
            }
        },
        deleteGoto(index) {
            this.unit.content.gotoList.splice(index, 1);
        },
    },
};
</script>
<style lang="less" scoped>
.action_enforce {
    .top-tip {
        background-color: #fff8f4;
        border-radius: 5px;
        padding: 8px;
        text-align: left;
        color: #ff9555;
        font-size: 12px;
    }
    .goto-list {
        margin-top: 8px;
        .jump-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 4px;
            .jump-cell-l {
                flex: auto;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                /deep/.inline-input {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-cascader {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-input__inner {
                    border: none;
                    padding-right: 0;
                }
                /deep/.el-input__suffix {
                    display: none;
                }
            }
            .left-select {
                flex: none;
                width: 120px;
                .el-select {
                    width: 100%;
                }
            }
            .right-select {
                margin-left: 16px;
                flex: auto;
                &.el-select {
                    width: 100%;
                }
                &.el-cascader {
                    width: 100%;
                }
            }
            .el-icon-plus-box {
                color: white;
                width: 29px;
                height: 29px;
                line-height: 29px;
                background: #366aff;
                border-radius: 5px;
                margin-left: 14px;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
            .el-icon-delete-box {
                margin-left: 14px;
                width: 27px;
                height: 27px;
                line-height: 27px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                color: #366aff;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
        }
    }
}
</style>