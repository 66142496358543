<!--未达标已下线意图-->
<template>
    <div :class="['unqualifie-offline-intent',isShowUnqualifieOfflineIntent ? 'isShowTest' : '']" v-if="isShowUnqualifieOfflineIntent">
        <div class="triangle"></div>
        <div class="title">
            <i class="iconfont guoran-askbot1"></i>
            <div class="add-automtated-closeBtn-new" @click.stop="closeEvent">
                <i class="el-icon-close"></i>
            </div>
        </div>
        <div class="warning-tips"> <i class="iconfont guoran-tongyichicun-yiguoqi"></i> 
            下列意图{{CompanyIsOld ? '未达标' : ' 未达标已下线'}}，请尽快完善意图。 
            <el-tooltip
                class="item"
                popper-class="unqualified-tooltip"
                effect="dark"
                content="意图达标标准：问法≥20条且答案完整，添加识别规则与开启超级意图有助于提高机器人的识别能力"
                placement="bottom">
                <span class="how-qualified">如何达标?</span>
            </el-tooltip>
        
        </div>
        <div class="unqualifie-offline-intent-list">
            <div class="intent-item" v-for="item in intentList" :key="item.intentId" @mouseenter="mouseenterFn(item)" @mouseleave="mouseleaveFn(item)" @click.stop="goLocation(item)">
                <div class="intent-name" v-if="item.isHover">
                    <span class="one-column-ellipsis">{{item.intentName}}</span>
                    <i class="iconfont guoran-tongyichicun-youjiantou" @click.stop="goLocation(item)"></i>
                </div>
                <div class="intent-content one-column-ellipsis" v-else>{{item.intentName}}</div>
            </div>
             <div class="look-more">
                <!-- <div v-if="loadStatus === 'lookmore'">查看更多<i class="el-icon-arrow-right"></i></div> -->
                <div v-if="loadStatus === 'loading'"><i class="el-icon-loading"></i>加载中...</div>
                <div v-else-if="loadStatus === 'noMore'">-- 到底了 --</div>
            </div>
        </div>
        <div class="next-time">
            <el-checkbox v-model="checked" @change="changeCheck">下次不再自动弹出</el-checkbox>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            checked:false,
            pageNo:1,
            pageSize:20,
            intentList:[],
            loadStatus:"loading",
            total:0,
            CompanyIsOld:true,
            isScroll:false
        }
    },
    props:{
        isShowUnqualifieOfflineIntent:{
            type:Boolean,
            default:false
        },
        botId:{
            type:Number,
            default:null
        },
        isReaload:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        isShowUnqualifieOfflineIntent(n){
            if(n){
                this.isScroll = false;
                this.CompanyIsOld = sessionStorage.getItem('CompanyIsOld') == 'true' ? true : false;
                this.judgeScroll();
            } 
        },
        isReaload(n){
            if(n){
                this.loadStatus = "loading";
                this.pageNo = 1;
                this.intentList = [];
                this.selectNoUpToStandardIntent();
            }
        },
        botId(n){
            console.log(n,66666666666);
            this.intentList = [];
            this.selectNoUpToStandardIntent();
        }
    },
    mounted(){
        this.intentList = [];
        this.selectNoUpToStandardIntent();
    },
    methods:{
        //判断滚动条是否滚到底部
        judgeScroll(){
            this.$nextTick(() => {
                let dom = document.querySelector('.unqualifie-offline-intent-list')
                dom.addEventListener('scroll', () => {
                    const clientHeight = dom.clientHeight;
                    const scrollTop = dom.scrollTop;
                    const scrollHeight = dom.scrollHeight;
                    if (clientHeight + scrollTop === scrollHeight) {
                        console.log('竖向滚动条已经滚动到底部')
                        if(this.intentList.length === 0){
                            // this.loadStatus = 'noMore'; 
                        } else {
                            if(this.loadStatus === 'noMore'){

                            } else {
                                console.log(this.intentList.length ,  this.total , !this.isScroll);
                                if(this.intentList.length <  this.total && !this.isScroll){
                                    this.loadStatus = 'loading';
                                    this.pageNo++;
                                    this.selectNoUpToStandardIntent();
                                } else {
                                    this.loadStatus === 'noMore'
                                }
                            }
                        }
                    }
                })
            })
        },
        // 查询未达标意图
        selectNoUpToStandardIntent(){
            this.isScroll = true;
            this.FetchGet(this.requestUrl.botHealthCheck.selectNoUpToStandardIntent+'?botId='+this.botId+"&pageNo="+this.pageNo+'&pageSize='+this.pageSize).then(res => {
                if (res.code === "0") {
                    this.total = res.data.total;
                    if(this.isReaload){
                        this.$emit('update:isReaload',false)
                    }
                    if(res.data.total === 0 ){
                        this.$emit('noUpToStandardIntentTotal',res.data.total);
                        this.$emit('update:isShowUnqualifieOfflineIntent',false)
                        // this.loadStatus = 'noMore'
                        this.loadStatus = 'noData';
                    } else {
                        this.intentList = [...this.intentList,...res.data.list];
                        if(this.intentList.length < res.data.total){
                            this.loadStatus = 'loading';
                        } else if(this.intentList.length === res.data.total){
                            this.loadStatus = 'noMore'
                        } 

                        if(this.pageNo === 1){
                            if(this.intentList.length < res.data.total || this.intentList.length === res.data.total){
                                this.loadStatus = 'noData';
                            }
                            this.$emit('update:isShowUnqualifieOfflineIntent',res.data.list[0].popupView)
                            this.$emit('popupView',res.data.list[0].popupView);
                            this.$emit('noUpToStandardIntentTotal',res.data.total);
                        }
                        this.checked = !res.data.list[0].popupView;
                    }
                    res.data.list.forEach((item,index) => {
                        item.isHover = false;
                    })
                   this.isScroll = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 关闭用户进入某个机器人自动弹出未达标意图视窗
        changeCheck(){
            this.FetchGet(this.requestUrl.botHealthCheck.closeNoUpToStandardIntentView+'?botId='+this.botId+"&noUpToStandardIntentView="+!this.checked).then(res => {
                if (res.code === "0") {
                   this.$message.success(this.checked ? '下次不再自动弹出设置成功' : '取消设置下次不再自动弹出设置成功');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeEvent(){
            console.log(11);
            this.$emit('update:isShowUnqualifieOfflineIntent',false)
        },
        // 定位到意图
        goLocation(item){
            console.log(item);
            sessionStorage.setItem("openIntentWeb", 1);
            sessionStorage.setItem("openIntentId", item.intentId);
            this.$emit('update:isShowUnqualifieOfflineIntent',false)
            this.$emit('goLocation',item)
        },
        // 加载更多
        loadMore(){
            this.pageNo++;
            this.selectNoUpToStandardIntent();
        },
        mouseenterFn(item){
            this.intentList.forEach((v,i) => {
                v.isHover = false;
            })
            item.isHover = true;
            this.$forceUpdate();
        },
        mouseleaveFn(item){
            this.intentList.forEach((v,i) => {
                v.isHover = false;
            })
        }
    }
}
</script>

<style lang="less" scoped>
.unqualifie-offline-intent{
    position: absolute;
    width: 362px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57,63,79,0.21);
    border-radius: 5px;
    padding: 10px 10px 20px 10px;
    top: 55px;
    z-index: 10;
    font-size: 14px;
    font-family: Microsoft YaHei;
    cursor: default;
    .triangle{
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        position: relative;
        bottom: 17px;
        left: 170px;
    }
    .title{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .iconfont{
            font-size: 28px;
        }
        .add-automtated-closeBtn-new{
            width: 25px;
            height: 25px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
            border-radius: 50%;
            position: absolute;
            right: -20px;
            top: -27px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i{
                font-size: 17px;
                color: #616161;
            }
        }
    }
    .warning-tips{
        width: 100%;
        height: 31px;
        background: #FFEBEC;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FE5965;
        margin-bottom: 10px;
        .guoran-tongyichicun-yiguoqi{
            color: #FE5965;
            margin-right: 4px;
        }
        .how-qualified{
            // color: #366AFF;
            margin-left: 2px;
            text-decoration: underline;

        }
    }
    .unqualifie-offline-intent-list{
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        .intent-item{
            margin-bottom: 6px;
            cursor: pointer;
            .intent-name{
                height: 35px;
                background: #F9FBFF;
                border-radius: 1px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #366AFF;
                padding: 0 16px;
                line-height: 35px;
                .iconfont{
                    cursor: pointer;
                    margin-left: 20px;
                }
            }
            .intent-content{
                height: 35px;
                color: #000;
                text-align: left;
                line-height: 35px;
                padding: 0 16px;

            }
        }
    }
    .look-more{
        margin-top: 10px;
        width: 415px;
        height: 33px;
        background: #FFFFFF;
        margin-top: 4px;
        color: #A9B3C6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 10px; transform: scale(0.83333); transform-origin: 0 0;
       i{
           margin-right: 6px;
       }
    }
    .next-time{
        text-align: center;
        /deep/.el-checkbox{
            .el-checkbox__label{
                color: #A9B3C6;
                font-size: 12px;
            }
            .el-checkbox__inner{
                border-color: #B6BECE;
            }
        }
    }
    &.isShowTest{
        right: -112px;
        .triangle{
            left: 220px;
        }
    }
}
</style>