var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-fast-select"},[_vm._m(0),_c('div',{staticClass:"fast-select-content"},[_vm._m(1),_c('div',{staticClass:"fast-select-content-sort"},_vm._l((_vm.unit.content.list),function(item,formIndex){return _c('div',{key:formIndex,staticClass:"fast-select-content-cell"},[_vm._m(2,true),_c('div',{staticClass:"fast-select-content-cell-form"},[_c('div',{staticClass:"select-cell-pre"},[_c('el-input',{attrs:{"size":"small","maxlength":"20","placeholder":"请输入名称"},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})],1),_c('div',{staticClass:"select-cell"},[_c('div',{staticClass:"select-cell-sub-content"},[_c('div',{staticClass:"select-cell-sub-content-pre"},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},on:{"change":function($event){return _vm.changeScope(formIndex)}},model:{value:(item.action.scope),callback:function ($$v) {_vm.$set(item.action, "scope", $$v)},expression:"item.action.scope"}},_vm._l((_vm.jumpTypeAddSendOptionsFast),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"select-cell-sub-content-aft"},[(
                                        item.action.scope ===
                                            'SEND_MSG_TO_BOT' ||
                                        item.action.scope ===
                                            'SEND_MSG_TO_USER' ||
                                        item.action.scope === 'LINK'
                                    )?_c('el-input',{attrs:{"size":"small","placeholder":item.action.scope === 'LINK'
                                            ? '请输入链接'
                                            : '请输入发送内容'},model:{value:(item.action.value),callback:function ($$v) {_vm.$set(item.action, "value", $$v)},expression:"item.action.value"}}):_vm._e(),(item.action.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":item.action.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionFast_select_${formIndex}_${_vm.unitIndex}`,"intentId":_vm.intentId,"index":formIndex},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(item.action.scope === 'INTENT'),expression:"item.action.scope === 'INTENT'"}],key:`actionFast_cascader_${formIndex}_${_vm.unitIndex}`,attrs:{"size":"small","options":_vm.intentTree4Radio,"placeholder":"请选择跳转到意图名称","props":{
                                        expandTrigger: 'hover',
                                        emitPath: false,
                                        label: 'name',
                                        value: 'id',
                                    }},model:{value:(item.action.value),callback:function ($$v) {_vm.$set(item.action, "value", $$v)},expression:"item.action.value"}})],1)])])]),_c('div',{staticClass:"d-a-handle-box"},[(formIndex == 0)?_c('span',{staticClass:"answer-text-cell-handle-icon-add",on:{"click":_vm.addToEntityCell}},[_c('i',{staticClass:"el-icon-plus"})]):_c('span',{staticClass:"answer-text-cell-handle-icon-delete",on:{"click":function($event){return _vm.deleteToEntityCell(formIndex)}}},[_c('i',{staticClass:"el-icon-close"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fast-select-des"},[_c('span',[_vm._v(" 用户在聊天页面低部出现快捷按钮，可配置相关问题或执行相关动作。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-cell-title"},[_c('span',{staticClass:"select-cell-title-name"},[_vm._v("名称")]),_c('span',{staticClass:"select-cell-title-action"},[_vm._v("执行动作")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"move-box"},[_c('span',{staticClass:"iconfont guoran-tongyichicun-16-10-paixu icon-handle-fast"})])
}]

export { render, staticRenderFns }