var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"el-zoom-in-top"}},[_c('div',{staticClass:"intent-node-list"},_vm._l((JSON.parse(_vm.intentItem).children),function(nodeItem,nodeIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(_vm.intentItem).show),expression:"JSON.parse(intentItem).show"}],key:nodeIndex,class:['intent-node-first', nodeItem.children.length == 1 ? '' : 'intent-node-first-border-l']},[(nodeItem.children.length == 1)?_c('div',{staticClass:"intent-node-first-pre-line"}):_vm._e(),_c('div',{staticClass:"intent-node-first-top-content"},[_c('div',{staticClass:"node-pre-first"}),_c('div',{staticClass:"node-node-name-first",on:{"click":function($event){_vm.configAction(
                            JSON.parse(_vm.intentItem).id,
                            nodeItem.id,
                            _vm.skillItem.id,
                            nodeItem.name,
                            [
                                _vm.skillIndex,
                                _vm.classIndex,
                                _vm.intentIndex,
                                nodeIndex,
                            ],
                            0
                        )}}},[_c('span',{staticClass:"node-name-c"},[_vm._v("节点")]),_c('div',{staticClass:"name-text csp"},[_vm._v(" "+_vm._s(nodeItem.name)+" ")])]),_c('div',{staticClass:"first-node-handle"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                            !(
                                (_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                    !(
                                        !_vm.skillItem.display &&
                                        _vm.skillItem.name == '其他'
                                    )) ||
                                (!_vm.skillItem.display &&
                                    _vm.skillItem.name == '其他' &&
                                    _vm.RP_Visible('OPEN_PROCESS_OTHER'))
                            )
                        ),expression:"\n                            !(\n                                (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                                    !(\n                                        !skillItem.display &&\n                                        skillItem.name == '其他'\n                                    )) ||\n                                (!skillItem.display &&\n                                    skillItem.name == '其他' &&\n                                    RP_Visible('OPEN_PROCESS_OTHER'))\n                            )\n                        "}],staticClass:"first-node-handle-cell add-text-forb"},[_c('i',{staticClass:"el-icon-plus"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                            !nodeItem.isAddEditing &&
                            ((_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !(
                                    !_vm.skillItem.display &&
                                    _vm.skillItem.name == '其他'
                                )) ||
                                (!_vm.skillItem.display &&
                                    _vm.skillItem.name == '其他' &&
                                    _vm.RP_Visible('OPEN_PROCESS_OTHER')))
                        ),expression:"\n                            !nodeItem.isAddEditing &&\n                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                                !(\n                                    !skillItem.display &&\n                                    skillItem.name == '其他'\n                                )) ||\n                                (!skillItem.display &&\n                                    skillItem.name == '其他' &&\n                                    RP_Visible('OPEN_PROCESS_OTHER')))\n                        "}],staticClass:"first-node-handle-cell add-text",on:{"click":function($event){_vm.addNodeHandleFirst(
        JSON.parse(_vm.intentItem).id,
        JSON.parse(_vm.intentItem).id,
        [_vm.skillIndex, _vm.classIndex, _vm.intentIndex],
        'first'
    )}}},[_c('el-tooltip',{attrs:{"content":"添加子节点","effect":"light","placement":"top"}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(nodeItem.isAddEditing),expression:"nodeItem.isAddEditing"}],staticClass:"add-input-first"},[_c('el-input',{ref:'add_input_' + JSON.parse(_vm.intentItem).id,refInFor:true,attrs:{"maxlength":"20","size":"small","placeholder":"请输入子节点名称"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.confirmAddNodeHandleFirst(
                                        JSON.parse(_vm.intentItem).id,
                                        nodeItem.id,
                                        [_vm.skillIndex, _vm.classIndex, _vm.intentIndex],
                                        _vm.nodeNameValue,
                                        'first'
                                    )}},model:{value:(_vm.nodeNameValue),callback:function ($$v) {_vm.nodeNameValue=$$v},expression:"nodeNameValue"}}),_c('span',{staticClass:"el-icon-success add-icon",on:{"click":function($event){_vm.confirmAddNodeHandleFirst(
                                    JSON.parse(_vm.intentItem).id,
                                    nodeItem.id,
                                    [_vm.skillIndex, _vm.classIndex, _vm.intentIndex],
                                    _vm.nodeNameValue,
                                    'first'
                                )}}}),_c('span',{staticClass:"el-icon-error delete-icon",on:{"click":function($event){_vm.concelAddNodeHandleFirst(
                                    JSON.parse(_vm.intentItem).id,
                                    JSON.parse(_vm.intentItem).id,
                                    [_vm.skillIndex, _vm.classIndex, _vm.intentIndex],
                                    'first'
                                )}}})],1)])]),_c('recursive',{attrs:{"list":JSON.stringify(nodeItem.children),"indexArr":JSON.stringify([
                        _vm.skillIndex,
                        _vm.classIndex,
                        _vm.intentIndex,
                        nodeIndex,
                    ]),"nodeTree":_vm.nodeTree,"isPart":_vm.isPart,"activeBot":_vm.activeBot,"intentId":JSON.parse(_vm.intentItem).id,"skillId":_vm.skillItem.id,"isOtherSkill":!_vm.skillItem.display && _vm.skillItem.name == '其他'}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }