var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-video"},[_c('div',{staticClass:"respose-video"},[_c('div',{staticClass:"album albumvideo"},[_c('div',[_c('div',{staticClass:"pic_img"},[_c('div',{staticClass:"pic_img_box"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.importFileUrl,"accept":".mp4,.MP4,.ogg,.OGG,.avi,.AVI,.mov,.MOV,.flv,.FLV,.wmv,.WMV","data":{},"on-progress":(event, file, fileList) =>
                                    _vm.uploadVideoProcess(
                                        event,
                                        file,
                                        fileList,
                                        _vm.index
                                    ),"on-success":(res, file) =>
                                    _vm.handleVideoSuccess(res, file, _vm.index),"before-upload":(file) => _vm.beforeUploadVideo(file, _vm.index),"show-file-list":false}},[(
                                    _vm.unit.content.url != '' &&
                                    !_vm.unit.content.videoFlag
                                )?_c('video',{staticClass:"avatar video-avatar",attrs:{"width":"248px","height":"148px","controlslist":"nodownload","object-fit":"fill","src":_vm.unit.content.url,"muted":"true","controls":"controls"},domProps:{"muted":true}},[_vm._v(" 您的浏览器不支持视频播放 ")]):(
                                    _vm.unit.content.url == '' &&
                                    !_vm.unit.content.videoFlag
                                )?_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}):_vm._e(),(_vm.unit.content.videoFlag == true)?_c('el-progress',{staticStyle:{"margin-top":"7px"},attrs:{"type":"circle","percentage":_vm.unit.content.videoUploadPercent}}):_vm._e()],1)],1)])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"Upload_pictures"},[_c('span'),_c('span',[_vm._v("最多可以上传1个视频，小于10M，格式mp4、avi、mov等（可点击已上传视频重新上传！）")])])
}]

export { render, staticRenderFns }