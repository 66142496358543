<template>
    <div class="dialogContentComPonentD" id="messageContent" ref="msgContainer">
        <div v-if="userMessages.length == 0" class="empty-tips-d">
            点击左侧列表“查看消息记录”按钮，查看相应记录
        </div>
        <div v-for="(item, index) in userMessages" :key="index">
            <!-- <el-link
                :underline="false"
                v-if="index == 0 && moreMessages"
                type="primary"
                >更多消息</el-link
            >
            <el-link
                :underline="false"
                v-if="index == 0 && !moreMessages"
                type="primary"
                disabled
                >没有更多消息了</el-link
            > -->
            <div
                class="message message-kf"
                v-if="
                    item.source == 'WEB_SOCKET' ||
                    item.source == 'PWX_SOCKET' ||
                    item.source == 'EKB_MALL' ||
                    item.source == 'DING_SOCKET' || 
                    item.source == 'WE_CHART_KF_CLIENT' ||
                    item.source == 'DINGTALK_GROUP' ||
                    item.source == 'DINGTALK_BOT' || 
                    item.source == 'APPLET' || 
                    item.source == 'FEISHU_SOCKET' || 
                    item.source == 'FEISHU_CHAT' ||
                    item.source == 'YUNZHIJIA_SOCKET'
                "
            >
                <div class="user-detail">
                    <div :id="'chat' + item.id" class="media-body">
                        <p
                            v-if="
                                item.type == 'user_action_to_satisfaction' ||
                                item.type == 'user_action_to_unsatisfactory'
                            "
                        >
                            {{ JSON.parse(item.content).input }}
                        </p>
                        <div v-else-if="item.type == 'FORM_SUBMIT'">
                            <FormTemplate
                                :submit="true"
                                :disable="true"
                                :formList="JSON.parse(item.content)"
                            >
                            </FormTemplate>
                        </div>
                        <p v-else-if="item.type === 'FEEDBACK'">
                            {{ JSON.parse(item.content).feedback.text }}
                        </p>
                        <div v-else-if="item.type == 'VALUE_CONFIRM'">
                            <TextMessage
                                :submit="true"
                                :disable="true"
                                :text="JSON.parse(item.content)"
                            >
                            </TextMessage>
                        </div>
                        <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                            {{ JSON.parse(item.content).name }}
                        </p>
                        <div v-else-if="item.type == 'VIDEO'">
                            <video-player
                                class="video-player vjs-custom-skin"
                                :options="
                                    videoOptions(JSON.parse(item.content))
                                "
                                style="width: 350px"
                            />
                        </div>
                        <div v-else-if="item.type == 'IMAGE'">
                            <el-image
                                style="width: 35%"
                                :src="JSON.parse(item.content).urls[0]"
                            ></el-image>
                        </div>
                        <div v-else-if="item.type == 'GOTO'">
                            [用户触发机器人交互卡片]
                        </div>
                        <div v-else-if="item.type == 'FAST_SELECT'">
                            <p v-html="JSON.parse(item.content).text"></p>
                        </div>
                        <div v-else-if="item.type == 'INTELLIGENT'">
                            <p v-html="JSON.parse(item.content).name"></p>
                        </div>
                        <div v-else-if="item.type == 'RADIO_V2'">
                            <p v-html="JSON.parse(item.content).optionName"></p>
                        </div>
                        <p
                            v-else
                            v-html="
                                item.type == 'RADIO'
                                    ? item.content.split('_')[
                                          item.content.split('_').length - 1
                                      ]
                                    : item.content
                            "
                        ></p>
                        <span>{{
                            new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                        }}</span>
                    </div>
                    <div class="user-image">
                        <img
                            src="./../../../assets/images/Female_user_help.png"
                        />
                        <span>用户</span>
                    </div>
                </div>
            </div>
            <div
                class="message message-kf"
                v-if="item.source == 'WEBSITE_SOCKET'"
            >
                <div class="user-detail">
                    <div class="media-body">
                        <p
                            v-if="
                                item.type == 'user_action_to_satisfaction' ||
                                item.type == 'user_action_to_unsatisfactory'
                            "
                        >
                            {{ JSON.parse(item.content).input }}
                        </p>
                        <div v-else-if="item.type == 'FORM_SUBMIT'">
                            <FormTemplate
                                :submit="true"
                                :disable="true"
                                :formList="JSON.parse(item.content)"
                            >
                            </FormTemplate>
                        </div>
                        <p v-else-if="item.type === 'FEEDBACK'">
                            {{ JSON.parse(item.content).feedback.text }}
                        </p>
                        <div v-else-if="item.type == 'VALUE_CONFIRM'">
                            <TextMessage
                                :submit="true"
                                :disable="true"
                                :text="JSON.parse(item.content)"
                            >
                            </TextMessage>
                        </div>

                        <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                            {{ JSON.parse(item.content).name }}
                        </p>
                        <div
                            v-else-if="
                                item.type === 'office_file' ||
                                item.type === 'other_file'
                            "
                            class="pre-message"
                        >
                            <file-type
                                :urls="JSON.parse(item.content).urls"
                            ></file-type>
                        </div>
                        <div v-else-if="item.type == 'VIDEO'">
                            <video-player
                                class="video-player vjs-custom-skin"
                                :options="
                                    videoOptions(
                                        JSON.parse(item.content).urls[0]
                                    )
                                "
                                style="width: 350px"
                            />
                        </div>
                        <div v-else-if="item.type == 'IMAGE'">
                            <el-image
                                style="width: 35%"
                                :src="JSON.parse(item.content).urls[0]"
                            ></el-image>
                        </div>
                        <p
                            v-else
                            v-html="
                                item.type == 'RADIO'
                                    ? item.content.split('_')[
                                          item.content.split('_').length - 1
                                      ]
                                    : item.content.replace(
                                          /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                          emotion
                                      )
                            "
                        ></p>
                        <span>{{
                            new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                        }}</span>
                    </div>
                    <div class="user-image">
                        <img
                            src="./../../../assets/images/Female_user_help.png"
                        />
                        <span>用户</span>
                    </div>
                </div>
            </div>
            <div
                class="message message-kf"
                v-if="item.source == 'MINI_PROGRAM_WEB_SOCKET'"
            >
                <div class="user-detail">
                    <div class="media-body">
                        <p
                            v-if="
                                item.type == 'user_action_to_satisfaction' ||
                                item.type == 'user_action_to_unsatisfactory'
                            "
                        >
                            {{ JSON.parse(item.content).input }}
                        </p>
                        <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                            {{ JSON.parse(item.content).name }}
                        </p>

                        <p
                            v-else
                            v-html="
                                item.type == 'RADIO'
                                    ? item.content.split('_')[
                                          item.content.split('_').length - 1
                                      ]
                                    : item.content
                            "
                        ></p>
                        <span>{{
                            new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                        }}</span>
                    </div>
                    <div class="user-image">
                        <!-- <i class="arsenal_icon arsenalyuangong"></i> -->
                        <img
                            src="./../../../assets/images/Female_user_help.png"
                        />
                        <span>用户</span>
                    </div>
                </div>
            </div>

            <!--  用户满意度满意事件-->
            <div
                class="message"
                v-if="item.type == 'user_action_to_satisfaction'"
            >
                <template>
                    <div
                        class="user-detail"
                        v-if="JSON.parse(item.content) != null"
                    >
                        <div class="user-image">
                            <img
                                src="./../../../assets/images/chatboticon.png"
                            />
                            <span>BOT</span>
                        </div>

                        <div
                            class="media-body-ps"
                            v-if="
                                JSON.parse(item.content).reply_options !=
                                    null &&
                                JSON.parse(item.content).reply_options.length >
                                    0
                            "
                        >
                            <p
                                class="other"
                                v-html="JSON.parse(item.content).reply_text"
                            ></p>
                            <p
                                class="ps"
                                v-if="
                                    JSON.parse(item.content).reply_options !=
                                        null &&
                                    JSON.parse(item.content).reply_options
                                        .length > 0
                                "
                                v-for="(option, index) in JSON.parse(
                                    item.content
                                ).reply_options"
                                :key="index"
                            >
                                {{ option.name }}
                            </p>
                            <span style="padding: 0 15px">{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                style="margin-right: 15px"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div v-else class="media-body">
                            <p v-html="JSON.parse(item.content).reply_text"></p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                    </div>
                </template>
            </div>

            <!--  用户满意度不满意按钮数据   -->
            <div
                class="message"
                v-if="item.type == 'user_action_to_unsatisfactory'"
            >
                <template>
                    <div
                        class="user-detail"
                        v-if="JSON.parse(item.content) != null"
                    >
                        <div class="user-image">
                            <img
                                src="./../../../assets/images/chatboticon.png"
                            />
                            <span>BOT</span>
                        </div>
                        <div class="media-body">
                            <p
                                class="other"
                                v-if="
                                    JSON.parse(item.content).reply_text != null
                                "
                            >
                                {{ JSON.parse(item.content).reply_text }}
                            </p>
                            <p
                                class="ps"
                                v-if="
                                    JSON.parse(item.content).reply_options !=
                                        null &&
                                    JSON.parse(item.content).reply_options
                                        .length > 0
                                "
                                v-for="(option, index) in JSON.parse(
                                    item.content
                                ).options"
                                :key="index"
                            >
                                {{ option.name }}
                            </p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <!-- <span v-if="(userInfor.botName != undefined)" class="botname">{{
                                userInfor.botName
                            }}</span> -->
                        </div>
                    </div>
                </template>
            </div>
            <!-- 人工客服消息 -->
            <template v-if="item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'">
                <div class="message" v-if="item.type == 'TEXT'">
                    <div class="user-detail">
                        <div class="user-image">
                            <img
                                alt="人工客服"
                                src="./../../../assets/images/customer_service.png"
                            />
                            <span>客服</span>
                        </div>
                        <div class="media-body">
                            <p
                                v-html="
                                    item.content.replace(
                                        /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                        emotion
                                    )
                                "
                            ></p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                                class="botname"
                                >{{ item.sourceName }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="message" v-if="item.type === 'image'">
                    <div class="user-detail">
                        <div class="user-image">
                            <img
                                alt="人工客服"
                                src="./../../../assets/images/customer_service.png"
                            />
                            <span>客服</span>
                        </div>
                        <div class="media-body">
                            <p>
                                <el-image
                                    style="width: 35%"
                                    :src="item.content"
                                ></el-image>
                            </p>

                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                                class="botname"
                                >{{ item.sourceName }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="message" v-if="item.type === 'video'">
                    <div class="user-detail">
                        <div class="user-image">
                            <img
                                alt="人工客服"
                                src="./../../../assets/images/customer_service.png"
                            />
                            <span>客服</span>
                        </div>
                        <div class="media-body">
                            <p>
                                <video-player
                                    class="video-player vjs-custom-skin"
                                    :options="
                                        videoOptions({ url: item.content })
                                    "
                                    style="width: 350px"
                                />
                            </p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                                class="botname"
                                >{{ item.sourceName }}</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="message"
                    v-if="
                        item.type === 'office_file' ||
                        item.type === 'other_file'
                    "
                >
                    <div class="user-detail">
                        <div class="user-image">
                            <img
                                alt="人工客服"
                                src="./../../../assets/images/customer_service.png"
                            />
                            <span>客服</span>
                        </div>
                        <div class="media-body">
                            <p>
                                <file-type
                                    :urls="JSON.parse(item.content).urls"
                                ></file-type>
                            </p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                                class="botname"
                                >{{ item.sourceName }}</span
                            >
                        </div>
                    </div>
                </div>
            </template>

            <!-- 机器人消息 -->
            <template
                v-if="
                    item.source == 'GUORAN_BOT' &&
                    item.type != 'user_action_to_satisfaction' &&
                    item.type != 'user_action_to_unsatisfactory'
                "
            >
                <div
                    class="message"
                    v-for="(msg, index) in JSON.parse(item.content)"
                    :key="index"
                >
                    <div class="user-detail" v-if="msg.content != null && JSON.stringify(msg.content)!='{}' && (msg.content.isGlobal == null || msg.content.isGlobal == false)">
                        <div class="user-image">
                            <img
                                src="./../../../assets/images/chatboticon.png"
                            />
                            <span>BOT</span>
                        </div>
                        <div
                            class="media-body"
                            v-if="
                                (msg.type == 'answer_text' ||
                                    msg.type == 'answer_welcometext' ||
                                    msg.type == 'answer_correct') &&
                                msg.content.list != null &&
                                msg.content.list[0] != '__welcome__'
                            "
                        >
                            <p
                                v-html="
                                    msg.content.list != null
                                        ? msg.content.list[0]
                                        : ''
                                "
                            ></p>
                            <Recocell :item="msg.content" />
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 325px"
                            v-else-if="msg.type == 'answer_rich_text'"
                        >
                            <p v-html="msg.content.html"></p>
                            <Recocell :item="msg.content" />
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_confirm_value'"
                        >
                            <TextMessage :disable="true" :text="msg.content">
                            </TextMessage>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_alert_iframe'"
                        >
                            <ActionAlert
                                :actionAlertIframe="msg.content"
                            ></ActionAlert>
                            <div
                                @click="gotoAlert(msg.content.template)"
                                class="alertUrl"
                            >
                                {{ msg.content.template }}
                            </div>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_form'"
                        >
                            <FormTemplate
                                :disable="true"
                                :formList="msg.content"
                            >
                            </FormTemplate>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 350px"
                            :id="'chat' + msg.content.feedbackUid"
                            v-else-if="msg.type == 'action_feedback'"
                        >
                            <FeedBack
                                :disable="true"
                                :feedBack="msg.content"
                            ></FeedBack>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>

                        <div
                            class="media-body"
                            style="max-width: 350px"
                            :id="'chat' + msg.content + index"
                            v-else-if="msg.type == 'answer_intellect_guide'"
                        >
                            <AiGuide
                                :aiGuide="msg"
                            ></AiGuide>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>

                        <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'answer_card_template'"
                        >
                            <TicketMessage
                                :disable="true"
                                :content="msg.content"
                            >
                            </TicketMessage>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>

                        <div
                            class="media-body"
                            v-else-if="
                                msg.type == 'action_question' &&
                                msg.content != null &&
                                msg.content.questions != null &&
                                msg.content.questions.length > 0
                            "
                        >
                            <p v-html="msg.content.questions[0]"></p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            v-else-if="msg.type == 'answer_rich_text_pro'"
                        >
                            <div v-html="msg.content.html"></div>
                            <Recocell :item="msg.content" />
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            class="media-body"
                            v-else-if="msg.type == 'answer_image'"
                        >
                            <p>
                                <el-image
                                    style="width: 35%"
                                    :src="msg.content.url"
                                ></el-image>
                            </p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            v-else-if="msg.type == 'answer_radio'"
                            class="media-body-ps"
                        >
                            <p class="other">{{ msg.content.description }}</p>
                            <p
                                class="ps"
                                v-for="(option, index) in msg.content.options"
                                :key="index"
                            >
                                {{ option.name }}
                            </p>
                            <span style="margin-left: 15px; margin-top: 5px">{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                                style="margin-top: 5px; margin-right: 15px"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div
                            v-else-if="msg.type == 'action_satisfaction'"
                            class="media-body-ps"
                        >
                            <p class="other">
                                {{ msg.content.satisfactionDes }}
                            </p>
                            <p
                                class="ps"
                                v-for="(option, index) in ['满意', '不满意']"
                                :key="index"
                            >
                                {{ option }}
                            </p>
                            <span style="margin-left: 15px; margin-top: 5px">{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                                style="margin-top: 5px; margin-right: 15px"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>

                        <div
                            v-else-if="msg.type == 'answer_video'"
                            class="media-body"
                        >
                            <p>
                                <video-player
                                    v-if="msg.type === 'answer_video'"
                                    class="video-player vjs-custom-skin"
                                    :options="videoOptions(msg.content)"
                                    style="width: 350px"
                                />
                            </p>
                            <span>{{
                                new Date(item.time).Format(
                                    "yyyy-MM-dd hh:mm:ss"
                                )
                            }}</span>
                            <span
                                v-if="item.source == 'GUORAN_BOT'"
                                class="botname"
                            ></span>
                            <span
                                v-else-if="
                                    item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                                "
                                class="botname"
                                >{{ item.sourceId }}</span
                            >
                        </div>
                        <div v-else-if="msg.type == 'action_transfer'">
                            <!--                                        机器人回复为转人工组件内容 -->
                        </div>
                        <div v-else-if="msg.type == 'action_transfer_2_ewechat'">
                            <!--                                        机器人回复为转企业微信客服组件内容 -->
                        </div>
                        <div
                            v-else-if="
                                msg.type === 'action_send_message_to_bot'
                            "
                        >
                            <span>{{ msg.content.description }}</span>
                            <span
                                v-for="(cell, index) in msg.content.options"
                                :key="'_' + index"
                                class="link-cell cursor"
                                >{{ cell.name }}</span
                            >
                        </div>
                        <div
                            v-else-if="msg.type == 'action_fast_select'"
                            class="media-body-ps"
                        >
                            <p class="other">【快捷选择】</p>
                            <p
                                class="ps"
                                v-for="(option, index) in msg.content.list"
                                :key="index"
                            >
                                {{ option.text }}
                            </p>
                        </div>
                        <div v-else>
                            <p>{{ msg }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";
import Emotion from "../../../components/Emotion/index";
import FileType from "askbot-dragon/src/components/FileType";
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import ActionAlert from "askbot-dragon/src/components/ActionAlertIframe";
import Recocell from "./../../dialogues/component/Recocell";
import AiGuide from "askbot-dragon/src/components/AiGuide";
window.addEventListener(
    "message",
    function (messageEvent) {
        var data = messageEvent.data;
        let iframeObj = document.getElementById(data.iframeId);
        if (iframeObj) {
            if (data.width) {
                iframeObj.width = data.width + "px";
            }
            if (data.height) {
                iframeObj.height = data.height + "px";
            }
        }
    },
    false
);
export default {
    props: ["feedbackChatUrl"],
    components: {
        Recocell,
        TicketMessage,
        TextMessage,
        FormTemplate,
        FeedBack,
        ActionAlert,
        Emotion,
        FileType,
        AiGuide,
    },
    data() {
        return {
            userMessages: [],
            moreMessages: false,
        };
    },
    watch: {},
    mounted() {},
    methods: {
        getFeedbackChat(feedbackChatUrl, feedbackUid, type) {
            let that = this;
            this.FetchGet(feedbackChatUrl, {}).then((res) => {
                if (res.code === "0") {
                    that.userMessages = [...[]];
                    let userMessages = res.data;
                    if (userMessages != null) {
                        if (userMessages.length > 10) {
                            let slice = userMessages.slice(0, 10);
                            for (let i = userMessages.length - 1; i >= 0; i--) {
                                if (userMessages[i].type == null) {
                                    let tampArr = JSON.parse(
                                        userMessages[i].content
                                    );
                                    if (tampArr != null) {
                                        tampArr.forEach((cell) => {
                                            if (
                                                cell.type ==
                                                "answer_rich_text_pro"
                                            ) {
                                                cell.content.html =
                                                    cell.content.html.replace(
                                                        /onclick="__rich_text_pro_button_onclick\(this\)"/g,
                                                        ""
                                                    );
                                            }
                                        });
                                    }
                                    userMessages[i].content =
                                        JSON.stringify(tampArr);
                                }
                                that.userMessages.unshift(userMessages[i]);
                            }
                            that.moreMessages = true;
                        } else {
                            for (let i = userMessages.length - 1; i >= 0; i--) {
                                that.userMessages.unshift(userMessages[i]);
                            }
                            that.moreMessages = false;
                        }
                    }
                    this.$nextTick(() => {
                        setTimeout(() => {
                            document
                                .querySelector("#chat" + feedbackUid)
                                .scrollIntoView({
                                    behavior: "smooth", // 平滑过渡
                                    block: "start", // 上边框与视窗顶部平齐。默认值
                                });
                        }, 1000);
                    });
                } else {
                    this.$message.error(res.message);
                }
                this.loading = true;
            });
        },
        gotoAlert(url) {
            window.open(url);
        },
        previewImage(_this) {
            this.showPreview = true;
            this.previewImageUrl = _this.src;
        },
        videoOptions(videoContent) {
            return {
                muted: true,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                    {
                        type: "video/mp4",
                        src: videoContent.url,
                    },
                ],
                width: "350",
                height: "160",
            };
        },
        scrollBottom() {
            this.$nextTick(() => {
                let container = document.getElementById("messageContent");
                let scrollHeight = container.scrollHeight;
                container.scrollTop = scrollHeight;
            });
        },
    },
};
</script>

<style lang="less">

.dialogContentComPonentD {
    width: 420px;
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    .pcFormClass {
        width: 320px;
    }
    .pcFormClass .field-item {
        width: 320px;
        display: flex !important;
        flex-direction: column !important;
    }
    .empty-tips-d {
        color: #e6a23c;
        font-size: 12px;
    }
    .message-notice {
        display: inline-block;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        background-color: transparent !important;
    }
    .notice-content {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #e8eaec;
        color: #666666;
        font-size: 12px;
    }
    .notice-content-day {
        display: inline-block;
        padding: 5px 10px;
        .line-left {
            display: inline-block;
            width: 100px;
            height: 1px;
            margin-bottom: 5px;
            margin-right: 5px;
            //background:#dcdcdc;
            background: -webkit-linear-gradient(left, #fff -4%, #666666 50%);
        }
        .line-text {
            color: #666666;
            font-size: 12px;
        }
        .line-right {
            display: inline-block;
            width: 100px;
            height: 1px;
            margin-bottom: 5px;
            margin-left: 5px;
            //background:#dcdcdc;
            background: -webkit-linear-gradient(left, #666666 50%, #fff 100%);
        }
    }
    //messages
    .message {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
        .user-detail {
            display: flex;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            .media-body {
                //max-width: calc(100vw - 800px);
                background-color: white;
                // border: 1px solid #e4e3e3;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 15px;
                border-top-left-radius: 0px;
                padding: 10px 10px 0 15px;
                text-align: left;

                h2 {
                    font-size: 18px;
                }
                p {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                }
                .content {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: left;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
            .user-image {
                width: 35px;
                height: 35px;
                margin-left: 10px;
                margin-right: 10px;
                img {
                    width: 35px;
                    height: 35px;
                }
                span {
                    color: #666666;
                    font-size: 12px;
                }
            }
            .media-body-ps {
                //max-width: calc(100vw - 800px);
                background-color: #f8f8f8;
                border: 1px solid #e4e3e3;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 25px;
                border-top-left-radius: 0px;
                text-align: left;
                h2 {
                    font-size: 18px;
                }
                .other {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    padding: 10px 10px 0 15px;
                }
                .ps {
                    padding: 5px 10px 5px 15px;
                    height: 25px;
                    line-height: 25px;
                    border-top: 1px solid #e4e3e3;
                    background-color: #ffffff;
                    color: #409eff;
                    cursor: pointer;
                }
                .end {
                    border-bottom-left-radius: 25px !important;
                    border-bottom-right-radius: 25px !important;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: left;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
        }
        .video-player,
        .video-js .vjs-icon-placeholder {
            width: 300px;
            height: 172px;
            display: block;
        }
        .el-image {
            float: right;
            width: 300px !important;
            height: auto !important;
        }
    }
    .message-kf {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
        .user-detail {
            display: flex;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            .media-body {
                //max-width: calc(100vw - 800px);
                border: 1px solid #c1e5ff !important;
                background-color: #c1e5ff !important;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 15px;
                border-top-right-radius: 0px;
                padding: 10px 10px 0 10px;
                text-align: left;
                h2 {
                    font-size: 18px;
                }
                p {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: right;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
        }
        .video-player,
        .video-js .vjs-icon-placeholder {
            width: 300px;
            height: 172px;
            display: block;
        }
        .el-image {
            float: right;
            width: 300px !important;
            height: auto !important;
        }
    }
}
</style>
