var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action_enforce"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'action_enforce'),expression:"type == 'action_enforce'"}],staticClass:"top-tip"},[_vm._v(" 执行该组件时，需要等待用户再发一句话给机器人后再执行跳转动作，并且强制进行跳转，多个跳转之间随机跳转一个。 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'action_random_jump'),expression:"type == 'action_random_jump'"}],staticClass:"top-tip"},[_vm._v(" 执行该组件时，多个跳转之间随机跳转一个。 ")]),_c('div',{staticClass:"goto-list"},_vm._l((_vm.unit.content.gotoList),function(cell,index){return _c('div',{key:index,staticClass:"jump-cell"},[_c('div',{staticClass:"jump-cell-l"},[_c('div',{staticClass:"left-select"},[_c('el-select',{staticStyle:{"width":"120px"},attrs:{"size":"small","placeholder":"请选择"},on:{"change":function($event){return _vm.jumpTypeValueChange($event, index)}},model:{value:(cell.scope),callback:function ($$v) {_vm.$set(cell, "scope", $$v)},expression:"cell.scope"}},_vm._l((_vm.filterJumpTypeOptions(
                                _vm.jumpTypeOptions
                            )),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"right-select"},[(cell.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":cell.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionEnforceAddnode_${_vm.com_index}_${index}`,"intentId":_vm.intentId,"index":index},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(cell.scope === 'INTENT'),expression:"cell.scope === 'INTENT'"}],attrs:{"placeholder":"请选择意图","size":"small","options":_vm.intentTree4Radio,"props":{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }},model:{value:(cell.value),callback:function ($$v) {_vm.$set(cell, "value", $$v)},expression:"cell.value"}})],1)]),(index == 0)?_c('span',{staticClass:"el-icon-plus-box",on:{"click":_vm.addGoto}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e(),(index !== 0)?_c('span',{staticClass:"el-icon-delete-box",on:{"click":function($event){return _vm.deleteGoto(index)}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }