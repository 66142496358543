<template>
    <div class="answer-text">
        <div class="answer-text-tip">如配置了多条答案，随机发送一条。</div>
        <div
            v-for="(textCell, textCellInd) in unit.content.list"
            :key="textCellInd"
            class="answer-text-cell"
        >
            <el-input
                autosize
                type="textarea"
                :ref="`ata_${unitIndex}_${textCellInd}`"
                v-model="unit.content.list[textCellInd]"
                maxlength="2000"
                placeholder="请输入文字答案"
            ></el-input>
            <span
                v-if="textCellInd == 0"
                class="answer-text-cell-handle-icon-add"
                @click="addText"
            >
                <i class="el-icon-plus"></i>
            </span>
            <span
                v-if="textCellInd !== 0"
                class="answer-text-cell-handle-icon-delete"
                @click="deleteText(textCellInd)"
            >
                <i class="el-icon-close"></i
            ></span>
        </div>
    </div>
</template>
<script>
export default {
    props: ["unit", "unitIndex"],
    data() {
        return {};
    },
    methods: {
        focusFirst(){
            let name = `ata_${this.unitIndex}_0`;
            this.$refs[name][0].focus();
        },
        // 添加文本答案输入框
        addText() {
            this.unit.content.list.push("");
            this.unit.content.extInfos.push({
                expectedIntention: "",
                notAsExpectedReply: "",
            });
            this.$nextTick(() => {
                let name = `ata_${this.unitIndex}_${this.unit.content.list.length - 1}`;
                this.$refs[name][0].focus();
            })
        },
        // 删除文本答案输入框
        deleteText(textCellInd) {
            this.unit.content.list.splice(textCellInd, 1);
            this.unit.content.extInfos.splice(textCellInd, 1);
        },
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/main/new_ui.less";
.answer-text {
    margin-top: 20px;
    .answer-text-tip {
        background: #fff8f4;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        color: #ff9555;
        line-height: 32px;
        text-align: left;
        padding: 0 12px;
        margin-bottom: 12px;
    }
    .answer-text-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;

        .el-select--small {
            flex: none;
            width: 100% !important;
        }

        .el-textarea {
            width: 100%;
        }
    }
}
</style>