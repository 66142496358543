<template>
    <div class="action-satisfaction">
        <div class="simple-cell-satisfaction">
            <span class="title">满意度描述</span>
            <div>
                <el-input autosize type="textarea" v-model="unit.content.satisfactionDes" placeholder="请输入文字描述">
                </el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title">满意度选择</span>
            <div class="option-sa-as">
                <span class="option-sa-cell">满意</span>
                <span class="option-sa-cell">不满意</span>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title">如果满意时回复</span>
            <div>
                <el-input autosize type="textarea" v-model="unit.content.satisfactoryAnswer"
                    placeholder="请输入满意时机器人的回复..."></el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title">如果不满意时回复</span>
            <div>
                <el-input autosize type="textarea" v-model="unit.content.unsatisfiedAnswer"
                    placeholder="请输入不满意时机器人的回复..."></el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title-other">如果想了解咨询者不满意的原因，可配置下方点选</span>
            <div id="btn-list-box-sort" class="btn-list-box satisfaction-radio-box">
                <div :key="answerRadioIndex" v-for="(item, answerRadioIndex) in unit.content.answerRadio"
                    class="btn-list-cell">
                    <span class="btn-list-cell-text">{{ item.name }}</span>
                    <span class="sortIcon my-handle-btn iconfont guoran-a-16-10"
                        @mouseenter="sortClickBtn($event, item, unitIndex)">
                    </span>
                    <span @click="handleCloseSaRadio(unitIndex, answerRadioIndex)"
                        class="my-handle-close iconfont guoran-a-16-09">
                    </span>
                </div>
                <span @click="addSelectCustRadio" class="cell-title-setting-inner"><i
                        class="el-icon-setting"></i></span>
            </div>
        </div>
        <popup v-if="selectCustRadioVisible" @closeEvent="selectCustRadioVisible = false">
            <div slot="popup-name">不满意原因点选按钮配置</div>
            <div slot="popup-con">
                <div class="select-cell-title">
                    <span class="select-cell-title-name">点选名称</span>
                    <span class="select-cell-title-action">用户点击后发送</span>
                </div>
                <div id="select-list-sort" class="select-list">
                    <div v-for="(cell, cellIndex) in activeOptions" :key="cellIndex" class="select-list-cell">
                        <span class="select-cell-move icon-handle-radio-btn">
                            <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
                        </span>
                        <div class="select-cell-name">
                            <el-input size="small" v-model="cell.name" maxlength="40" placeholder="请输入点选名称"></el-input>
                        </div>
                        <div class="select-cell-value">
                            <el-input size="small" v-model="cell.answer" maxlength="200" placeholder="请输入用户点击时机器人的回复">
                            </el-input>
                        </div>
                        <span v-if="cellIndex === 0" @click="addRCell" class="answer-text-cell-handle-icon-add">
                            <i class="el-icon-plus"></i>
                        </span>
                        <span v-else @click="deleteRCell(cellIndex)" class="answer-text-cell-handle-icon-delete">
                            <i class="el-icon-close"></i></span>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="selectCustRadioVisible = false" plain size="small">取 消</el-button>
                <el-button type="primary" @click="confirmselectCustRadioVisible" size="small">确定</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import Sortable from "sortablejs";
export default {
    props: ["unitIndex", "unit", "custonerOptions",],
    components: {
        popup,
    },
    data() {
        return {
            activeOptions: [],
            selectCustRadioVisible: false,
        };
    },
    methods: {
        handleCloseSaRadio(unitIndex, answerRadioIndex) {
            console.log(answerRadioIndex);
            this.unit.content.answerRadio.splice(answerRadioIndex, 1);
        },
        // 满意度类型
        // 编辑满意度点选
        cancelSatiAnswerRadioVisible() {
            this.satiAnswerRadioVisible = false;
        },
        confirmSaveSatiAnswerRadioVisible() {
            if (
                this.activeSatiAnswerRadioObj.name.trim() != "" &&
                this.activeSatiAnswerRadioObj.answer.trim() != ""
            ) {
                let obj = JSON.stringify(this.activeSatiAnswerRadioObj);
                if (this.activeSaIndexType == "add") {
                    this.actionList[
                        this.activeSaIndex
                    ].content.answerRadio.push(JSON.parse(obj));
                } else {
                    this.actionList[this.activeSaIndex].content.answerRadio[
                        this.activeSaCellIndex
                    ] = JSON.parse(obj);
                }
                this.satiAnswerRadioVisible = false;
            } else {
                this.$message.error("点选名称、点击回复不能为空！");
            }
        },
        // 删除点选
        deleteRCell(cellIndex) {
            this.activeOptions.splice(cellIndex, 1);
        },
        confirmselectCustRadioVisible() {
            let flag = true;
            this.activeOptions.forEach((item) => {
                if (item.name.trim() === "") {
                    flag && this.$message.error("点选按钮名称不能为空！");
                    flag = false;
                }
            });
            if (flag) {
                this.unit.content.answerRadio = [...this.activeOptions];
                this.selectCustRadioVisible = false;
            }
        },
        // 编辑点选
        addSelectCustRadio() {
            this.activeOptions = JSON.parse(
                JSON.stringify(this.unit.content.answerRadio)
            );
            if (this.activeOptions.length === 0) {
                this.activeOptions.push({
                    name: "",
                    customerId: this.custonerOptions[0].id || "",
                    value: "",
                });
            }
            this.selectCustRadioVisible = true;
            this.$nextTick(() => {
                this.popSortClickBtn();
            });
        },
        addRCell() {
            this.activeOptions.push({ name: "", answer: "" });
        },
        sortClickBtn(e, items, index) {
            e.stopPropagation();
            let _this = this;
            let idname = "btn-list-box-sort";
            let box = document.getElementById(idname);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.answerRadio.splice(
                        oldIndex,
                        1
                    );
                    _this.unit.content.answerRadio.splice(newIndex, 0, item[0]);
                },
            });
            this.unit.content.answerRadio = _this.unit.content.answerRadio;
        },
        popSortClickBtn() {
            const box = document.querySelector("#select-list-sort");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-radio-btn",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeOptions.splice(oldIndex, 1);
                    _this.activeOptions.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.activeOptions = _this.activeOptions;
        },
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/main/new_ui.less";

.action-satisfaction {
    .cell-title-setting-inner {
        cursor: pointer;
        margin-top: 0px;
        display: inline-block;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        border: solid 1px #366aff;
        text-align: center;
        line-height: 30px;

        i {
            font-size: 14px;
            color: #366aff;
            font-weight: 700;
        }
    }

    .btn-list-cell:hover {
        .my-handle-btn {
            display: inline-block;
        }

        .my-handle-close {
            display: inline-block;
        }
    }

    .btn-list-cell {
        height: 28px;
        line-height: 28px;
        border-radius: 14px;
        padding: 0;
        margin-right: 6px;
        margin-top: 0;
        border: solid 1px #366aff;
        color: #366aff;
        cursor: pointer;
        position: relative;
        background-color: white;

        .btn-list-cell-text {
            display: inline-block;
            height: 28px;
            line-height: 28px;
            padding: 0 16px;
        }

        .my-handle-btn {
            display: none;
            position: absolute;
            top: -8px;
            right: 26px;
            height: 16px;
            line-height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: white;
        }

        .my-handle-close {
            display: none;
            position: absolute;
            top: -8px;
            right: 3px;
            height: 16px;
            line-height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: white;
        }
    }

    .select-cell-title {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 12px;

        .select-cell-title-name {
            flex: none;
            margin-left: 24px;
            width: 170px;
            text-align: left;
        }
    }

    .select-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;

        .select-cell-move {
            color: #366aff;
            font-size: 20px;
            font-weight: 500;
            margin-right: 8px;
        }

        .select-cell-name {
            width: 155px;
            flex: none;
        }

        .select-cell-value {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 15px;

            /deep/.el-select {
                width: 100%;
            }

            .select-type {
                width: 90px;
            }

            /deep/.inline-input {
                width: 200px;
                padding-right: 0;
            }

            /deep/.el-cascader {
                width: 200px;
                padding-right: 0;
            }

            /deep/.el-input__inner {
                border: none;
                padding-right: 0;
            }

            /deep/.el-input__suffix {
                display: none;
            }
        }
    }

    .simple-cell-satisfaction {
        .title {
            color: #616161;
        }

        .option-sa-as {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .option-sa-cell {
                flex: none;
                width: 48.5%;
                height: 32px;
                line-height: 34px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                color: #a9b3c6;
            }
        }
    }
}
</style>