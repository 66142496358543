<template>
    <div class="note-list">
        <el-table
            v-loading="loading"
            
            :data="tableData"
            stripe
            style="width: 100%"
        >
            <el-table-column prop="botName" label="机器人" width="180">
            </el-table-column>
            <el-table-column prop="botVersion" label="版本" width="180">
            </el-table-column>
            <el-table-column prop="time" label="训练时间"> </el-table-column>
            <el-table-column
                align="center"
                prop="tagCount"
                label="标注问题条数"
            >
            </el-table-column>
            <el-table-column align="center" prop="status" label="状态">
                <template slot-scope="scope">
                    <el-tag
                        :type="
                            scope.row.status === 'TRAIN_SUCCESS'
                                ? 'success'
                                : scope.row.status === 'TRAIN_ERROR'
                                ? 'danger'
                                : ''
                        "
                        size="mini"
                    >
                        {{
                            scope.row.status === "TRAIN_SUCCESS"
                                ? "训练完成"
                                : scope.row.status === "TRAIN_ERROR"
                                ? "训练失败"
                                : "训练中"
                        }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="" label="操作">
                <template slot-scope="scope">
                    <div>
                        <el-button
                            v-if="[''].indexOf(scope.row.status) > -1"
                            type="text"
                            disabled
                            >详情</el-button
                        >
                        <el-button
                            v-if="
                                ['TRAIN_SUCCESS'].indexOf(scope.row.status) > -1
                            "
                            type="text"
                            @click="viewDetails(scope.row)"
                            >详情</el-button
                        >
                        <el-button
                            v-if="
                                ['TRAIN_ERROR'].indexOf(scope.row.status) > -1
                            "
                            type="text"
                            >重新训练</el-button
                        >
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :page-size="pageSize"
            :current-page="currentPage"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="pageChange"
        >
        </el-pagination>
        <note-details
            :visible-details="showDetails"
            :r-id="rId"
            @close="close"
        />
    </div>
</template>

<script>
import NoteDetails from "./noteDetails";
export default {
    components: {
        NoteDetails,
    },
    props: ["noteCurrentBot", "newStartTime", "newEndTime"],
    data() {
        return {
            pageSize: 20,
            currentPage: 1,
            total: 0,
            tableData: [],
            showDetails: false,
            rId: "",
            loading: false,
            params:{}
        };
    },
    mounted() {
        // this.getTrainList();
    },
    methods: {
        viewDetails(row) {
            this.showDetails = true;
            this.rId = row.id;
        },
        close() {
            this.showDetails = false;
            this.getTrainList(this.params)
        },
        // 训练记录列表
        getTrainList(data) {
            this.loading = true;
            let param = data;
            param.page = this.currentPage;
            param.size = this.pageSize;
            param.beginTrainTime = data.beginTrainTime == null?null:new Date(data.beginTrainTime);
            param.endTrainTime = data.endTrainTime == null?null: new Date(data.endTrainTime);
            this.params = param;
            this.FetchPost(this.requestUrl.trainList.trainList, param)
                .then((res) => {
                    if (res.code === "0") {
                        this.tableData = [];
                        res.data.list.forEach((v) => {
                            v.time = new Date(v.createTime).Format(
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            this.tableData.push(v);
                        });
                        this.total = res.data.total;
                        this.$emit('getSummaryTrainingRecords',param)
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        pageChange(curr) {
            this.currentPage = curr;
            this.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
        },
    },
};
</script>

<style lang="less">
.note-list {
    // margin-top: 10px;
    // margin-right: 10px;
    overflow: hidden;
    background: #ffffff;
}
.el-pagination {
    padding: 20px 60px;
    text-align: center;
    background-color: white;
}
</style>