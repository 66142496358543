var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"radio-fonfig"}},[_vm._m(0),_c('div',{staticClass:"radio-btn-list"},_vm._l((_vm.activeRadioBtnList),function(rCell,rCellIndex){return _c('div',{key:rCellIndex,staticClass:"radio-btn-list-cell"},[_vm._m(1,true),_c('div',{staticClass:"select-cell select-cell-name"},[_c('el-input',{attrs:{"size":"small","maxlength":"40","placeholder":"请输入点选名称"},model:{value:(rCell.name),callback:function ($$v) {_vm.$set(rCell, "name", $$v)},expression:"rCell.name"}})],1),_c('div',{staticClass:"select-cell select-cell-value"},[_c('el-select',{key:'rblcS' + rCellIndex,staticClass:"select-type",attrs:{"size":"small","placeholder":"请选择"},on:{"change":_vm.answerRadioValueChange},model:{value:(rCell.scope),callback:function ($$v) {_vm.$set(rCell, "scope", $$v)},expression:"rCell.scope"}},_vm._l((_vm.jumpTypeOptions),function(item){return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(
                            (_vm.isStartOrEnd && item.value != 'NODE') ||
                            !_vm.isStartOrEnd
                        ),expression:"\n                            (isStartOrEnd && item.value != 'NODE') ||\n                            !isStartOrEnd\n                        "}],key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),(rCell.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":rCell.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionEnforceAddnode_${_vm.activeRadioConfigIndex[0]}_${rCellIndex}`,"intentId":_vm.intentId,"selectAddnodeKey":'rblcSA' + rCellIndex,"index":rCellIndex},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(rCell.scope === 'INTENT'),expression:"rCell.scope === 'INTENT'"}],key:'rblcEc' + rCellIndex,attrs:{"placeholder":"请选择意图名称","size":"small","options":_vm.intentTree4Radio,"props":{
                        expandTrigger: 'hover',
                        emitPath: false,
                        label: 'name',
                        value: 'id',
                    }},model:{value:(rCell.value),callback:function ($$v) {_vm.$set(rCell, "value", $$v)},expression:"rCell.value"}})],1),(rCellIndex === 0)?_c('span',{staticClass:"answer-text-cell-handle-icon-add",on:{"click":_vm.addRCell}},[_c('i',{staticClass:"el-icon-plus"})]):_c('span',{staticClass:"answer-text-cell-handle-icon-delete",on:{"click":function($event){return _vm.deleteRCell(rCellIndex)}}},[_c('i',{staticClass:"el-icon-close"})])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radio-btn-list-header"},[_c('span',{staticClass:"radio-btn-list-header-name"},[_vm._v("点选名称")]),_c('span',{staticClass:"radio-btn-list-header-action"},[_vm._v("如果用户点击")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"select-cell-move icon-handle-radio-btn"},[_c('i',{staticClass:"iconfont guoran-tongyichicun-16-10-paixu"})])
}]

export { render, staticRenderFns }