<template>
    <div class="batch-intent-baili-update">
        <div class="intent-tree-content">
            <div class="intent-tree-box">
                <el-tree
                    ref="allIntentTree"
                    :data="intentOptions"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    :current-node-key="currentNodeKey"
                    :default-expanded-keys="defaultExpandedKeys"
                    :props="defaultProps"
                >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span
                            v-if="data.type == 'BOT'"
                            class="pre-icon-type arsenal_icon arsenaljiqiren"
                        ></span>
                        <span
                            v-if="data.type == 'SKILL'"
                            class="pre-icon-type el-icon-collection"
                        ></span>
                        <span
                            v-if="data.type == 'TYPE'"
                            class="pre-icon-type el-icon-files"
                        ></span>
                        <span
                            v-if="data.type == 'INTENT'"
                            class="pre-icon-type el-icon-tickets"
                        ></span>

                        <span @click="showChangeFlow(data, node)">{{
                            node.label
                        }}</span>
                    </span>
                </el-tree>
            </div>
            <div class="intent-selected-content-pre">
                提示：点击意图名称可查看意图流程对比
            </div>
        </div>
        <!-- <div class="no-opration-tips">
            
        </div> -->
        <div class="intent-selected-content">
            <div class="copy-intent-footer ">
                <el-button @click="closeUpdateIntentOpration"  round class="cancel-btn"
                    >关闭</el-button
                >
                <el-button @click="updateIntent" type="primary" round class="confirm-btn"
                    >更新流程</el-button
                >
            </div>
        </div>
        <el-drawer
            title="我是标题"
            size="100%"
            :visible.sync="drawer"
            :direction="direction"
            :with-header="false"
            :modal="false"
            :modal-append-to-body="false"
            :before-close="handleClose"
        >
            <div class="intent-change-view" style="height: 100vh">
                <div class="intent-change-header">
                    <div class="intent-name">{{ activeCopIntentName }}</div>
                    <div class="intent-flow-title">
                        <span>当前流程</span>
                        <span>新流程</span>
                    </div>
                </div>
                <div v-if="!hiddenFlow" class="intent-change-content">
                    <ChatFlowComponent
                        ref="cfc"
                        :isPart="1"
                        :intentIds="[newIntentId, parentIntentId]"
                        :drawerSize="200"
                    />
                </div>
                <div class="intent-change-footer">
                    <el-button @click="colseComp"
                        >关闭</el-button
                    >
                    <!-- <el-button
                        v-if="showPreAndAft"
                        :disabled="newIntentId == handleIntentIds[0].id"
                        @click="switchActiveIntentPre"
                        size="mini"
                        >上一个</el-button
                    >
                    <el-button
                        v-if="showPreAndAft"
                        :disabled="
                            newIntentId ==
                            handleIntentIds[handleIntentIds.length - 1].id
                        "
                        @click="switchActiveIntentAft"
                        size="mini"
                        >下一个</el-button
                    > -->
                    <el-button
                        @click="batchOperation([newIntentId], 2)"
                        type="primary"
                        >同步流程</el-button
                    >
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import ChatFlowComponent from "./../../ChatFlowOld/FlowComponent.vue";
export default {
    components: {
        ChatFlowComponent,
    },
    data() {
        return {
            drawer: false,
            direction: "ttb",
            defaultExpandedKeys: [],
            intentOptions: [],
            skillList: [],
            deraction: [],
            optionProps: {
                value: "id",
                label: "name",
                children: "children",
                expandTrigger: "hover",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: [],
            isDefaultIntents: [],
            newIntentId: "",
            parentIntentId: "",
            activeCopIntentName: "",
            handleIntentIds: [],
            hiddenFlow: false,
            showPreAndAft: false,
        };
    },
    methods: {
        colseComp(){
            this.drawer = false;
            this.getIntentTree(this.$route.query.id);
            document.getElementsByClassName('v-modal')[0].style.height = '100%';
        },
        switchActiveIntentPre() {
            // 上一个
            this.handleIntentIds.forEach((item, index) => {
                if (item.id == this.newIntentId && index != 0) {
                    this.newIntentId = this.handleIntentIds[index - 1].id;
                    this.activeCopIntentName =
                        this.handleIntentIds[index - 1].name;
                    this.parentIntentId =
                        this.handleIntentIds[index - 1].refAttrId;
                }
            });
            this.$nextTick(() => {
                this.$refs.cfc.getOldAndNewIntentFlow();
            });
        },
        switchActiveIntentAft() {
            // 下一个
            this.handleIntentIds.forEach((item, index) => {
                if (
                    item.id == this.newIntentId &&
                    index != this.handleIntentIds.length - 1
                ) {
                    this.newIntentId = this.handleIntentIds[index + 1].id;
                    this.activeCopIntentName =
                        this.handleIntentIds[index + 1].name;
                    this.parentIntentId =
                        this.handleIntentIds[index + 1].refAttrId;
                }
            });
            this.$nextTick(() => {
                this.$refs.cfc.getOldAndNewIntentFlow();
            });
        },
        showChangeFlow(data, node) {
            if (data.type == "INTENT") {
                this.drawer = true;
                this.newIntentId = data.id;
                this.activeCopIntentName = data.name;
                this.parentIntentId = data.refAttrId;
                this.$nextTick(() => {
                    this.$refs.cfc.getOldAndNewIntentFlow();
                });
            }
        },
        closeUpdateIntentOpration() {
            this.$emit("closeUpdateIntentOpration");
        },
        batchOperation(param, type) {
            this.FetchPost(this.requestUrl.intent.batchSyncProcess, param).then(
                (res) => {
                    if (res.code === "0") {
                        this.getIntentTree(this.$route.query.id);
                        this.drawer = false;
                        this.$message.success("更新成功！");
                        this.$emit("getIntentSyncProcessTree");
                        if (type == 1) {
                            this.$emit("closeUpdateIntentOpration");
                        }
                        if (type == 2) {
                            this.$nextTick(() => {
                                this.$refs.cfc.getOldAndNewIntentFlow();
                            });
                        }
                        this.$emit("switchSkillCopy");
                        document.getElementsByClassName('v-modal')[0].style.height = '100%';
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        updateIntent() {
            if (
                this.$refs.allIntentTree
                    .getCheckedKeys(true)
                    .filter((item) => Number(item) + "" !== NaN + "").length ==
                0
            ) {
                this.$message.warning("请至少选择一个意图");
                return false;
            }
            let param = this.$refs.allIntentTree
                .getCheckedKeys(true)
                .filter((item) => Number(item) + "" !== NaN + "");
            this.batchOperation(param, 1);
        },
        getIntentTree(bId) {
            this.showPreAndAft = false;
            let handleIntentIds = [];
            this.FetchGet(
                this.requestUrl.intent.syncProcessTree + bId,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    if (res.data.node == null) {
                        this.$emit("closeUpdateIntentOpration");
                    } else if (res.data.node.length == 0) {
                        this.intentOptions = [];
                    } else {
                        this.intentOptions = [res.data.node];
                        this.defaultExpandedKeys = [bId];
                        this.intentOptions.forEach((bot) => {
                            if (bot.children != null) {
                                bot.children.forEach((skill) => {
                                    skill.type = "SKILL";
                                    if (skill.children != null) {
                                        skill.children.forEach((_type) => {
                                            _type.type = "TYPE";
                                            if (_type.children != null) {
                                                _type.children.forEach(
                                                    (intent) => {
                                                        intent.type = "INTENT";
                                                        handleIntentIds.push(
                                                            intent
                                                        );
                                                    }
                                                );
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        this.handleIntentIds = handleIntentIds;
                        this.showPreAndAft = true;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
    mounted() {
        this.getIntentTree(this.$route.query.id);
    },
};
</script>

<style lang="less">
.intent-change-view {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .el-dialog__wrapper {
        z-index: 99999 !important;
        .el-dialog {
            z-index: 999999 !important;
        }
    }
    .intent-change-header {
        height: 80px;
        flex: none;
        .intent-name {
            margin: 16px auto;
            font-weight: 800;
            font-size: 20px;
        }
        .intent-flow-title {
            display: flex;
            justify-content: space-around;
            text-align: center;
            font-weight: 500;
        }
    }
    .intent-change-content {
        flex: auto;
        overflow: auto;
        #ChatFlow {
            height: 100%;
        }
        .el-drawer__body {
            width: 800px !important;
        }
    }
    .intent-change-footer {
        height: 80px;
        flex: none;
    }
}
.batch-intent-baili-update {
    .custom-tree-node {
        .el-tag {
            display: inline-block;
            margin-left: 6px;
            font-size: 12px;
            line-height: 16px;
            height: 16px;
        }
    }
    .intent-tree-content {
        // height: 400px;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        margin-bottom: 30px;
        //  max-height: calc(84vh - 350px);
        //   min-height: 250px;
        .pre-icon-type {
            color: #A1B9FF;
            display: inline-block;
            margin: 0 8px;
            font-size: 14px;
        }
        .arsenal_icon {
            font-size: 14px;
        }
        .copy-intent-title {
            display: block;
            height: 32px;
            line-height: 32px;
            text-align: left;
            font-weight: 600;
        }
        .intent-tree-box {
            max-height: calc(84vh - 400px);
            overflow-y: scroll;
            padding: 0 64px;
            min-height: 200px;
            padding: 20px 20px 0;
            ::v-deep .el-tree{
                background: #E0E6F7;
            }
        }
    }
    .intent-selected-content-pre{
        color: #909399;
        width: 100%;
        text-align: center;
        line-height: 42px;
        height: 42px;
        background: #FFF8F4;
        border-radius: 5px;
        color: #FF9555;
        font-size: 12px;
        
    }
    .intent-selected-content {
        height: 60px;
      
        .copy-intent-title {
            display: block;
            height: 32px;
            line-height: 32px;
            text-align: left;
            font-weight: 600;
        }
        .copy-intent-footer {
            text-align: center;
            padding-bottom: 30px;
            .cancel-btn{
                width: 99px;
                height: 37px;
                background: #FFFFFF;
                border: 2px solid #E0E6F7;
                border-radius: 19px;
                padding: 0;
                line-height: 37px;
                border-radius: 19px;
            }
            .confirm-btn{
                height: 37px;
                width: 99px;
                background: #366AFF;
                border-radius: 19px;
                padding: 0;
                line-height: 37px;
                border-radius: 19px;
            }
            .el-button--default:hover{
                border-color: #366AFF;
                color: #366AFF;
            }
           
        }
    }
}
</style>