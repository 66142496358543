<template>
    <div class="relation-recommend-item">
        <div class="relation-recommend-switch-outer">
            <div class="relation-recommend-switch">
                <span class="relation-recommend-switch-pre"
                    >是否启用关联推荐</span
                >
                <span class="relation-recommend-switch-after"
                    >暂不支持小程序、网页版</span
                >
            </div>
            <!-- <el-switch
                size="mini"
                @change="changeStatus"
                v-model="unit.content.recommend.status"
                active-color="#366AFF"
                inactive-color="#C0C4CC"
            >
            </el-switch> -->
            <div
                class="icon-switch-box"
                v-if="unit.content.recommend.status"
                @click.stop="
                    changeStatus(false)
                "
            >
                <i
                    class="el-icon-check"
                ></i>
                <div></div>
            </div>
            <div
                class="icon-switch-box false"
                v-if="!unit.content.recommend.status"
                @click.stop="
                    changeStatus(true)
                "
            >
                <i
                    class="el-icon-check"
                ></i>
                <div></div>
            </div>
        </div>
        <div
            v-if="
                unit.content.recommend.status"
            class="relation-recommend-content"
        >
            <div class="relation-recommend-content-des">
                <div class="relation-recommend-content-des-title">
                    关联意图推荐描述
                </div>
                <el-input
                    size="small"
                    ref="recommendfocus"
                    v-model="unit.content.recommend.description"
                    placeholder="请输入关联意图推荐描述"
                ></el-input>
            </div>
            <div
                v-if="unit.content.recommend.list.length != 0"
                class="relation-recommend-content-title"
            >
                <span class="relation-recommend-content-title-pre"
                    >关联意图</span
                >
                <span class="relation-recommend-content-title-aft"
                    >推荐文案</span
                >
            </div>

            <div class="relation-recommend-content-list">
                <div
                    v-for="(recommendIntent, recommendIntentIndex) in unit
                        .content.recommend.list"
                    :key="recommendIntentIndex"
                    class="relation-recommend-content-item"
                >
                    <div class="relation-recommend-content-item-intent">
                        <el-cascader
                            size="small"
                            :show-all-levels="false"
                            :key="'rri' + index + recommendIntentIndex"
                            @change="
                                intentSelectedValue(
                                    $event,
                                    recommendIntentIndex
                                )
                            "
                            v-model="recommendIntent.ext.intentValue"
                            placeholder="请搜索或选择意图"
                            :options="intentSAkillTypeOptionsNoChildren"
                            filterable
                        ></el-cascader>
                    </div>
                    <div class="relation-recommend-content-item-text-type">
                        <el-select
                            size="small"
                            v-model="recommendIntent.type"
                            placeholder="请选择"
                            @change="
                                recommendIntentCypeChange(
                                    $event,
                                    recommendIntentIndex
                                )
                            "
                        >
                            <el-option
                                v-for="item in [
                                    { value: 0, label: '自定义' },
                                    { value: 1, label: '跟随意图' },
                                ]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="relation-recommend-content-item-text">
                        <el-input
                            size="small"
                            :disabled="recommendIntent.type == 1"
                            v-model="recommendIntent.text"
                            placeholder="请输入"
                        ></el-input>
                    </div>
                    <div class="relation-recommend-content-item-handle">
                        <span v-show="recommendIntentIndex != 0"
                            class="answer-text-cell-handle-icon-delete"
                            @click="deleteText(recommendIntentIndex)"
                        >
                            <i class="el-icon-close"></i
                        ></span>
                        <span
                            v-show="recommendIntentIndex == 0"
                            class="answer-text-cell-handle-icon-add"
                            @click="addText"
                        >
                            <i class="el-icon-plus"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="unit.content.recommend.list.length == 0"
                class="empty-intent-r"
            >
                暂无关联意图设置
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["unit", "intentSAkillTypeOptionsNoChildren", "index"],
    data() {
        return {};
    },
    methods: {
        changeStatus(val){
            console.log(val);
            this.$set(this.unit.content.recommend, 'status', val);
            if (val) {
                this.$nextTick(() => {
                    this.$refs.recommendfocus.focus();
                })
            }
        },
        intentSelectedValue($event, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].id =
                $event[1];
            if (
                this.unit.content.recommend.list[recommendIntentIndex].type ==
                "1"
            ) {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        recommendIntentCypeChange(val, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].type = val;
            if (val == "1") {
                if (
                    this.unit.content.recommend.list[recommendIntentIndex].id !=
                    ""
                ) {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
                } else {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = "";
                }
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        filterRecoText(id) {
            let name = "";
            this.intentSAkillTypeOptionsNoChildren.forEach((item) => {
                item.children.forEach((intent) => {
                    if (intent.id == id) {
                        name =
                            intent.exampleQuestion == ""
                                ? intent.label
                                : intent.exampleQuestion;
                    }
                });
            });
            return name;
        },
        // 添加文本答案输入框
        addText(index) {
            this.unit.content.recommend.list.push({
                id: "",
                type: 0,
                text: "",
                ext: {
                    intentValue: [],
                },
            });
        },
        // 删除文本答案输入框
        deleteText(index) {
            this.unit.content.recommend.list.splice(index, 1);
        },
        // 判断是否还是初始化推荐
        isInitRec(rec) {
            let flag = true;
            if (rec.description != "") {
                flag = false;
            } else {
                if (rec.list.length != 0) {
                    rec.list.forEach((item) => {
                        if (item.id != "" || item.text != "") {
                            flag = false;
                        }
                    });
                }
            }
            return flag;
        },
    },
};
</script>

<style lang="less" scoped>
@import "./../../../../assets/less/main/new_ui.less";
.relation-recommend-item {
    margin-top: 8px;
    color: #616161;
    background-color: #fbfcfd;

    .icon-switch-box{
        display: none;
        height: 24px;
        background: #366AFF;
        border-radius: 12px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        div{
            width: 14px;
            height: 14px;
            background: #FFFFFF;
            box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.18);
            border-radius: 50%;
            margin-left: 5px;
        }
        &.false{
            background-color: rgb(226, 226, 226);
            color: #6E6B7B;
        }
    }
    .relation-recommend-switch-outer {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        height: 39px;
        line-height: 39px;
        align-items: center;

        .relation-recommend-switch {
            .relation-recommend-switch-pre {
                margin-right: 4px;
                color: #616161;
            }
            .relation-recommend-switch-after {
                margin-left: 10px;
                color: #a9b3c6;
                font-size: 13px;
                margin-right: 8px;
            }
        }
    }
    .relation-recommend-content {
        border-top: dashed 1px #e0e6f7;
        padding: 12px;
        .relation-recommend-content-des {
            .relation-recommend-content-des-title {
                text-align: left;
                margin-bottom: 8px;
            }
        }
        .relation-recommend-content-title {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            margin-top: 12px;
            margin-bottom: 4px;
            .relation-recommend-content-title-pre {
                flex: none;
                width: 160px;
            }
        }
        .bottom-plus-btn {
            margin-top: 8px;
            font-size: 26px;
        }
        .empty-intent-r {
            margin-top: 16px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
        }
        .relation-recommend-content-list {
            margin-top: 4px;
            .relation-recommend-content-item {
                margin-top: 4px;
                display: flex;
                justify-content: flex-start;
                .relation-recommend-content-item-intent {
                    flex: none;
                    width: 146px;
                }
                .relation-recommend-content-item-text-type {
                    margin-left: 14px;
                    width: 100px;
                    flex: none;
                }
                .relation-recommend-content-item-text {
                    flex: auto;
                    margin-left: 14px;
                }
                .relation-recommend-content-item-handle {
                    flex: none;
                    display: flex;
                    align-items: center;
                    .answer-text-cell-handle-icon-delete-inner {
                        margin-left: 6px;
                        margin-right: -6px;
                        i {
                            color: #366aff;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
</style>