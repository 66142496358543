<template>
  <div id="botIntent">
    <div class="botContent">
      <div class="outer-pane">
        <div class="class-manage">
          <ul class="class-list">
            <li
              v-for="item in skills"
              style="width:auto"
              :class="item.skillId == activeClass ? 'active-class' : ''"
              :key="item.skillId"
              @click="switchClass(item.skillId)"
            >{{item.skillName+'('+item.entityNum+')'}}</li>
          </ul>
        </div>
        <div class="intentWrap">
          <ul class="entity-list">
            <li class="entity cursor" @click="addIntent" v-if="!isDefault">
              <div class="add-entity-inner">
                <span class="addIntentBtn">
                  <em class="el-icon-circle-plus"></em>
                </span>
                <span class="addBotText">添加实体</span>
              </div>
            </li>
            <li class="entity" v-for="(item, index) in entityList" :key="item.id">
              <div class="intent-inner">
                <div class="intent-handle-box">
                  <em class="el-icon-edit" @click="edit(index)" v-if="!isDefault"></em>
                  <em class="el-icon-delete" @click="deleteEntity(index)" v-if="!isDefault"></em>
                  <el-tooltip placement="top">
                    <div slot="content">
                      更新时间：{{item.uTime}}
                      <br />
                      创建时间：{{item.cTime}}
                    </div>
                    <em class="el-icon-more" v-if="!isDefault"></em>
                  </el-tooltip>
                </div>
                <div class="entity-name">{{item.name}}</div>
                <div v-if="item.type=='DICTIONARY'">
                  <div class="word-list" >
                    <div class="word-cell" v-for="( content,i) in item.content" :key="i" v-if="i<2">
                      <div v-if="item.type==='DICTIONARY'">
                        <span class="standard-word">{{item.content[i].standardWord}}</span>
                        <span
                          class="synonyms"
                        >{{Array.isArray(content.synonyms)?content.synonyms.join(','):""}}</span>
                      </div>
                      <div v-if="item.type==='MODEL'">
                        <span class="standard-word-model">{{item.content[i].standardWord}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="intent-handle-box-bottom">
                    <!--   <el-popover
                                              placement="top"
                                              width="260"
                                              v-model="item.visible" trigger="manual">
                                          <p>实体关闭后，可能会导致对话流程不可用，是否继续关闭？</p>
                                          <div style="text-align: right; margin: 0">
                                              <el-button size="mini" plain @click="openStatusChangeCancel(index)">取消
                                              </el-button>
                                              <el-button type="danger" plain size="mini"
                                                        @click="openStatusChangeConfirm(index)">关闭
                                              </el-button>
                                          </div>

                                          <el-switch
                                                  v-model="item.isOpen" @change="openStatusChange(index)"
                                                  slot="reference">
                                          </el-switch>
                                      </el-popover>
                    -->
                    <em @click="clickDisplayWhole(index)" v-if="item.type==='DICTIONARY'">查看全部</em>
                  </div>
                </div>
                <div v-else>
                    <span class="standard-word regular-desc">{{item.content[0].description | ellipsis}}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <AddIntntDia
      :addIntentDiaVisible="addIntentDiaVisible"
      :entity="currentEntity"
      :skills="skills"
      :isEdit="isEdit"
      @changeAddIntentVisible="changeAddIntentVisible"
      @afterSave="afterSave"
    ></AddIntntDia>
    <dispaly-whole
      :displayWhole="displayWhole"
      @changeDisplayWholeVisible="changeDisplayWholeVisible"
      :entity="currentEntity"
    ></dispaly-whole>
    <el-dialog id="deleteConfirm" :close-on-click-modal="false" :visible.sync="deleteVisible" title="删除实体" width="421px">
      <span class="content">删除实体可能会导致对话流程不可用，确认要删除吗？</span>
      <div class="buttons">
        <el-button plain size="mini" @click="deleteEntityCancel">取消</el-button>
        <el-button type="danger" plain size="mini" @click="deleteEntityOk">删除</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BotNameAndTolick from "./../../components/BotNameAndToLink";
import AddIntntDia from "../entity/addEntity";
import DispalyWhole from "../entity/displayWholeDrawer";
import BotSetting from "../botIndex/BotSettingNew.vue";

export default {
  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 108) {
        return value.slice(0,108) + '...'
      }
      return value
    }
  },
  props: ["isDefault"],
  data() {
    return {
      activePageInfo: {
        // 当前页跳转信息
        name: "返回全部机器人",
        gotoPageName: "botIndex",
        botId: 1,
        headerName: "营销线索机器人"
      },
      intentName: "",
      activeClass: null,
      skills: [],
      entityList: [],
      currentEntity: {},
      activeName: "first",
      stretchValue: false,
      disabled_input: true,
      addIntentDiaVisible: false, // 点击添加意图，弹窗状态
      displayWhole: false, // 显示全部内容
      intentTableData: [],
      // 分页
      currentPage: 1,
      pageSize: 15,
      total: 50,
      isEdit: null,
      deleteVisible: false,
      isOpen: null
    };
  },
  components: {
    BotNameAndTolick,
    AddIntntDia,
    DispalyWhole,
    BotSetting
  },
  methods: {
    goTo() {
      this.$router.push({
        name: this.activePageInfo.gotoPageName
      });
    },
    switchClass(classId) {
      this.activeClass = classId;
      this.getEntityList(classId);
    },
    botHandleCommand() {},
    gotoBotIntent() {},
    addIntent() {
      this.isEdit = false;
      this.currentEntity = {
        id: null,
        skillId: this.activeClass,
        type: "DICTIONARY",
        isDefault: this.isDefault,
        content: [{ standardWord: "", synonyms: "" }]
      };
      this.addIntentDiaVisible = true;
    },
    edit(index) {
      this.isEdit = true;
      this.currentEntity = Object.assign({}, this.entityList[index]);
      this.currentEntity.skillId = this.activeClass;
      this.addIntentDiaVisible = true;
    },
    deleteEntity(index) {
      this.deleteVisible = true;
      this.currentEntity = this.entityList[index];
      this.currentEntity.index = index;
    },
    deleteEntityOk() {
      this.FetchDelete(
        this.requestUrl.entity.deleteEntity,
        this.currentEntity.id
      )
        .then(res => {
          if (res.code === "0") {
            this.entityList.splice(this.currentEntity.index, 1);
            this.deleteVisible = false;
            this.getEntitySkills();
          } else {
            console.log("deleteEntityOk", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteEntityCancel() {
      this.deleteVisible = false;
    },
    openStatusChange(index) {
      this.isOpen = this.entityList[index].isOpen;
      if (this.isOpen) {
        let current = this.entityList[index];
        this.FetchPut(this.requestUrl.entity.updateEntity, current.id, current)
          .then(res => {
            if (res.code === "0") {
            } else {
              console.log("openStatusChangeConfirm", res);
              this.$message.error(res.message);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.entityList[index].isOpen = !this.entityList[index].isOpen;
        this.entityList[index].visible = true;
      }
    },
    openStatusChangeCancel(index) {
      let current = this.entityList[index];
      current.visible = false;
      this.entityList.splice(index, 1, current);
    },
    openStatusChangeConfirm(index) {
      let current = this.entityList[index];
      current.isOpen = this.isOpen;
      this.FetchPut(this.requestUrl.entity.updateEntity, current.id, current)
        .then(res => {
          if (res.code === "0") {
            current.visible = false;
            this.entityList.splice(index, 1, current);
          } else {
            console.log("openStatusChangeConfirm", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickDisplayWhole(index) {
      console.log("clickDisplayWhole");
      this.currentEntity = Object.assign({}, this.entityList[index]);
      this.displayWhole = true;
    },
    changeDisplayWholeVisible(value) {
      this.displayWhole = value;
    },
    changeAddIntentVisible(value) {
      this.addIntentDiaVisible = value;
    },
    afterSave(value) {
      this.getEntitySkills();
      if (this.isEdit) {
        let i = this.entityList.indexOf(value);
        if (i != -1) {
          this.entityList.splice(i, 1, value);
        }
      } else {
        this.entityList.push(value);
      }
    },
    // 跳转意图操作页
    gotoIntentHandle(id) {
      console.log(id);
      this.$router.push({
        name: "intentQa"
      });
    },
    // 分页操作
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    getEntitySkills() {
      this.FetchGet(this.requestUrl.entity.getEntitySkills, {
        isDefault: this.isDefault
      })
        .then(res => {
          if (res.code === "0") {
            this.skills = res.data;
            if (this.skills) {
              if (this.activeClass == null) {
                this.activeClass = this.skills[0].skillId;
              }
              this.getEntityList();
            }
          } else {
            console.log("getEntitySkills", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          this.$message(err);
        });
    },
    getEntityList() {
      let params = {
        isDefault: this.isDefault,
        skillId: this.activeClass
      };
      this.FetchGet(this.requestUrl.entity.getEntityList, params)
        .then(res => {
          if (res.code === "0") {
            this.entityList = res.data.list;
            if (this.entityList) {
              this.entityList.forEach(i => this.$set(i, "visible", false));
              this.entityList.forEach(item => {
                item.uTime = this.dateFormat('YYYY-mm-dd HH:MM:SS', (new Date(item.updateTime)));
                item.cTime = this.dateFormat('YYYY-mm-dd HH:MM:SS', (new Date(item.createTime)));
              });
            }
            this.entityList = [...this.entityList];
          } else {
            console.log("getEntityList", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log("getEntityList", err);
        });
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    search(value) {
      let params = {
        isDefault: this.isDefault,
        name: value,
        skillId: this.activeClass
      };
      this.FetchGet(this.requestUrl.entity.getEntityList, params)
        .then(res => {
          if (res.code === "0") {
            this.entityList = res.data.list;
          } else {
            console.log("getEntityList", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log("getEntityList", err);
        });
    }
  },
  mounted() {
    // 获取skills列表
    this.getEntitySkills();
  }
};
</script>
<style lang="less">
@import "./../../assets/less/entity/entityBase.less";
</style>

<style lang="less">
#deleteConfirm {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  .el-dialog {
    margin: 0 auto !important;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    .el-dialog__header {
      .el-dialog__title {
        font-family: "微软雅黑 Bold", "微软雅黑";
        font-weight: 700;
        font-style: normal;
        font-size: 16px;
        color: #666666;
      }
    }
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0 !important;
      z-index: 1;
      overflow: hidden;
      .content {
        font-family: "Microsoft Tai Le";
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #aaaaaa;
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        margin: 20px 0px;
        padding-right: 20px;
      }
    }
  }
}
</style>

