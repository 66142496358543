<template>
    <div id="choose-keyword-intent">
        <popup @closeEvent="closeEvent" v-if="openChooseIntentDialog">
            <div slot="popup-name">关联意图</div>
            <div slot="popup-tip">设置关联词关联意图</div>
            <div slot="popup-con-c" class="popup-con-c">
                <div class="popup-content">
                    <div class="keyword-list">
                        <div class="keyword-list-title">关键词：</div>
                        <div class="keyword-list-content" v-if="keywordList && keywordList.length > 0">
                            <div class="keyword-item" v-for="(item,index) in keywordList" :key="index">{{item}}</div>
                        </div>
                        <div class="keyword-list-content no-data" v-else>暂无关键词</div>
                    </div>
                    <div class="choose-checked-intent-box">
                        <div class="keyword-ecommend-content-left">
                            <div class="choose-intent-title">意图选择</div>
                            <div class="choose-intent-box">
                                <el-input
                                    placeholder="搜索意图名字"
                                    v-model="filterText">
                                </el-input>
                                 <el-tree
                                    :filter-node-method="filterNode"
                                    ref="chooseIntentTree"
                                    :data="intentTree"
                                    show-checkbox
                                    node-key="id"
                                    empty-text="暂无意图，请先添加意图"
                                    :props="defaultProps"
                                    @node-click="nodeClick"
                                    @check-change="checkChange">
                                    <span style="justify-content: flex-start;" :class="['custom-tree-node',data.typeStr == 'INTENT'?'INTENT-no-icon' : '']" slot-scope="{ node, data }">
                                        <span
                                            v-if="data.typeStr == 'BOT'"
                                            class="pre-icon-type iconfont guoran-tongyichicun-jiqirentouxiang"
                                        ></span>
                                        <span
                                            v-if="data.typeStr == 'SKILL'"
                                            class="pre-icon-type iconfont guoran-tongyichicun-zujianzhishiku"
                                        ></span>
                                        <span
                                            v-if="data.typeStr == 'TYPE'"
                                            class="pre-icon-type iconfont guoran-tongyichicun-morenfenlei"
                                        ></span>
                                        <span
                                            v-if="data.typeStr == 'INTENT'"
                                            class="pre-icon-type el-icon-tickets"
                                        ></span>
                                        <span>{{ node.label }}</span>
                                    </span>
                                </el-tree>
                            </div>
                        </div>
                        <div class="keyword-ecommend-content-right">
                            <div class="keyword-ecommend-content-right-title">
                                <i class="iconfont guoran-tongyichicun-jiqirentouxiang"></i>
                                <span>AskBot智能助手</span>
                            </div>
                            <div class="checked-intent">
                                <div class="checked-intent-title">已关联</div>
                                <div class="checked-intent-box">
                                    <template v-if="checkedIntent && checkedIntent.length > 0">
                                        <div class="checked-intent-item" v-for="(item,index) in checkedIntent" :key="item.id">
                                            <div class="intent-names one-column-ellipsis">{{item.name}}</div>
                                            <i class="guoran-tongyichicun-16-09-shanchu2 iconfont" @click="delIntent(item,index)"></i>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="no-data">暂无关联意图</div>
                                    </template>
                                </div>
                            </div>
                            <div class="checked-intent recommend-intent">
                                <div class="checked-intent-title">推荐您关联以下意图</div>
                                <div class="checked-intent-box">
                                    <template v-if="recommendIntent && recommendIntent.length > 0">
                                        <div class="checked-intent-item" v-for="(item,index) in recommendIntent" :key="item.id">
                                            <div class="intent-names one-column-ellipsis">{{item.name}}</div>
                                            <i class="el-icon-success" v-if="item.checked" @click="switchStatus(item,index,'no-checked')"></i>
                                            <div class="no-checked" v-else @click.stop="switchStatus(item,index,'checked')"></div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="no-data">暂无推荐意图</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button round @click="closeEvent">取消</el-button>
                <el-button type="primary" round @click="saveBtn">保存</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import OpenData from '../../../components/openData.vue'
import popup from '../../../components/popupNew.vue'
export default {
    components:{popup,OpenData},
    props:{
        openChooseIntentDialog:{
            type:Boolean,
            default:false
        },
        checkedData:{
            type:Array,
            default(){
                return[]
            }
        },
        keywordList:{
            type:Array,
            default(){
                return[]
            }
        },
        intentTree:{
            type:Array,
            default(){
                return[]
            }
        },
        checkedIntentList:{
            type:Array,
            default(){
                return[]
            }
        },
        keywordsStr:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            defaultExpandedKeys:[],
            defaultProps: {
                children: "children",
                label: "name",
            },
            checkedIntent:[],
            recommendIntent:[],
            filterText:""
        }
    },
    watch:{
        openChooseIntentDialog(n){
            if(n){
                this.filterText = '';
                this.getRecommendIntent();
            }
        },
        intentTree:{
            handler(n){
                console.log(n,'nnnnnn');
                if(n && n.length > 0){
                    this.defaultExpandedKeys = n[0].id
                }
            },
            deep:true
        },
        keywordList:{
            handler(n){
                console.log(n,'keywordListkeywordListkeywordList');
            },
            deep:true
        },
        filterText(val) {
            this.$refs.chooseIntentTree.filter(val);
        }
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 回显选中处理
        handlerChecked(){
            
        },
        getRecommendIntent(){
            console.log(this.keywordsStr);
             this.FetchGet(this.requestUrl.bot.intent4keyword + "/" + this.$route.query.id,{
                    keywords:this.keywordsStr
                }).then(res => {
                    if (res.code === "0") {
                        console.log(this.checkedIntentList,444444);
                       res.data && res.data.length > 0 && res.data.forEach((item,index) => {
                            item.dataType = 'recommend';
                            item.typeStr = 'recommend';
                            
                       })
                        this.checkedIntent = []; 
                        this.recommendIntent = res.data || [];
                        if(this.checkedIntentList.length > 0){
                            this.$nextTick(() => {
                                this.checkedIntentList.forEach((item,index) => {
                                    // if(item.dataType === 'recommend' ){
                                        if(this.recommendIntent.length > 0){
                                            this.recommendIntent.forEach((v,i) => {
                                                if(item.id == v.id){
                                                    v.checked = true;
                                                    this.checkedIntent.push(v);
                                                }
                                            })
                                        }
                                        
                                    // } else {
                                        this.$refs.chooseIntentTree.setChecked(item.id,true,false)
                                    // }
                                })
                            })
                        }
                       
                    } else {
                        this.$message.error(res.message);
                    }
                })
        },
        closeEvent(){
            this.$emit('checkedIntent','close')
        },
        saveBtn(){
            this.$emit('checkedIntent','save',this.checkedIntent)
        },
        // 删除已选中意图
        delIntent(item,index){
            if(item.dataType === 'treeIntent'){
                this.$refs.chooseIntentTree.setChecked(item,false,false)
            } else {
                this.recommendIntent.forEach((v,i) => {
                    if(v.id === item.id){
                        v.checked = false;
                    }
                })
            }
            this.$refs.chooseIntentTree.setChecked(item.id,false,false)
            this.checkedIntent.splice(index,1);
        },
        // 切换选中状态
        switchStatus(item,index){
            item.checked = !item.checked;
            console.log( item.checked,'---');
            if(item.checked){
                let flag = false;
                this.checkedIntent.forEach((v,i) => {
                    if(v.id == item.id){
                        flag = true;
                    }
                })
                if(!flag){
                    this.checkedIntent.push(item);
                    this.$refs.chooseIntentTree.setChecked(item.id,true,false)
                }
            } else {
                let ind = -1;
                this.checkedIntent.forEach((v,i) => {
                    if(v.id == item.id){
                        ind = i;
                    }
                })
                this.$refs.chooseIntentTree.setChecked(item.id,false,false)
                this.checkedIntent.splice(ind,1);
            }
        },
        nodeClick(){
            console.log(55555555);
        },
        handlerList(arr){
           for(let i=0; i<arr.length; i++){
                for(let j=i+1; j<arr.length; j++){
                    if(arr[i]==arr[j]){ //第一个等同于第二个，splice方法删除第二个
                        arr.splice(j,1);
                        j--;
                    }
                }
            }
            return arr
        },
        // 树节点选中
        checkChange(data,node,el){
            console.log(data,node,el,1111);
            console.log(this.$refs.chooseIntentTree.getCheckedNodes(),'获取选中数据');
            console.log(this.checkedIntent,222222);
            let checkedList = [];
            this.$refs.chooseIntentTree.getCheckedNodes()&&this.$refs.chooseIntentTree.getCheckedNodes().length > 0&&this.$refs.chooseIntentTree.getCheckedNodes().forEach((item,index) => {
                if(item.typeStr === 'INTENT'){
                    checkedList.push(item);
                }
            });
            let _list = [];
            if(this.checkedIntent.length > 0){
                this.checkedIntent.forEach((item,index) => {
                    if(checkedList.length > 0){
                        checkedList.forEach((v,i) => {
                            if(item.id == v.id){
                                // this.checkedIntent.splice(i,1);
                            } else {
                                _list.push(v);
                            }
                        })
                    } else {
                        _list = [];
                    }
                    this.recommendIntent.forEach((v,i) => {
                        if(v.id == item.id){
                            this.$set(v,'checked',true)
                        }
                    })
                })
            } else {
                _list = checkedList;
            }
            this.checkedIntent = this.handlerList([...checkedList,..._list])
            console.log(this.checkedIntent,'处理过的选中数据',this.checkedIntent);
            if(this.checkedIntent.length > 0){
                 this.recommendIntent.forEach((v,i) => {
                    this.$set(v,'checked',false)
                })
                this.checkedIntent.forEach((item,index) => {
                    this.recommendIntent.forEach((v,i) => {
                        if(v.id == item.id){
                            this.$set(v,'checked',true)
                        }
                    })
                })
            } else  {
                this.recommendIntent.forEach((v,i) => {
                    v.checked = false;
                })
                this.$refs.chooseIntentTree.setCurrentKey(null);
            }
            console.log(this.checkedIntent,44444444);
        },
    }
}
</script>

<style scoped lang="less">
#choose-keyword-intent{
    /deep/ #popup-assembly-new{
        width: 856px !important;
    }
   .keyword-list{
        height: 25px;
        margin-bottom: 15px;
        display: flex;
        line-height: 25px;
        width: 100%;
        overflow: hidden;
        .keyword-list-title{
           flex-shrink: 0; 
           width: 70px;
        }
       .keyword-list-content{
            display: flex;
            width:calc(100% - 70px);
            overflow-x: auto;
            overflow-y: hidden;
            // height: 28px;
            .keyword-item{
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                border-radius: 12px; 
                color: #366AFF;
                font-size: 14px;
                line-height: 25px;
                padding: 0px 12px;
                margin-right: 8px;
                padding-bottom: 10px;
                white-space: nowrap;
            }
            // 滚动条样式
            &::-webkit-scrollbar {
                width: 2px;
                height: 6px;
                /* background-color:#E7ECF6;*/
                background-color: rgba(0,0,0,0);
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color:#BFCEEC;
            }
            &.no-data{
                color: #A9B3C6;
                font-size: 14px;
            }
        }
   }
   .choose-checked-intent-box{
       display: flex;
       .keyword-ecommend-content-left,.keyword-ecommend-content-right{
            width: 358px;
            background: #FBFCFD;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-height: calc(84vh - 246px);
            min-height: 438px;
            .choose-intent-title{
                height: 40px;
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #F5F7FB;
                border-bottom: 1px solid #E0E6F7;
                border-radius: 5px 5px 0 0;
            }
            .choose-intent-box{
                padding:20px;
                flex: auto;
                height: 0px;
                padding-bottom: 10px;
                /deep/ .el-input{
                    margin-bottom: 10px;
                    .el-input__inner{
                        height: 36px !important;
                        line-height: 36px !important;
                    }
                }
            }
            /deep/ .el-tree{
                height: calc(100% - 50px);
                overflow-y: auto;
                background-color:transparent;
                .pre-icon-type {
                    color: #A1B9FF;
                    display: inline-block;
                    margin: 0 6px 0 4px;
                    font-size: 14px;
                }
                .el-tree-node__content{
                    height: 30px;
                }
                .iconfont,.el-icon-tickets{
                    font-size: 14px;
                }
                .el-tree-node__expand-icon{
                    color: #616161;
                }
                .el-checkbox__inner{
                    border-radius: 4px;
                    // width: 16px;
                    // height: 16px;
                }
                .is-leaf{
                    // display: none;
                    color: #fff;
                }
                .el-tree__empty-block{
                    white-space: nowrap;
                }
            }
        }
        .keyword-ecommend-content-right{
            // max-height: calc(84vh - 300px);
            background: #F5F7FB;
            margin-left: 20px;
            padding: 0 30px;
            // min-height: 378px;
            overflow-y: auto;
            .keyword-ecommend-content-right-title{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                line-height: 34px;
                margin-bottom: 20px;
                margin-top: 30px;
                .guoran-tongyichicun-jiqirentouxiang{
                    margin-right: 5px;
                }
            }
            .no-data{
                height: 126px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #A9B3C6;
                font-size: 14px;
            }
            .checked-intent{
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 3px;
                .checked-intent-title{
                    height: 38px;
                    background: #F5F8FF;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #366AFF;
                    text-align: center;
                    line-height: 38px;
                }
                .checked-intent-box{
                    height: 126px;
                    overflow-x: hidden;
                    overflow-y: auto;
                   .checked-intent-item{
                        height: 42px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 0px 0px #E0E6F7;
                        padding: 0 10px 0 15px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #616161;
                        font-size: 14px;
                        border-bottom: 1px solid #E0E6F7;
                        .intent-names{
                            width: 250px;
                            text-align: left;
                        }
                        .iconfont,.el-icon-success{
                            color: #366AFF;
                            cursor: pointer;
                        }
                        &:last-child{
                            border: none;
                        }
                   }
                }
                &.recommend-intent{
                    margin-top: 16px;
                    margin-bottom: 30px;
                    .checked-intent-title{
                        background: #366AFF;
                        color: #fff;
                    }
                    .el-icon-success{
                       font-size: 20px; 
                    }
                    .no-checked{
                        width: 15px;
                        height: 15px;
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        border-radius: 50%;
                        cursor: pointer;
                        margin-left: -3px;
                    }
                }
            }
        }
   }    
    
}
</style>