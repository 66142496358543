<template>
	<div class="intent-statistics">
		<el-drawer
            class="drawer-statistics"
            key="intent-statistics"
            title="意图更新统计"
            size="1081px"
            show-close
            direction="rtl"
            :visible.sync="drawerStatistics"
            :close-on-press-escape="false"
            :wrapperClosable="false"
            :with-header="true">
            <div class="notice-drewer-header">
                <div class="title">意图更新统计</div>
                <div class="close">
                    <span @click="handleCloseStatistics" class="el-icon-close"></span>
                </div>
            </div>
            <div class="statistics-content">
                <div class="search-top">
                    <fastChooseDatePicker :key="openNum" v-model="dateValue" @changeDate="getSearchStatisticsTable" :type="'datetimerange'"></fastChooseDatePicker>
                    <div class="right-select-intent">
                        <div class="default-choose-all" v-if="takeEffectIntentId.length === 0 || !takeEffectIntentId">
                            <span>全部意图</span>
                            <!-- <i class="el-icon-error" @click="delAll"></i> -->
                        </div>
                        <el-cascader
                            collapse-tags
                            v-model="takeEffectIntentId"
                            filterable
                            placeholder=""
                            @change="changeCascader"
                            :options="intentTree4Radio"
                            :props="{
                                multiple:true,
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                </div>
                <div class="show-statistics-num">
                    <div class="show-statistics-num-left">
                        <div class="add-intent-num">
                            <div :class="['num-text',Number(statisticsObj.totalAddNum) > 99 ? 'max' : '']">{{statisticsObj.totalAddNum || 0}}</div>
                            <div class="text-tips">新增意图</div>
                        </div>
                        <div class="other-num">
                            <div class="other-num-text">{{statisticsObj.totalDelNum || 0}}</div>
                            <div class="text-tips">删除</div>
                        </div>
                        <div class="other-num">
                            <div class="other-num-text">{{statisticsObj.totalOpenNum || 0}}</div>
                            <div class="text-tips">开启</div>
                        </div>
                        <div class="other-num">
                            <div class="other-num-text">{{statisticsObj.totalCloseNum || 0}}</div>
                            <div class="text-tips">关闭</div>
                        </div>
                    </div>
                    <div class="show-statistics-num-right">
                        <div class="add-intent-num qa">
                            <div :class="['num-text',Number(statisticsObj.totalQuestionUpdateNum)  > 99 ? 'max' : '']">{{statisticsObj.totalQuestionUpdateNum || 0}}</div>
                            <div class="text-tips">问法变更</div>
                        </div>
                        <div class="add-intent-num rule">
                            <div :class="['num-text',Number(statisticsObj.totalRuleUpdateNum)  > 99 ? 'max' : '']">{{statisticsObj.totalRuleUpdateNum || 0}}</div>
                            <div class="text-tips">规则变更</div>
                        </div>
                        <div class="add-intent-num session">
                            <div :class="['num-text',Number(statisticsObj.totalProcessUpdateNum)  > 99 ? 'max' : '']">{{statisticsObj.totalProcessUpdateNum || 0}}</div>
                            <div class="text-tips">对话流程变更</div>
                        </div>
                    </div>
                </div>
                <el-table
                    :height="statisticsTableTop"
                    class="statistics-table"
                    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:600}"
                    :data="statisticsObj.intentUpdateCases"
                    style="width: 100%">
                    <el-table-column
                        prop="date"
                        width="350"
                        label="意图">
                        <template slot-scope="scope">
                            <div class="statistics-intent-name">
                                <div class="name one-column-ellipsis">{{scope.row.intentName}}</div>
                                <div class="intent-classifi" v-if="scope.row.skillName && scope.row.typeName">{{scope.row.skillName}}/{{scope.row.typeName}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="意图最新状态">
                        <template slot-scope="scope">
                            <div class="intent-add-status add" v-if="scope.row.intentStatus === '新增'">新增</div>
                            <div class="intent-add-status close" v-else-if="scope.row.intentStatus === '关闭'">关闭</div>
                            <div class="intent-add-status delete" v-else-if="scope.row.intentStatus === '删除'">删除</div>
                            <div class="intent-add-status open" v-else-if="scope.row.intentStatus === '开启'">开启</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="问法变更">
                        <template slot="header" slot-scope="slot">
                            <span class="strategy-column">问法变更</span>
                            <el-tooltip class="item" effect="dark" content="统计时间内，问法个数的变更情况，正数表示问法增加，负数表示问法减少。" placement="top-start">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <div class="intent-add-status-num add" v-if="scope.row.intentStatus === '新增'">{{scope.row.intentQuestionUpdate || 0}}</div>
                            <div class="intent-add-status-num close" v-else-if="scope.row.intentStatus === '删除' || scope.row.intentStatus === '开启'">{{scope.row.intentQuestionUpdate || 0}}</div>
                            <div class="intent-add-status-num delete" v-else-if="scope.row.intentStatus === '关闭'">{{scope.row.intentQuestionUpdate || 0}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="规则变革">
                        <template slot="header" slot-scope="slot">
                            <span class="strategy-column">规则变更</span>
                            <el-tooltip class="item" effect="dark" content="统计时间内，规则个数的变更情况，正数表示规则增加，负数表示规则减少。" placement="top-start">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <div class="intent-add-status-num add" v-if="scope.row.intentStatus === '新增'">{{scope.row.intentRuleUpdate || 0}}</div>
                            <div class="intent-add-status-num close" v-else-if="scope.row.intentStatus === '删除' || scope.row.intentStatus === '开启'">{{scope.row.intentRuleUpdate || 0}}</div>
                            <div class="intent-add-status-num delete" v-else-if="scope.row.intentStatus === '关闭'">{{scope.row.intentRuleUpdate || 0}}</div>
                        </template>
                    </el-table-column>
                     <el-table-column
                        prop="address"
                        label="对话流程变更">
                        <template slot-scope="scope">
                            <div class="intent-add-status-num add" v-if="scope.row.intentStatus === '新增' || scope.row.intentStatus === '关闭'">{{scope.row.processUpdateNum || 0}}</div>
                            <div class="intent-add-status-num close" v-else-if="scope.row.intentStatus === '删除' || scope.row.intentStatus === '开启'">{{scope.row.processUpdateNum || 0}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-drawer>
	</div>
</template>

<script>
import fastChooseDatePicker from './fastChooseDatePicker.vue'
export default {
	data(){
		return{
			statisticsObj:{
                totalAddNum: 1,
                totalCloseNum: 0,
                totalDelNum: 0,
                totalOpenNum: 0,
                totalProcessUpdateNum: 0,
                totalQuestionUpdateNum: 1,
                totalRuleUpdateNum: 0,
                intentUpdateCases:[],
            },
            statisticsTableTop:0,
            statisticalParams:{
                botId:'',
                startDay:'',
                endDay:'',
                intentIds:[],
            },
            openNum:1,
			dateValue:'',
            suspensionOperationIconTop:null,
            testDrawerVisibleP:false,
            intentTree4Radio: [],
            takeEffectIntentId:[],
		}
	},
	components:{
		fastChooseDatePicker
	},
	props:{
		drawerStatistics:{
			type:Boolean,
			default:false
		},
	},
	watch:{
		drawerStatistics(n){
			if(n){
				this.openNum++
				this.statisticsTableTop = 'calc(100vh - 310px)'
				this.getIntentListByBotId(this.$route.query.id);

				let givenTime =  new Date('2022-08-17').getTime(new Date('2022-08-17'));
				let end = new Date();
				// 获取当前周的第一天
				let start = new Date(
					end.getFullYear(),
					end.getMonth(),
					end.getDate() - ((end.getDay() + 6) % 7)
				);
				if(start.getTime() < givenTime){
					start = this.startTime(givenTime);;
				} else {
					start.setTime(start.getTime());
				}
				end = new Date();
				this.dateValue = [start,end];


				this.takeEffectIntentId = [];
				this.getSearchStatisticsTable();
			}
		}
	},
	methods:{
		 // time为某一天的时间戳  获取任意一天的开始时间
		startTime(time) {
			const nowTimeDate = new Date(time)
			return new Date(nowTimeDate.setHours(0, 0, 0, 0))
		},
		// 获取任意一天的结束时间
		endTime(time) {
			const nowTimeDate = new Date(time)
			return new Date(nowTimeDate.setHours(23, 59, 59, 999))
		},
		 // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId) {
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId }).then((res) => {
                if (res.code === "0") {
                    this.intentTree4Radio = [];
                    res.data.forEach((item) => {
                        if (item.name != "特殊规则") {
                            this.intentTree4Radio.push(item);
                        }
                    });
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        },
		getSearchStatisticsTable(){
            let givenTime =  new Date('2022-08-17').getTime(new Date('2022-08-17'));
			console.log(givenTime,'negivenTimewDate-------');
			const newDate = new Date(new Date(givenTime).setHours(0, 0, 0, 0)).getTime();
            let params = {
                botId:this.$route.query.id,
                startDay:'',
                endDay:'',
                intentIds:this.takeEffectIntentId || [],
            }
            if(this.dateValue !== '' && this.dateValue.length === 2){
                params.startDay = this.dateValue[0].getTime();
                params.endDay = this.dateValue[1].getTime();
            }
            if(params.endDay < newDate){
                this.$message.closeAll()
                this.$message.warning('仅支持2022年-8月-16日后的统计');
				// params.endDay = newDate;
            } 
			this.statisticalParams = params;
			this.getStaticData();
        },
        getStaticData(){
            this.FetchPost(this.requestUrl.skill.getIntentStatistical,this.statisticalParams).then( res =>{
                if(res.code == 0 ){
                    this.statisticsObj = res.data;
                    this.$nextTick(() => {
                        let top = document.querySelector('.statistics-table').offsetTop + 26;
                        this.statisticsTableTop = 'calc(100vh - ' + top + 'px'
                    })
                }else{
                    this.$message.error(res.message);
                }
            })
            .catch( err => {
                console.log(err)
            })
        },
		changeCascader(){
            this.statisticalParams.botId = this.$route.query.id;
            this.statisticalParams.intentIds = this.takeEffectIntentId || [];
            this.getStaticData();
        },
		handleCloseStatistics(){
			this.$emit('update:drawerStatistics',false)
        },
	}
}
</script>

<style lang="less" scoped>
.intent-statistics{
	.drawer-statistics{
		/deep/ .el-drawer{
			.el-drawer__header{
				height: 0;
				padding: 0;
				margin: 0;
				overflow: hidden; 
			}
			.statistics-content{
				background-color: #fff;
				padding: 26px 32px;
				width: 100%;
				.search-top{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 23px;
					.el-date-editor{
						width: 502px;
					}
					.right-select-intent{
						width: 502px;
						flex: auto;
						margin-left: 8px;
						margin-left: 0;
						position: relative;
						.default-choose-all{
							position: absolute;
							top: 8px;
							left: 14px;
							z-index: 2;
							display: flex;
							align-items: center;
							max-width: 100%;
							text-overflow: ellipsis;
							background: #f0f2f5;
							height: 24px;
							padding: 0 5px;
							line-height: 24px;
							border-radius: 3px;
							span{
								overflow: hidden;
								text-overflow: ellipsis;
								color: #909399;
								font-size: 12px;
							}
							i{
								color: #C0C4CC;
								font-size: 13px;
								cursor: pointer;
								font-size: 12px;
								top: -1px;
								right: -5px;
								margin-left: 5px;
								&:hover{
									color: #909399;
								}
							}
						}
					
						.el-select,.el-cascader {
							width: 502px;
							.el-cascader__search-input{
								display: none;
							}
							.el-tag {
								max-width: 85%;
							}
						}
					}
				}
				.show-statistics-num{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 24px;
					.show-statistics-num-left,.show-statistics-num-right{
						width: 502px;
						height: 99px;
						background: #749BFF;
						box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.0700);
						border-radius: 5px;
						display: flex;
						align-items: center;
						.add-intent-num,.other-num{
							width: 154px;
							border-right: 1px solid #FFFFFF;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content:center;
							.num-text{
								display: flex;
								justify-content: center;
								align-items: center;
								padding:0 15px;
								height: 40px;
								background: #87A9FF;
								border-radius: 50%;
								font-size: 18px;
								margin-bottom: 11px;
								color: #FFFFFF;
								&.max{
									border-radius: 20px;
								}
							}
							.text-tips{
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
							}
							.other-num-text{
								height: 40px;
								line-height: 40px;
								margin-bottom: 11px;
								font-size: 18px;
								color: #FFFFFF;
							}
							&.other-num{
								border: none;
							}
						}
					}
					.show-statistics-num-right{
						width: 502px;
						display: flex;
						background-color: transparent;
						justify-content: space-between;
						.qa{
							width: 159px;
							height: 99px;
							background: #68DDD5;
							box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.0700);
							border-radius: 5px;
							.num-text{
								background-color: #89E5DF;
							}
						}
						.rule{
							width: 159px;
							height: 99px;
							background: #FB9D84;
							box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.0700);
							border-radius: 5px;
							.num-text{
								background-color: #FFAD97;
							}
						}
						.session{
							width: 159px;
							height: 99px;
							background: #A198F9;
							box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.0700);
							border-radius: 5px;
							.num-text{
								background-color: #AFA8FA;
							}
						}
					}
				}
				.el-table{
					.el-icon-warning-outline{
						margin-left: 2px;
					}
					.statistics-intent-name{
						.name{
							color: #000;
							font-size: 14px;
							margin-bottom: 6px;
						}
						.intent-classifi{
							color: #A9B3C6;
							font-size: 12px;
						}
					}
					.intent-add-status-num,.intent-add-status{
						height: 29px;
						width: 40px;
						background: #ECF1FF;
						border-radius: 14px;
						text-align: center;
						line-height: 29px;
						white-space: nowrap;
						&.intent-add-status{
							width: 45px;
							height: 22px;
							border-radius: 11px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						&.add{
							background: #ECF1FF;
							color: #366AFF;
						}
						&.close{
						background: #EEEEEE; 
						color: #9DA5BF;
						}
						&.delete{
							background: #FFEBEC; 
							color: #FE5965;
						}
						&.open{
							background: #FFEEE0; 
							color: #FF7200;
						}
					}
				}
			}
		}
	}
}

</style>