var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action_Jump"},[_c('div',{staticClass:"goto-list"},[_c('div',{staticClass:"jump-cell"},[_c('div',{staticClass:"jump-cell-l"},[_c('div',{staticClass:"left-select"},[_c('el-select',{staticStyle:{"width":"120px"},attrs:{"size":"small","placeholder":"请选择"},on:{"change":_vm.jumpTypeValueChange},model:{value:(_vm.unit.content.scope),callback:function ($$v) {_vm.$set(_vm.unit.content, "scope", $$v)},expression:"unit.content.scope"}},_vm._l((_vm.filterJumpTypeOptions(
                                _vm.jumpTypeOptions
                            )),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"right-select"},[(_vm.unit.content.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":_vm.unit.content.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionJumpAddnode_${_vm.com_index}`,"intentId":_vm.intentId,"index":0},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit.content.scope === 'INTENT'),expression:"unit.content.scope === 'INTENT'"}],attrs:{"placeholder":"请选择意图","size":"small","options":_vm.intentTree4Radio,"props":{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }},model:{value:(_vm.unit.content.value),callback:function ($$v) {_vm.$set(_vm.unit.content, "value", $$v)},expression:"unit.content.value"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }